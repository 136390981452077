<div class="activities-updater-control" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="activities-updater-header">
    <h1 mat-dialog-title class="dialog-header">Progress Campaign Activities</h1>
  </div>
  @if (!saveInProgress) {
    <div class="activities-updater-content">
      <div class="form-group">
        <div style="width: 18rem; display: flex; flex-direction: column;">
          <div class="activity-status-wrapper activity-status">
            <div class="status-header ">
              Activity Status
            </div>
            <kendo-dropdownlist [(ngModel)]="currentActivityStatus"
              [ngModelOptions]="{standalone: true}"
              [ngClass]="activityStatusClass"
              [data]="statuses"
              [textField]="'Name'"
              [valueField]="'Value'"
              [valuePrimitive]="false"
              [size]="'small'"
              [fillMode]="'flat'"
              [rounded]="'none'"
              (valueChange)="handleActivityStatusChange($event)"
              style="padding: 3px; font-size: 1rem">
            </kendo-dropdownlist>
            <div class="held-date-wrapper">
              <label class="held-date-label">{{heldUntilText}}</label>
              @if (heldUntilText) {
                <kendo-datepicker [size]="'small'" [fillMode]="'flat'" class="held-date" [ngModelOptions]="{standalone: true}" [(ngModel)]="OnHoldDate" placeholder="" (valueChange)="heldDateChange($event)" [disabled]="disableHeldDate">
                </kendo-datepicker>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="span-all-grid-columns" style="margin-left: 0.3rem">
        <fieldset>
          <legend>Credit Line</legend>
          <div class="credit-line-grid">
            @if (showCreditLineType) {
              <mat-form-field>
                <mat-label>Type</mat-label>
                <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="CreditLineTypeId">
                  <mat-option [value]="0" selected>None</mat-option>
                  <mat-option [value]="1">New</mat-option>
                  <mat-option [value]="2">Repeat</mat-option>
                  <mat-option [value]="3">Existing</mat-option>
                </mat-select>
              </mat-form-field>
            }
            @if (showCreditLineRotation) {
              <mat-form-field>
                <mat-label>Rotation</mat-label>
                <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="CreditLineRotationTypeId">
                  <mat-option [value]="0" selected>None</mat-option>
                  <mat-option [value]="1">Even</mat-option>
                  <mat-option [value]="2">100%</mat-option>
                  <mat-option [value]="3">Other</mat-option>
                </mat-select>
              </mat-form-field>
            }
            <mat-form-field>
              <mat-label>Credit Line Due Date</mat-label>
              <input matInput [matDatepicker]="creditlineduedate" [ngModelOptions]="{standalone: true}" [(ngModel)]="CreditLineDueDate" placeholder="Credit Line Due Date" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="creditlineduedate"></mat-datepicker-toggle>
              <mat-datepicker #creditlineduedate startView="month" [startAt]="CreditLineDueDate"></mat-datepicker>
            </mat-form-field>
            @if (showCreditLine) {
              <kendo-formfield class="span-all-grid-columns">
                <kendo-floatinglabel text="Credit Line">
                  <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="5" selectOnFocus="true" title="CreditLine" [ngModelOptions]="{standalone: true}" [(ngModel)]="CreditLine" maxlength="2000"></kendo-textarea>
                </kendo-floatinglabel>
                <kendo-formerror>Credit Line is required</kendo-formerror>
              </kendo-formfield>
            }
            @if (showCreditLineNotes) {
              <div class="span-all-grid-columns">
                <kendo-floatinglabel text="Credit Notes">
                  <kendo-textarea [size]="'small'" [fillMode]="'flat'" rows="5" selectOnFocus="true" title="CreditLineNotes" [ngModelOptions]="{standalone: true}" [(ngModel)]="CreditLineNotes" maxlength="2000"></kendo-textarea>
                </kendo-floatinglabel>
              </div>
            }
          </div>
        </fieldset>
      </div>
    </div>
  }
  @if (saveInProgress) {
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
      @if (saveInProgress) {
        <label class="spinner-loading-label">Progressing Activities, please wait...</label>
      }
    </div>
  }
  <div class="activities-updater-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button [disabled]="saveInProgress" (click)="onCancelClick()">Close</button>
        <button mat-stroked-button [disabled]="saveInProgress" color="primary" (click)="progressActivities($event)" [disabled]="!activities || activities?.length < 1" title="Click to progress the status and set the Credit Line details for selected activities">Progress Selected Activities</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
