<kendo-grid [data]="view | async"
            class="kendo-grid"
            style="height: 540px; border-radius: 5px;"
            [sort]="gridState.sort"
            [sortable]="false"
            (dataStateChange)="onStateChange($event)"
            (edit)="editHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler()">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand size="small" rounded="medium" fillMode="solid" themeColor="primary">Add extension</button>
    <h3 style="padding-right: 50px;">Total Cost of Extensions: {{commonService.getSymbolForCurrency()}}{{TotalCosts | number:'1.0-0'}}</h3>
  </ng-template>
  <kendo-grid-column field="Id" title="Id" width="90px"></kendo-grid-column>
  <kendo-grid-column field="Extension" title="Extension" width="155px">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-dropdown-filter [filter]="filter"
                             [data]="administrationService.extensions"
                             [textField]="'Name'"
                             [valueField]="'Id'"
                             filterBy="ExtensionId">
      </kendo-dropdown-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem?.Extension?.Name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Deliverables" title="Deliverables">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.Deliverables}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="TotalCost" title="Total Cost" width="155px" filter="number" format="{0:c}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{commonService.getSymbolForCurrency()}}{{dataItem.TotalCost | number:'1.0-0'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ExtensionDate" title="Extension Date" width="155px" filter="date" format="{0:d}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.ExtensionDate | date }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title=" " [width]="220">
    <ng-template kendoGridCellTemplate>
      <button kendoGridEditCommand size="small" rounded="medium" fillMode="solid" [primary]="true">Edit</button>
      <button kendoGridRemoveCommand size="small" rounded="medium" fillMode="solid">Delete</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<activity-extension-edit-form [model]="editDataItem"
                              [isNew]="isNew"
                              (save)="saveHandler($event)"
                              (cancel)="cancelHandler()">
</activity-extension-edit-form>
