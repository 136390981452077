import { DatePipe } from "@angular/common";
import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { IconDefinition, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { injectQueryClient } from '@tanstack/angular-query-experimental';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { ConfirmationDialog } from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { MatTableExporterDirective } from "../../../shared/directives/mat-table-exporter.directive";
import { PropertiesInfo } from '../../../shared/models/common.models';
import { SaveResult } from "../../../shared/models/result.model";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { LoggerService } from '../../../shared/services/logger.service';
import { Category, PropertyType, Station } from "../../calendars/calendars.models";
import { CalendarsService } from "../../calendars/calendars.service";
import { PropertyComponent } from "../../calendars/property/property.component";
import { SearchProperty } from "../search.models";
import { SearchService } from '../search.service';

@Component({
  selector: 'search-properties',
  templateUrl: './search-properties.component.html',
  styleUrls: ['./search-properties.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [SearchService, MatTableCrudService],
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableExporterDirective, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatMenuModule, FaIconComponent, MatPaginatorModule, DatePipe]
})
export class SearchPropertiesComponent extends MatTableCrudComponent<SearchProperty> implements OnInit, OnDestroy
{
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  public faFileExport: IconDefinition = faFileExport;

  private _isOnline = false;
  private _stations: Station[] = [];
  private itemDeletedSubscription: Subscription;

  public IdFilter: any;
  public CategoryIdFilter: any;
  public PropertyNameFilter: any;
  public PropertyTypeIdFilter: any;
  public StationsFilter: any;
  public StartDateFilter: any;
  public EndDateFilter: any;

  private queryClient = injectQueryClient();

  constructor(protected injector: Injector,
    private calendarsService: CalendarsService,
    private configurationService: ConfigurationService,
    private searchService: SearchService,
    private commonService: CommonService,
    private loggerService: LoggerService)
  {
    super(injector,
      PropertyComponent,
      PropertyComponent,
      PropertyComponent,
      "CreateProperty",
      "DeleteProperty",
      "GetSearchProperties",
      "UpdateProperty",
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl);

    if (!this.postPayload)
    {
      this.postPayload = {};
    }

    this.postPayload.stationIds = [];
    this.postPayload.isOnline = false;

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'CategoryName', 'PropertyName', 'PropertyTypeName', 'Stations', 'StartDate', 'EndDate'];
    this.filteredValues = [
      { Field: "Id", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "CategoryId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "PropertyName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "PropertyTypeId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "Stations", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "StartDate", Value: "", Path: "", DataType: FilterDataType.Date },
      { Field: "EndDate", Value: "", Path: "", DataType: FilterDataType.Date }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProperty);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteProperty);
  }

  @Input() get stations(): Station[]
  {
    return this._stations;
  }
  set stations(value: Station[])
  {
    this._stations = value;

    if (!this.postPayload)
    {
      this.postPayload = {};
    }

    const ids: number[] = [];

    if (this._stations && this._stations.length > 0)
    {
      this._stations.map((s) => ids.push(s.Id));
    }

    const valuesChanged = !this.commonService.numberArrayEquals(this.postPayload.stationIds, ids);

    if (valuesChanged)
    {
      this.postPayload.stationIds = ids;

      this.refresh();
    }
  }

  @Input() get isOnline(): boolean
  {
    return this._isOnline;
  }
  set isOnline(val: boolean)
  {
    this._isOnline = val;

    if (!this.postPayload)
    {
      this.postPayload = {};
    }

    const valuesChanged: boolean = this.postPayload.isOnline != this._isOnline;

    if (valuesChanged)
    {
      this.postPayload.isOnline = this._isOnline;

      this.refresh();
    }
  }

  ngOnInit(): void
  {
    super.ngOnInit();

    this.dataSubscription.add(
      this.mtCrudService.OnDataFetched.subscribe((data: any) =>
      {
        data.map((a: SearchProperty) =>
        {
          if (a)
          {
            if (a.StartDate)
            {
              a.StartDate = this.commonService.parseJsonDate(a.StartDate);
            }

            if (a.EndDate)
            {
              a.EndDate = this.commonService.parseJsonDate(a.EndDate);
            }
          }
        });
      })
    );

    this.mtCrudService.OnSaved.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) =>
    {
      if (result && result.IsSuccessful && result.Message.indexOf("deleted") > -1)
      {
        this.calendarsService.resetHttpCache();
        this.calendarsService.resetCalendarData();
        this.calendarsService.dataResetFromPropertySave = true;
      }
    });

    this.itemDeletedSubscription = this.itemDeleted.subscribe((result: any) =>
    {
      if (result)
      {
        this.calendarsService.resetHttpCache();
        this.calendarsService.resetCalendarData();
        this.calendarsService.dataResetFromPropertySave = true;
      }
    });
  }

  deleteItem(event: any, searchProperty: SearchProperty)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this Property (${searchProperty.PropertyName})?`;
    this.deleteHeaderText = "Delete Property?";

    super.deleteItem(event, searchProperty);

    this.queryClient.invalidateQueries({
      type: "all", exact: false, predicate: (query: any) =>
      {
        return query.queryKey[0] == "Properties";
      }
    });

    this.queryClient.invalidateQueries({
      type: "all", exact: false, predicate: (query: any) =>
      {
        return query.queryKey[0] == "Calendar";
      }
    });
  }

  addNew(event: any)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;

        const aItem: any = this.addedItem;

        aItem[this.changedByField as keyof typeof aItem] = this.authorisationService.currentuser.Id;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = aItem;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "52rem";
        dialogConfig.height = "98vh";
        dialogConfig.hasBackdrop = true;

        const dialogRef = this.dialog.open(PropertyComponent, dialogConfig);
        const dialogRefComponentInstance: any = dialogRef.componentInstance;
        dialogRefComponentInstance['mtCrudService' as keyof typeof dialogRefComponentInstance] = this.mtCrudService;

        this.addCloseSubscription = dialogRef.afterClosed().subscribe(result =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            // After dialog is closed we're doing frontend updates
            // For add we're just pushing a new row inside DataService
            this.mtCrudService.dataChange.value.push(this.mtCrudService.getDialogData());

            this.refreshTable();
            this.refresh();

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromPropertySave = true;
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  propertyHover(event: any, id: number)
  {
    fromEvent(event.target, event.type)
      .pipe(
        debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
        distinctUntilChanged()
      ).subscribe(() =>
      {
        if (event)
        {
          if (event.stopPropagation)
          {
            // stop event bubbling up 
            event.stopPropagation();
          }

          //prevents browser from performing the default action for this element
          event.preventDefault();
        }

        this.queryClient.prefetchQuery({
          queryKey: ["Property", id],
          queryFn: () => this.calendarsService.getProperty(id),
          //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
        });
      });
  }

  editItem(event: any, Property: SearchProperty)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty) || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewProperty))
    {
      if (!this.dialogIsOpen)
      {
        try
        {
          this.dialogIsOpen = true;

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "52rem";
          dialogConfig.height = "98vh";
          dialogConfig.hasBackdrop = true;

          const propertiesInfo: PropertiesInfo = new PropertiesInfo();
          propertiesInfo.id = Property.Id;
          propertiesInfo.isOnline = this.isOnline;

          dialogConfig.data = propertiesInfo;

          const dialogRef = this.dialog.open(PropertyComponent, dialogConfig);

          this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
          {
            this.dialogIsOpen = false;

            if (result && result.IsSuccessful)
            {
              this.refreshTable();
              this.refresh();

              this.calendarsService.resetHttpCache();
              this.calendarsService.resetCalendarData();
              this.calendarsService.dataResetFromPropertySave = true;
            }
          });
        }
        catch (e)
        {
          this.dialogIsOpen = false;
        }
      }
    }
  }

  exportData(type: any)
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to export the selected property records?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Export Property Records?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        try
        {
          this.commonService.isLoading.set(true);

          let recordsToExport = this.paginator.length;

          if (this.paginator.length > this.configurationService.cbSettings().maxExportRecords)
          {
            recordsToExport = this.configurationService.cbSettings().maxExportRecords;
          }

          this.paginator.pageSize = recordsToExport;
          this.loadData();

          this.mtCrudService.OnDataFetched.pipe(take(1)).subscribe((result: any) =>
          {
            setTimeout(() =>
            {
              switch (type)
              {
                case "xlsx":
                  this.exporter.exportTable('xlsx', { fileName: 'Properties', sheet: 'Properties' });
                  break;
                case "csv":
                  this.exporter.exportTable('csv', { fileName: 'Properties' });
                  break;
                case "json":
                  this.exporter.exportTable('json', { fileName: 'Properties' });
                  break;
                case "txt":
                  this.exporter.exportTable('txt', { fileName: 'Properties' });
                  break;
              }
            }, 0);
          });
        }
        catch
        {
          this.commonService.isLoading.set(false);
        }
      }
    });
  }

  exportCompleted()
  {
    this.paginator.pageSize = 10;
    this.loadData();

    this.commonService.isLoading.set(false);
  }

  ngOnDestroy()
  {
    super.ngOnDestroy();

    if (this.addCloseSubscription)
    {
      this.addCloseSubscription.unsubscribe();
    }
    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }
    if (this.itemDeletedSubscription)
    {
      this.itemDeletedSubscription.unsubscribe();
    }
  }


  get categories(): Category[]
  {
    //if (!this.isOnline)
    //{
    return this.calendarsService.categories().filter(c =>
    {
      return !c.Disabled;
    });
    //}
    //else
    //{
    //  return this.onlineCalendarsService.categories.filter(c =>
    //  {
    //    return !c.Disabled;
    //  });
    //}
  }


  get propertyTypes(): PropertyType[]
  {
    //if (!this.isOnline)
    //{
    return this.calendarsService.propertyTypes().filter(p =>
    {
      return !p.Disabled;
    });
    //}
    //else
    //{
    //  return this.onlineCalendarsService.propertyTypes.filter(p =>
    //  {
    //    return !p.Disabled;
    //  });
    //}
  }



}


