<div class="notify-group-member-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="notify-group-member-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="notify-group-member-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Notification Group</mat-label>
        <mat-select formControlName="NotifyGroup" placeholder="Notification Group" [compareWith]="equalsNotifyGroup" (selectionChange)="notificationGroupChange($event)">
          @for (notifygroup of administrationService.notifyGroups; track notifygroup) {
            <mat-option [value]="notifygroup">{{notifygroup.Name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    @if (!isNotifyTypeInterfacing) {
      <div class="form-group">
        <mat-form-field>
          <mat-label>Platform Provider</mat-label>
          <mat-select formControlName="Station" placeholder="Platform Provider" [compareWith]="equalsStation">
            @for (station of administrationService.stations; track station) {
              <mat-option [value]="station">{{station.StationName}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (!isNotifyTypeInterfacing) {
      <div class="form-group">
        <mat-form-field>
          <mat-label>Property Type</mat-label>
          <mat-select formControlName="PropertyType" placeholder="All Property Types" [compareWith]="equalsPropertyType">
            <mat-option [value]="null">All</mat-option>
            @for (propertyType of calendarsService.propertyTypes() | filter:[{Disabled: false}]; track trackByPropertyTypes($index, propertyType)) {
              <mat-option [value]="propertyType">{{propertyType.PropertyTypeName}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    <div class="form-group">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="Email" formControlName="Email" maxlength="150" autocomplete="off">
        <mat-hint></mat-hint>
        @if (form.hasError('Email') && !form.hasError('required')) {
          <mat-error>
            Please enter a valid email address
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Mobile</mat-label>
        <input matInput placeholder="Mobile" formControlName="Mobile" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="notify-group-member-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
