import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SaveResult } from '../../shared/models/result.model';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { ActivitiesList, ActivityInfoCopier, ActivityListGetParameters, ContactsReplacer, PropertyInfoCopier } from './tools.models';



@Injectable({ providedIn: 'root' })

export class ToolsService
{
  private _activitiesList: ActivitiesList[] = [];

  constructor(private commonService: CommonService, private configurationService: ConfigurationService, private dataHandler: DataHandlerService)
  {

  }


  get activitiesList(): ActivitiesList[]
  {
    return this._activitiesList;
  }
  set activitiesList(value: ActivitiesList[])
  {
    this._activitiesList = value;
  }


  replaceContacts(contactsReplacer: ContactsReplacer): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().toolsServiceUrl + "/ReplaceContacts", contactsReplacer, false);
  }

  copyPropertyInfo(propertyInfoCopier: PropertyInfoCopier): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().toolsServiceUrl + "/CopyPropertyInfo", propertyInfoCopier, false);
  }

  copyActivityInfo(activityInfoCopier: ActivityInfoCopier): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().toolsServiceUrl + "/CopyActivityInfo", activityInfoCopier, false);
  }

  getActivitiesList(activityListGetParameters: ActivityListGetParameters): Promise<ActivitiesList[]>
  {
    return this.dataHandler.postHttpPromise<ActivitiesList[]>(this.configurationService.cbSettings().toolsServiceUrl + "/GetActivitiesList", activityListGetParameters, false, false);
  }
  getActivitiesFromActivityClient(id: number): Promise<ActivitiesList[]>
  {
    return this.dataHandler.getHttpPromise<ActivitiesList[]>(this.configurationService.cbSettings().toolsServiceUrl + "/GetActivitiesFromActivityClient?id=" + id.toString(), false);
  }
  getActivity(id: number): Promise<ActivitiesList>
  {
    return this.dataHandler.getHttpPromise<ActivitiesList>(this.configurationService.cbSettings().toolsServiceUrl + "/GetActivity?id=" + id.toString(), false);
  }

}
