import { NgClass } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { GenericFilterPipe } from '../../shared/pipes/generic-filter.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';
import { AppService } from '../app/app.service';


@Component({
  selector: 'nav-menu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, NgClass, RouterLinkActive, RouterLink, MatMenuModule, GenericFilterPipe]
})
export class NavMenuComponent 
{
  @Output() public sideBarToggle: EventEmitter<any> = new EventEmitter<any>();

  constructor(public appService: AppService, public commonService: CommonService, public authenticationService: AuthenticationService)
  {

  }

  ngOnInit()
  {



  }


  public sidenavButtonClick(event: any)
  {
    this.sideBarToggle.emit(event);
  }





}
