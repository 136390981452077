import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { MechanicType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";


@Component({
    templateUrl: './mechanic-type-edit.component.html',
    selector: 'mechanic-type-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './mechanic-types.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDialogActions, MatButtonModule]
})
export class MechanicTypeEditComponent extends MatTableCrudEditComponent<MechanicTypeEditComponent, MechanicType>
{
  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<MechanicTypeEditComponent>, @Inject(MAT_DIALOG_DATA) public data: MechanicType, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      MechanicTypeName: [{ value: this.data.MechanicTypeName, disabled: true }, Validators.required],
      MechanicLabelText: [this.data.MechanicLabelText, Validators.required],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    const mechanicType: any = this.form.getRawValue();  //use getRawValue to include disabled controls as well

    if (mechanicType)
    {
      if (this.isNew)
      {
        mechanicType.Id = 0;

        if (mechanicType.Disabled == null)
        {
          mechanicType.Disabled = false;
        }
      }

      mechanicType[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(mechanicType);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Mechanic Type";

    if (this.isNew)
    {
      headerText = "New Mechanic Type";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.MechanicTypeName}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
