import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';


export const ErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
  const authenticationService: AuthenticationService = inject(AuthenticationService);

  return next(req).pipe(catchError(err =>
  {
    if ([401, 403].indexOf(err.status) !== -1)
    {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      authenticationService.logout();
      location.reload();
    }

    const error = err.error.message || err.statusText;
    return throwError(error);
  }))

}
