import { Injectable } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';



@Injectable({ providedIn: 'root' })

export class AuditService
{
  constructor(public commonService: CommonService, public configurationService: ConfigurationService, public dataHandler: DataHandlerService)
  {

  }


}
