@if (active) {
  <kendo-dialog (close)="closeForm()" class="campaign-editor-control k-window-content">
    <kendo-dialog-titlebar>
      {{ isNew ? 'Add New Campaign' : 'Edit Campaign ( Id: ' + editForm?.get('Id').value + ')' }}
    </kendo-dialog-titlebar>
    <campaign-editor [form]="editForm"></campaign-editor>
    <kendo-dialog-actions>
      <button kendoButton size="small" rounded="medium" fillMode="solid" (click)="onCancel($event)">Cancel</button>
      <button kendoButton size="small" rounded="medium" fillMode="solid" themeColor="primary" [disabled]="!editForm?.valid || !editForm?.dirty" (click)="onSave($event)">Update</button>
    </kendo-dialog-actions>
  </kendo-dialog>
}


