import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  template: ''
})
export abstract class KendoGridEditFormComponent<T> implements OnInit
{
  public constructor()
  {

  }

  public active: boolean = true;

  @Input() public isNew: boolean = false;

  @Input() public set model(dataModel: T)
  {
    this.editForm?.reset(dataModel);

    this.modelIsFilled.emit(!!dataModel);

    this.active = !!dataModel;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<T> = new EventEmitter();
  @Output() modelIsFilled: EventEmitter<boolean> = new EventEmitter();

  public editForm: any = null;

  public onSave(e: any): void
  {
    e.preventDefault();
    this.save.emit(this.editForm.getRawValue());
    this.active = false;
  }

  public onCancel(e: any): void
  {
    e.preventDefault();
    this.closeForm();

  }

  public closeForm(): void
  {
    this.active = false;
    this.cancel.emit();
  }

  public ngOnInit(): void
  {

  }

}
