import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings, MultiSelectModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { CrudOperation, CurrentTimeSettings, DateChangeEvent, EditMode, EventClickEvent, EventStyleArgs, MonthViewModule, MultiDayViewModule, PDFModule, RemoveEvent, SchedulerModule, SlotClickEvent } from '@progress/kendo-angular-scheduler';
import { addMinutes, format } from 'date-fns';
import { filter } from 'rxjs/operators';
import { ActivitiesInfo } from '../../shared/models/common.models';
import { SaveResult } from '../../shared/models/result.model';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { ExportPrintService } from '../../shared/services/export-print.service';
import { ActivitiesComponent } from '../calendars/activities/activities.component';
import { Station, StationType } from '../calendars/calendars.models';
import { CalendarsService } from '../calendars/calendars.service';
import { MyEvent } from './diary.interfaces';
import { EventStation } from './diary.models';
import { DiaryService } from './diary.service';
import { EventEditFormComponent } from './event-edit.component';



@Component({
  selector: "app:cb-diary",
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
  providers: [ExportPrintService],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    MultiSelectModule,
    SharedDirectivesModule,
    FaIconComponent,
    SchedulerModule,
    MultiDayViewModule,
    MonthViewModule,
    PDFModule,
    DialogModule,
    EventEditFormComponent,
    AsyncPipe
  ],
})
export class DiaryComponent
{
  public selectedDatesLabel = ""
  public selectedDate: Date = this.commonService.today;
  public editedEvent: any;
  public editMode: EditMode;
  public isNew: boolean;
  public faPrint: IconDefinition = faPrint;
  public dialogIsOpen = false;

  private _isPrinting = false;

  public resources: any[] = [
    {
      name: 'Categories',
      data: this.diaryService.eventCategories,
      field: 'EventCategoryId',
      valueField: 'Id',
      textField: 'Name',
      colorField: 'Colour'
    }
  ];

  public currentTimezoneSettings: CurrentTimeSettings = {
    localTimezone: false
  };

  public currentTimeZone = "Etc/UTC";

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  private configurationService = inject(ConfigurationService);

  constructor(
    private commonService: CommonService,
    private dialog: MatDialog,
    private authorisationService: AuthorisationService,
    public diaryService: DiaryService,
    private exportPrintService: ExportPrintService,
    public calendarsService: CalendarsService,
    private cdr: ChangeDetectorRef
  )
  {
    if (this.configurationService.cbSettings().systemTimezone)
    {
      this.currentTimeZone = this.configurationService.cbSettings().systemTimezone;
    }


  }

  public ngOnInit()
  {
    this.diaryService.selectedViewIndex = 1;
    this.selectedDate = new Date();


    //zero indexed month number
    if (this.calendarsService.currentEventMonth > -1
      && this.calendarsService.currentEventMonth < 12
      && this.calendarsService.currentEventYear > 2010
      && this.calendarsService.currentEventStationId > 0
      && this.calendarsService.currentEventStationTypeId > 0
    )
    {
      //Select Day view as it came from badge click
      this.diaryService.selectedViewIndex = 0;

      if (!this.calendarsService.currentEventDay || isNaN(this.calendarsService.currentEventDay) || (this.calendarsService.currentEventDay < 1 || this.calendarsService.currentEventDay > 31))
      {
        this.calendarsService.currentEventDay = 1;

        //Select Month view because day was invalid
        this.diaryService.selectedViewIndex = 1;
      }

      this.selectedDate = new Date(this.calendarsService.currentEventYear, this.calendarsService.currentEventMonth, this.calendarsService.currentEventDay);

      this.diaryService.selectedStationGroupId = this.calendarsService.currentEventStationTypeId;

      const currentStation = this.calendarsService.stations().filter(s => s.Id == this.calendarsService.currentEventStationId)[0];

      if (currentStation)
      {
        this.diaryService.selectedStations = [];
        this.diaryService.selectedStations.push(currentStation);
      }
    }
    else
    {
      if (this.authorisationService.currentuser != null && this.authorisationService.currentuser.Id > 0 && !this.diaryService.homeStation)
      {
        this.diaryService.homeStation = this.calendarsService.stations().filter(s => s.Id == this.authorisationService.currentuser.HomeStationId)[0];

        if (this.diaryService.homeStation)
        {
          this.diaryService.selectedStationGroupId = this.diaryService.homeStation.StationType.Id;
        }
      }

      this.diaryService.resetSelectedStations();
    }

    this.selectedDatesLabel = format(this.commonService.today, "MMMM yyyy");
  }

  ngOnDestroy()
  {
    //Reset day so it will go into Month view if not entered via badge click
    this.calendarsService.currentEventDay = 0;
  }

  get isPrinting(): boolean
  {
    return this._isPrinting;
  }

  print()
  {
    try
    {
      this._isPrinting = true;

      this.cdr.detectChanges();

      const reportTitle = "What's On Calendar";

      const diaryElement: any = document.getElementById("diary");

      this.exportPrintService.exportElement(diaryElement, reportTitle.replace(/\s/g, '') + ".pdf", true).then((data: any) =>
      {
        this._isPrinting = false;
      })
        .catch((err: any) =>
        {
          this._isPrinting = false;
        });
    }
    catch (e)
    {
      this._isPrinting = false;
    }
  }

  public getEventClass = (args: EventStyleArgs) =>
  {
    const dataItem = args.event.dataItem;

    if (dataItem.ActivityId && args.event.dataItem.ActivityId > 0)
    {
      return "remove-x";
    }
    else
    {
      return "";
    }
  };

  handleStationGroupChange(event: any)
  {
    const stationGroupId: number = parseInt(event.target.value);

    this.diaryService.selectedStationGroupId = stationGroupId;

    this.diaryService.resetSelectedStations();

    this.diaryService.read();
  }

  dStationsSelectedChange(stations: any)
  {
    if (!this.diaryService.selectedStations || this.diaryService.selectedStations.length < 1)
    {
      //prevent no stations by putting back home or 1st available
      let currentStation: Station = this.diaryService.availableStations[0];

      if (this.diaryService.homeStation && this.diaryService.availableStations.some(s => s.Id == this.diaryService.homeStation.Id))
      {
        currentStation = this.diaryService.availableStations.filter(s => s.Id == this.diaryService.homeStation.Id)[0];
      }

      if (currentStation)
      {
        this.diaryService.selectedStationGroupId = currentStation.StationType.Id;
        this.diaryService.selectedStations = [];
        this.diaryService.selectedStations.push(currentStation);
      }
    }

    this.diaryService.read();
  }


  public onDateChange(e: DateChangeEvent): void
  {
    this.diaryService.endDate = e.dateRange.end;
    this.diaryService.startDate = e.dateRange.start;

    this.selectedDatesLabel = format(e.selectedDate, "MMMM yyyy");

    this.diaryService.read();
  }

  public slotDblClickHandler({ sender, start, end, isAllDay }: SlotClickEvent): void
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddWhatsOnEvent))
    {
      this.isNew = true;
      this.diaryService.isEditing = true;
      this.editMode = EditMode.Series;

      const stationIds: number[] = [];

      this.diaryService.selectedStations.map(s =>
      {
        stationIds.push(s.Id);
      });

      let firstEventCategoryId = 0;

      if (this.diaryService.eventCategories() && this.diaryService.eventCategories().length > 0)
      {
        firstEventCategoryId = this.diaryService.eventCategories()[0].Id;
      }

      const offset: number = start.getTimezoneOffset();

      start = addMinutes(start, (offset));
      end = addMinutes(end, (offset));

      this.editedEvent = {
        Id: 0,
        StartDate: start,
        EndDate: start, //Default to 1 day so start and end are the same
        IsAllDayEvent: isAllDay,
        StationIds: stationIds,
        EventCategoryId: firstEventCategoryId
      };
    }
  }

  public eventDblClickHandler({ sender, event }: EventClickEvent): void
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewWhatsOnEvent) || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditWhatsOnEvent))
    {
      this.isNew = false;

      let dataItem: MyEvent = event.dataItem;

      if (dataItem.ActivityId)
      {
        if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityImplementation)
          || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitySales)
          || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityStationSpecific)
          || (dataItem.ActivityId > 0 && (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales)
            || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation)
            || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific))))
        {
          if (!this.dialogIsOpen)
          {
            this.dialogIsOpen = true;

            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = "95%";
            dialogConfig.maxWidth = "1280px";
            dialogConfig.hasBackdrop = true;

            let dialogRef: any = null;

            const actInfo: ActivitiesInfo = new ActivitiesInfo();
            actInfo.id = dataItem.ActivityId;

            dialogConfig.data = actInfo;

            dialogRef = this.dialog.open(ActivitiesComponent, dialogConfig);

            dialogRef.afterClosed().subscribe((result: SaveResult) =>
            {
              this.dialogIsOpen = false;

              if (result && result.IsSuccessful)
              {
                this.calendarsService.resetHttpCache();
                this.calendarsService.resetCalendarData();
                this.calendarsService.dataResetFromActivitySave = true;

                this.diaryService.read();
              }
            });
          }
        }
      }
      else
      {
        if (this.diaryService.isRecurring(dataItem))
        {
          sender.openRecurringConfirmationDialog(CrudOperation.Edit)
            // The dialog will emit `undefined` on cancel
            .pipe(filter(editMode => !!editMode))
            .subscribe((editMode: EditMode) =>
            {
              const masterItem: MyEvent = this.diaryService.findRecurrenceMaster(dataItem);

              if (editMode === EditMode.Series)
              {
                dataItem = masterItem;
              }
              else
              {
                const offset: number = dataItem.StartDate.getTimezoneOffset();

                dataItem.StartDate = addMinutes(dataItem.StartDate, (offset));
                dataItem.EndDate = addMinutes(dataItem.EndDate, (offset));
              }

              this.editMode = editMode;
              this.editedEvent = dataItem;

              this.diaryService.isEditing = true;
            });
        }
        else
        {
          this.editMode = EditMode.Event;
          this.editedEvent = dataItem;

          this.diaryService.isEditing = true;
        }
      }
    }
  }

  public saveHandler(formValue: MyEvent): void
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.AddWhatsOnEvent) || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditWhatsOnEvent))
    {
      let eventStations: EventStation[] = formValue.EventStations;

      if (!eventStations)
      {
        eventStations = [];
      }

      if (formValue.StationIds && formValue.StationIds.length > 0)
      {
        formValue.StationIds.map((s: number) =>
        {
          if (!eventStations.some((ess: EventStation) => ess.EventId == formValue.Id && ess.StationId == s))
          {
            const eventStation: EventStation = new EventStation();
            eventStation.EventId = formValue.Id;
            eventStation.StationId = s;

            eventStations.push(eventStation);
          }
        });

        //Remove eventstations with stations that have been deselected.
        eventStations = eventStations.filter(s => formValue.StationIds.some(i => i == s.StationId));
      }

      formValue["EventStations"] = eventStations;

      if (this.isNew)
      {
        this.diaryService.create(formValue);
      }
      else
      {
        this.handleUpdate(this.editedEvent, formValue, this.editMode);
      }
    }
  }

  private handleUpdate(item: any, value: any, mode: EditMode): void
  {
    const service = this.diaryService;

    if (mode === EditMode.Occurrence)
    {
      if (service.isException(item))
      {
        service.update(item, value);
      }
      else
      {
        service.createException(item, value);
      }
    }
    else
    {
      // Item is not recurring or we're editing the entire series
      service.update(item, value);
    }
  }

  public removeHandler({ sender, dataItem }: RemoveEvent): void
  {
    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteWhatsOnEvent))
    {
      if (dataItem)
      {
        //Parent events lose the Id and it is in the recurrenceId so this fixes it
        if (!dataItem.Id || dataItem.Id < 1)
        {
          dataItem.Id = dataItem.recurrenceId;

          //need to make the recurrenceId null afterwards or it thinks it is an exception
          dataItem.recurrenceId = undefined;
        }
        else if (!dataItem.recurrenceId || dataItem.recurrenceId < 1)
        {
          //child events (exceptions) have an Id but no recurrenceId so add it as they have the ParentId
          dataItem.recurrenceId = dataItem.ParentId;
        }

        if (this.diaryService.isRecurring(dataItem))
        {
          sender.openRecurringConfirmationDialog(CrudOperation.Remove)
            // result will be undefined if the Dialog was closed
            .pipe(filter(editMode => !!editMode))
            .subscribe((editMode) =>
            {
              this.handleRemove(dataItem, editMode);
            });
        }
        else
        {
          sender.openRemoveConfirmationDialog().subscribe((shouldRemove) =>
          {
            if (shouldRemove)
            {
              this.diaryService.removeEvent(dataItem, true);
            }
          });
        }
      }
    }
  }

  private handleRemove(item: any, mode: EditMode): void
  {
    const service = this.diaryService;

    if (mode === EditMode.Series)
    {
      this.diaryService.removeEventSeries(item);
    }
    else if (mode === EditMode.Occurrence)
    {
      if (service.isException(item))
      {
        this.diaryService.removeEvent(item, true);
      }
      else
      {
        //When you want to get rid of an occurrence that is not an exception just add as exception
        this.diaryService.createExceptionForDeletedOccurrence(item);
      }
    }
    else
    {
      this.diaryService.removeEvent(item, true);
    }
  }



  public closeForm(): void
  {
    this.diaryService.isEditing = false;

  }






  trackByStationType(index: number, stationType: StationType)
  {
    return stationType["Id"];
  }




}




