import { Directive, AfterViewInit } from '@angular/core';

import { PlatformService } from '../services/platform.service';
import { MatInput } from '@angular/material/input';

@Directive({
    selector: '[matautofocus]',
    standalone: true,
})
export class MatAutoFocusDirective implements AfterViewInit
{
    private readonly isBrowser: boolean;

    constructor(
        private matInput: MatInput,
        platform: PlatformService
    )
    {

        this.isBrowser = platform.isBrowser;
    }

    ngAfterViewInit(): void
    {
        this.focusElement();
    }

    private focusElement(): void
    {
        if (!this.isBrowser)
        {
            return;
        }

        // Otherwise Angular throws error: Expression has changed after it was checked.
        setTimeout(() =>
        {
            this.matInput.focus();
        }, 500);
    }
}
