import { Injectable } from '@angular/core';
import { ActivityInfo } from '../../../components/calendars/calendars.models';
import { ProgressActivitiesInfo } from '../../../components/tools/tools.models';
import { SaveResult } from '../../models/result.model';
import { ConfigurationService } from '../../services/configuration.service';
import { DataHandlerService } from '../../services/data-handler.service';


@Injectable()

export class ActivitiesUpdaterService
{
  private _activities: ActivityInfo[] = [];

  constructor(private configurationService: ConfigurationService, private dataHandler: DataHandlerService)
  {

  }



  get activities(): ActivityInfo[]
  {
    return this._activities;
  }
  set activities(value: ActivityInfo[])
  {
    this._activities = value;
  }


  progressActivities(progressActivitiesInfo: ProgressActivitiesInfo): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().toolsServiceUrl + "/ProgressActivities", progressActivitiesInfo, false, false);
  }
}
