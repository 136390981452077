import { Injectable } from '@angular/core';


@Injectable()

export class DocumentUploaderService
{
  constructor()
  {

  }


}
