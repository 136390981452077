import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'activityDisplayField',
    standalone: true
})
export class ActivityDisplayFieldPipe implements PipeTransform
{
  transform(displayText: string, propertyTypeName: string, digital: boolean = false, isSold: boolean = false, hasMedia: boolean = false): boolean
  {
    switch (propertyTypeName)
    {
      case "Sponsorship":
        switch (displayText)
        {
          case "showMechanicDescription":
            return digital;
          case "showAirtimeBreakdown":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showDigital":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showScreenShots":
          case "showWebsiteUrl":
          case "showIsFreeOfCharge":
          case "showCreditLineRotation":
          case "showCreditLineType":
          case "showPCRSpecialRequest":
          case "showScreenshotPhotosAndVideos":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showSsiTab":
          case "showAirtimeGrid":
            return true;
          default:
            return false;
        }
        break;
      case "Promotion":
        switch (displayText)
        {
          case "showHasEntryForm":
            return digital;
          case "showHasPhotos":
            return digital;
          case "showHasVideo":
            return digital;
          case "showFinalPcrPhotos":
            return hasMedia;
          case "showFinalPcrVideos":
            return hasMedia;
          case "showMechanicDescription":
            return digital;
          case "showAirtimeBreakdown":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showDigital":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showScreenShots":
          case "showWebsiteUrl":
          case "showIsFreeOfCharge":
          case "showPCRSpecialRequest":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showActivityName":
          case "showDailyUpdate":
          case "showNewsletter":
          case "showMinorPrizeQuantity":
          case "showMinorPrizeValue":
          case "showMinorPrizeHowFulFilled":
          case "showMinorPrize":
          case "showMajorPrizeQuantity":
          case "showMajorPrizeValue":
          case "showMajorPrizeHowFulFilled":
          case "showMajorPrize":
          case "showMechanic":
          case "showSsiTab":
          case "showAirtimeGrid":
            return true;
          default:
            return false;
        }
        break;
      case "Feature":
        switch (displayText)
        {
          case "showHasEntryForm":
            return digital;
          case "showHasPhotos":
            return digital;
          case "showHasVideo":
            return digital;
          case "showFinalPcrPhotos":
            return hasMedia;
          case "showFinalPcrVideos":
            return hasMedia;
          case "showMechanicDescription":
            return digital;
          case "showAirtimeBreakdown":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showDigital":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showScreenShots":
          case "showWebsiteUrl":
          case "showIsFreeOfCharge":
          case "showPCRSpecialRequest":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showActivityName":
          case "showNewsletter":
          case "showHasAudio":
          case "showMinorPrizeQuantity":
          case "showMinorPrizeValue":
          case "showMinorPrizeHowFulFilled":
          case "showMinorPrize":
          case "showMajorPrizeQuantity":
          case "showMajorPrizeValue":
          case "showMajorPrizeHowFulFilled":
          case "showMajorPrize":
          case "showSsiTab":
          case "showAirtimeGrid":
            return true;
          default:
            return false;
        }
        break;
      case "Prize Provider":
        switch (displayText)
        {
          case "showAirtimeBreakdown":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showIsFreeOfCharge":
          case "showPCRSpecialRequest":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showActivityName":
          case "showMinorPrizeQuantity":
          case "showMinorPrizeValue":
          case "showMinorPrizeHowFulFilled":
          case "showMinorPrize":
          case "showMechanic":
          case "showSsiTab":
          case "showAirtimeGrid":
            return true;
          default:
            return false;
        }
        break;
      case "Street":
        switch (displayText)
        {
          case "showHasEntryForm":
            return digital && isSold;
          case "showHasPhotos":
            return digital;
          case "showHasVideo":
            return digital;
          case "showFinalPcrPhotos":
            return hasMedia;
          case "showFinalPcrVideos":
            return hasMedia;
          case "showAirtimeBreakdown":
          case "showMechanicDescription":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showDigital":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showScreenShots":
          case "showWebsiteUrl":
          case "showIsFreeOfCharge":
          case "showSignageRequired":
          case "showWhereisUrl":
          case "showOnsiteContact":
          case "showBroadcastingLive":
          case "showParkingInstructions":
          case "showLocationAddress":
          case "showPCRSpecialRequest":
          case "showOnAirTime":
          case "showActivityEndTime":
          case "showActivityStartTime":
          case "showCrossBookedWithActivity":
          case "showPreRecorded":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showActivityName":
          case "showNewsletter":
          case "showHasAudio":
          case "showMinorPrizeQuantity":
          case "showMinorPrizeHowFulFilled":
          case "showMinorPrize":
          case "showMajorPrizeQuantity":
          case "showMajorPrizeValue":
          case "showMajorPrizeHowFulFilled":
          case "showMajorPrize":
          case "showMechanic":
          case "showVideoBrief":
          case "showPhotoBrief":
          case "showSsiTab":
          case "showAirtimeGrid":
            return true;
          default:
            return false;
        }
        break;
      case "Client Promotion":
        switch (displayText)
        {
          case "showAirtimeBreakdown":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showScreenShots":
          case "showIsFreeOfCharge":
          case "showPCRSpecialRequest":
          case "showPreRecorded":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showActivityName":
          case "showDailyUpdate":
          case "showNewsletter":
          case "showMechanicType":
          case "showMinorPrizeQuantity":
          case "showMinorPrizeValue":
          case "showMinorPrizeHowFulFilled":
          case "showMinorPrize":
          case "showMajorPrizeQuantity":
          case "showMajorPrizeValue":
          case "showMajorPrizeHowFulFilled":
          case "showMajorPrize":
          case "showMechanic":
          case "showSsiTab":
            return true;
          default:
            return false;
        }
        break;
      case "Partnership":
        switch (displayText)
        {
          case "showFinalPcrPhotos":
            return hasMedia;
          case "showFinalPcrVideos":
            return hasMedia;
          case "showAirtimeBreakdown":
          case "showHasVideo":
          case "showHasPhotos":
          case "showMechanicDescription":
          case "showHasImage":
          case "showHasLogo":
          case "showHasAdditionalInfoAttachment":
          case "showDigital":
          case "showProgrammer":
          case "showCreditLine":
          case "showCreditLineDueDate":
          case "showFirstDayAirCheck":
          case "showRate":
          case "showScreenShots":
          case "showWebsiteUrl":
          case "showIsFreeOfCharge":
          case "showSignageRequired":
          case "showTicketingAllocation":
          case "showTicketingOutlet":
          case "showBroadcastingLive":
          case "showParkingInstructions":
          case "showLocationAddress":
          case "showPCRSpecialRequest":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showActivityName":
          case "showDailyUpdate":
          case "showNewsletter":
          case "showExclusive":
          case "showMinorPrize":
          case "showMechanic":
          case "showSsiTab":
          case "showAirtimeGrid":
            return true;
          default:
            return false;
        }
        break;
      case "Podcast":
        switch (displayText)
        {
          case "showIsFreeOfCharge":
          case "showRate":
          case "showHasLogo":
          case "showDateDueToAdOps":
          case "showFinalPCR":
          case "showFinalPcrAirChecks":
          case "showFirstDayAirCheck":
          case "showHasAdditionalInfoAttachment":
          case "showApprovedScriptAttachment":
          case "showWebsiteUrl":
          case "showProduction":
          case "showRevenue":
          case "showMultimediaRecovery":
          case "showProgrammer":
          case "showCreativeBriefUrl":
          case "showNoOfScripts":
          case "showDuration":
          case "showCreativeSets":
          case "showApprovedVerticals":
          case "showCreditLine":
          case "showSsiTab":
          case "showHashtags":
          case "showWeeklyRevenue":
          case "showSoldCpmRate":
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }
}
