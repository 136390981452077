import { Component, inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCircle, faGhost, faPauseCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TileLayoutModule } from '@progress/kendo-angular-layout';
import { injectQueryClient } from '@tanstack/angular-query-experimental';
import { debounceTime, distinctUntilChanged, fromEvent, Subscription } from 'rxjs';
import { ActivitiesComponent } from '../../../../components/calendars/activities/activities.component';
import { ActivityInfo } from '../../../../components/calendars/calendars.models';
import { CalendarsService } from '../../../../components/calendars/calendars.service';
import { ActivitiesInfo } from '../../../models/common.models';
import { ActivityUpdateResult } from '../../../models/result.model';
import { AuthorisationService } from '../../../services/authorisation.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { ActivityListInfo } from '../dashboard.models';
import { SkeletonModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss', '../dashboard.component.scss'],
  providers: [],
  standalone: true,
  imports: [SkeletonModule, TileLayoutModule, FaIconComponent]
})
export class DashboardListComponent implements OnInit
{
  public faIcon: IconDefinition = faCircle;

  private dialogIsOpen = false;
  private activityEditClosedSubscription: Subscription;
  private _iconName = "faCircle";

  @Input() isDisabled = false;


  @Input() public activitiesList: ActivityListInfo[] = [];
  @Input() public title: string = "";
  @Input() public id: number = 0;
  @Input() public col: number = 0;
  @Input() public colspan: number = 0;
  @Input() public row: number = 0;
  @Input() public rowspan: number = 0;
  @Input() public processing: boolean = false;

  @Input() get iconName(): string
  {
    return this._iconName;
  }
  set iconName(value: string)
  {
    switch (value)
    {
      case "Circle":
        this.faIcon = faCircle;
        break;
      case "Ghost":
        this.faIcon = faGhost;
        break;
      case "Pause":
        this.faIcon = faPauseCircle;
        break;
      default:
        this.faIcon = faCircle;
        break;
    }
  }

  private queryClient = injectQueryClient();
  public configurationService = inject(ConfigurationService);

  constructor(
    private calendarsService: CalendarsService,
    private authorisationService: AuthorisationService,
    private dialog: MatDialog)
  {
  }


  ngOnInit(): void
  {

  }

  activityHover(event: any, id: number)
  {
    if (id > 0)
    {
      fromEvent(event.target, event.type)
        .pipe(
          debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
          distinctUntilChanged()
        ).subscribe(() =>
        {
          if (event)
          {
            if (event.stopPropagation)
            {
              // stop event bubbling up
              event.stopPropagation();
            }

            //prevents browser from performing the default action for this element
            event.preventDefault();
          }

          this.queryClient.prefetchQuery({
            queryKey: ["Activity", id],
            queryFn: () => this.calendarsService.getActivity(id),
            //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
          });
        });
    }
  }

  activityClick(event: any, id: number)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityImplementation)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitySales)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityStationSpecific)
      || (id > 0 && (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific))))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "95%";
        dialogConfig.maxWidth = "1280px";
        dialogConfig.hasBackdrop = true;

        let dialogRef: any = null;

        const actInfo: ActivitiesInfo = new ActivitiesInfo();
        actInfo.id = id;

        dialogConfig.data = actInfo;

        dialogRef = this.dialog.open(ActivitiesComponent, dialogConfig);

        this.activityEditClosedSubscription = dialogRef.afterClosed().subscribe((result: ActivityUpdateResult) =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            if (result.ActivityJson)
            {
              const actInfo: ActivityInfo = JSON.parse(result.ActivityJson);

              if (this.activitiesList)
              {
                const ali: ActivityListInfo = this.activitiesList.filter(f => f.Id == actInfo.Id)[0];

                if (ali)
                {
                  ali.ClientName = actInfo.ClientName;
                  ali.Product = actInfo.Product;
                }
              }
            }

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromActivitySave = true;
          }
        });
      }
    }
  }


  ngOnDestroy()
  {
    if (this.activityEditClosedSubscription)
    {
      this.activityEditClosedSubscription.unsubscribe();
    }
  }







}
