import { Injectable, Component } from '@angular/core';


@Injectable()

export class SelectableChipsService
{
    constructor()
    {

    }

    private _chips: any;

    get chips(): any
    {
        return this._chips;
    }
    set chips(value: any)
    {
        this._chips = value;
    }



}