import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { DataHandlerService } from './data-handler.service';


@Injectable({ providedIn: 'root' })

export class UploadService
{
  private serviceUrl = this.configurationService.cbSettings().fileUploadApiUrl;

  constructor(private configurationService: ConfigurationService, private dataHandler: DataHandlerService)
  {


  }


  async postFile(fileToUpload: File, id: string, subFolder: string = "", subId: string = "", sizeLimit: string = ""): Promise<boolean>
  {
    const formData: FormData = new FormData();
    formData.append('files', fileToUpload, fileToUpload.name);
    formData.append('id', id);
    formData.append('subFolder', subFolder);
    formData.append('subId', subId);
    formData.append('sizeLimit', sizeLimit);

    return await lastValueFrom(this.dataHandler.postHttpObservable<boolean>(`${this.serviceUrl}/upload`, formData, false, false));
  }

  async postFileRemove(fileNames: string[], id: string, subFolder: string = "", subId: string = ""): Promise<boolean>
  {
    const formData: FormData = new FormData();
    formData.append('fileNames', JSON.stringify(fileNames));
    formData.append('id', id);
    formData.append('subFolder', subFolder);
    formData.append('subId', subId);

    return await lastValueFrom(this.dataHandler.postHttpObservable<boolean>(`${this.serviceUrl}/remove`, formData, false, false));
  }









}
