import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DropDownFilterSettings, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ContactTypeUser } from '../../../shared/models/common.models';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { Client } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { ContactsReplacer } from '../tools.models';
import { ToolsService } from '../tools.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@Component({
    templateUrl: 'replace-contacts.component.html',
    styleUrls: ['./replace-contacts.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MultiSelectModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatCheckboxModule, MatSelectModule, MatOptionModule, MatButtonModule]
})
export class ReplaceContactsComponent implements OnInit
{
  public form: UntypedFormGroup;
  public saveInProgress = false;

  public clientExecutives: ContactTypeUser[] = [];
  public clientSupport: ContactTypeUser[] = [];
  public briefManagers: ContactTypeUser[] = [];
  public projectManagers: ContactTypeUser[] = [];
  public nationalProjectManagers: ContactTypeUser[] = [];
  public programmers: ContactTypeUser[] = [];
  public adOps: ContactTypeUser[] = [];


  constructor(
    public fb: UntypedFormBuilder,
    private confirmDialog: MatDialog,
    public calendarsService: CalendarsService,
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    private toolsService: ToolsService
  )
  {
    this.form = this.fb.group({
      StartDate: [],
      EndDate: [],
      UpdatePropertyContacts: [false],
      StationIds: [],
      ClientIds: [],
      FromClientExecId: [],
      ToClientExecId: [],
      FromClientSupportId: [],
      ToClientSupportId: [],
      FromBriefManagerId: [],
      ToBriefManagerId: [],
      FromProjectManagerId: [],
      ToProjectManagerId: [],
      FromNatProjectManagerId: [],
      ToNatProjectManagerId: [],
      FromProgrammerId: [],
      ToProgrammerId: [],
      FromAdOpsId: [],
      ToAdOpsId: []
    });

    this.setInitialDisabledState();
  }

  ngOnInit()
  {
    const user: ContactTypeUser = new ContactTypeUser();
    user.FirstName = "(none)";
    user.Surname = "";
    user.FullName = "";
    user.Id = null;
    user.Disabled = false;
    user.Phone = "";
    user.Email = "";
    user.Mobile = "";
    user.SortOrder = 0;

    this.clientExecutives = this.commonDataService.clientExecutives();
    this.clientSupport = this.commonDataService.clientSupport();
    this.briefManagers = this.commonDataService.briefManagers();
    this.projectManagers = this.commonDataService.projectManagers();
    this.nationalProjectManagers = this.commonDataService.nationalProjectManagers();
    this.programmers = this.commonDataService.programmers();
    this.adOps = this.commonDataService.adOps();

      if (!this.clientExecutives)
      {
        this.clientExecutives = [];
      }

      if (!this.clientSupport)
      {
        this.clientSupport = [];
      }

      if (!this.briefManagers)
      {
        this.briefManagers = [];
      }

      if (!this.projectManagers)
      {
        this.projectManagers = [];
      }

      if (!this.nationalProjectManagers)
      {
        this.nationalProjectManagers = [];
      }

      if (!this.programmers)
      {
        this.programmers = [];
      }

      if (!this.adOps)
      {
        this.adOps = [];
      }

    if (!this.clientExecutives.some((u: ContactTypeUser) => u.Id == null))
    {
      this.clientExecutives.unshift(JSON.parse(JSON.stringify(user)));
    }

    if (!this.clientSupport.some((u: ContactTypeUser) => u.Id == null))
    {
      this.clientSupport.unshift(JSON.parse(JSON.stringify(user)));
    }

    if (!this.briefManagers.some((u: ContactTypeUser) => u.Id == null))
    {
      this.briefManagers.unshift(JSON.parse(JSON.stringify(user)));
    }

    if (!this.projectManagers.some((u: ContactTypeUser) => u.Id == null))
    {
      this.projectManagers.unshift(JSON.parse(JSON.stringify(user)));
    }

    if (!this.nationalProjectManagers.some((u: ContactTypeUser) => u.Id == null))
    {
      this.nationalProjectManagers.unshift(JSON.parse(JSON.stringify(user)));
    }

    if (!this.programmers.some((u: ContactTypeUser) => u.Id == null))
    {
      this.programmers.unshift(JSON.parse(JSON.stringify(user)));
    }

    if (!this.adOps || this.adOps.length == 0 || !this.adOps.some((u: ContactTypeUser) => u.Id == null))
    {
      this.adOps.unshift(JSON.parse(JSON.stringify(user)));
    }
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  replace(): void
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to replace all contacts as configured?`;
    confirmDialogRef.componentInstance.confirmTitle = "Replace Contacts?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        const cr: ContactsReplacer = this.form.value;

        if (cr)
        {
          this.saveInProgress = true;

          this.toolsService.replaceContacts(cr).then(
            data =>
            {
              this.saveInProgress = false;

              if (data)
              {
                if (data.Message)
                {
                  const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                  confirmDialogRef.componentInstance.confirmMessage = data.Message;
                  confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                  confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                  confirmDialogRef.componentInstance.showCancelButton = false;
                  confirmDialogRef.componentInstance.confirmText = "OK";

                  this.form.reset();

                  this.form.get('UpdatePropertyContacts').patchValue(false);
                  this.setInitialDisabledState();
                }
              }

            }).catch((e) =>
            {
              this.saveInProgress = false;
            });
        }
      }
    });
  }

  setInitialDisabledState()
  {
    this.form.get('ToClientExecId').disable({ emitEvent: false });
    this.form.get('ToClientSupportId').disable({ emitEvent: false });
    this.form.get('ToBriefManagerId').disable({ emitEvent: false });
    this.form.get('ToProjectManagerId').disable({ emitEvent: false });
    this.form.get('ToNatProjectManagerId').disable({ emitEvent: false });
    this.form.get('ToProgrammerId').disable({ emitEvent: false });
    this.form.get('ToAdOpsId').disable({ emitEvent: false });
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["EndDate"].patchValue(this.form.get("StartDate").value, { emitEvent: false });
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["StartDate"].patchValue(this.form.get("EndDate").value, { emitEvent: false });
    }
  }


  onFromClientExecIdChange(select: any)
  {
    this.form.get('ToClientExecId').enable({ emitEvent: false });

    if (this.form.get('FromClientExecId') == null || this.form.get('FromClientExecId').value == null || this.form.get('FromClientExecId').value < 1)
    {
      this.form.get('ToClientExecId').disable({ emitEvent: false });
      this.form.get('ToClientExecId').patchValue(null);
    }
    else if (this.form.get('FromClientExecId').value == this.form.get('ToClientExecId').value)
    {
      this.form.get('ToClientExecId').patchValue(null);
    }
  }

  onFromClientSupportIdChange(select: any)
  {
    this.form.get('ToClientSupportId').enable({ emitEvent: false });

    if (this.form.get('FromClientSupportId') == null || this.form.get('FromClientSupportId').value == null || this.form.get('FromClientSupportId').value < 1)
    {
      this.form.get('ToClientSupportId').disable({ emitEvent: false });
      this.form.get('ToClientSupportId').patchValue(null);
    }
    else if (this.form.get('FromClientSupportId').value == this.form.get('ToClientSupportId').value)
    {
      this.form.get('ToClientSupportId').patchValue(null);
    }
  }

  onFromBriefManagerIdChange(select: any)
  {
    this.form.get('ToBriefManagerId').enable({ emitEvent: false });

    if (this.form.get('FromBriefManagerId') == null || this.form.get('FromBriefManagerId').value == null || this.form.get('FromBriefManagerId').value < 1)
    {
      this.form.get('ToBriefManagerId').disable({ emitEvent: false });
      this.form.get('ToBriefManagerId').patchValue(null);
    }
    else if (this.form.get('FromBriefManagerId').value == this.form.get('ToBriefManagerId').value)
    {
      this.form.get('ToBriefManagerId').patchValue(null);
    }
  }

  onFromProjectManagerIdChange(select: any)
  {
    this.form.get('ToProjectManagerId').enable({ emitEvent: false });

    if (this.form.get('FromProjectManagerId') == null || this.form.get('FromProjectManagerId').value == null || this.form.get('FromProjectManagerId').value < 1)
    {
      this.form.get('ToProjectManagerId').disable({ emitEvent: false });
      this.form.get('ToProjectManagerId').patchValue(null);
    }
    else if (this.form.get('FromProjectManagerId').value == this.form.get('ToProjectManagerId').value)
    {
      this.form.get('ToProjectManagerId').patchValue(null);
    }
  }

  onFromNatProjectManagerIdChange(select: any)
  {
    this.form.get('ToNatProjectManagerId').enable({ emitEvent: false });

    if (this.form.get('FromNatProjectManagerId') == null || this.form.get('FromNatProjectManagerId').value == null || this.form.get('FromNatProjectManagerId').value < 1)
    {
      this.form.get('ToNatProjectManagerId').disable({ emitEvent: false });
      this.form.get('ToNatProjectManagerId').patchValue(null);
    }
    else if (this.form.get('FromNatProjectManagerId').value == this.form.get('ToNatProjectManagerId').value)
    {
      this.form.get('ToNatProjectManagerId').patchValue(null);
    }
  }

  onFromProgrammerIdChange(select: any)
  {
    this.form.get('ToProgrammerId').enable({ emitEvent: false });

    if (this.form.get('FromProgrammerId') == null || this.form.get('FromProgrammerId').value == null || this.form.get('FromProgrammerId').value < 1)
    {
      this.form.get('ToProgrammerId').disable({ emitEvent: false });
      this.form.get('ToProgrammerId').patchValue(null);
    }
    else if (this.form.get('FromProgrammerId').value == this.form.get('ToProgrammerId').value)
    {
      this.form.get('ToProgrammerId').patchValue(null);
    }
  }

  onFromAdOpsIdChange(select: any)
  {
    this.form.get('ToAdOpsId').enable({ emitEvent: false });

    if (this.form.get('FromAdOpsId') == null || this.form.get('FromAdOpsId').value == null || this.form.get('FromAdOpsId').value < 1)
    {
      this.form.get('ToAdOpsId').disable({ emitEvent: false });
      this.form.get('ToAdOpsId').patchValue(null);
    }
    else if (this.form.get('FromAdOpsId').value == this.form.get('ToAdOpsId').value)
    {
      this.form.get('ToAdOpsId').patchValue(null);
    }
  }


  get hasSomethingToDo(): boolean
  {
    let hasSomething: boolean = false;

    hasSomething = this.form.get('FromClientExecId').value
      || this.form.get('FromClientSupportId').value
      || this.form.get('FromBriefManagerId').value
      || this.form.get('FromProjectManagerId').value
      || this.form.get('FromNatProjectManagerId').value
      || this.form.get('FromProgrammerId').value
      || this.form.get('FromAdOpsId').value;

    return hasSomething;
  }


  equalsClient(o1: Client, o2: Client)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsUser(o1: ContactTypeUser, o2: ContactTypeUser)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }









}
