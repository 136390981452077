<div class="role-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="role-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="role-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="RoleName" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        @if (form.hasError('RoleName') && !form.hasError('required')) {
          <mat-error>
            Please enter a valid Name
          </mat-error>
        }
        @if (form.hasError('required')) {
          <mat-error>
            Name is <strong>required</strong>
          </mat-error>
        }
      </mat-form-field>
    </div>
    <!--<div class="form-group">
    <mat-form-field>
      <mat-label>Role Level</mat-label>
      <input matInput (focus)="$event.target.select()" placeholder="Role Level" formControlName="RoleLevel" type="number" min="0" autocomplete="off" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="form.hasError('RoleLevel') && !form.hasError('required')">
        Please enter a valid Role Level
      </mat-error>
      <mat-error *ngIf="form.hasError('required')">
        Role Level is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>-->
  <div class="form-group">
    <mat-form-field>
      <mat-label>Sort Order</mat-label>
      <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
    </mat-form-field>
  </div>
  <div class="form-group">
    <div class="checkbox-form-group">
      <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
    </div>
  </div>
  <div class="form-group" style="width:45rem">
    @if (showRolePermissions) {
      <mat-label>Role Permissions</mat-label>
    }
    @if (showRolePermissions) {
      <dual-list layout-fill [disabled]="disableRolePermissions" [source]="administrationService.permissions" display="Name" key="Id" height="20.5rem" (destinationChange)="rolePermissionsChange($event)" [format]="rolePermissionsControlSettings" [(destination)]="selectedPermissions"></dual-list>
    }
  </div>
</div>
<div class="role-footer">
  <hr />
  <mat-dialog-actions>
    <div class="button-row">
      <button mat-button (click)="onCancelClick()">Close</button>
      <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
    </div>
  </mat-dialog-actions>
</div>
</div>
