import { Injectable } from '@angular/core';
import { ContactTypeUser } from '../../../shared/models/common.models';


@Injectable()

export class ContactsSelectorService
{
    constructor()
    {

    }

  private _user: ContactTypeUser;
  private _users: ContactTypeUser[] = [];

  get user(): ContactTypeUser
    {
        return this._user;
    }
  set user(value: ContactTypeUser)
    {
        this._user = value;
    }



  get users(): ContactTypeUser[]
    {
        return this._users;
    }
  set users(value: ContactTypeUser[])
    {
        this._users = value;
    }
}
