import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { CbSettings, ConfigurationService } from '../services/configuration.service';


export const JwtInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{

  const authenticationService: AuthenticationService = inject(AuthenticationService);
  const configurationService: ConfigurationService = inject(ConfigurationService);
  const router: Router = inject(Router);

  let isApi = false;

  const settings: CbSettings = configurationService.cbSettings();

  if (settings
    && settings.calendarServiceUrl
    && settings.campaignServiceUrl
    && settings.dashboardServiceUrl
    && settings.reportsApiUrl
    && settings.eventsApiUrl
    && settings.toolsServiceUrl
    && settings.controlPanelServiceUrl
    && settings.fileUploadApiUrl
    && settings.adminServiceUrl
    && settings.authorisationServiceUrl
    && settings.loggingApiUrl
  )
  {
    isApi = req.url.trim().toLowerCase().includes(settings.calendarServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.campaignServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.dashboardServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.reportsApiUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.eventsApiUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.toolsServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.controlPanelServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.fileUploadApiUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.adminServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.authorisationServiceUrl.trim().toLowerCase())
      || req.url.trim().toLowerCase().includes(settings.loggingApiUrl.trim().toLowerCase());
  }


  if (isApi)
  {
    let tokenType = "access";

    if (req.url.includes("/GetUserContext"))
    {
      tokenType = "id";
    }

    return from(authenticationService.getToken(tokenType).then(async (token: any) =>
    {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
      const authReq = req.clone({ headers });

      return await lastValueFrom(next(authReq).pipe(tap({
        next: (event: any) =>
        {

        },
        error: (error) =>
        {
          const respError = error as HttpErrorResponse;

          if (respError && (respError.status === 401 || respError.status === 403))
          {
            router.navigate(['/unauthorised']);
          }
        }
      })));
    }));
  }
  else
  {
    return next(req);
  }
}



















