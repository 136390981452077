import { Injectable, inject } from "@angular/core";
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AdministrationService } from "../../components/administration/administration.service";
import { ToolsService } from "../../components/tools/tools.service";
import { DashboardData } from "../components/dashboard/dashboard.models";
import { DashboardService } from "../components/dashboard/dashboard.service";
import { AuthorisationService } from "./authorisation.service";
import { CommonDataService } from "./common-data.service";


@Injectable({ providedIn: 'root' })

export class ResolveDataService
{
  private administrationService = inject(AdministrationService);
  private commonDataService = inject(CommonDataService);
  private toolsService = inject(ToolsService);
  private dashboardService = inject(DashboardService);
  private authorisationService = inject(AuthorisationService);


  public resolveAccessTypes()
  {
    if (this.administrationService.accessTypes && this.administrationService.accessTypes.length > 0)
    {
      return of(this.administrationService.accessTypes);
    }
    else
    {
      return this.administrationService.getAccessTypes().pipe(take(1), tap(data => this.administrationService.accessTypes = !data || !Array.isArray(data) ? [] : data));
    }
  }

  public resolveActivitiesList()
  {
    if (this.toolsService.activitiesList && this.toolsService.activitiesList.length > 0)
    {
      return of(this.toolsService.activitiesList);
    }
    else
    {
      return of(this.toolsService.activitiesList);
      //return this.toolsService.getActivitiesList().pipe(take(1), tap(data => this.toolsService.activitiesList = !data || !Array.isArray(data) ? [] : data));
    }
  }

  public resolveCampaigns()
  {
    if (this.commonDataService.campaigns && this.commonDataService.campaigns.length > 0)
    {
      return of(this.commonDataService.campaigns);
    }
    else
    {
      return this.commonDataService.getCampaigns().pipe(take(1), tap(data => this.commonDataService.campaigns = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false))).subscribe();
    }
  }

  public resolveContactTypes()
  {
    if (this.administrationService.contactTypes && this.administrationService.contactTypes.length > 0)
    {
      return of(this.administrationService.contactTypes);
    }
    else
    {
      return this.administrationService.getContactTypes().pipe(take(1), tap(data => this.administrationService.contactTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }


  public resolveDepartments()
  {
    if (this.administrationService.departments && this.administrationService.departments.length > 0)
    {
      return of(this.administrationService.departments);
    }
    else
    {
      return this.administrationService.getDepartments().pipe(take(1), tap(data => this.administrationService.departments = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveExtensions()
  {
    if (this.administrationService.extensions && this.administrationService.extensions.length > 0)
    {
      return of(this.administrationService.extensions);
    }
    else
    {
      return this.administrationService.getExtensions().pipe(take(1), tap(data => this.administrationService.extensions = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveJobActions()
  {
    if (this.administrationService.jobActions && this.administrationService.jobActions.length > 0)
    {
      return of(this.administrationService.jobActions);
    }
    else
    {
      return this.administrationService.getJobActions().pipe(take(1), tap(data => this.administrationService.jobActions = !data || !Array.isArray(data) ? [] : data));
    }
  }

  public resolveJobDocumentTypes()
  {
    if (this.administrationService.jobDocumentTypes && this.administrationService.jobDocumentTypes.length > 0)
    {
      return of(this.administrationService.jobDocumentTypes);
    }
    else
    {
      return this.administrationService.getJobDocumentTypes().pipe(take(1), tap(data => this.administrationService.jobDocumentTypes = !data || !Array.isArray(data) ? [] : data));
    }
  }

  public resolveJobTypes()
  {
    if (this.administrationService.jobTypes && this.administrationService.jobTypes.length > 0)
    {
      return of(this.administrationService.jobTypes);
    }
    else
    {
      return this.administrationService.getJobTypes().pipe(take(1), tap(data => this.administrationService.jobTypes = !data || !Array.isArray(data) ? [] : data));
    }
  }

  public resolveNotifyGroups()
  {
    if (this.administrationService.notifyGroups && this.administrationService.notifyGroups.length > 0)
    {
      return of(this.administrationService.notifyGroups);
    }
    else
    {
      return this.administrationService.getNotifyGroups().pipe(take(1), tap(data => this.administrationService.notifyGroups = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveNotifyGroupTypes()
  {
    if (this.administrationService.notifyGroupTypes && this.administrationService.notifyGroupTypes.length > 0)
    {
      return of(this.administrationService.notifyGroupTypes);
    }
    else
    {
      return this.administrationService.getNotifyGroupTypes().pipe(take(1), tap(data => this.administrationService.notifyGroupTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveOnlineDependencyTypes()
  {
    if (this.administrationService.onlineDependencyTypes && this.administrationService.onlineDependencyTypes.length > 0)
    {
      return of(this.administrationService.onlineDependencyTypes);
    }
    else
    {
      return this.administrationService.getOnlineDependencyTypes().pipe(take(1), tap(data => this.administrationService.onlineDependencyTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolvePermissions()
  {
    if (this.administrationService.permissions && this.administrationService.permissions.length > 0)
    {
      return of(this.administrationService.permissions);
    }
    else
    {
      return this.administrationService.getPermissions().pipe(take(1), tap(data => this.administrationService.permissions = !data || !Array.isArray(data) ? [] : data));
    }
  }

  public resolveRoles()
  {
    if (this.administrationService.roles && this.administrationService.roles.length > 0)
    {
      return of(this.administrationService.roles);
    }
    else
    {
      return this.administrationService.getRoles().pipe(take(1), tap(data => this.administrationService.roles = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveStationsAdmin()
  {
    if (this.administrationService.stations && this.administrationService.stations.length > 0)
    {
      return of(this.administrationService.stations);
    }
    else
    {
      return this.administrationService.getStations().pipe(take(1), tap(data => this.administrationService.stations = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveStationTypesAdmin()
  {
    if (this.administrationService.stationTypes && this.administrationService.stationTypes.length > 0)
    {
      return of(this.administrationService.stationTypes);
    }
    else
    {
      return this.administrationService.getStationTypes().pipe(take(1), tap(data => this.administrationService.stationTypes = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }

  public resolveSupervisors()
  {
    if (this.administrationService.supervisors && this.administrationService.supervisors.length > 0)
    {
      return of(this.administrationService.supervisors);
    }
    else
    {
      return this.administrationService.getSupervisors().pipe(take(1), tap(data => this.administrationService.supervisors = !data || !Array.isArray(data) ? [] : data.filter(x => x.Disabled == false)));
    }
  }



























}
