import { Injectable } from '@angular/core';
import { drawDOM, DrawOptions, exportPDF, Group } from '@progress/kendo-drawing';
import { defineFont, PDFOptions } from '@progress/kendo-drawing/pdf';
import { saveAs } from '@progress/kendo-file-saver';
import { CommonService } from './common.service';
import { ConfigurationService } from './configuration.service';

@Injectable({ providedIn: 'root' })

export class ExportPrintService
{
  private _defaultReportDrawOptions: DrawOptions = null;
  private _defaultReportPDFOptions: PDFOptions = null;
  private _reportDrawOptions: DrawOptions = null;
  private _reportPDFOptions: PDFOptions = null;
  private _reportForcePageBreak: string = null;
  private _reportKeepTogether: string = null;


  constructor(private configurationService: ConfigurationService, private commonService: CommonService)
  {

  }

  get reportForcePageBreak(): string
  {
    return this._reportForcePageBreak;
  }
  set reportForcePageBreak(value: string)
  {
    this._reportForcePageBreak = value;
  }

  get reportKeepTogether(): string
  {
    return this._reportKeepTogether;
  }
  set reportKeepTogether(value: string)
  {
    this._reportKeepTogether = value;
  }


  get defaultReportDrawOptions(): DrawOptions
  {
    if (!this._defaultReportDrawOptions)
    {
      let pageSize = "A4";
      let leftMargin = "1cm";
      let rightMargin = "1cm";
      let topMargin = "1cm";
      let bottomMargin = "1cm";
      let isLandscape = false;
      let scale: number[] = null

      if (this.configurationService)
      {
        if (this.configurationService.cbSettings().reportPaperSize)
        {
          pageSize = this.configurationService.cbSettings().reportPaperSize;
        }
        if (this.configurationService.cbSettings().reportLeftMargin)
        {
          leftMargin = this.configurationService.cbSettings().reportLeftMargin;
        }
        if (this.configurationService.cbSettings().reportRightMargin)
        {
          rightMargin = this.configurationService.cbSettings().reportRightMargin;
        }
        if (this.configurationService.cbSettings().reportTopMargin)
        {
          topMargin = this.configurationService.cbSettings().reportTopMargin;
        }
        if (this.configurationService.cbSettings().reportBottomMargin)
        {
          bottomMargin = this.configurationService.cbSettings().reportBottomMargin;
        }

        if (this.configurationService.cbSettings().reportIsLandscape)
        {
          isLandscape = this.configurationService.cbSettings().reportIsLandscape;
        }

        if (this.configurationService.cbSettings().reportScale)
        {
          scale = this.configurationService.cbSettings().reportScale;
        }
      }

      this._defaultReportDrawOptions = <DrawOptions>{
        avoidLinks: true, paperSize: pageSize, forcePageBreak: this.reportForcePageBreak, keepTogether: this.reportKeepTogether, repeatHeaders: false, scale: scale, template: null, landscape: isLandscape,
        margin: { left: leftMargin, top: topMargin, right: rightMargin, bottom: bottomMargin }
      };
    }

    return this._defaultReportDrawOptions;
  }

  get defaultReportPDFOptions(): PDFOptions
  {
    if (!this._defaultReportPDFOptions)
    {
      const title: string = this.commonService.currentpagetitle;

      this._defaultReportPDFOptions = <PDFOptions>{ title: title, multiPage: true, paperSize: this.defaultReportDrawOptions.paperSize, landscape: this.defaultReportDrawOptions.landscape, margin: this.defaultReportDrawOptions.margin };
    }

    return this._defaultReportPDFOptions;
  }

  get reportDrawOptions(): DrawOptions
  {
    if (!this._reportDrawOptions)
    {
      this._reportDrawOptions = JSON.parse(JSON.stringify(this.defaultReportDrawOptions));
    }

    return this._reportDrawOptions;
  }
  set reportDrawOptions(value: DrawOptions)
  {
    this._reportDrawOptions = value;
  }

  get reportPDFOptions(): PDFOptions
  {
    if (!this._reportPDFOptions)
    {
      this._reportPDFOptions = JSON.parse(JSON.stringify(this.defaultReportPDFOptions));
    }

    return this._reportPDFOptions;
  }
  set reportPDFOptions(value: PDFOptions)
  {
    this._reportPDFOptions = value;
  }


  exportElement(element: HTMLElement, fileName: string, exportToNewTab: boolean, pdfOptions?: PDFOptions, drawOptions?: DrawOptions)
  {
    //reset report option values.
    this._reportDrawOptions = null;
    this._reportPDFOptions = null;

    if (pdfOptions != null)
    {
      //merge values with the default values
      Object.assign(this.reportPDFOptions, pdfOptions);
    }

    if (drawOptions != null)
    {
      //merge values with the default values
      Object.assign(this.reportDrawOptions, drawOptions);
    }

    return drawDOM(element).then((group: Group) =>  
    {
      return exportPDF(group).then((dataUri) =>
      {
        if (!exportToNewTab)
        {
          saveAs(dataUri, fileName);
        }
        else
        {
          try
          {
            const blob = this.dataURItoBlob(dataUri);

            const pdfData = new File([blob], fileName, { type: "application/pdf" });

            const objectURL = URL.createObjectURL(pdfData);
            const popup = window.open(objectURL, '_blank');

            if (this.hasPopUpsBlocked(popup))
            {
              saveAs(dataUri, fileName);
              this.commonService.notifySuccess("Report Downloaded", "Report has been successfully downloaded.");
            }
          }
          catch (e)
          {
            saveAs(dataUri, fileName);
            this.commonService.notifySuccess("Report Downloaded", "Report has been successfully downloaded.");
          }
        }
      }).catch((err: any) =>
      {
      });
    }).catch((err: any) =>
    {
    });
  }


  dataURItoBlob(dataURI: any)
  {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)
    {
      byteString = atob(dataURI.split(',')[1]);
    }
    else
    {
      byteString = encodeURI(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++)
    {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  hasPopUpsBlocked(popup_window: any)
  {
    let popUpsBlocked = false;

    if (popup_window)
    {
      if (/chrome/.test(navigator.userAgent.toLowerCase()))
      {
        setTimeout(function ()
        {
          popUpsBlocked = (popup_window.innerHeight > 0) == false;
        }, 200);
      }
      else
      {
        popup_window.onload = function ()
        {
          popUpsBlocked = (popup_window.innerHeight > 0) == false;
        };
      }
    }
    else
    {
      popUpsBlocked = true;
    }

    return popUpsBlocked;
  }


}
