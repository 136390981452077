import { DatePipe } from "@angular/common";
import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { IconDefinition, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { take } from "rxjs/operators";
import { ConfirmationDialog } from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { MatTableExporterDirective } from "../../../shared/directives/mat-table-exporter.directive";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { LoggerService } from '../../../shared/services/logger.service';
import { CalendarsService } from "../../calendars/calendars.service";
import { AuditTool } from "../audit.models";
import { AuditService } from '../audit.service';


@Component({
    selector: 'audit-tools',
    templateUrl: './audit-tools.component.html',
    styleUrls: ['./audit-tools.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [AuditService, MatTableCrudService],
    standalone: true,
  imports: [MatCardModule, MatTableModule, MatSortModule, MatTableExporterDirective, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatButtonModule, MatMenuModule, FaIconComponent, MatIconModule, MatPaginatorModule, DatePipe]
})
export class AuditToolsComponent extends MatTableCrudComponent<AuditTool> implements OnInit, OnDestroy
{
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  public faFileExport: IconDefinition = faFileExport;

  public ActionFilter: any;
  public FromParametersFilter: any;
  public ToParametersFilter: any;
  public AffectedRecordsFilter: any;
  public UpdatedDateFilter: any;
  public UserFilter: any;


  constructor(protected injector: Injector,
    public calendarsService: CalendarsService,
    public configurationService: ConfigurationService,
    public auditService: AuditService,
    public commonService: CommonService,
    public loggerService: LoggerService)
  {
    super(injector,
      {},
      {},
      {},
      "",
      "",
      "GetAuditToolsServer",
      "",
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl);

    this.changedByField = "ChangedByUserId";
    this.ServerPaging = true;

    this.displayedColumns = ['Id', 'Action', 'FromParameters', 'ToParameters', 'AffectedRecords', 'UpdatedDate', 'User'];
    this.filteredValues = [
      { Field: "Action", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "FromParameters", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "ToParameters", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "AffectedRecords", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "User", Value: "", Path: "", DataType: FilterDataType.String },
      //{ Field: "UpdatedDate", Value: "", Path: "", DataType: FilterDataType.Date }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";

    this.CanAdd = false;
    this.CanEdit = false;
    this.CanDelete = false;
  }


  ngOnInit(): void
  {
    super.ngOnInit();

    this.commonService.isLoading.set(false);

    this.dataSubscription.add(
      this.mtCrudService.OnDataFetched.subscribe((result: any) =>
      {
        let data: any;

        if (this.ServerPaging)
        {
          const ds = JSON.parse(result);

          if (ds)
          {
            data = ds.Data;
          }
        }
        else
        {
          data = result;
        }

        if (data)
        {
          data.map((a: AuditTool) =>
          {
            if (a)
            {
              if (a.UpdatedDate)
              {
                a.UpdatedDate = this.commonService.parseJsonDate(a.UpdatedDate);
              }
            }
          });
        }
      })
    );
  }

  exportData(type: any)
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to export the selected audit records?  It may take a while.`;
    confirmDialogRef.componentInstance.confirmTitle = "Export Audit Records?";

    //Do not need to unsubscribe as afterClose completes itself.
    confirmDialogRef.afterClosed().subscribe(result =>
    {
      if (result)
      {
        try
        {
          this.commonService.isLoading.set(true);

          let recordsToExport = this.paginator.length;

          if (this.paginator.length > this.configurationService.cbSettings().maxExportRecords)
          {
            recordsToExport = this.configurationService.cbSettings().maxExportRecords;
          }

          this.paginator.pageSize = recordsToExport;
          this.loadData();

          this.mtCrudService.OnDataFetched.pipe(take(1)).subscribe((result: any) =>
          {
            setTimeout(() =>
            {
              switch (type)
              {
                case "xlsx":
                  this.exporter.exportTable('xlsx', { fileName: 'AuditTools', sheet: 'AuditTools' });
                  break;
                case "csv":
                  this.exporter.exportTable('csv', { fileName: 'AuditTools' });
                  break;
                case "json":
                  this.exporter.exportTable('json', { fileName: 'AuditTools' });
                  break;
                case "txt":
                  this.exporter.exportTable('txt', { fileName: 'AuditTools' });
                  break;
              }
            }, 0);
          });
        }
        catch
        {
          this.commonService.isLoading.set(false);
        }
      }
    });
  }

  exportCompleted()
  {
    this.paginator.pageSize = 10;
    this.loadData();

    this.commonService.isLoading.set(false);
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();

    this.commonService.isLoading.set(false);

    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }


  }




}


