<div class="replace-contacts-control" [formGroup]="form">
  @if (false) {
    <div class="replace-contacts-header">
      <label>Replace Activity Contacts</label>
    </div>
  }
  <div class="replace-contacts-content-filter">
    <kendo-multiselect [autoClose]="false" [data]="calendarsService.stations()"
      [textField]="'StationName'"
      [valueField]="'Id'"
      [valuePrimitive]="true"
      [size]="'medium'"
      [fillMode]="'flat'"
      formControlName="StationIds"
      placeholder="All Platform Providers"
      style="padding-top: 3%;">
    </kendo-multiselect>
    @if (false) {
      <kendo-multiselect [autoClose]="false" [data]="commonDataService.clients()"
        [textField]="'Name'"
        [valueField]="'Id'"
        [valuePrimitive]="true"
        [size]="'small'"
        [fillMode]="'flat'"
        formControlName="ClientIds"
        placeholder="All Clients"
        style="padding-top: 3%;">
      </kendo-multiselect>
    }
    <mat-form-field style="width:unset">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Start Date" (dateChange)="startDateChange($event)" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
      <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width:unset">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="End Date" [min]="form.get('StartDate').value" (dateChange)="endDateChange($event)" autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
      <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
    </mat-form-field>
    <div class="form-group" style="margin: 15px 5px;">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="UpdatePropertyContacts" title="Select to replace the contacts in the relevant properties">Update Property Contacts</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="replace-contacts-content">
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.clientExecutiveLabelText" formControlName="FromClientExecId" [compareWith]="equalsUser" (selectionChange)="onFromClientExecIdChange($event)" title="{{'From ' + commonDataService.clientExecutiveLabelText}}">
          @for (user of clientExecutives; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromClientExecId').value" [placeholder]="'To ' + commonDataService.clientExecutiveLabelText" formControlName="ToClientExecId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.clientExecutiveLabelText}}">
          @for (user of clientExecutives; track user) {
            @if (user.Id !== this.form.get('FromClientExecId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.clientSupportLabelText" formControlName="FromClientSupportId" [compareWith]="equalsUser" (selectionChange)="onFromClientSupportIdChange($event)" title="{{'From ' + commonDataService.clientSupportLabelText}}">
          @for (user of clientSupport; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromClientSupportId').value" [placeholder]="'To ' + commonDataService.clientSupportLabelText" formControlName="ToClientSupportId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.clientSupportLabelText}}">
          @for (user of clientSupport; track user) {
            @if (user.Id !== this.form.get('FromClientSupportId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.briefManagerLabelText" formControlName="FromBriefManagerId" [compareWith]="equalsUser" (selectionChange)="onFromBriefManagerIdChange($event)" title="{{'From ' + commonDataService.briefManagerLabelText}}">
          @for (user of briefManagers; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromBriefManagerId').value" [placeholder]="'To ' + commonDataService.briefManagerLabelText" formControlName="ToBriefManagerId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.briefManagerLabelText}}">
          @for (user of briefManagers; track user) {
            @if (user.Id !== this.form.get('FromBriefManagerId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.projectManagerLabelText" formControlName="FromProjectManagerId" [compareWith]="equalsUser" (selectionChange)="onFromProjectManagerIdChange($event)" title="{{'From ' + commonDataService.projectManagerLabelText}}">
          @for (user of projectManagers; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromProjectManagerId').value" [placeholder]="'To ' + commonDataService.projectManagerLabelText" formControlName="ToProjectManagerId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.projectManagerLabelText}}">
          @for (user of projectManagers; track user) {
            @if (user.Id !== this.form.get('FromProjectManagerId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.nationalProjectManagerLabelText" formControlName="FromNatProjectManagerId" [compareWith]="equalsUser" (selectionChange)="onFromNatProjectManagerIdChange($event)" title="{{'From ' + commonDataService.nationalProjectManagerLabelText}}">
          @for (user of nationalProjectManagers; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromNatProjectManagerId').value" [placeholder]="'To ' + commonDataService.nationalProjectManagerLabelText" formControlName="ToNatProjectManagerId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.nationalProjectManagerLabelText}}">
          @for (user of nationalProjectManagers; track user) {
            @if (user.Id !== this.form.get('FromNatProjectManagerId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.programmerLabelText" formControlName="FromProgrammerId" [compareWith]="equalsUser" (selectionChange)="onFromProgrammerIdChange($event)" title="{{'From ' + commonDataService.programmerLabelText}}">
          @for (user of programmers; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromProgrammerId').value" [placeholder]="'To ' + commonDataService.programmerLabelText" formControlName="ToProgrammerId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.programmerLabelText}}">
          @for (user of programmers; track user) {
            @if (user.Id !== this.form.get('FromProgrammerId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-select [placeholder]="'From ' + commonDataService.adOpsLabelText" formControlName="FromAdOpsId" [compareWith]="equalsUser" (selectionChange)="onFromAdOpsIdChange($event)" title="{{'From ' + commonDataService.adOpsLabelText}}">
          @for (user of adOps; track user) {
            <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select [required]="this.form.get('FromAdOpsId').value" [placeholder]="'To ' + commonDataService.adOpsLabelText" formControlName="ToAdOpsId" [compareWith]="equalsUser" title="{{'To ' + commonDataService.adOpsLabelText}}">
          @for (user of adOps; track user) {
            @if (user.Id !== this.form.get('FromAdOpsId').value) {
              <mat-option [value]="user.Id">{{!user ? "" : user.FirstName  + " " + user.Surname}}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="replace-contacts-footer">
    <button mat-raised-button color="primary" [disabled]="!form.valid || !form.dirty || !hasSomethingToDo || saveInProgress" class="replace-button" (click)="replace()">Replace Contacts</button>
  </div>
</div>

