import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { cancelIcon, saveIcon, SVGIcon } from "@progress/kendo-svg-icons";
import { CommonService } from "../../../shared/services/common.service";
import { AdministrationService } from "../../administration/administration.service";
import { ActivityExtension } from "../calendars.models";
import { ButtonModule } from "@progress/kendo-angular-buttons";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NumericTextBoxModule, TextBoxModule, TextAreaModule } from "@progress/kendo-angular-inputs";
import { ComboBoxModule } from "@progress/kendo-angular-dropdowns";
import { FloatingLabelModule } from "@progress/kendo-angular-label";
import { DialogModule } from "@progress/kendo-angular-dialog";



@Component({
    templateUrl: './activity-extension-edit.component.html',
    selector: 'activity-extension-edit-form',
    styleUrls: ['./activity-extensions.component.scss'],
    providers: [],
    standalone: true,
    imports: [DialogModule, FormsModule, ReactiveFormsModule, FloatingLabelModule, ComboBoxModule, NumericTextBoxModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatCheckboxModule, TextBoxModule, TextAreaModule, ButtonModule]
})
export class ActivityExtensionEditComponent 
{
  constructor(
    public administrationService: AdministrationService,
    public commonService: CommonService
  )
  {



  }

 
  public saveIcon: SVGIcon = saveIcon;
  public cancelIcon: SVGIcon = cancelIcon;

  public active = false;
  public editForm: FormGroup = new FormGroup({
    Id: new FormControl(),
    Extension: new FormControl(),
    TotalCost: new FormControl(),
    ExtensionDate: new FormControl({ value: null, disabled: false }),
    Deliverables: new FormControl(),
    Talent: new FormControl(),
    ExtensionTiming: new FormControl(),
    PlaformAsset: new FormControl(),
    FurtherDetails: new FormControl(),
    SocialBriefDetails: new FormControl(),
    BrandToneKeyMessaging: new FormControl(),
    IsProductSupplied: new FormControl(),
    IsExtensionApproved: new FormControl()

  });

  @Input() public isNew = false;

  @Input() public set model(activityExtension: ActivityExtension)
  {
    this.editForm.reset(activityExtension);

    // toggle the Dialog visibility
    this.active = !!activityExtension;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<ActivityExtension> = new EventEmitter();

  public onSave(e: PointerEvent): void
  {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public onCancel(e: PointerEvent): void
  {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void
  {
    this.active = false;
    this.cancel.emit();
  }


  ngOnInit()
  {


  }


  get dialogHeaderText(): string
  {
    let headerText = "Edit Activity Extension";

    if (this.isNew)
    {
      headerText = "New Activity Extension";
    }

    return headerText;
  }







}

