import { EventEmitter, Injectable, Output, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getDate } from '@progress/kendo-date-math';
import { injectQuery, injectQueryClient } from "@tanstack/angular-query-experimental";
import { addDays, differenceInCalendarDays, format, parse, toDate } from 'date-fns';
import { ActivityInsertResult, DeleteResult, SaveResult } from '../../shared/models/result.model';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { ActivitiesByDateRangeParameters, ActivitiesParameters, Activity, ActivityStatus, CalendarDayLetter, CalendarInfo, CalendarParameters, CalendarStateGroupInfo, Category, EventDay, EventDaysParameters, MechanicType, ProductCategory, Property, PropertyType, Station, StationType, WeekListItem } from './calendars.models';


@Injectable({ providedIn: 'root' })

export abstract class CalendarsBaseService
{
  @Output() calendarResetStarted: EventEmitter<any> = new EventEmitter();
  @Output() calendarDataArrived: EventEmitter<any> = new EventEmitter();
  @Output() calendarInformationUpdated: EventEmitter<any> = new EventEmitter();
  @Output() dateLettersProcessed: EventEmitter<any> = new EventEmitter();

  private queryClient = injectQueryClient();

  public _sanitizer = inject(DomSanitizer);
  public configurationService = inject(ConfigurationService);
  public commonService = inject(CommonService);
  private dataHandler = inject(DataHandlerService);

  protected abstract isOnline: boolean;

  public homeStation: Station = null;

  private _availableStations: Station[] = [];

  private _stationIdOfClickedActivity: number;

  private _selectedStationGroupId: number = 0;
  private _selectedCategoryIds: number[] = [];
  private _selectedWeek: WeekListItem = null;
  private _selectedYear: number = this.commonService.today.getFullYear();
  private _availableOnly: boolean = false;
  private _noOfStations = 0;
  private _colSpan = 0;
  private _stationsColSpan = 0;

  private _noOfDays = 0;
  private _noOfWeeks = 5;
  private _weeksForSelectedYear: WeekListItem[] = undefined;
  private _years: number[] = [];
  private _statuses: ActivityStatus[] = [];
  private _dataResetFromActivitySave = false;
  private _dataResetFromCategorySave = false;
  private _dataResetFromPropertySave = false;

  private _currentEventDay = 1;
  private _currentEventMonth = 0;
  private _currentEventYear = 0;
  private _currentEventStationId = 0;
  private _currentEventStationTypeId = 0;



  public currentStations: WritableSignal<Station[]> = signal([]);

  public selectedPropertyTypeIds: WritableSignal<number[]> = signal([]);

  public startDate: WritableSignal<Date> = signal(new Date());
  public endDate: WritableSignal<Date> = signal(addDays(new Date(), ((this.noOfWeeks < 1 ? 1 : 0) * 7) - 1));

  public selectedPropertyTypeIdsChangedEffect = effect(() =>
  {
    localStorage.setItem("selectedPropertyTypeIds", JSON.stringify(this.selectedPropertyTypeIds()));
  });


  public calendarParameters: Signal<CalendarParameters> = computed(() =>
  {
    const eDate: string = format(this.endDate(), "yyyy-MM-dd");
    const sDate: string = format(this.startDate(), "yyyy-MM-dd");

    let selectedStationIds: number[] = [];

    if (this.commonService.hasUserInfoLoaded())
    {
      if (this.commonService.homeStationId > 0)
      {
        selectedStationIds.push(this.commonService.homeStationId);
      }

      if (!!this.selectedStations && this.selectedStations.length > 0)
      {
        selectedStationIds = this.selectedStations.map<number>(x => x.Id);
      }
    }

    const calendarParams: CalendarParameters = {
      CategoryIds: this.selectedCategoryIds,
      endDate: eDate,
      isOnline: this.isOnline,
      noOfStations: this.noOfStations,
      noOfWeeks: this.noOfWeeks,
      PropertyTypeIds: this.selectedPropertyTypeIds(),
      startDate: sDate,
      stationIds: selectedStationIds,
      availableOnly: this.availableOnly
    };

    return calendarParams;
  });


  public gueryCalendarHtml = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0 && !!this.calendarParameters()?.stationIds && this.calendarParameters().stationIds.length > 0,
    queryKey: ["Calendar", this.calendarParameters()],
    queryFn: () => this.getEventDaysAndCalendarHtml(this.calendarParameters()),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public properties: Signal<Property[]> = computed(() =>
  {
    const result: any = this.gueryProperties.data();

    return result;
  });

  public gueryProperties = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["Properties"],
    queryFn: () => this.getProperties(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public categories: Signal<Category[]> = computed(() =>
  {
    const result: any = this.gueryCategories.data();

    return result;
  });

  public gueryCategories = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["Categories"],
    queryFn: () => this.getCategories(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public stationGroups: Signal<StationType[]> = computed(() =>
  {
    const result: any = this.gueryStationGroups.data();

    return result;
  });

  public gueryStationGroups = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["StationGroups"],
    queryFn: () => this.getStationGroups(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public productCategories: Signal<ProductCategory[]> = computed(() =>
  {
    const result: any = this.gueryProductCategories.data();

    return result;
  });

  public gueryProductCategories = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["ProductCategories"],
    queryFn: () => this.getProductCategories(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public mechanicTypes: Signal<MechanicType[]> = computed(() =>
  {
    const result: any = this.gueryMechanicTypes.data();

    return result;
  });

  public gueryMechanicTypes = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["MechanicTypes"],
    queryFn: () => this.getMechanicTypes(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public propertyTypes: Signal<PropertyType[]> = computed(() =>
  {
    const result: any = this.gueryPropertyTypes.data();

    return result;
  });

  public gueryPropertyTypes = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["PropertyTypes"],
    queryFn: () => this.getPropertyTypes(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public stations: Signal<Station[]> = computed(() =>
  {
    const result: any = this.gueryStations.data();

    return result;
  });

  public gueryStations = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["Stations"],
    queryFn: () => this.getStations(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public weeks: Signal<WeekListItem[]> = computed(() =>
  {
    const result: any = this.gueryWeekListItems.data();

    if (result && result.length > 0)
    {
      result.map((w: WeekListItem) => w.WeekCommencingDate = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today));

      if (!this.setWeekFromStorage())
      {
        const weekForToday = result.filter((w: WeekListItem) =>
        {
          const weekCommencingDate: Date = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today);

          return weekCommencingDate <= getDate(this.commonService.today) && addDays(weekCommencingDate, 6) >= getDate(this.commonService.today);
        })[0];

        this.selectedWeek = weekForToday;
      }
    }

    return result;
  });

  public gueryWeekListItems = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["Weeks"],
    queryFn: () => this.getWeekListItems(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public dayLetters: CalendarDayLetter[] = [];

  private updateDayletters(eventDays: EventDay[])
  {
    const dls: CalendarDayLetter[] = [];

    if (eventDays)
    {
      try
      {
        const lastStationId = this.selectedStations[this.selectedStations.length - 1].Id;

        this.selectedStations.forEach((s) =>
        {
          let daycount = 1;
          const currDate: Date = new Date(this.startDate().valueOf());
          let calendarWeek = 1;

          while (currDate <= this.endDate())
          {
            let eventCount = 0;
            let eventTooltip = "";

            let eventDay: EventDay = null;

            if (eventDays && eventDays.length > 0)
            {
              eventDay = eventDays.find(e => this.commonService.parseJsonDate(e.EventDate).valueOf() == currDate.valueOf() && e.StationId == s.Id);

              if (eventDay)
              {
                eventCount = eventDay.EventCount;
                eventTooltip = eventDay.EventsTooltip;
              }
            }

            const cdl: CalendarDayLetter = new CalendarDayLetter();
            cdl.Id = currDate.valueOf() + s.Id;
            cdl.Letter = currDate.toLocaleDateString(navigator.language, { weekday: 'narrow' });
            cdl.DayNumber = currDate.getDate().toString();
            cdl.EventMonth = currDate.getMonth();
            cdl.EventYear = currDate.getFullYear();
            cdl.EventStationId = s.Id;
            cdl.EventStationTypeId = s.StationTypeId;
            cdl.EventCount = eventCount;
            cdl.EventsTooltip = eventTooltip;

            dls.push(cdl);

            //increment date
            currDate.setDate(currDate.getDate() + 1);

            if (daycount == 7)
            {
              const divider = new CalendarDayLetter();
              divider.LastDivider = s.Id == lastStationId && currDate > this.endDate();
              divider.Id = currDate.valueOf() + s.Id + calendarWeek;

              //add week divider cells
              dls.push(divider);

              //reset for new week
              daycount = 1;

              calendarWeek++
            }
            else
            {
              daycount++;
            }
          }
        });
      }
      catch (e: any)
      {

      }
    }

    this.dateLettersProcessed.emit(true);

    this.dayLetters = dls;
  }


  public calendarInformation: Signal<SafeHtml> = computed(() =>
  {
    let result: any = null;

    if (this.commonService.isLoggedIn()
      && this.configurationService.cbSettings().calendarServiceUrl.length > 0
      && !!this.calendarParameters()?.stationIds
      && this.calendarParameters().stationIds.length > 0)
    {
      result = this.gueryCalendarHtml.data();
    }

    try
    {
      this.calendarDataArrived.emit(true);

      if (result)
      {
        this.updateDayletters(result.EventDays);

        const stationIds: number[] = [];
        this.selectedStations.map((s) => stationIds.push(s.Id));

        let colspan = 1;

        if (this.noOfDays && this.noOfStations)
        {
          colspan = (this.noOfDays + (this.noOfWeeks * this.noOfStations));
        }

        if (colspan < 1)
        {
          colspan = 1;
        }

        this._colSpan = colspan;

        colspan = 1;

        if (this.noOfDays && this.noOfStations)
        {
          colspan = (this.noOfDays + (this.noOfWeeks * this.noOfStations)) / this.noOfStations;
        }

        if (colspan < 1)
        {
          colspan = 1;
        }

        this._stationsColSpan = colspan;

        const calendarStateGroupInfo: CalendarStateGroupInfo = new CalendarStateGroupInfo();
        calendarStateGroupInfo.EndDate = this.endDate();
        calendarStateGroupInfo.IsOnline = this.isOnline;
        calendarStateGroupInfo.NoOfWeeks = this.noOfWeeks;
        calendarStateGroupInfo.StartDate = this.startDate();
        calendarStateGroupInfo.StationIds = stationIds;

        this.commonService.appSignalRService.run('JoinCalendarStateGroupAsync', calendarStateGroupInfo);

        result.CalendarHtml = this.transformCalendarHtml(result.CalendarHtml);
        result.CalendarSafeHtml = this._sanitizer.bypassSecurityTrustHtml(result.CalendarHtml);

        if (this.dataResetFromActivitySave || this.dataResetFromCategorySave || this.dataResetFromPropertySave)
        {
          this.commonService.sendCalendarHtml(result.CalendarHtml);

          //only activity saves will affect dashboard data
          if (this.dataResetFromActivitySave)
          {
            this.commonService.refreshDashboard();
          }

          this.dataResetFromActivitySave = false;
          this.dataResetFromCategorySave = false;
          this.dataResetFromPropertySave = false;
        }

        this.calendarInformationUpdated.emit(true);

        const previousWeek: WeekListItem = this.getPreviousWeekById(this.selectedWeek.Id);

        if (previousWeek)
        {
          const sDate = this.getStartDateForWeekNo(previousWeek.WeekNo);

          let eDate = new Date(sDate); //clone start date
          eDate = addDays(eDate, (this.noOfWeeks * 7) - 1);

          const weekBeforeCalendarParameters = {
            ...this.calendarParameters(),
            startDate: this.commonService.dateToWebApiString(sDate),
            endDate: this.commonService.dateToWebApiString(eDate)
          };

          this.queryClient.prefetchQuery({
            queryKey: ["Calendar", weekBeforeCalendarParameters],
            queryFn: () => this.getEventDaysAndCalendarHtml(weekBeforeCalendarParameters),
            //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
          });
        }

        const nextWeek: WeekListItem = this.getNextWeekById(this.selectedWeek.Id);

        if (nextWeek)
        {
          const sDate = this.getStartDateForWeekNo(nextWeek.WeekNo);

          let eDate = new Date(sDate); //clone start date
          eDate = addDays(eDate, (this.noOfWeeks * 7) - 1);

          const weekAfterCalendarParameters = {
            ...this.calendarParameters(),
            startDate: this.commonService.dateToWebApiString(sDate),
            endDate: this.commonService.dateToWebApiString(eDate)
          };

          this.queryClient.prefetchQuery({
            queryKey: ["Calendar", weekAfterCalendarParameters],
            queryFn: () => this.getEventDaysAndCalendarHtml(weekAfterCalendarParameters),
            //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
          });
        }
      }
    }
    catch (e)
    {
      this.calendarDataArrived.emit(true);
    }

    if (result?.CalendarSafeHtml)
    {
      this.setCalendarScrollPosition();
    }

    return result?.CalendarSafeHtml;
  });



  constructor()
  {

  }

  public load()
  {
    this.weeks();
  }

  public resetHttpCache()
  {
    this.dataHandler.resetCache();
  }

  get dataResetFromActivitySave(): boolean
  {
    return this._dataResetFromActivitySave;
  }
  set dataResetFromActivitySave(value: boolean)
  {
    this._dataResetFromActivitySave = value;
  }

  get dataResetFromCategorySave(): boolean
  {
    return this._dataResetFromCategorySave;
  }
  set dataResetFromCategorySave(value: boolean)
  {
    this._dataResetFromCategorySave = value;
  }

  get dataResetFromPropertySave(): boolean
  {
    return this._dataResetFromPropertySave;
  }
  set dataResetFromPropertySave(value: boolean)
  {
    this._dataResetFromPropertySave = value;
  }



  get stationIdOfClickedActivity(): number
  {
    return this._stationIdOfClickedActivity;
  }
  set stationIdOfClickedActivity(value: number)
  {
    this._stationIdOfClickedActivity = value;
  }



  get selectedStationGroupId(): number
  {
    return this._selectedStationGroupId;
  }
  set selectedStationGroupId(value: number)
  {
    this._selectedStationGroupId = value;
    this._availableStations = [];
  }


  get selectedStations(): Station[]
  {
    return this.currentStations();
  }
  set selectedStations(value: Station[])
  {
    this.currentStations.set(value.sort((a, b) =>
    {
      // Sort by SortOrder
      // If the first item has a higher number, move it up
      // If the first item has a lower number, move it down
      if (a.SortOrder > b.SortOrder) return 1;
      if (a.SortOrder < b.SortOrder) return -1;

      // If the SortOrder number is the same between both items, sort alphabetically
      // If the first item comes first in the alphabet, move it up
      // Otherwise move it down
      if (a.StationName > b.StationName) return 1;
      if (a.StationName < b.StationName) return -1;

      return 1;
    }));

    localStorage.setItem("selectedStations", JSON.stringify(this.currentStations()));
  }

  get selectedCategoryIds(): number[]
  {
    return this._selectedCategoryIds;
  }
  set selectedCategoryIds(value: number[])
  {
    this._selectedCategoryIds = value;

    localStorage.setItem("selectedCategoryIds", JSON.stringify(value));
  }

  get selectedPropertyTypeNames(): string
  {
    let name = "All Property Types";  // if none selected name is "ALL"

    if (this.selectedPropertyTypeIds() && this.selectedPropertyTypeIds().length > 0)
    {
      const pts = this.propertyTypes().filter(p => this.selectedPropertyTypeIds().some(spi => spi == p.Id));

      if (pts && pts.length > 0)
      {
        //If all selected then choose name is "ALL" as well
        if (pts.length < this.propertyTypes().length)
        {
          name = "";

          pts.map(p =>
          {
            name += p.PropertyTypeName + ", ";
          });

          //remove trailing comma
          name = name.substring(0, name.length - 2);
        }
      }
    }

    return name;
  }


  setNoOfWeeks()
  {
    switch (this.selectedStations.length)
    {
      case 0:
        this.noOfWeeks = 5;
        break;
      case 1:
        this.noOfWeeks = 5;
        break;
      case 2:
        this.noOfWeeks = 2;
        break;
      case 3:
        this.noOfWeeks = 1;
        break;
      default:
        this.noOfWeeks = 1;
        break;
    }
  }

  get currentEventDay(): number
  {
    return this._currentEventDay;
  }
  set currentEventDay(value: number)
  {
    this._currentEventDay = value;
  }

  get currentEventMonth(): number
  {
    return this._currentEventMonth;
  }
  set currentEventMonth(value: number)
  {
    this._currentEventMonth = value;
  }

  get currentEventYear(): number
  {
    return this._currentEventYear;
  }
  set currentEventYear(value: number)
  {
    this._currentEventYear = value;
  }

  get currentEventStationId(): number
  {
    return this._currentEventStationId;
  }
  set currentEventStationId(value: number)
  {
    this._currentEventStationId = value;
  }

  get currentEventStationTypeId(): number
  {
    return this._currentEventStationTypeId;
  }
  set currentEventStationTypeId(value: number)
  {
    this._currentEventStationTypeId = value;
  }

  get selectedWeek(): WeekListItem
  {
    return this._selectedWeek;
  }
  set selectedWeek(value: WeekListItem)
  {
    this._selectedWeek = value;

    if (!this.startDate() && value && value.WeekNo > 0)
    {
      this.startDate.set(this.getStartDateForWeekNo(value.WeekNo));
    }

    localStorage.setItem("selectedWeek", JSON.stringify(value));
  }

  get availableOnly(): boolean
  {
    this._availableOnly = JSON.parse(localStorage.getItem("availableOnly"));

    if (this._availableOnly == null)
    {
      this._availableOnly = false;
    }

    return this._availableOnly;
  }
  set availableOnly(value: boolean)
  {
    this._availableOnly = value;

    localStorage.setItem("availableOnly", JSON.stringify(value));
  }

  get selectedYear(): number
  {
    if (!this._selectedYear)
    {
      this._selectedYear = this.commonService.today.getFullYear();
    }

    return this._selectedYear;
  }
  set selectedYear(value: number)
  {
    this._selectedYear = value;

    localStorage.setItem("selectedYear", JSON.stringify(value));
  }

  get noOfWeeks(): number
  {
    return this._noOfWeeks;
  }
  set noOfWeeks(value: number)
  {
    if (value != null && value > 0)
    {
      if (this.startDate())
      {
        const eDate: Date = new Date(this.startDate().valueOf())
        eDate.setDate(toDate(this.startDate()).getDate() + ((value * 7) - 1));

        this.endDate.set(eDate);
      }

      this._noOfWeeks = value;
    }
  }

  get years(): number[]
  {
    if (this.weeks() && (!this._years || this._years.length == 0))
    {
      this._years = this.weeks().reduce((a, d) =>
      {
        if (!a.includes(d["Year"])) { a.push(d["Year"]); }
        return a;
      }, []);

      //for (var year = this.minDate.getFullYear(); year <= this.maxDate.getFullYear(); year++)
      //{
      //  this._years.push(year);
      //}
    }

    // return Array.from(this._years);
    return this._years;
  }

  get weeksForSelectedYear(): WeekListItem[]
  {
    if (!this._weeksForSelectedYear || this._weeksForSelectedYear.length < 1 || this._weeksForSelectedYear[0].Year != this.selectedYear)
    {
      if (this.weeks() && this.weeks().length > 0 && this.selectedYear)
      {
        this._weeksForSelectedYear = this.weeks().filter(w => w.Year == this.selectedYear);
      }
      else
      {
        this._weeksForSelectedYear = [];
      }
    }

    return this._weeksForSelectedYear;
  }

  getPreviousWeekById(id: number): WeekListItem
  {
    let week: WeekListItem = undefined;

    if (this.weeks() && this.weeks().length > 0)
    {
      const prevId = id - 1;

      if (prevId > 0 && this.weeks().some((w: WeekListItem) => w.Id == prevId))
      {
        week = this.weeks().filter((w: WeekListItem) => w.Id == prevId)[0];
      }
    }

    return week;
  }

  getNextWeekById(id: number): WeekListItem
  {
    let week: WeekListItem = undefined;

    if (this.weeks() && this.weeks().length > 0)
    {
      const nextId = id + 1;

      if (this.weeks().some((w: WeekListItem) => w.Id == nextId))
      {
        week = this.weeks().filter((w: WeekListItem) => w.Id == nextId)[0];
      }
    }

    return week;
  }


  getWeekNoForDate(date: Date): number
  {
    let weekNo = 1;

    if (this.weeks() && this.weeks().length > 0)
    {
      if (this.weeks().some((w: WeekListItem) =>
      {
        const weekCommencingDate: Date = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today);

        return weekCommencingDate <= getDate(date) && addDays(weekCommencingDate, 6) >= getDate(date);
      }))
      {
        weekNo = this.weeks().filter((w: WeekListItem) =>
        {
          const weekCommencingDate: Date = parse(w.WeekCommencingDateString, "yyyy-MM-dd", this.commonService.today);

          return weekCommencingDate <= getDate(date) && addDays(weekCommencingDate, 6) >= getDate(date);
        })[0].WeekNo;
      }
    }

    return weekNo;
  }

  getStartDateForWeekNo(weekNo: number): Date
  {
    let selectedDate: Date = this.startDate();
    let selectedDateString = "";

    if (weekNo > 0 && this.weeksForSelectedYear && this.weeksForSelectedYear.length > 0)
    {
      if (this.weeksForSelectedYear.some((w: WeekListItem) => w.WeekNo == weekNo))
      {
        selectedDateString = this.weeksForSelectedYear.filter((w: WeekListItem) => w.WeekNo == weekNo)[0].WeekCommencingDateString;
      }
      else
      {
        //Select 1st week if unable to get week for week number
        this.selectedWeek = this.weeksForSelectedYear.filter((w: WeekListItem) => w.WeekNo == 1)[0];

        selectedDateString = this.selectedWeek.WeekCommencingDateString;
      }

      selectedDate = parse(selectedDateString, "yyyy-MM-dd", this.commonService.today);
    }

    return selectedDate;
  }




  get statuses(): ActivityStatus[]
  {
    if (!this._statuses || this._statuses.length == 0)
    {
      this._statuses = [
        { Name: 'Pending', Value: 'Pending' },
        { Name: 'On Hold', Value: 'OnHold' },
        { Name: 'Submitted For Delivery', Value: 'IsUnConfirmedSold' },
        { Name: 'First Right Refusal', Value: 'IsGuaranteed' },
        { Name: 'Confirmed', Value: 'IsSold' }
      ];
    }

    return this._statuses;
  }

  get noOfDays(): number
  {
    return this._noOfDays;
  }

  get colSpan(): number
  {
    return this._colSpan;
  }

  get stationsColSpan(): number
  {
    return this._stationsColSpan;
  }

  cssGridSpan(span: number): string
  {
    return "span " + span;
  }

  get noOfStations(): number
  {
    return this._noOfStations;
  }

  resetSelectedStations()
  {
    if (!this.selectedStations)
    {
      this.selectedStations = [];
    }

    if (this.availableStations && this.availableStations.length > 0)
    {
      if (this.selectedStationGroupId == 0) //ALL
      {
        this.selectedStations = [];

        const propTypes = this.propertyTypes().filter(pt => this.selectedPropertyTypeIds().includes(pt.Id) || (this.selectedPropertyTypeIds().length == 1 && this.selectedPropertyTypeIds()[0] == 0));

        if (propTypes && propTypes.length > 0 && !propTypes.some(pt => pt.IsBroadcast))
        {
          this.selectedStations = this.availableStations;
        }
      }
      else
      {
        //Remove selected stations from other Station Groups
        this.selectedStations = this.selectedStations.filter(s => s.StationTypeId == this.selectedStationGroupId);
      }

      //Add default selected station if all were another station group which wasn't ALL
      if (this.selectedStations.length == 0)
      {
        const newSelectedStations: Station[] = [];

        if (this.homeStation
          && (this.homeStation.StationTypeId == this.selectedStationGroupId || this.selectedStationGroupId == 0)
          && this.availableStations.map(s => s.StationTypeId).includes(this.homeStation.StationTypeId)) //All is StationGroupId 0
        {
          newSelectedStations.push(this.homeStation);
        }
        else
        {
          newSelectedStations.push(this.availableStations[0]);
        }

        //selected Stations set NOT added to with push so selectedStations localStorage saved
        this.selectedStations = newSelectedStations;
      }
    }
  }



  get currentProperties(): Property[]
  {
    return this.properties().filter(p => !p.Disabled && !p.Deleted && (p.IsPermanent || (toDate(p.StartDate) <= this.commonService.today && toDate(p.EndDate) >= this.commonService.today)));
  }


  get availableStations(): Station[]
  {
    if (!this._availableStations || this._availableStations.length == 0 && this.stations())
    {
      if (this.selectedStationGroupId != null)
      {
        if (this.selectedStationGroupId == 0
          && (!this.selectedPropertyTypeIds()
            || this.selectedPropertyTypeIds().length == 0
            || (this.selectedPropertyTypeIds().length == 1 && this.selectedPropertyTypeIds()[0] == 0)))
        {
          this._availableStations = this.stations();
        }
        else
        {
          if (this.selectedStationGroupId > 0)
          {
            this._availableStations = this.stations().filter((s) => s.StationType?.Id == this.selectedStationGroupId);
          }
          else
          {
            const propertyTypes = this.propertyTypes().filter(f => this.selectedPropertyTypeIds().includes(f.Id));

            this._availableStations = this.stations().filter(f => (f.PlatformType?.Name == "Internet" && propertyTypes.some(s => !s.IsBroadcast)) || (f.PlatformType?.Name == "Radio Station" && propertyTypes.some(s => s.IsBroadcast)));
          }
        }
      }

      //Ensure that there are always stations returned so if the above results in nothing, return all
      if (!this._availableStations || this._availableStations.length == 0)
      {
        this._availableStations = this.stations();
      }
    }

    return this._availableStations;
  }

  setCalendarScrollPosition()
  {
    setTimeout(() =>
    {
      const calendarElement: HTMLElement = document.getElementById("core-calendar");

      if (calendarElement)
      {
        // Retrieve the stored scroll position from localStorage
        const storedScrollPosition = localStorage.getItem("calendarScroll");

        // If a stored scroll position exists, scroll the sidebar to that position
        if (storedScrollPosition !== null && storedScrollPosition !== undefined)
        {
          calendarElement.scrollTop = Number(storedScrollPosition);
        }
      }
    }, 0)
  }


  resetCalendarData()
  {
    this.calendarResetStarted.emit(true);

    let stationsQty = 1;

    if (this.selectedStations && this.selectedStations.length > 0)
    {
      stationsQty = this.selectedStations.length;
    }

    this._noOfStations = stationsQty;

    if (this.selectedWeek)
    {
      const sDate = this.selectedWeek.WeekCommencingDate;
      const eDate = addDays(sDate, (this.noOfWeeks * 7) - 1);

      this._noOfDays = (differenceInCalendarDays(eDate, sDate) + 1) * this.noOfStations;

      this.startDate.set(sDate);
      this.endDate.set(eDate);
    }
  }



  transformCalendarHtml(html: string)
  {
    if (html && (html.includes("z1") || html.includes("z5")))
    {
      html = html.replace(/z0/g, "<div class='ch c' style='grid-area:");
      html = html.replace(/z1/g, "<div class='c' style='grid-area:");
      html = html.replace(/z2/g, "/1/span 1/span 1;' onclick='window.my.cc(event,");
      html = html.replace(/z3/g, "/2/span 1/span");
      html = html.replace(/z4/g, ";' onclick='window.my.cc(event,");
      html = html.replace(/z5/g, "<div class='pr' title='Id:");
      html = html.replace(/z6/g, "style='grid-area:");
      html = html.replace(/z7/g, ";' onclick='window.my.pc(event,");
      html = html.replace(/z8/g, "<div class=");
      html = html.replace(/z9/g, "oncontextmenu='window.my.arc(event)' onmousedown=\"window.my.ac(event,'");
      // html = html.replace(/q0/g, "oncontextmenu=\"window.my.arc(event,'");
      html = html.replace(/q1/g, "<input id='");
      html = html.replace(/q2/g, "</div>");
      html = html.replace(/q3/g, "'><div class='l'>");
      html = html.replace(/q4/g, "' type='hidden' value='");
      html = html.replace(/q5/g, "onmouseenter=\"window.my.ah(event,'");
      html = html.replace(/q6/g, "onmouseenter='window.my.ph(event,");
    }

    return html;
  }



  getStationNameFromId(id: number): string
  {
    let name = "";

    const station: Station = this.stations().filter(s => s.Id == id)[0];

    if (station)
    {
      name = station.StationName;
    }

    return name;
  }

  setWeekFromStorage(): boolean
  {
    const selectedWeek = JSON.parse(localStorage.getItem("selectedWeek"));

    if (selectedWeek)
    {
      this.selectedWeek = selectedWeek;

      return true;
    }

    return false;
  }

  setStationsFromStorage(): boolean
  {
    const selectedStations = JSON.parse(localStorage.getItem("selectedStations"));

    if (selectedStations && Array.isArray(selectedStations) && selectedStations.length > 0)
    {
      this.selectedStations = selectedStations;

      const stationGroupId = this.selectedStations[0].StationTypeId;
      this.selectedStationGroupId = stationGroupId;

      if (this.selectedStations.some(s => s.StationTypeId != stationGroupId))
      {
        //set to all groups if selected stations in different groups
        this.selectedStationGroupId = 0;
      }

      this.setNoOfWeeks();

      return true;
    }

    return false;
  }





  getWeekListItems(useCachedData: boolean = false): Promise<WeekListItem[]>
  {
    return this.dataHandler.getHttpPromise<WeekListItem[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetAllWeekListItems", useCachedData, false);
  }

  getStationGroups(useCachedData: boolean = false): Promise<StationType[]>
  {
    return this.dataHandler.getHttpPromise<StationType[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetStationGroups", useCachedData, false);
  }

  getStations(useCachedData: boolean = false): Promise<Station[]>
  {
    return this.dataHandler.getHttpPromise<Station[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetStations", useCachedData, false);
  }

  getPropertyTypes(useCachedData: boolean = false): Promise<PropertyType[]>
  {
    return this.dataHandler.getHttpPromise<PropertyType[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetPropertyTypes?isOnline=" + this.isOnline, useCachedData, false);
  }

  getMechanicTypes(useCachedData: boolean = false): Promise<MechanicType[]>
  {
    return this.dataHandler.getHttpPromise<MechanicType[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetMechanicTypes", useCachedData, false);
  }

  getProductCategories(useCachedData: boolean = false): Promise<ProductCategory[]>
  {
    return this.dataHandler.getHttpPromise<ProductCategory[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetProductCategories", useCachedData, false);
  }

  getCategory(id: number, useCachedData: boolean = false): Promise<Category>
  {
    return this.dataHandler.getHttpPromise<Category>(this.configurationService.cbSettings().calendarServiceUrl + "/GetCategory?id=" + id, useCachedData, false);
  }

  getCategories(useCachedData: boolean = false): Promise<Category[]>
  {
    return this.dataHandler.getHttpPromise<Category[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetCategories?isOnline=" + this.isOnline, useCachedData, false);
  }

  createCategory(category: Category, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().calendarServiceUrl + "/CreateCategory", category, useCachedData, false);
  }

  updateCategory(category: Category): Promise<SaveResult>
  {
    return this.dataHandler.putHttpPromise<SaveResult>(this.configurationService.cbSettings().calendarServiceUrl + "/UpdateCategory", category, false);
  }

  deleteCategory(category: Category): Promise<DeleteResult>
  {
    return this.dataHandler.putHttpPromise<DeleteResult>(this.configurationService.cbSettings().calendarServiceUrl + "/DeleteCategory", category, false);
  }


  getProperty(id: number, useCachedData: boolean = false): Promise<Property>
  {
    return this.dataHandler.getHttpPromise<Property>(this.configurationService.cbSettings().calendarServiceUrl + "/GetProperty?id=" + id, useCachedData, false);
  }

  getProperties(useCachedData: boolean = false): Promise<Property[]>
  {
    return this.dataHandler.getHttpPromise<Property[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetProperties?isOnline=" + this.isOnline, useCachedData, false);
  }

  createProperty(property: Property, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().calendarServiceUrl + "/CreateProperty", property, useCachedData, false);
  }

  updateProperty(property: Property): Promise<SaveResult>
  {
    return this.dataHandler.putHttpPromise<SaveResult>(this.configurationService.cbSettings().calendarServiceUrl + "/UpdateProperty", property, false);
  }

  deleteProperty(property: Property): Promise<DeleteResult>
  {
    return this.dataHandler.putHttpPromise<DeleteResult>(this.configurationService.cbSettings().calendarServiceUrl + "/DeleteProperty", property, false);
  }


  getActivity(id: number, isBlocker: boolean = false, useCachedData: boolean = false): Promise<Activity>
  {
    if (id > 0)
    {
      return this.dataHandler.getHttpPromise<Activity>(this.configurationService.cbSettings().calendarServiceUrl + "/GetActivity?id=" + id + "&isBlocker=" + isBlocker, useCachedData, false);
    }
    else
    {
      return Promise.resolve(null);
    }
  }

  getActivitiesForPropertyAndDate(propertyId: number, spotDate: Date, stationIds: number[], level: number, useCachedData: boolean = false): Promise<Activity[]>
  {
    const activitiesParameters: ActivitiesParameters = new ActivitiesParameters();
    activitiesParameters.spotDate = spotDate;
    activitiesParameters.stationIds = stationIds;
    activitiesParameters.propertyId = propertyId;
    activitiesParameters.level = level;

    return this.dataHandler.postHttpPromise<Activity[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetActivitiesForPropertyAndDate", activitiesParameters, useCachedData, false, false);
  }

  getActivitiesForPropertyAndDateRangeNp(propertyId: number, startDate: string, endDate: string, stationIds: number[], level: number, useCachedData: boolean = false): Promise<Activity[]>
  {
    const activitiesParameters: ActivitiesByDateRangeParameters = new ActivitiesByDateRangeParameters();
    activitiesParameters.startDate = startDate;
    activitiesParameters.endDate = endDate;
    activitiesParameters.stationIds = stationIds;
    activitiesParameters.propertyId = propertyId;
    activitiesParameters.level = level;

    return this.getActivitiesForPropertyAndDateRange(activitiesParameters);
  }

  getActivitiesForPropertyAndDateRange(activitiesParameters: ActivitiesByDateRangeParameters, useCachedData: boolean = false): Promise<Activity[]>
  {
    return this.dataHandler.postHttpPromise<Activity[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetActivitiesForPropertyAndDateRange", activitiesParameters, useCachedData, false, false);
  }


  createActivity(activity: Activity, useCachedData: boolean = false): Promise<ActivityInsertResult>
  {
    return this.dataHandler.postHttpPromise<ActivityInsertResult>(this.configurationService.cbSettings().calendarServiceUrl + "/CreateActivity", activity, useCachedData, false);
  }

  updateActivity(activity: Activity): Promise<SaveResult>
  {
    return this.dataHandler.putHttpPromise<SaveResult>(this.configurationService.cbSettings().calendarServiceUrl + "/UpdateActivity", activity, false);
  }

  deleteActivity(activity: Activity): Promise<DeleteResult>
  {
    return this.dataHandler.putHttpPromise<DeleteResult>(this.configurationService.cbSettings().calendarServiceUrl + "/DeleteActivity", activity, false);
  }

  getEventDays(startDate: Date, endDate: Date, stationIds: number[], useCachedData: boolean = false): Promise<EventDay[]>
  {
    const eventDaysParameters: EventDaysParameters = new EventDaysParameters();
    eventDaysParameters.startDate = startDate;
    eventDaysParameters.endDate = endDate;
    eventDaysParameters.stationIds = stationIds;

    return this.dataHandler.postHttpPromise<EventDay[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetEventDays", eventDaysParameters, useCachedData, false, false);
  }


  getEventDaysAndCalendarHtml(calendarParameters: CalendarParameters, useCachedData: boolean = false): Promise<CalendarInfo>
  {
    return this.dataHandler.postHttpPromise<CalendarInfo>(this.configurationService.cbSettings().calendarServiceUrl + "/GetEventDaysAndCalendarHtml", calendarParameters, useCachedData, false, false);
  }


}










