import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DatePickerModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { EditMode, SchedulerModule } from '@progress/kendo-angular-scheduler';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { MatAutoFocusDirective } from '../../shared/directives/mat-auto-focus.directive';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CalendarsService } from '../calendars/calendars.service';
import { MyEvent } from './diary.interfaces';
import { DiaryService } from './diary.service';

import { ConfigurationService } from '../../shared/services/configuration.service';

@Component({
  selector: 'event-edit-form',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./diary.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutoFocusDirective, MultiSelectModule, DropDownListModule, LabelModule, DatePickerModule, TimePickerModule, SchedulerModule, ButtonModule]
})
export class EventEditFormComponent
{
  @Input()
  public isNew = false;

  @Input()
  public editMode: EditMode;

  @Input()
  public set event(ev: any)
  {
    if (ev !== null && ev !== undefined)
    {
      this.editForm.reset(ev);
      this.active = true;
    }
  }

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  public save: EventEmitter<any> = new EventEmitter();

  public active = false;
  public saveInProgress = false;
  public formInitiallyDisabled = false;

  public editForm = new UntypedFormGroup({
    'Id': new UntypedFormControl(),
    'Title': new UntypedFormControl('', Validators.required),
    'Description': new UntypedFormControl(''),
    'StartDate': new UntypedFormControl(null, Validators.required),
    'EndDate': new UntypedFormControl(null, Validators.required),
    'IsAllDayEvent': new UntypedFormControl(false),
    'RecurrencePattern': new UntypedFormControl(),
    'StationIds': new UntypedFormControl(null, Validators.required),
    'EventStations': new UntypedFormControl(),
    'EventCategoryId': new UntypedFormControl({ value: 0, disabled: false }, Validators.required)
  });

  public get isEditingSeries(): boolean
  {
    return this.editMode === EditMode.Series;
  }

  public groupedData: GroupResult[] = groupBy(this.availableStations, [{ field: 'StationType.StationTypeName' }]);

  private configurationService = inject(ConfigurationService);

  constructor(
    private authorisationService: AuthorisationService,
    public diaryService: DiaryService,
    private calendarsService: CalendarsService
  )
  {

  }

  ngOnInit(): void
  {
    this.formInitiallyDisabled = (this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.AddWhatsOnEvent)) || (!this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditWhatsOnEvent));
    this.disableFormControls(this.formInitiallyDisabled);
  }


  disableFormControls(disable: boolean)
  {
    this.editForm.get('Title').disable({ emitEvent: false });
    this.editForm.get('Description').disable({ emitEvent: false });
    this.editForm.get('StartDate').disable({ emitEvent: false });
    this.editForm.get('EndDate').disable({ emitEvent: false });
    this.editForm.get('IsAllDayEvent').disable({ emitEvent: false });
    this.editForm.get('RecurrencePattern').disable({ emitEvent: false });
    this.editForm.get('StationIds').disable({ emitEvent: false });
    this.editForm.get('EventStations').disable({ emitEvent: false });
    this.editForm.get('EventCategoryId').disable({ emitEvent: false });

    if (!disable && !this.saveInProgress)
    {
      this.editForm.get('Title').enable({ emitEvent: false });
      this.editForm.get('Description').enable({ emitEvent: false });
      this.editForm.get('StartDate').enable({ emitEvent: false });

      if (!this.isOneDayOnlyRecurrenceType)
      {
        this.editForm.get('EndDate').enable({ emitEvent: false });
      }

      this.editForm.get('IsAllDayEvent').enable({ emitEvent: false });
      this.editForm.get('RecurrencePattern').enable({ emitEvent: false });
      this.editForm.get('StationIds').enable({ emitEvent: false });
      this.editForm.get('EventStations').enable({ emitEvent: false });
      this.editForm.get('EventCategoryId').enable({ emitEvent: false });
    }
  }

  get isOneDayOnlyRecurrenceType(): boolean
  {
    let oneDayOnly = false;

    const recurrencePattern: string = this.editForm.get("RecurrencePattern").value;

    if (recurrencePattern)
    {
      oneDayOnly = recurrencePattern.toUpperCase().includes("DAILY") || recurrencePattern.toUpperCase().includes("WEEKLY");
    }

    return oneDayOnly;
  }

  recurrenceChange(event: any)
  {
    this.editForm.get('EndDate').enable({ emitEvent: false });

    if (this.isOneDayOnlyRecurrenceType)
    {
      //Daily and Weekly recurrence types can only have activites with a duration of 1 day.
      this.editForm.controls["EndDate"].patchValue(this.editForm.get("StartDate").value, { emitEvent: false });
      this.editForm.get('EndDate').disable({ emitEvent: false });
    }
  }


  get availableStations(): Array<any>
  {
    return this.calendarsService.stations();
  }


  public onSave(e: MouseEvent): void
  {
    e.preventDefault();

    this.active = false;

    const formValue: MyEvent = this.editForm.value

    //manually set disabled fields as they don't get set through binding
    formValue.EndDate = this.editForm.get('EndDate').value;

    if (this.configurationService.cbSettings().systemTimezone)
    {
      formValue.StartTimezone = this.configurationService.cbSettings().systemTimezone;
    }
    else
    {
      formValue.StartTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    formValue.EndTimezone = formValue.StartTimezone;

    this.save.emit(formValue);

    this.diaryService.isEditing = false;
  }

  public onCancel(e: MouseEvent): void
  {
    e.preventDefault();

    this.active = false;

    this.cancel.emit();

    this.diaryService.isEditing = false;
  }



  startDateChange(event: any)
  {
    this.editForm.get('EndDate').enable({ emitEvent: false });

    if (this.editForm.get("StartDate").value > this.editForm.get("EndDate").value || this.isOneDayOnlyRecurrenceType)
    {
      //Ensure StartDate is not greater than EndDate
      this.editForm.controls["EndDate"].patchValue(this.editForm.get("StartDate").value, { emitEvent: false });
    }

    if (this.isOneDayOnlyRecurrenceType)
    {
      this.editForm.get('EndDate').disable({ emitEvent: false });
    }
  }

  endDateChange(event: any)
  {
    if (this.editForm.get("StartDate").value > this.editForm.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.editForm.controls["StartDate"].patchValue(this.editForm.get("EndDate").value, { emitEvent: false });
    }
  }






















}
