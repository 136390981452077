import { Component, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common'
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signin-callback',
    templateUrl: 'signin-redirect-callback.component.html',
    styleUrls: ['./home.scss'],
    standalone: true
})

export class SigninRedirectCallbackComponent implements OnInit
{
  constructor(private _authService: AuthenticationService, private _router: Router)
  {

  }

  ngOnInit()
  {
    this._authService.completeLogin().then(user =>
    {
      if (user)
      {

      }
    });
  }
}
