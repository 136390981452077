import { Platform } from '@angular/cdk/platform';
import { NativeDateAdapter } from '@angular/material/core';


/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter
{
  constructor(matDateLocale: string)
  {
    super(matDateLocale)
  }

  //// parse the date from input component as it only expect dates in 
  //// mm-dd-yyyy format
  //parse(value: any): Date | null
  //{
  //  if ((typeof value === 'string') && (value.indexOf('/') > -1))
  //  {
  //    const str = value.split('/');

  //    const year = Number(str[2]);
  //    const month = Number(str[1]) - 1;
  //    const date = Number(str[0]);

  //    return new Date(year, month, date);
  //  }
  //  const timestamp = typeof value === 'number' ? value : Date.parse(value);
  //  return isNaN(timestamp) ? null : new Date(timestamp);
  //}

  //format(date: Date, displayFormat: Object): string
  //{
  //  let result = date.toDateString();
  //  const day = date.getDate();
  //  const month = date.getMonth() + 1;
  //  const year = date.getFullYear();
  //  switch (displayFormat)
  //  {
  //    case 'DD/MM/YYYY':
  //      // Return the format as per your requirement
  //      result = `${day}-${month}-${year}`;
  //      break;
  //    default:
  //    case 'MMM YYYY':
  //      // Return the format as per your requirement
  //      result = `${month}-${year}`;
  //      break;
  //  }
  //  return result;
  //}
  parse(value: string): any
  {
    const parts = value.split('/');
    if (parts.length == 3)
      return new Date(+parts[2], (+parts[1]) - 1, +parts[0])

  }



  getDayOfWeekNames(style: 'long' | 'short' | 'narrow')
  {
    return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  }

  getFirstDayOfWeek(): number
  {
    return 1;
  }

}
