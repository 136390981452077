<div class="contact-selector-wrapper" [formGroup]="form">
  <fieldset>
    <legend>{{contactNameLabel}}</legend>
    <div class="contact-item contact-item-name">
      <fa-icon [icon]="faUser" class="contact-icon contact-icon-name"></fa-icon>
      <kendo-combobox [data]="data"
                      formControlName="contact"
                      [kendoDropDownFilter]="filterSettings"
                      [size]="'small'"
                      [fillMode]="'flat'"
                      [textField]="'FullName'"
                      [valueField]="'Id'"
                      [valuePrimitive]="false"
                      [placeholder]=" "
                      [popupSettings]="popupSettings"
                      [disableControl]="isDisabled"
                      style="width: 100%; transform: perspective(0);"
                      (valueChange)="contactChange($event)">
      </kendo-combobox>
      @if (required && (!value || !value.Id || value.Id === 0))
      {
      <div class="k-tooltip-validation error-msg">
        <div>{{contactNameLabel}} is a required field</div>
      </div>
      }
    </div>
    @if (showCombinedPhone)
    {
    <div class="contact-item contact-item-phone">
      <mat-label>
        {{contactPhoneLabel}}
        <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
      </mat-label><mat-label>{{combinedPhone}}</mat-label>
    </div>
    }
    @if (showPhone)
    {
    <div class="contact-item contact-item-phone">
      <mat-label>
        {{contactPhoneLabel}}
        <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
      </mat-label><mat-label>{{value?.Phone}}</mat-label>
    </div>
    }
    @if (showMobile)
    {
    <div class="contact-item contact-item-phone">
      <mat-label>
        {{contactMobileLabel}}
        <fa-icon [icon]="faPhone" class="contact-icon"></fa-icon>
      </mat-label><mat-label>{{value?.Mobile}}</mat-label>
    </div>
    }
    @if (showEmail)
    {
    <div class=" contact-item contact-item-link">
      <mat-label (click)="emailClick($event)">
        {{contactEmailLabel}}
        <fa-icon [icon]="faEnvelope" class="contact-icon"></fa-icon>
      </mat-label><mat-label (click)="emailClick($event)">{{value?.Email}}</mat-label>
    </div>
    }
  </fieldset>
</div>
