import { Component, EventEmitter, forwardRef, Input, OnInit, Output, VERSION } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { LoggerService } from '../../services/logger.service';
import { UploadService } from '../../services/upload.service';
import { DocumentUploaderService } from './document-uploader.service';
import { MatIconModule } from '@angular/material/icon';


@Component({
    selector: 'document-uploader',
    templateUrl: './document-uploader.component.html',
    styleUrls: ['./document-uploader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DocumentUploaderComponent),
            multi: true,
        },
        DocumentUploaderService
    ],
    standalone: true,
    imports: [MatIconModule]
})
export class DocumentUploaderComponent implements OnInit, ControlValueAccessor
{

  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: string) =>
  {

  };

  private _downloadDocument: boolean = true;
  private _isDisabled: boolean = false;
  private _useFileNameForLinkText: boolean = true;
  private _showLabel: boolean = false;
  private _addSizelimitsToLabel: boolean = true;
  private _emptyMsg = "No Attachments";
  private _labelText = "";
  private _linkText = this.value;
  private _recordId = "";
  private _recordSubId = "";
  private _sizeLimit = 0;
  private _subFolder = "";
  private _uploadsFolder = "";
  private _value = "";

  @Output() documentSuccessfullyUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() documentSuccessfullyDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  version = VERSION;

  @Input() get value(): string
  {
    return this._value;
  }
  set value(val: string)
  {
    this._value = val;

    if (this.useFileNameForLinkText && val)
    {
      this._linkText = val;
    }

    if (this.propagateChange)
    {
      this.propagateChange(this._value);
    }
  }

  @Input() get downloadDocument(): boolean
  {
    return this._downloadDocument;
  }
  set downloadDocument(value: boolean)
  {
    this._downloadDocument = JSON.parse(value.toString());
  }

  @Input() get isDisabled(): boolean
  {
    return this._isDisabled;
  }
  set isDisabled(value: boolean)
  {
    this._isDisabled = JSON.parse(value.toString());
  }

  @Input() get useFileNameForLinkText(): boolean
  {
    return this._useFileNameForLinkText;
  }
  set useFileNameForLinkText(value: boolean)
  {
    this._useFileNameForLinkText = JSON.parse(value.toString());
  }

  @Input() get showLabel(): boolean
  {
    return this._showLabel;
  }
  set showLabel(value: boolean)
  {
    this._showLabel = JSON.parse(value.toString());
  }

  @Input() get addSizelimitsToLabel(): boolean
  {
    return this._addSizelimitsToLabel;
  }
  set addSizelimitsToLabel(value: boolean)
  {
    this._addSizelimitsToLabel = JSON.parse(value.toString());
  }

  @Input() get emptyMsg(): string
  {
    return this._emptyMsg;
  }
  set emptyMsg(value: string)
  {
    this._emptyMsg = value;
  }

  @Input() get labelText(): string
  {
    return this._labelText;
  }
  set labelText(value: string)
  {
    this._labelText = value;
  }

  @Input() get linkText(): string
  {
    return this._linkText;
  }
  set linkText(value: string)
  {
    this._linkText = value;

    if (this.useFileNameForLinkText && this.value)
    {
      this._linkText = this.value;
    }
  }

  @Input() get recordId(): string
  {
    return this._recordId;
  }
  set recordId(value: string)
  {
    this._recordId = value;
  }

  @Input() get recordSubId(): string
  {
    return this._recordSubId;
  }
  set recordSubId(value: string)
  {
    this._recordSubId = value;
  }

  @Input() get sizeLimit(): number
  {
    return this._sizeLimit;
  }
  set sizeLimit(value: number)
  {
    this._sizeLimit = value;
  }

  @Input() get subFolder(): string
  {
    return this._subFolder;
  }
  set subFolder(value: string)
  {
    this._subFolder = value;
  }

  @Input() get uploadsFolder(): string
  {
    return this._uploadsFolder;
  }
  set uploadsFolder(value: string)
  {
    this._uploadsFolder = value;
  }

  constructor(
    private documentUploaderService: DocumentUploaderService,
    public commonService: CommonService,
    private uploadService: UploadService,
    private loggerService: LoggerService,
  )
  {

  }

  ngOnInit(): void
  {
    if (this.addSizelimitsToLabel)
    {
      this.labelText = `${this.labelText} (${this.commonService.formatBytes(this.sizeLimit, 0, false)})`;
    }
  }

  get documentUrl(): string
  {
    let subDirectory = "";
    let subId = "";

    if (this.recordSubId)
    {
      subId = '/' + this.recordSubId;
    }

    if (this.subFolder)
    {
      subDirectory = '/' + this.subFolder;
    }

    return this.uploadsFolder + subDirectory + '/' + this.recordId + subId + '/' + this.value;
  }

  async updateFile(event: any)
  {
    let uploadSuccessful = false;

    if (event && event.files && event.files.length > 0)
    {
      const name = event.files[0].name;

      if (event.files[0].size > this.sizeLimit)
      {
        this.commonService.notifyAlert("WARNING", `File ${name} is too large (${this.commonService.formatBytes(event.files[0].size, 2, false)}).  The size limit is ${this.commonService.formatBytes(this.sizeLimit, 0, false)}`);
      }
      else
      {
        await this.uploadService.postFile(event.files[0], this.recordId, this.subFolder, this.recordSubId, this.sizeLimit.toString()).then(data =>
        {
          // do something, if upload success
          this.value = name;

          uploadSuccessful = true;
        }, error =>
        {
          this.commonService.notifyFailure("Error", `${error} Unable to upload file ${name}.`, null, null);
          this.loggerService.error(`${error} Unable to upload file ${name}.`);
        });
      }
    }

    //need to set value to null and return null so the same file can be selected twice in a row.
    event.value = null;

    this.documentSuccessfullyUploaded.emit(uploadSuccessful);
    this.valueChange.emit(event);

    if (this.onTouchedCallback)
    {
      this.onTouchedCallback();
    }

    return false;
  }

  async clearFile(event: any)
  {
    let removeSuccessful = false;

    const fileNames: string[] = [];
    fileNames.push(this.value);

    await this.uploadService.postFileRemove(fileNames, this.recordId, this.subFolder, this.recordSubId).then(data =>
    {
      // clear value if upload success
      this.value = null;

      removeSuccessful = true;
    }, error =>
    {
      this.commonService.notifyFailure("Error", `${error} Unable to upload file ${name}.`, null, null);
      this.loggerService.error(`${error} Unable to upload file ${name}.`);
    });

    this.documentSuccessfullyDeleted.emit(removeSuccessful);
    this.valueChange.emit(event);
  }

  ngOnDestroy()
  {

  }


  ngOnChanges(inputs: any): void
  {
    if (this.propagateChange)
    {
      this.propagateChange(this.value);
    }
  }


  writeValue(value: any): void
  {
    this.value = value;
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this._isDisabled = disabled
  }




}
