import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { AdministrationService } from '../administration.service';
import { NotifyGroupEditComponent } from "./notify-group-edit.component";
import { NotifyGroup, NotifyGroupType } from "../administration.models";
import { CommonService } from "../../../shared/services/common.service";
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { MatPaginatorModule } from "@angular/material/paginator";

import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";


@Component({
    templateUrl: './notify-groups.component.html',
    styleUrls: ['./notify-groups.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, MatPaginatorModule, GenericFilterPipe]
})
export class NotifyGroupsComponent extends MatTableCrudComponent<NotifyGroup>
{
  public NameFilter: any;
  public NotifyGroupTypeIdFilter: any;

  constructor(public authorisationService: AuthorisationService,
    protected injector: Injector,
    public commonService: CommonService,
    public configurationService: ConfigurationService,
    public administrationService: AdministrationService)
  {
    super(injector,
      NotifyGroupEditComponent,
      NotifyGroupEditComponent,
      NotifyGroupEditComponent,
      "CreateNotifyGroup",
      "DeleteNotifyGroup",
      "GetNotifyGroups",
      "UpdateNotifyGroup",
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'Name', 'NotifyGroupType.Name'];
    this.filteredValues = [{ Field: "Name", Value: "", Path: "", DataType: FilterDataType.String }, { Field: "NotifyGroupTypeId", Value: "", Path: "NotifyGroupType.Id", DataType: FilterDataType.Number }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "70rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddNotificationGroups);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditNotificationGroups);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteNotificationGroups);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  get notifyGroupTypes(): NotifyGroupType[]
  {
    if (this.commonService.isInterfacing)
    {
      return this.administrationService.notifyGroupTypes;
    }
    else
    {
      return this.administrationService.notifyGroupTypes.filter(n => n.Id != this.commonService.interfacingNotificationGroupTypeId);
    }
  }


  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, notifyGroup: NotifyGroup)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this notification group (${notifyGroup.Name})?`;
    this.deleteHeaderText = "Delete Notification Group?";

    super.deleteItem(event, notifyGroup);
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

