import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DropDownButtonModule, ButtonModule } from '@progress/kendo-angular-buttons';

import { AfterValueChangedDirective } from '../directives/after-value-changed.directive';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';

//////////////////  EXAMPLE  ///////////////////////////////
//<ng-template kendoGridFilterCellTemplate let-filter>
//  <kendo-numerictextbox-filter [filter]="filter"
//                                filterBy="SampleResult"
//                                [showOperators]="true"
//                                [showFilterClear]="false">
//  </kendo-numerictextbox-filter>
//</ng-template>
/////////////////////////////////////////////////////////////

@Component({
  selector: 'kendo-numerictextbox-filter',
  encapsulation: ViewEncapsulation.None,
  template: `
    <kendo-numerictextbox size="small"
      rounded="medium"
      fillMode="outline"
      [format]="'#'"
      (afterValueChanged)="onChange($event)"
      [value]="selectedValue"
      >
    </kendo-numerictextbox>
    @if (showOperators) {
      <kendo-dropdownbutton
        size="small"
        rounded="medium"
        [data]="defaultOperators"
        icon="filter"
        [title]="currentFilterOperator?.text"
        (itemClick)="onFilterOperatorChange($event)"
        >
      </kendo-dropdownbutton>
    }
    @if (showFilterClearButton) {
      <button kendoButton
        icon="filter-clear"
        title="Clear"
        (click)="onFilterClear($event)"
        >
      </button>
    }
    `,
  styles: [
    `
      kendo-numerictextbox-filter input
      {
        box-sizing: border-box;
      }

      kendo-numerictextbox-filter kendo-dropdownbutton > button
      {
        padding: 2px !important;
        box-sizing: border-box;
      }

      kendo-numerictextbox-filter kendo-dropdownbutton + button
      {
        padding: 2px !important;
        margin-left: 2px;
        box-sizing: border-box;
      }


      .k-menu-group-sm
      {
        font-size: inherit !important;
      }

      .k-menu-group-sm .k-menu-item
      {
        font-size: inherit !important;
      }
    `
  ],
  standalone: true,
  imports: [NumericTextBoxModule, AfterValueChangedDirective, DropDownButtonModule, ButtonModule]
})
export class NumericTextBoxFilterComponent extends BaseFilterCellComponent
{
  @Output() filterSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  public currentFilterOperator: any = { text: "Is equal to", value: "eq" };

  private filterValue: any;

  public showFilterClearButton: boolean = false;

  public get selectedValue(): any
  {
    const filter = this.filterByField(this.filterBy);
    return filter ? filter.value : null;
  }

  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public filterBy: string; //field to filter the grid's data by.

  @Input() public showOperators: boolean = false;
  @Input() public showFilterClear: boolean = false;


  constructor(filterService: FilterService)
  {
    super(filterService);


    this.defaultOperators = [];
    this.defaultOperators.push({ text: "Is equal to", value: "eq" });
    this.defaultOperators.push({ text: "Is greater than", value: "gt" });
    this.defaultOperators.push({ text: "Is greater than or equal to", value: "gte" });
    this.defaultOperators.push({ text: "Is less than", value: "lt" });
    this.defaultOperators.push({ text: "Is less than or equal to", value: "lte" });
  }


  public onFilterClear(value: any): void
  {
    this.filterValue = null;

    this.applyFilter(
      (this.filterValue === null || this.filterValue === undefined) ?
        this.removeFilter(this.filterBy) : // remove the filter 
        this.updateFilter({ // add a filter for the field with the value
          field: this.filterBy,
          operator: this.currentFilterOperator.value ?? "eq",
          value: this.filterValue
        })
    ); // update the root filter

    this.showFilterClearButton = false;
  }

  public onFilterOperatorChange(value: any): void
  {
    this.currentFilterOperator = value;

    if (this.filterValue)
    {
      this.applyFilter(
        (this.filterValue === null || this.filterValue === undefined) ?
          this.removeFilter(this.filterBy) : // remove the filter 
          this.updateFilter({ // add a filter for the field with the value
            field: this.filterBy,
            operator: this.currentFilterOperator.value ?? "eq",
            value: this.filterValue
          })
      ); // update the root filter
    }
  }

  public onChange(value: any): void
  {
    this.filterValue = value;

    this.showFilterClearButton = this.showFilterClear && this.filterValue !== null && this.filterValue !== undefined;

    this.applyFilter(
      (this.filterValue === null || this.filterValue === undefined) ?
        this.removeFilter(this.filterBy) : // remove the filter 
        this.updateFilter({ // add a filter for the field with the value
          field: this.filterBy,
          operator: this.currentFilterOperator.value ?? "eq",
          value: this.filterValue
        })
    ); // update the root filter

    this.filterSelectionChange.emit(value);
  }





}
