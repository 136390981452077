import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableCrudService } from './mat-table-crud.service';
import { Subscription } from 'rxjs';



@Component({
    selector: 'mat-table-crud-edit-base',
    template: `NO UI TO BE FOUND HERE!`,
    standalone: true
})
export class MatTableCrudEditComponent<T, D> implements OnInit
{
  public form: UntypedFormGroup;

  public idField = "Id";
  public changedByField = "ChangedByUserId";
  public showEditItemNameInHeaderText = false;
  private _isNew = false;
  public saveInProgress = false;
  public mtCrudService: MatTableCrudService
  private onSaveSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<T>, @Inject(MAT_DIALOG_DATA) public data: D)
  {

  }

  ngOnInit()
  {
    const d: any = this.data;

    this.isNew = !d || !d[this.idField as keyof typeof d] || d[this.idField as keyof typeof d] < 1;

    this.onSaveSubscription = this.mtCrudService.OnSaved.subscribe((result: any) =>
    {
      this.saveInProgress = false;

      this.dialogRef.close(result);
    });
  }


  onCancelClick(): void
  {
    this.dialogRef.close();
  }

  onSave(data: D): void
  {
    this.saveInProgress = true;

    try
    {
      this.mtCrudService.save(data, this.isNew);
    }
    catch
    {
      this.saveInProgress = false;
    }
  }

  get isNew(): boolean
  {
    return this._isNew;
  }
  set isNew(value: boolean)
  {
    this._isNew = value;
  }


  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.onSaveSubscription)
    {
      this.onSaveSubscription.unsubscribe();
    }
  }

}
