<mat-card appearance="outlined" class="mat-crud-narrow">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
      Roles
    </mat-card-title>
    <div class="card-header-text-icons">
      Reload data:
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">
      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="Id">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Id</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="RoleName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Name
          </div>
          <mat-form-field>
            <mat-label>Search Name</mat-label>
            <input matInput class="form-field" [formControl]="RoleNameFilter" placeholder="Search Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.RoleName}}</mat-cell>
      </ng-container>



      <!--<ng-container matColumnDef="RoleLevel">
      <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
        <div mat-sort-header class="sort-header">
          Level
        </div>
        <mat-form-field>
          <mat-label>Search Level</mat-label>
          <input matInput class="form-field" [formControl]="RoleLevelFilter" placeholder="Search Level" autocomplete="off">
        </mat-form-field>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let row">{{row?.RoleLevel}}</mat-cell>
    </ng-container>-->
    <!-- actions -->
    <ng-container matColumnDef="actions">
      <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
        <div class="card-header-text-icons">
          Reload data:
          <button mat-icon-button (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        @if (CanAdd) {
          <button mat-icon-button color="accent" (click)="addNew($event)" title="add">
            <i class="material-icons">add_box</i>
          </button>
        }
      </mat-header-cell>

      <mat-cell mat-cell *matCellDef="let row; let i=index;">
        @if (CanEdit) {
          <button mat-icon-button color="accent" (click)="editItem($event, row)" title="edit">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>
        }

        @if (CanDelete) {
          <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>


  <div class="no-results" [style.display]="dataSource?.data?.length === 0 ? '' : 'none'">
    No results
  </div>

</mat-card-content>
<mat-card-footer>
  <mat-paginator #paginator
    [length]="dataSource?.filteredData?.length"
    [pageIndex]="0"
    [pageSize]="10"
    [pageSizeOptions]="mtCrudService.pagerPageSizes"
    showFirstLastButtons="true">
  </mat-paginator>
</mat-card-footer>
</mat-card>
