import { Component, Injector } from "@angular/core";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { MechanicType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { MechanicTypeEditComponent } from "./mechanic-type-edit.component";
import { MatPaginatorModule } from "@angular/material/paginator";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { injectQueryClient } from "@tanstack/angular-query-experimental";
import { Subscription } from "rxjs";



@Component({
    templateUrl: './mechanic-types.component.html',
    styleUrls: ['./mechanic-types.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatPaginatorModule]
})
export class MechanicTypesComponent extends MatTableCrudComponent<MechanicType>
{
  public MechanicTypeNameFilter: any;
  public MechanicLabelTextFilter: any;

  private queryClient = injectQueryClient();

  private onDataFetchedSubscription: Subscription;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      MechanicTypeEditComponent,
      MechanicTypeEditComponent,
      MechanicTypeEditComponent,
      "CreateMechanicType",
      "DeleteMechanicType",
      "GetMechanicTypes",
      "UpdateMechanicType",
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'MechanicTypeName', 'MechanicLabelText'];
    this.filteredValues = [{ Field: "MechanicTypeName", Value: "", Path: "", DataType: FilterDataType.String }, { Field: "MechanicLabelText", Value: "", Path: "", DataType: FilterDataType.String }];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "24rem";

    this.CanAdd = false; // this.authorisationService.hasPermission(this.authorisationService.Permissions.AddMechanicTypes);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditMechanicTypes);
    this.CanDelete = false; // this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteMechanicTypes);
  }

  ngOnInit(): void
  {
    super.ngOnInit();

    this.onDataFetchedSubscription = this.mtCrudService.OnDataFetched.subscribe((result: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "MechanicTypes";
        }
      });
    });
  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, mechanictype: MechanicType)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this mechanic type (${mechanictype.MechanicTypeName})?`;
    this.deleteHeaderText = "Delete Mechanic Type?";

    super.deleteItem(event, mechanictype);
  }



  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.onDataFetchedSubscription)
    {
      this.onDataFetchedSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }











}

