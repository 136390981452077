import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { RequestCacheEntry } from '../models/common.models';
import { LoggerService } from './logger.service';


@Injectable({ providedIn: 'root' })

export class DataHandlerService 
{
  public changedByField = "ChangedByUserId";

  private _changedByUserId = 0;

  private cache = new Map<string, RequestCacheEntry>();

  private useDataHandlerCache: boolean = false;

  constructor(private httpClient: HttpClient, private loggerService: LoggerService)
  {

  }

  get changedByUserId(): number
  {
    return this._changedByUserId;
  }
  set changedByUserId(value: number)
  {
    this._changedByUserId = value;
  }

  resetCache()
  {
    this.cache.clear();
  }

  removeCacheKey(key: string)
  {
    this.cache.delete(key);
  }


  getHttpPromise<T>(url: string, useCachedData: boolean = false, useReviver: boolean = true): Promise<T>
  {
    return lastValueFrom(this.getHttpObservable<T>(url, useCachedData, useReviver));
  }

  getHttpObservable<T>(url: string, useCachedData: boolean = false, useReviver: boolean = true): Observable<T>
  {
    if (useCachedData && this.useDataHandlerCache)
    {
      let cached: RequestCacheEntry | undefined = this.cache.get(url);

      return cached ? of(cached.response) : this.httpClient.get<T>(url)
        .pipe(
          shareReplay(),
          retry({ count: 10, delay: 1000 }),
          map((res: any) =>
          {
            const response: any = this.extractData(res, useReviver);
            cached = new RequestCacheEntry();
            cached.url = url;
            cached.response = JSON.parse(JSON.stringify(response));
            cached.lastRead = Date.now();
            this.cache.set(url, cached);

            return response;
          }),
          catchError((err: any) =>
          {
            return this.handleHttpError(err);
          })
        );
    }
    else
    {
      const headers = new HttpHeaders();

      return this.httpClient.get<T>(url, { headers: headers })
        .pipe(
          retry({ count: 10, delay: 1000 }),
          map((res: any) => this.extractData(res, useReviver)),
          catchError((err: any) =>
          {
            return this.handleHttpError(err);
          })
        );
    }
  }

  postHttpPromise<T>(url: string, param: any, useCachedData: boolean = false, useReviver: boolean = true, addChangedBy: boolean = true): Promise<T>
  {
    return lastValueFrom(this.postHttpObservable<T>(url, param, useCachedData, useReviver, addChangedBy));
  }

  postHttpObservable<T>(url: string, param: any, useCachedData: boolean = false, useReviver: boolean = true, addChangedBy: boolean = true): Observable<T>
  {
    const body = param;

    if (body && addChangedBy)
    {
      body[this.changedByField] = this.changedByUserId;
    }

    if (useCachedData && this.useDataHandlerCache)
    {
      const key = `${url}${JSON.stringify(param)}`;

      let cached: RequestCacheEntry | undefined = this.cache.get(key);

      return cached ? of(cached.response) : this.httpClient.post<T>(url, body)
        .pipe(
          retry({ count: 10, delay: 1000 }),
          map((res: any) =>
          {
            const response: any = this.extractData(res, useReviver);
            cached = new RequestCacheEntry();
            cached.url = url;
            cached.params = JSON.stringify(param);
            cached.response = JSON.parse(JSON.stringify(response));
            cached.lastRead = Date.now();
            this.cache.set(key, cached);

            return response;
          }),
          catchError((err: any) =>
          {
            return this.handleHttpError(err);
          })
        );
    }
    else
    {
      const headers = new HttpHeaders();

      return this.httpClient.post<T>(url, body, { headers: headers })
        .pipe(
          retry({ count: 10, delay: 1000 }),
          map((res: any) => this.extractData(res, useReviver)),
          catchError((err: any) =>
          {
            return this.handleHttpError(err);
          })
        );
    }
  }

  putHttpPromise<T>(url: string, param: any, useReviver: boolean = true, addChangedBy: boolean = true): Promise<T> 
  {
    return lastValueFrom(this.putHttpObservable<T>(url, param, useReviver, addChangedBy));
  }

  putHttpObservable<T>(url: string, param: any, useReviver: boolean = true, addChangedBy: boolean = true): Observable<T>
  {
    const body = param;

    if (body && addChangedBy)
    {
      body[this.changedByField] = this.changedByUserId;
    }

    const headers = new HttpHeaders();

    return this.httpClient.put<T>(url, body, { headers: headers })
      .pipe(
        retry({ count: 10, delay: 1000 }),
        map((res: any) => this.extractData(res, useReviver)),
        catchError((err: any) =>
        {
          return this.handleHttpError(err);
        })
      );
  }

  deleteHttpPromise<T>(url: string, useReviver: boolean = true): Promise<T>
  {
    return lastValueFrom(this.deleteHttpObservable<T>(url, useReviver));
  }

  deleteHttpObservable<T>(url: string, useReviver: boolean = true): Observable<T>
  {
    const headers = new HttpHeaders();

    return this.httpClient.delete<T>(url, { headers: headers })
      .pipe(
        retry({ count: 10, delay: 1000 }),
        map((res: any) => this.extractData(res, useReviver)),
        catchError((err: any) =>
        {
          return this.handleHttpError(err);
        })
      );
  }


  extractData(res: HttpResponse<any>, useReviver: boolean = false)
  {
    let body: any = null;

    if (res != null)
    {
      if (res.body != null)
      {
        body = res.body;
      }
      else
      {
        body = res;
      }

      if (useReviver)
      {
        let text = "";

        if (Array.isArray(body))
        {
          body.map(b =>
          {
            Object.keys(b).forEach(key =>
            {
              if (key != null && b[key] != null)
              {

                text = JSON.stringify(b[key]);

                b[key] = JSON.parse(text, this.reviver);
              }
            });
          });
        }
        else
        {
          try
          {
            text = body.text();
          }
          catch
          {
            text = JSON.stringify(body);
          }

          body = JSON.parse(text, this.reviver);
        }
      }
    }

    if (Array.isArray(body))
    {
      return body || [];
    }
    else if (typeof body == "object")
    {
      return body || {};
    }
    else
    {
      return body;
    }
  }


  handleHttpError(error: any)
  {
    let errMsg = 'Unknown error!';

    if (error?.error instanceof ErrorEvent)
    {
      // A client-side or network error occurred.
      errMsg = `A client-side or network error occurred: ${error?.error.message}`;
    }
    else
    {
      if (!error?.status || !error?.error)
      {
        errMsg = JSON.stringify(error);
      }
      else
      {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errMsg = `Backend returned error code ${error?.status}, ` + `body was: ${error?.error}`;
      }
    }

    if (errMsg && this.loggerService)
    {
      this.loggerService.error(errMsg);
    }

    // return an observable with a user-facing error message
    return throwError(() => 'An error has occurred, please try again later.');
  }


  reviver(key: any, value: any): any 
  {

    if (typeof value === 'string' && value.startsWith('/Date'))
    {
      return new Date(parseInt(value.replace('/Date(', '')));
    }

    return value;
  }


}
