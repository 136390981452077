@if (active) {
  <kendo-dialog
    [width]="1460"
    [height]="525"
    (close)="closeForm()">
    <kendo-dialog-titlebar>
      {{dialogHeaderText}}
    </kendo-dialog-titlebar>
    <form novalidate
      class="k-form k-form-md k-form-vertical"
      [formGroup]="editForm">
      <div class="activity-extension-control">
        <div class="activity-extension-content">
          <div class="form-group span-all-grid-columns">
            <div class="extensions-grid">
              <kendo-floatinglabel text="Extension">
                <kendo-combobox [data]="administrationService.extensions"
                  size="small"
                  rounded="medium"
                  formControlName="Extension"
                  fillMode="flat"
                  textField="Name"
                  valueField="Id"
                  [valuePrimitive]="false"
                  style="width: 12rem"
                  required>
                </kendo-combobox>
              </kendo-floatinglabel>
              <kendo-floatinglabel text="Total Cost">
                <kendo-numerictextbox [size]="'small'" [fillMode]="'flat'" style="width: 10rem" selectOnFocus="true" format="c0" [min]="0" title="{{editForm.get('TotalCost').value}}" formControlName="TotalCost" autocomplete="off"></kendo-numerictextbox>
              </kendo-floatinglabel>
              <mat-form-field style="width: 10rem">
                <mat-label>Extension Date</mat-label>
                <input matInput [matDatepicker]="ExtensionDate" formControlName="ExtensionDate" placeholder="Extension Date" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="ExtensionDate"></mat-datepicker-toggle>
                <mat-datepicker #ExtensionDate startView="month"></mat-datepicker>
              </mat-form-field>
              <mat-checkbox formControlName="IsExtensionApproved">Is Extension Approved</mat-checkbox>
              <mat-checkbox formControlName="IsProductSupplied">Is Product Supplied</mat-checkbox>
            </div>
            <div style="grid-column-gap: 2rem; display: flex;">
              <kendo-floatinglabel text="Deliverables">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{editForm.get('Deliverables').value}}" formControlName="Deliverables" maxlength="500" autocomplete="off"></kendo-textbox>
              </kendo-floatinglabel>
              <kendo-floatinglabel text="Talent">
                <kendo-textbox [size]="'small'" [fillMode]="'flat'" selectOnFocus="true" title="{{editForm.get('Talent').value}}" formControlName="Talent" maxlength="500" autocomplete="off"></kendo-textbox>
              </kendo-floatinglabel>
            </div>
            <div class="span-all-grid-columns">
              <kendo-floatinglabel text="Extension Timing">
                <kendo-textarea [size]="'small'" [fillMode]="'flat'" matAutosizeMaxRows="1" rows="1" selectOnFocus="true" title="{{editForm.get('ExtensionTiming')?.value}}" formControlName="ExtensionTiming" maxlength="500" resizable="none"></kendo-textarea>
              </kendo-floatinglabel>
              <kendo-floatinglabel text="Plaform/Asset">
                <kendo-textarea [size]="'small'" [fillMode]="'flat'" matAutosizeMaxRows="1" rows="1" selectOnFocus="true" title="{{editForm.get('PlaformAsset')?.value}}" formControlName="PlaformAsset" maxlength="1000" resizable="none"></kendo-textarea>
              </kendo-floatinglabel>
              <kendo-floatinglabel text="Further Details">
                <kendo-textarea [size]="'small'" [fillMode]="'flat'" matAutosizeMaxRows="2" rows="2" selectOnFocus="true" title="{{editForm.get('FurtherDetails')?.value}}" formControlName="FurtherDetails" maxlength="2000" resizable="none"></kendo-textarea>
              </kendo-floatinglabel>
              @if (false) {
                <kendo-floatinglabel text="Social Brief Details">
                  <kendo-textarea [size]="'small'" [fillMode]="'flat'" matAutosizeMaxRows="3" rows="3" selectOnFocus="true" title="{{editForm.get('SocialBriefDetails')?.value}}" formControlName="SocialBriefDetails" maxlength="2000" resizable="none"></kendo-textarea>
                </kendo-floatinglabel>
              }
              @if (false) {
                <kendo-floatinglabel text="Brand Tone/Key Messaging">
                  <kendo-textarea [size]="'small'" [fillMode]="'flat'" matAutosizeMaxRows="3" rows="3" selectOnFocus="true" title="{{editForm.get('BrandToneKeyMessaging')?.value}}" formControlName="BrandToneKeyMessaging" maxlength="2000" resizable="none"></kendo-textarea>
                </kendo-floatinglabel>
              }
            </div>
          </div>
        </div>
      </div>
    </form>
    <kendo-dialog-actions layout="start">
      <button kendoButton [svgIcon]="cancelIcon" (click)="onCancel($event)">
        Cancel
      </button>
      <button kendoButton
        themeColor="primary"
        [svgIcon]="saveIcon"
        [disabled]="!editForm.valid"
        (click)="onSave($event)">
        Save
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
}
