import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { addMonths } from 'date-fns';
import { RRule, Weekday } from 'rrule';
import { DaysInWeek, Recurrence } from '../../../components/calendars/calendars.models';
import { CommonDataService } from '../../services/common-data.service';
import { CommonService } from '../../services/common.service';
import { LoggerService } from '../../services/logger.service';
import { SelectableChipsComponent } from '../selectable-chips/selectable-chips.component';

@Component({
  selector: 'recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatDialogTitle, MatRadioModule, MatFormFieldModule, MatInputModule, SelectableChipsComponent, MatDatepickerModule, MatDialogActions, MatButtonModule]
})
export class RecurrenceComponent implements OnInit
{
  public form: FormGroup;

  public RecurrenceTypeNone = 0;
  public RecurrenceTypeDaily = 1;
  public RecurrenceTypeWeekly = 2;
  public RecurrenceTypeMonthly = 3;
  public RecurrenceTypeYearly = 4;

  public RangeTypeNoEndDate = 1;
  public RangeTypeEndAfter = 2;
  public RangeTypeEndBy = 3;

  public EveryWeekDaySelectValue = 2;

  public OnSpecificDayOfMonthSelectValue = 1;

  public previousDaysInTheWeek: DaysInWeek[] = [];

  public IsYearlyOnSpecificDayOfMonth = false;

  public dayOfWeekPatternStartDate: DaysInWeek;

  constructor(private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<RecurrenceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Recurrence,
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    private loggerService: LoggerService)
  {
    if (this.data)
    {
      if (this.data.PatternEndDate)
      {
        this.data.PatternEndDate = this.commonService.parseJsonDate(this.data.PatternEndDate);
      }
      else
      {
        this.data.PatternEndDate = this.commonService.today;
      }

      if (this.data.PatternStartDate)
      {
        this.data.PatternStartDate = this.commonService.parseJsonDate(this.data.PatternStartDate);
      }
      else
      {
        this.data.PatternStartDate = this.commonService.today;
      }

      const startDateCtrl = this.fb.control({ value: this.data.PatternStartDate, disabled: false });
      const endDateCtrl = this.fb.control({ value: this.data.PatternEndDate, disabled: false });

      this.dayOfWeekPatternStartDate = this.commonDataService.daysInTheWeek.filter(d => d.DayOfWeek == this.data.PatternStartDate.getDay())[0];

      if (!this.data.RecurrenceType)
      {
        this.data.RecurrenceType = this.RecurrenceTypeNone;
      }
      if (!this.data.Interval)
      {
        this.data.Interval = 1;
      }

      if (!this.data.Occurrences)
      {
        this.data.Occurrences = 0;
      }

      if (this.data.DaysOfWeek && this.data.RecurrenceType == this.RecurrenceTypeWeekly)
      {
        const days: DaysInWeek[] = this.commonDataService.daysInTheWeek.filter(d => (this.data.DaysOfWeek & d.Value) == d.Value);

        if (days != null && days.length > 0)
        {
          this.data.SelectedDaysInWeek = days;
        }
      }

      if (!this.data.SelectedDaysInWeek || this.data.SelectedDaysInWeek.length < 1)
      {
        this.data.SelectedDaysInWeek = [];
        this.data.SelectedDaysInWeek.push(this.dayOfWeekPatternStartDate);
      }

      this.previousDaysInTheWeek = this.data.SelectedDaysInWeek;



      if (this.data.EndAfter == null)
      {
        this.data.EndAfter = false;
      }
      if (this.data.EndBy == null)
      {
        this.data.EndBy = false;
      }
      if (this.data.EveryWeekDay == null)
      {
        this.data.EveryWeekDay = false;
      }
      if (this.data.NoEndDate == null)
      {
        this.data.NoEndDate = false;
      }
      if (this.data.OnSpecificDayOfMonth == null)
      {
        this.data.OnSpecificDayOfMonth = true;
      }
      if (!this.data.EndBy && !this.data.EndAfter && !this.data.NoEndDate)
      {
        this.data.NoEndDate = true;
      }

      this.data.DaysOfWeek = this.dayOfWeekPatternStartDate.Value;
      this.data.Instance = this.commonService.getDayInstanceInMonthForDate(this.data.PatternStartDate);
      this.data.DayOfMonth = this.data.PatternStartDate.getDate();
      this.data.MonthOfYear = this.data.PatternStartDate.getMonth() + 1;  //month is zero indexed


      this.form = this.fb.group({
        Id: [this.data.Id, Validators.required],
        PatternEndDate: endDateCtrl,
        PatternStartDate: startDateCtrl,
        RecurrenceType: [this.data.RecurrenceType],
        Interval: [this.data.Interval],
        DayOfMonth: [this.data.DayOfMonth],
        Instance: [this.data.Instance],
        SelectedDaysInWeek: [this.data.SelectedDaysInWeek],
        DaysOfWeek: [this.data.DaysOfWeek],
        EndAfter: [this.data.EndAfter],
        EndBy: [this.data.EndBy],
        EveryWeekDay: [this.data.EveryWeekDay],
        NoEndDate: [this.data.NoEndDate],
        OnSpecificDayOfMonth: [this.data.OnSpecificDayOfMonth],
        Occurrences: [this.data.Occurrences],
        MonthOfYear: [this.data.MonthOfYear],
        MaximumMonths: [this.data.MaximumMonths]
      });

      this.setControls(this.data.RecurrenceType);

      if (this.data.NoEndDate)
      {
        this.form.get('Occurrences').disable({ emitEvent: false });
        this.form.get('PatternEndDate').disable({ emitEvent: false });
      }
      if (this.data.EndBy)
      {
        this.form.get('Occurrences').disable({ emitEvent: false });
        this.form.get('PatternEndDate').enable({ emitEvent: false });
      }
      if (this.data.EndAfter)
      {
        this.form.get('Occurrences').enable({ emitEvent: false });
        this.form.get('PatternEndDate').disable({ emitEvent: false });
      }


    }
  }

  ngOnInit(): void
  {

  }

  setControls(recurrenceType: number)
  {
    switch (recurrenceType)
    {
      case this.RecurrenceTypeDaily:
        if (this.form.get('EveryWeekDay').value)
        {
          this.form.get('Interval').disable({ emitEvent: false });
          this.form.controls["Interval"].patchValue(1);
        }
        else
        {
          this.form.get('Interval').enable({ emitEvent: false });
        }

        break;
      case this.RecurrenceTypeWeekly:
        this.form.get('Interval').enable({ emitEvent: false });
        break;
      case this.RecurrenceTypeMonthly:
        this.form.get('Interval').enable({ emitEvent: false });
        break;
      case this.RecurrenceTypeYearly:
        this.form.get('Interval').enable({ emitEvent: false });
        break;
    }
  }

  recurrenceTypeChanged(event: any)
  {
    if (event && event.value != null)
    {
      this.form.controls["Interval"].patchValue(1);

      if (event.value != this.RecurrenceTypeWeekly)
      {
        if (this.form.controls["SelectedDaysInWeek"].value)
        {
          this.previousDaysInTheWeek = this.form.controls["SelectedDaysInWeek"].value;
        }
      }

      switch (event.value)
      {
        case this.RecurrenceTypeNone:
          this.resetToNoRecurrenceDefaults();
          break;
        case this.RecurrenceTypeDaily:
          break;
        case this.RecurrenceTypeWeekly:
          this.form.controls["SelectedDaysInWeek"].patchValue(this.previousDaysInTheWeek);
          break;
        case this.RecurrenceTypeMonthly:
          this.form.controls["DaysOfWeek"].patchValue(this.dayOfWeekPatternStartDate.Value);
          break;
        case this.RecurrenceTypeYearly:
          this.form.controls["DaysOfWeek"].patchValue(this.dayOfWeekPatternStartDate.Value);
          break;
      }

      this.setControls(event.value);

      this.form.markAsDirty();
    }
  }

  dailySelectionChanged(event: any)
  {
    if (event && event.value != null)
    {
      this.form.controls["EveryWeekDay"].patchValue(event.value == this.EveryWeekDaySelectValue);

      this.setControls(this.RecurrenceTypeDaily);

      this.form.markAsDirty();
    }
  }


  monthlySelectionChanged(event: any)
  {
    if (event && event.value != null)
    {
      this.form.controls["OnSpecificDayOfMonth"].patchValue(event.value == this.OnSpecificDayOfMonthSelectValue);

      this.setControls(this.RecurrenceTypeMonthly);

      this.form.markAsDirty();
    }
  }

  yearlySelectionChanged(event: any)
  {
    if (event && event.value != null)
    {
      this.form.controls["OnSpecificDayOfMonth"].patchValue(event.value == this.OnSpecificDayOfMonthSelectValue);

      this.setControls(this.RecurrenceTypeYearly);

      this.form.markAsDirty();
    }
  }

  rangeTypeChanged(event: any)
  {
    if (event && event.value != null)
    {
      this.form.controls["NoEndDate"].patchValue(event.value == this.RangeTypeNoEndDate);
      this.form.controls["EndBy"].patchValue(event.value == this.RangeTypeEndBy);
      this.form.controls["EndAfter"].patchValue(event.value == this.RangeTypeEndAfter);

      switch (event.value)
      {
        case this.RangeTypeNoEndDate:
          this.form.controls["Occurrences"].patchValue(0);
          this.form.get('Occurrences').disable();
          this.form.get('PatternEndDate').disable({ emitEvent: false });
          break;
        case this.RangeTypeEndBy:
          this.form.controls["Occurrences"].patchValue(0);
          this.form.get('Occurrences').disable({ emitEvent: false });
          this.form.get('PatternEndDate').enable({ emitEvent: false });
          break;
        case this.RangeTypeEndAfter:
          if (!this.form.controls["Occurrences"].value || this.form.controls["Occurrences"].value < 1)
          {
            this.form.controls["Occurrences"].patchValue(10);
          }

          this.form.get('Occurrences').enable({ emitEvent: false });
          this.form.get('PatternEndDate').disable({ emitEvent: false });
          break;
      }

      this.form.markAsDirty();
    }
  }

  occurrenceChange(event: any)
  {
    if (this.form.controls["EndAfter"].value && (!this.form.controls["Occurrences"].value || this.form.controls["Occurrences"].value < 1))
    {
      this.form.controls["Occurrences"].patchValue(1);
    }
  }

  resetToNoRecurrenceDefaults()
  {
    this.form.controls["EveryWeekDay"].patchValue(false, { emitEvent: false });
    this.form.controls["Interval"].patchValue(1, { emitEvent: false });
    this.form.controls["EndBy"].patchValue(true, { emitEvent: false });
    this.form.controls["EndAfter"].patchValue(false, { emitEvent: false });
    this.form.controls["NoEndDate"].patchValue(false, { emitEvent: false });
    this.form.controls["OnSpecificDayOfMonth"].patchValue(true, { emitEvent: false });
  }

  resetPatternStartDateRelatedValues()
  {
    const newStartDate: Date = this.form.get("PatternStartDate").value;

    this.dayOfWeekPatternStartDate = this.commonDataService.daysInTheWeek.filter(d => d.DayOfWeek == newStartDate.getDay())[0];

    this.form.controls["DaysOfWeek"].patchValue(this.dayOfWeekPatternStartDate.Value, { emitEvent: false });
    this.form.controls["Instance"].patchValue(this.commonService.getDayInstanceInMonthForDate(newStartDate), { emitEvent: false });
    this.form.controls["DayOfMonth"].patchValue(newStartDate.getDate(), { emitEvent: false });
    this.form.controls["MonthOfYear"].patchValue(newStartDate.getMonth() + 1, { emitEvent: false });  //month is zero indexed


    if (this.form.get("RecurrenceType").value == this.RecurrenceTypeWeekly)
    {
      const days: DaysInWeek[] = this.commonDataService.daysInTheWeek.filter(d => (this.form.get("DaysOfWeek").value & d.Value) == d.Value);

      if (days != null && days.length > 0)
      {
        this.form.controls["SelectedDaysInWeek"].patchValue(days, { emitEvent: false });
      }
    }
    else
    {
      this.form.controls["SelectedDaysInWeek"].patchValue(this.dayOfWeekPatternStartDate, { emitEvent: false });
    }

    this.previousDaysInTheWeek = this.data.SelectedDaysInWeek;

    this.cdr.detectChanges();
  }

  onCancelClick(): void
  {
    this.dialogRef.close();
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("PatternStartDate").value > this.form.get("PatternEndDate").value)
    {
      //Ensure PatternStartDate is not greater than PatternEndDate
      this.form.controls["PatternEndDate"].patchValue(this.form.get("PatternStartDate").value, { emitEvent: false });
    }

    this.resetPatternStartDateRelatedValues();
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("PatternStartDate").value > this.form.get("PatternEndDate").value)
    {
      //Ensure PatternStartDate is not greater than PatternEndDate
      this.form.controls["PatternStartDate"].patchValue(this.form.get("PatternEndDate").value, { emitEvent: false });

      this.resetPatternStartDateRelatedValues();
    }
  }

  onSave(): void
  {
    const recurrence: Recurrence = this.form.getRawValue();

    if (recurrence.NoEndDate)
    {
      recurrence.PatternEndDate = null;
    }

    if (recurrence.EndAfter)
    {
      recurrence.PatternEndDate = recurrence.PatternStartDate;
    }

    switch (recurrence.RecurrenceType)
    {
      case this.RecurrenceTypeDaily:
        if (recurrence.EveryWeekDay)
        {
          recurrence.DaysOfWeek = 62;
          recurrence.Interval = 1;
        }
        else
        {
          recurrence.DaysOfWeek = 127;
        }

        if (recurrence.EndAfter)
        {
          if (recurrence.EveryWeekDay)
          {
            const rule =
              new RRule({
                freq: RRule.DAILY,
                dtstart: recurrence.PatternStartDate,
                count: recurrence.Occurrences,
                interval: recurrence.Interval,
                byweekday: ['MO', 'TU', 'WE', 'TH', 'FR']
              })

            recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
          }
          else
          {
            const rule =
              new RRule({
                freq: RRule.DAILY,
                dtstart: recurrence.PatternStartDate,
                count: recurrence.Occurrences,
                interval: recurrence.Interval,
              })

            recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
          }
        }

        break;
      case this.RecurrenceTypeWeekly:
        let weekdays: Weekday[] = [];

        if (recurrence.SelectedDaysInWeek != null)
        {
          let daysBitMask = 0;

          recurrence.SelectedDaysInWeek.map(d =>
          {
            daysBitMask += d.Value;

            weekdays.push(this.stringToWeekday(d.ShortAbbreviation.toUpperCase()));
          });

          recurrence.DaysOfWeek = daysBitMask;
        }

        if (recurrence.EndAfter)
        {
          const rule =
            new RRule({
              freq: RRule.WEEKLY,
              dtstart: recurrence.PatternStartDate,
              count: recurrence.Occurrences,
              interval: recurrence.Interval,
              byweekday: weekdays
            })

          recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
        }
        break;
      case this.RecurrenceTypeMonthly:
        if (recurrence.EndAfter)
        {
          if (recurrence.OnSpecificDayOfMonth)
          {
            const rule =
              new RRule({
                freq: RRule.MONTHLY,
                dtstart: recurrence.PatternStartDate,
                count: recurrence.Occurrences,
                interval: recurrence.Interval,
                bymonthday: recurrence.DayOfMonth
              })

            recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
          }
          else
          {
            const rule =
              new RRule({
                freq: RRule.MONTHLY,
                dtstart: recurrence.PatternStartDate,
                count: recurrence.Occurrences,
                interval: recurrence.Interval,
                byweekday: [ this.getWeekdayInstance(recurrence.SelectedDaysInWeek[0].ShortAbbreviation.toUpperCase(), recurrence.Instance) ]
              })

            recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
          }
        }
        break;  
      case this.RecurrenceTypeYearly:
        if (recurrence.EndAfter)
        {
          if (recurrence.OnSpecificDayOfMonth)
          {
            const rule =
              new RRule({
                freq: RRule.YEARLY,
                dtstart: recurrence.PatternStartDate,
                count: recurrence.Occurrences,
                interval: recurrence.Interval,
                bymonthday: recurrence.DayOfMonth,
                bymonth: recurrence.MonthOfYear
              })

            recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
          }
          else
          {
            const rule =
              new RRule({
                freq: RRule.YEARLY,
                dtstart: recurrence.PatternStartDate,
                count: recurrence.Occurrences,
                interval: recurrence.Interval,
                byweekday: [this.getWeekdayInstance(recurrence.SelectedDaysInWeek[0].ShortAbbreviation.toUpperCase(), recurrence.Instance)]
              })

            recurrence.PatternEndDate = rule.all()[rule.options.count - 1];
          }
        }
        break;
    }

    if (recurrence.PatternEndDate == null || recurrence.PatternEndDate < recurrence.PatternStartDate)
    {
      recurrence.PatternEndDate = recurrence.PatternStartDate;
    }

    const maxDate: Date = addMonths(recurrence.PatternStartDate, this.data.MaximumMonths);

    if (recurrence.NoEndDate && recurrence.PatternEndDate < maxDate)
    {
      recurrence.PatternEndDate = maxDate;
    }

    if (recurrence.FirstOccurrenceDate == null || recurrence.FirstOccurrenceDate < recurrence.PatternStartDate)
    {
      recurrence.FirstOccurrenceDate = recurrence.PatternStartDate;
    }

    if (recurrence.LastOccurrenceDate == null || recurrence.LastOccurrenceDate < recurrence.PatternEndDate)
    {
      recurrence.LastOccurrenceDate = recurrence.PatternEndDate;
    }


    this.dialogRef.close(recurrence);
  }


  stringToWeekday(input: string): Weekday
  {
    switch (input)
    {
      case "MO":
        return RRule.MO;
      case "TU":
        return RRule.TU;
      case "WE":
        return RRule.WE;
      case "TH":
        return RRule.TH;
      case "FR":
        return RRule.FR;
      case "SA":
        return RRule.SA;
      case "SU":
        return RRule.SU;
      default:
        throw `Unknown weekday number ${input}`;
    }
  }

  getWeekdayInstance(input: string, instance: number): Weekday
  {
    switch (input)
    {
      case "MO":
        return RRule.MO.nth(instance);
      case "TU":
        return RRule.TU.nth(instance);
      case "WE":
        return RRule.WE.nth(instance);
      case "TH":
        return RRule.TH.nth(instance);
      case "FR":
        return RRule.FR.nth(instance);
      case "SA":
        return RRule.SA.nth(instance);
      case "SU":
        return RRule.SU.nth(instance);
      default:
        throw `Unknown weekday number ${input}`;
    }
  }





























}
