import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { DateAdapter, MATERIAL_SANITY_CHECKS, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS, MatDateFormats, MatNativeDateModule } from '@angular/material/core';

import { enableProdMode, importProvidersFrom } from '@angular/core';


import '@angular/localize/init';
import { BrowserModule, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import 'hammerjs';
import { AppComponent } from './app/components/app/app.component';
import { AppService } from './app/components/app/app.service';
import { ErrorInterceptor } from './app/shared/interceptors/error.interceptor';
import { JwtInterceptor } from './app/shared/interceptors/jwt.interceptor';
import { CommonService } from './app/shared/services/common.service';
import { environment } from './environments/environment';

import { provideRouter } from '@angular/router';
import '@progress/kendo-angular-intl/locales/en-AU/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import '@progress/kendo-angular-intl/locales/en/all';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { QueryClient, provideAngularQuery } from '@tanstack/angular-query-experimental';
import { appRoutes } from './app/components/app/app.routing';
import { CustomDateAdapter } from './app/custom-date-adapter';
import { Platform } from '@angular/cdk/platform';


export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    } as Intl.DateTimeFormatOptions,
  }
};


export function appServiceFactory(appService: AppService): Function
{
  return () =>
  {
    return appService.loadApplication().then(() =>
    {

    });
  }
}
export function getBaseUrl()
{
  return document.getElementsByTagName('base')[0].href;
}

const appProviders = [
  { provide: APP_ID, useValue: 'ng-cli-universa' },
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] as any[] },
  importProvidersFrom(NotificationModule, MatNativeDateModule, BrowserModule, HammerModule),
  provideAnimations(),
  provideHttpClient(withFetch(), withInterceptors([JwtInterceptor, ErrorInterceptor])),
  provideAngularQuery(new QueryClient()),
  //  new QueryClient({
  //    defaultOptions: { 
  //      queries: {
  //        gcTime: 1000 * 60 * 60 * 24, // 24 hours
  //      },
  //    },
  //  }),
  //),
  provideRouter(appRoutes),
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  {
    provide: DateAdapter, useClass: CustomDateAdapter,
    deps: [MAT_DATE_LOCALE, Platform]
  },
  { provide: APP_INITIALIZER, useFactory: appServiceFactory, deps: [AppService], multi: true },
  {
    provide: MATERIAL_SANITY_CHECKS,
    useValue: false
  },
  {
    provide: LOCALE_ID,
    deps: [CommonService],
    useFactory: (commonService: CommonService) => commonService.getLocaleName() //returns locale string
  },
  {
    provide: MAT_DATE_LOCALE,
    deps: [CommonService],
    useFactory: (commonService: CommonService) => commonService.getLocaleName() //returns locale string
  },
  //{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  //CldrIntlService,
  //{
  //  provide: IntlService,
  //  useClass: CustomKendoIntlService
  //}
  // environment.production ? [] : provideQueryDevTools({ initialIsOpen: true  })
];

if (environment.production)
{
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: appProviders
})
  .catch((err: any) => console.log(err));
