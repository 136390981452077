import { Component, EventEmitter, Inject, Injector, OnInit, inject } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { ConfigurationService } from '../services/configuration.service';
import { DataHandlerService } from '../services/data-handler.service';
import { KendoGridService, Parameter } from '../services/kendo-grid.service';


@Component({
  template: ''
})
export abstract class KendoExternalEditGridComponent<T, E> implements OnInit
{
  public OnDataFetched: EventEmitter<any> = new EventEmitter<any>();
  public OnSaved: EventEmitter<any> = new EventEmitter<any>();
  public OnEdit: EventEmitter<any> = new EventEmitter<any>();
  public editDataItem: E;
  private originalDataItem: E;
  private savedSubscription: Subscription;

  public isNew: boolean;
  public modifiedByField: string = "ChangedByUserId";
  public idField = "Id";

  private dataFetchedSubscription: Subscription;
  protected useReviver: boolean = false;
  protected prepareDataForSave: boolean = false;

  public sort: SortDescriptor[] = [];
  public saveInProgress: boolean = false;
  public useCachedData: boolean = false;
  public exportAllData: boolean = false;

  private _CanAdd: boolean = false;
  private _CanEdit: boolean = false;
  private _CanDelete: boolean = false;
  private _CanExport: boolean = false;

  get CanAdd(): boolean
  {
    return this._CanAdd;
  }
  set CanAdd(value: boolean)
  {
    this._CanAdd = value;
  }

  get CanEdit(): boolean
  {
    return this._CanEdit;
  }

  set CanEdit(value: boolean)
  {
    this._CanEdit = value;
  }

  get CanDelete(): boolean
  {
    return this._CanDelete;
  }
  set CanDelete(value: boolean)
  {
    this._CanDelete = value;
  }

  get CanExport(): boolean
  {
    return this._CanExport;
  }
  set CanExport(value: boolean)
  {
    this._CanExport = value;
  }

  get gridAllData(): any
  {
    return this.kgKendoGridService.gridAllData;
  }

  get serviceParameters(): Parameter[]
  {
    return this.kgKendoGridService.serviceParameters;
  }
  set serviceParameters(value: Parameter[])
  {
    this.kgKendoGridService.serviceParameters = value;
  }

  private configurationService = inject(ConfigurationService);
  private dataHandler = inject( DataHandlerService);


  constructor(private kgKendoGridService: KendoGridService,
    private inj: Injector,
    @Inject('getSingleItemActionMethodPathFromRoot') private getSingleItemActionMethodPathFromRoot: string,
    @Inject('createActionMethodPathFromRoot') private createActionMethodPathFromRoot: string,
    @Inject('destroyActionMethodPathFromRoot') private destroyActionMethodPathFromRoot: string,
    @Inject('readActionMethodPathFromRoot') private readActionMethodPathFromRoot: string,
    @Inject('updateActionMethodPathFromRoot') private updateActionMethodPathFromRoot: string,
    @Inject('modifiedBy') private modifiedBy: any,
    @Inject('exportActionMethodPathFromRoot') private exportActionMethodPathFromRoot: string = readActionMethodPathFromRoot
  )
  {
    this.exportData = this.exportData.bind(this);


  }

  public async exportData(): Promise<ExcelExportData>
  {
    if (this.exportAllData)
    {
      if (!this.kgKendoGridService.serverPaging)
      {
        const exportState: State = {
          sort: [],
          skip: 0,
          take: null
        };

        const result: ExcelExportData = {
          data: process(this.gridAllData, exportState).data
        };

        return result;
      }

      await this.kgKendoGridService.serverPagingExport(null);

      const result: ExcelExportData = {
        data: this.gridAllData
      };

      return result;
    }
    else
    {
      //clone state to export state and remove paging
      const exportState: State = JSON.parse(JSON.stringify(this.gridState));
      exportState.skip = 0;
      exportState.take = null;

      if (!this.kgKendoGridService.serverPaging)
      {
        const result: ExcelExportData = {
          data: process(this.gridAllData, exportState).data
        };

        return result;
      }

      await this.kgKendoGridService.serverPagingExport(exportState);

      const result: ExcelExportData = {
        data: this.gridAllData.Data
      };

      return result;
    }
  }


  get view(): any
  {
    return this.kgKendoGridService.view;
  }
  set view(value: any)
  {
    this.kgKendoGridService.view = value;
  }

  get gridState(): State
  {
    return this.kgKendoGridService.gridState;
  }
  set gridState(value: State)
  {
    this.kgKendoGridService.gridState = value;
  }

  public ngOnInit(): void
  {
    this.dataFetchedSubscription = this.kgKendoGridService.OnDataFetched.subscribe((data: any) =>
    {
      this.OnDataFetched.emit(data);
    });

    this.savedSubscription = this.kgKendoGridService.OnSaved.subscribe((data: any) =>
    {
      this.saveInProgress = false;
      this.OnSaved.emit(data);
    });

    this.kgKendoGridService.getSingleItemActionMethodPathFromRoot = this.getSingleItemActionMethodPathFromRoot;
    this.kgKendoGridService.createActionMethodPathFromRoot = this.createActionMethodPathFromRoot;
    this.kgKendoGridService.destroyActionMethodPathFromRoot = this.destroyActionMethodPathFromRoot;
    this.kgKendoGridService.readActionMethodPathFromRoot = this.readActionMethodPathFromRoot;
    this.kgKendoGridService.updateActionMethodPathFromRoot = this.updateActionMethodPathFromRoot;
    this.kgKendoGridService.exportActionMethodPathFromRoot = this.exportActionMethodPathFromRoot;
    this.kgKendoGridService.useReviver = this.useReviver;
    this.kgKendoGridService.prepareDataForSave = this.prepareDataForSave;
    this.kgKendoGridService.useCachedData = this.useCachedData;
    this.kgKendoGridService.idField = this.idField;

    this.kgKendoGridService.read(this.gridState);
  }

  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.dataFetchedSubscription)
    {
      this.dataFetchedSubscription.unsubscribe();
    }
    if (this.savedSubscription)
    {
      this.savedSubscription.unsubscribe();
    }
  }

  public onStateChange(state: State)
  {
    this.gridState = state;

    this.kgKendoGridService.read(state);
  }

  public addHandler()
  {
    this.editDataItem = {} as E;
    this.isNew = true;
  }

  public async editHandler({ dataItem }: any)
  {
    //Get item from database via api instead of using item passed from grid
    if (this.getSingleItemActionMethodPathFromRoot)
    {
      const url: string = `${this.configurationService.cbSettings().appServiceApiUrlRoot}/${this.getSingleItemActionMethodPathFromRoot}?id=${dataItem[this.idField]}`;
      await this.dataHandler.getHttpPromise(url, false, false).then(data =>
      {
        dataItem = data;
      });
    }

    //Get item passed from grid
    this.originalDataItem = JSON.parse(JSON.stringify(dataItem));

    this.editDataItem = dataItem;
    this.isNew = false;

    this.OnEdit.emit(dataItem);
  }

  public cancelHandler()
  {
    this.originalDataItem = undefined;
    this.editDataItem = undefined;
  }


  public saveHandler(dataItem: E)
  {
    this.saveInProgress = true;

    try
    {
      if (this.modifiedByField && this.modifiedBy)
      {
        dataItem[this.modifiedByField as keyof typeof dataItem] = this.modifiedBy;
      }

      this.kgKendoGridService.save(dataItem, this.isNew);
      this.editDataItem = undefined;
    }
    catch (e)
    {
      this.saveInProgress = false;
    }
  }

  public async removeHandler({ dataItem }: any)
  {
    if (confirm('Are you sure you want to delete this item?'))
    {
      //Get item from database via api instead of using item passed from grid
      if (this.getSingleItemActionMethodPathFromRoot)
      {
        const url: string = `${this.configurationService.cbSettings().appServiceApiUrlRoot}/${this.getSingleItemActionMethodPathFromRoot}?id=${dataItem[this.idField]}`;
        await this.dataHandler.getHttpPromise(url, false, false).then(data =>
        {
          dataItem = data;
        });
      }

      this.kgKendoGridService.remove(dataItem);
    }
  }

}
