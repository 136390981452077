<mat-card appearance="outlined">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
    </mat-card-title>
    <div class="card-header-text-icons">
      Reload data:
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" matSort class="mat-table">
      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="PropertyId">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Id</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.PropertyId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="CategoryName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Category Name
          </div>
          <mat-form-field>
            <mat-label>Category Name</mat-label>
            <input matInput class="form-field" [formControl]="CategoryNameFilter" placeholder="Category Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.CategoryName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="PropertyName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Property Name
          </div>
          <mat-form-field>
            <mat-label>Property Name</mat-label>
            <input matInput class="form-field" [formControl]="PropertyNameFilter" placeholder=" Property Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.PropertyName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ChangedDate">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Changed Date
          </div>
          @if (false) {
            <mat-form-field style="width:120px">
              <mat-label>Changed Date</mat-label>
              <input matInput [matDatepicker]="cdatepicker" [formControl]="ChangedDateFilter" placeholder="Changed Date" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="cdatepicker"></mat-datepicker-toggle>
              <mat-datepicker #cdatepicker startView="month"></mat-datepicker>
            </mat-form-field>
          }
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.ChangedDate | date:'dd/MM/yyyy'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="User">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            User
          </div>
          <mat-form-field>
            <mat-label>User</mat-label>
            <input matInput class="form-field" [formControl]="UserFilter" placeholder="User" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.User}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FieldChanged">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Field Changed</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.FieldChanged}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="OldValue">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Old Value</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.OldValue}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="NewValue">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">New Value</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.NewValue}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Action</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Action}}</mat-cell>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
          <div class="card-header-text-icons">
            Reload data:
            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          @if (CanAdd) {
            <button mat-icon-button color="accent" (click)="addNew($event)" title="add">
              <i class="material-icons">add_box</i>
            </button>
          }
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row; let i=index;">
          @if (CanEdit) {
            <button mat-icon-button color="accent" (click)="editItem($event, row)" title="edit">
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
          }

          @if (CanDelete) {
            <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
          }
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource?.data?.length === 0 ? '' : 'none'">
      No results
    </div>

  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator
      [length]="dataSource?.filteredData?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="mtCrudService.pagerPageSizes"
      showFirstLastButtons="true">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
