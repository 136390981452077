import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from "../../shared/services/configuration.service";
import { LoggerService } from '../../shared/services/logger.service';
import { CalendarsService } from "../calendars/calendars.service";
import { AuditService } from './audit.service';
import { AuditToolsComponent } from "./audit-tools/audit-tools.component";
import { AuditPropertiesComponent } from "./audit-properties/audit-properties.component";
import { AuditActivitiesComponent } from "./audit-activities/audit-activities.component";

import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss'],
    providers: [AuditService],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, AuditActivitiesComponent, AuditPropertiesComponent, AuditToolsComponent]
})
export class AuditComponent implements OnInit, OnDestroy
{
  public form: UntypedFormGroup;

  constructor(public fb: UntypedFormBuilder, public calendarsService: CalendarsService, public configurationService: ConfigurationService, public auditService: AuditService, public commonService: CommonService, public loggerService: LoggerService)
  {
    this.form = fb.group({
      SearchType: [1]
    });
  }

  ngOnInit(): void
  {


  }


  ngOnDestroy()
  {

  }








}


