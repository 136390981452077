import { NgControl } from '@angular/forms';
import { Input, Directive, Self, Optional } from '@angular/core';

///////This is for controls that have "formControlName" or "formControl" attributes.  Normal "disabled" is fine for other controls. ////////////////////////////

@Directive({
    selector: '[disableControl]',
    standalone: true
})
export class DisableControlDirective
{

  @Input() set disableControl(condition: boolean)
  {
    const action = condition ? 'disable' : 'enable';

    if (this.ngControl && this.ngControl.control)
    {
      this.ngControl.control[action]();
    }
  }

  constructor(@Self() @Optional() private ngControl: NgControl)
  {
  }

}
