<div class="form-group-url">
  @if (showLabel) {
    <label style="opacity:unset">{{labelText}}</label>
  }
  <div class="upload-btn-wrapper">
    <button class="btn">...</button>
    <input #file type="file" accept="*" (change)="updateFile(file)" />
  </div>
  @if (!value) {
    <label>{{emptyMsg}}</label>
  }
  @if (value && downloadDocument) {
    <a download="{{value}}" href="{{documentUrl}}" target="_blank" title="{{value}}">{{linkText}}</a>
  }
  @if (value && !downloadDocument) {
    <a href="{{documentUrl}}" target="_blank" title="{{value}}">{{linkText}}</a>
  }
  @if (value) {
    <mat-icon class="clear-icon" title="Clear file" (click)="clearFile($event)">clear</mat-icon>
  }
</div>
