import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { Station } from '../../../components/calendars/calendars.models';
import { User } from '../../models/common.models';
import { AuthorisationService } from '../../services/authorisation.service';
import { CommonService } from '../../services/common.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DataHandlerService } from '../../services/data-handler.service';
import { DashboardData } from './dashboard.models';

@Injectable({ providedIn: 'root' })

export class DashboardService
{
  private _stationStatusData: any[] = [];
  private _userStatusData: any[] = [];
  private _homeStation: Station = null;

  private queryClient = injectQueryClient();


  public queryDashboardData = injectQuery(() =>
  ({
    enabled: this.currentUser()?.Id > 0 && this.dashboardMonth() > 0 && this.dashboardMonth() < 13 && this.dashboardYear() > 0,
    queryKey: ["DashboardData", this.currentUser()?.Id, this.teamView(), this.dashboardMonth(), this.dashboardYear(), this.isOnline()],
    queryFn: () => this.getDashboardData(this.currentUser()?.Id, this.teamView(), this.dashboardMonth(), this.dashboardYear(), this.isOnline()),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))



  constructor(
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private dataHandler: DataHandlerService,
    private authorisationService: AuthorisationService
  )
  {

  }


  public currentUser: WritableSignal<User> = signal(null);

  public dashboardMonth: WritableSignal<number> = signal(this.commonService.today.getMonth() + 1);
  public dashboardYear: WritableSignal<number> = signal(this.commonService.today.getFullYear());

  public isOnline: WritableSignal<boolean> = signal(false);
  public teamView: WritableSignal<boolean> = signal(false);


  public dashboardData: Signal<DashboardData> = computed(() =>
  {
    const result: DashboardData = this.queryDashboardData.data();

    if (result)
    {
      this.updateDashboardData(result);
    }

    return result;
  });


  resetDashboardData()
  {
    this._stationStatusData = [];
    this._userStatusData = [];
  }


  get homeStation(): Station
  {
    return this._homeStation;
  }
  set homeStation(value: Station)
  {
    this._homeStation = value;
  }

  get stationStatusData(): any[]
  {
    if ((!this._stationStatusData || this._stationStatusData.length == 0) && this.dashboardData() && this.dashboardData().ActivitiesStationStatusThisMonth)
    {
      this._stationStatusData = [];

      this._stationStatusData.push({ "name": "First Right of Refusal", "value": this.dashboardData().ActivitiesStationStatusThisMonth.Guaranteed, "colour": "#22a0ce" });
      this._stationStatusData.push({ "name": "On Hold", "value": this.dashboardData().ActivitiesStationStatusThisMonth.OnHold, "colour": "#684579" });
      this._stationStatusData.push({ "name": "Pending", "value": this.dashboardData().ActivitiesStationStatusThisMonth.Pending, "colour": "#73bf4c" });
      this._stationStatusData.push({ "name": "Confirmed", "value": this.dashboardData().ActivitiesStationStatusThisMonth.Sold, "colour": "#ef5252" });
      this._stationStatusData.push({ "name": "Submitted for Delivery", "value": this.dashboardData().ActivitiesStationStatusThisMonth.UnConfirmedSold, "colour": "#e0e000" });
    }

    return this._stationStatusData;
  }

  get stationStatusDataTotal(): number
  {
    return this.stationStatusData.reduce((sum, current) => sum + current.value, 0);
  }

  get userStatusData(): any[]
  {
    if ((!this._userStatusData || this._userStatusData.length == 0) && this.dashboardData() && this.dashboardData().ActivitiesUserStatusThisMonth)
    {
      this._userStatusData = [];

      this._userStatusData.push({ "name": "First Right of Refusal", "value": this.dashboardData().ActivitiesUserStatusThisMonth.Guaranteed, "colour": "#22a0ce" });
      this._userStatusData.push({ "name": "On Hold", "value": this.dashboardData().ActivitiesUserStatusThisMonth.OnHold, "colour": "#684579" });
      this._userStatusData.push({ "name": "Pending", "value": this.dashboardData().ActivitiesUserStatusThisMonth.Pending, "colour": "#73bf4c" });
      this._userStatusData.push({ "name": "Confirmed", "value": this.dashboardData().ActivitiesUserStatusThisMonth.Sold, "colour": "#ef5252" });
      this._userStatusData.push({ "name": "Submitted for Delivery", "value": this.dashboardData().ActivitiesUserStatusThisMonth.UnConfirmedSold, "colour": "#e0e000" });
    }

    return this._userStatusData;
  }

  get userStatusDataTotal(): number
  {
    return this.userStatusData.reduce((sum, current) => sum + current.value, 0);
  }

  public updateDashboardData(dashboardData: DashboardData)
  {
    if (dashboardData.DropDeadActivities && dashboardData.DropDeadActivities.length > 0)
    {
      dashboardData.DropDeadActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (dashboardData.OnHoldActivities && dashboardData.OnHoldActivities.length > 0)
    {
      dashboardData.OnHoldActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (dashboardData.CreditLineDueActivities && dashboardData.CreditLineDueActivities.length > 0)
    {
      dashboardData.CreditLineDueActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (dashboardData.FinalPcrActivities && dashboardData.FinalPcrActivities.length > 0)
    {
      dashboardData.FinalPcrActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }

    if (dashboardData.FirstDayAirCheckActivities && dashboardData.FirstDayAirCheckActivities.length > 0)
    {
      dashboardData.FirstDayAirCheckActivities.map(d =>
      {
        d.ListDate = this.commonService.parseJsonDate(d.ListDate);
      });
    }
  }

  getDashboardData(userId: number, isTeamView: boolean = false, month: number = this.commonService.today.getMonth() + 1, year: number = this.commonService.today.getFullYear(), isOnline: boolean = false, useCachedData: boolean = false): Promise<DashboardData>
  {
    this.resetDashboardData();

    return this.dataHandler.getHttpPromise<DashboardData>(this.configurationService.cbSettings().dashboardServiceUrl + `/GetDashboardData?month=${month}&year=${year}&userId=${userId}&isTeamView=${isTeamView}&isOnline=${isOnline}`, useCachedData, false);
  }
}
