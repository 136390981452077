import { Injectable, inject } from '@angular/core';
import { ActivityInfo } from '../../calendars/calendars.models';
import { ActivityAssignInfo, Campaign } from '../campaigns.models';
import { Observable } from 'rxjs';
import { DataHandlerService } from '../../../shared/services/data-handler.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SaveResult } from '../../../shared/models/result.model';


@Injectable()

export class CampaignEditorService
{
  private dataHandler = inject(DataHandlerService);
  public configurationService = inject(ConfigurationService);

  private _rowsSelected: ActivityInfo[] = [];

  constructor()
  {

  }

  private _campaign: Campaign;

  get campaign(): Campaign
  {
    return this._campaign;
  }
  set campaign(value: Campaign)
  {
    this._campaign = value;
  }

  get rowsSelected(): ActivityInfo[]
  {
    return this._rowsSelected;
  }
  set rowsSelected(value: ActivityInfo[])
  {
    this._rowsSelected = value;
  }








  



  getCampaignActivities(campaignId: number, useCachedData: boolean = false): Promise<ActivityInfo[]>
  {
    return this.dataHandler.getHttpPromise<ActivityInfo[]>(this.configurationService.cbSettings().campaignServiceUrl + "/GetCampaignActivities?id=" + campaignId, useCachedData, false);
  }

  unassignActivityFromCampaign(activityAssignInfo: ActivityAssignInfo, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().campaignServiceUrl + "/UnassignActivityFromCampaign", activityAssignInfo, useCachedData, false);
  }



}
