import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectableChipsService } from './selectable-chips.service';

import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'selectable-chips',
  templateUrl: './selectable-chips.component.html',
  styleUrls: ['./selectable-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectableChipsComponent),
      multi: true
    },
    SelectableChipsService
  ],
  standalone: true,
  imports: [MatChipsModule]
})
export class SelectableChipsComponent<T> implements OnInit, ControlValueAccessor
{
  @Input() isDisabled = false;

  @Input() data: T[] = [];
  @Output() onChipSelected = new EventEmitter<T[]>();
  @Input() public textField: string;
  @Input() valueField: string;
  @Input() public titleField: string;

  @Input() get value(): T[]
  {
    return this.selectableChipsService.chips;
  }
  set value(val: T[])
  {
    this.resetChipCollection();

    if (val)
    {
      for (const chip of val)
      {
        const ch: any = chip;
        this.chipCollection.set(ch[this.valueField as keyof typeof ch], true);
      }

      if (this.data && this.data.length > 0)
      {
        this.selectableChipsService.chips = this.data.filter(c => this.getSelected().some(s => c[this.valueField as keyof typeof c] == s));
      }
    }

    if (this.propagateChange)
    {
      this.propagateChange(val);
    }
  }

  private chipCollection: Map<T, boolean> = new Map<T, boolean>();

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: T[]) => { };

  constructor(private selectableChipsService: SelectableChipsService)
  {

  }

  ngOnInit(): void
  {
    if (!this.titleField)
    {
      //default title to text field if blank.
      this.titleField = this.textField;
    }

    if (!this.value)
    {
      this.selectableChipsService.chips = [];
    }

    this.resetChipCollection();
  }

  resetChipCollection()
  {
    for (const chip of this.data)
    {
      const ch: any = chip;
      this.chipCollection.set(ch[this.valueField as keyof typeof ch], false);
    }
  }

  ngOnDestroy()
  {

  }

  isSelected(chip: T)
  {
    const ch: any = chip;
    return this.chipCollection.get(ch[this.valueField as keyof typeof ch]);
  }

  onClick(event: Event, chip: T)
  {
    if (!this.isDisabled)
    {
      this.selectChip(chip);

      const selected = this.getSelected();
      this.onChipSelected.emit(selected);

      this.value = this.data.filter(c => selected.some(s => c[this.valueField as keyof typeof chip] == s));
    }
  }

  selectChip(chip: T)
  {
    if (!this.isDisabled)
    {
      const ch: any = chip;

      if (this.chipCollection.get(ch[this.valueField as keyof typeof ch]))
      {
        this.chipCollection.set(ch[this.valueField as keyof typeof ch], false);
      }
      else
      {
        this.chipCollection.set(ch[this.valueField as keyof typeof ch], true);
      }
    }
  }

  getSelected()
  {
    const selected = <T[]>[];

    Array.from(this.chipCollection.entries(), (v: [T, boolean]) =>
    {
      if (v['1'])
      {
        selected.push(v['0']);
      }
    });

    return selected;
  }


  ngOnChanges(inputs: any): void
  {
    if (this.value)
    {
      if (this.propagateChange)
      {
        this.propagateChange(this.value);
      }
    }
  }

  writeValue(value: any): void
  {
    if (value)
    {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this.isDisabled = disabled;
  }



}
