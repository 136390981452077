export class Event
{
  public Id = 0;
  public Title = "";
  public Description = "";
  public Header = "";
  public StartDate: Date;
  public EndDate: Date;
  public StartTime: Date;
  public EndTime: Date;
  public StartTimeZone = "";
  public EndTimeZone = "";
  public IsAllDayEvent = false;
  public ActivityId = 0;
  public EventCategoryId = 0;
  public Importance = 0;
  public TimeMarker = "";
  public Location = "";
  public Url = "";
  public Type = 0;
  public ParentId: number;
  public RecurrencePattern = "";
  public ExceptionAppointments: any;
  public StationIds: number[] = [];
  public Disabled = false;
  public ChangedByUserId = 0;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class EventStation
{
  public Id = 0;
  public EventId = 0;
  public StationId = 0;
}
