<div class="control-panel-control" [formGroup]="form">
  @if (false) {
    <div class="control-panel-header">
      <label>Control Panel</label>
    </div>
  }
  <div class="control-panel-content">
    <div>
      <button mat-raised-button color="primary" class="control-panel-button" (click)="ResetActivitiesCache()">Reset Activities Cache</button>
      <button mat-raised-button color="primary" class="control-panel-button" (click)="ResetCategoriesCache()">Reset Categories Cache</button>
      <button mat-raised-button color="primary" class="control-panel-button" (click)="ResetClientsCache()">Reset Clients Cache</button>
      <button mat-raised-button color="primary" class="control-panel-button" (click)="ResetProductCategoriesCache()">Reset Client Categories Cache</button>
      <button mat-raised-button color="primary" class="control-panel-button" (click)="ResetPropertiesCache()">Reset Properties Cache</button>
      <button mat-raised-button color="primary" class="control-panel-button" (click)="PurgeAuditTrail()">Purge Audit Trail</button>
    </div>
    <div>
      @if (configurationService?.cbSettings?.isInterfacingClientCategories) {
        <button mat-raised-button color="primary" class="control-panel-button" (click)="ImportProductCategories()">Import Client Categories</button>
      }
      @if (configurationService?.cbSettings?.isInterfacingRates) {
        <button mat-raised-button color="primary" class="control-panel-button" (click)="ImportRates()">Import Rates</button>
      }
      @if (configurationService?.cbSettings?.isInterfacingUsers) {
        <button mat-raised-button color="primary" class="control-panel-button" (click)="ImportUsers()">Import Users</button>
      }
      @if (configurationService?.cbSettings?.isInterfacingActivities) {
        <div>
          <button mat-raised-button color="primary" class="control-panel-button" (click)="ImportActivities()">Import Activities</button>
          <mat-form-field style="width:unset">
            <mat-label>Activity Modified Date</mat-label>
            <input matInput [matDatepicker]="activitystartdatepicker" formControlName="ActivityStartDate" placeholder="Activity Modified Date" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="activitystartdatepicker"></mat-datepicker-toggle>
            <mat-datepicker #activitystartdatepicker startView="month" disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      }
      @if (configurationService?.cbSettings?.isInterfacingClients) {
        <div>
          <button mat-raised-button color="primary" class="control-panel-button" (click)="ImportClients()">Import Clients</button>
          <mat-form-field style="width:unset">
            <mat-label>Client Modified Date</mat-label>
            <input matInput [matDatepicker]="clientstartdatepicker" formControlName="ClientsStartDate" placeholder="Client Modified Date" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="clientstartdatepicker"></mat-datepicker-toggle>
            <mat-datepicker #clientstartdatepicker startView="month" disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      }
    </div>
  </div>
  <div class="control-panel-footer"></div>
</div>
