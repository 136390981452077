import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { faBroadcastTower, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { DropDownFilterSettings, MultiSelectModule, SharedDirectivesModule } from "@progress/kendo-angular-dropdowns";
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { Role, User } from "../../../shared/models/common.models";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { AdministrationService } from '../administration.service';
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { MatButtonModule } from "@angular/material/button";
import { FloatingLabelModule } from "@progress/kendo-angular-label";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";

import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";


@Component({
    templateUrl: './user-edit.component.html',
    selector: 'user-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './users.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatCheckboxModule, FloatingLabelModule, MultiSelectModule, SharedDirectivesModule, MatDialogActions, MatButtonModule, GenericFilterPipe]
})
export class UserEditComponent extends MatTableCrudEditComponent<UserEditComponent, User>
{
  public faBroadcastTower: IconDefinition = faBroadcastTower;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UserEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    public administrationService: AdministrationService,
    public authorisationService: AuthorisationService,
    public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      UserName: [this.data.UserName],
      FirstName: [this.data.FirstName, Validators.required],
      Surname: [this.data.Surname, Validators.required],
      Email: [this.data.Email, Validators.compose([Validators.required, Validators.email])],
      Phone: [this.data.Phone, Validators.compose([Validators.pattern('(?=.*[0-9])[- +()0-9]+')])],
      Mobile: [this.data.Mobile, Validators.compose([Validators.pattern('(?=.*[0-9])[- +()0-9]+')])],
      HomeStationId: [this.data.HomeStationId, Validators.required],
      ContactTypes: [this.data.ContactTypes],
      DepartmentId: [this.data.DepartmentId],
      UserRole: [this.data.UserRole, Validators.required],
      Stations: [this.data.Stations, []],
      ReportsToUser: [this.data.ReportsToUser],
      //IsLocked: [this.data.IsLocked],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    const user: any = this.form.value;

    if (user)
    {
      if (this.isNew)
      {
        user.Id = 0;
        user.DepartmentId = 0;

        if (user.Disabled == null)
        {
          user.Disabled = false;
        }
      }

      if (user.UserRole)
      {
        if (!user.UserRole.Permissions)
        {
          user.UserRole.Permissions = [];
        }

        user.UserRole.LastUpdated = new Date();
        user.UserRole.ChangedByUserId = this.authorisationService.currentuser.Id;
      }

      user[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(user);
    }
  }


  get dialogHeaderText(): string
  {
    let headerText = "Edit User";

    if (this.isNew)
    {
      headerText = "New User";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.UserName}`;
      }
    }

    return headerText;
  }

  get supervisors(): User[]
  {
    return this.administrationService.supervisors.filter(s => s.Id != this.data.Id);
  }


  equalsRole(o1: Role, o2: Role)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsUser(o1: User, o2: User)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
