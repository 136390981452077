<div class="copy-property-info-control" [formGroup]="form">
  @if (false) {
    <div class="copy-property-info-header">
      <label>Copy Property Information</label>
    </div>
  }
  <div class="copy-property-info-content-filter">
  </div>
  <div class="copy-property-info-content">
    <div>
      <label>From Property</label>
      <kendo-combobox [data]="calendarsService.properties()"
        formControlName="PropertyId"
        [kendoDropDownFilter]="filterSettings"
        [textField]="'IdAndPropertyName'"
        [valueField]="'Id'"
        [valuePrimitive]="true"
        [size]="'small'"
        [fillMode]="'flat'"
        [placeholder]="'Please Select Property'"
        style="width:100%"
        (valueChange)="propertyChange($event)">
      </kendo-combobox>
      <span class="copy-match">
        <div title="Click to copy the information of the platform providers of the selected From Property to the matching platform providers of the properties selected to be updated.">
          <label class="k-label" [for]="match">Match Platform Provider Data&nbsp;&nbsp;</label>
          <input type="radio"
            name="CopyStationData"
            value="0"
            #match
            formControlName="CopyStationData"
            (change)="matchCopyChanged($event)"
            kendoRadioButton />
          </div>
          <div title="Click to copy the information of the selected platform provider of the From Property to the platform provider information of ALL the properties selected to be updated.">
            <label class="k-label" [for]="copy">Copy From Selected&nbsp;&nbsp;</label>
            <input type="radio"
              name="CopyStationData"
              value="1"
              #copy
              formControlName="CopyStationData"
              (change)="matchCopyChanged($event)"
              kendoRadioButton />
            </div>
          </span>
          <kendo-combobox [data]="propertyStations"
            formControlName="FromStationId"
            [kendoDropDownFilter]="filterSettings"
            [textField]="'StationName'"
            [valueField]="'Id'"
            [valuePrimitive]="true"
            [size]="'small'"
            [fillMode]="'flat'"
            [placeholder]="'Please Select Platform Provider'"
            style="width:100%;margin-bottom:35px">
          </kendo-combobox>
          <mat-checkbox formControlName="CopyExclusionDates">Copy Exclusion Dates</mat-checkbox>
          <mat-checkbox formControlName="CopyAirtimeCredit">Copy Airtime Credit</mat-checkbox>
          <mat-checkbox formControlName="CopySpotsAvailable">Copy Spots Available</mat-checkbox>
          <mat-checkbox formControlName="CopyProjectManager">Copy Platform Provider Contact</mat-checkbox>
        </div>
        <div>
          <label>To Properties</label>
          <kendo-multiselect [autoClose]="false" [data]="calendarsService.stations()"
            [textField]="'StationName'"
            [valueField]="'Id'"
            [valuePrimitive]="true"
            [size]="'small'"
            [fillMode]="'flat'"
            formControlName="StationIds"
            placeholder="All Platform Providers"
            [kendoDropDownFilter]="filterSettings"
            style="padding-top: 0.65rem;">
          </kendo-multiselect>
          <div>
            <kendo-multiselect [autoClose]="false" [data]="calendarsService.propertyTypes()"
              [textField]="'PropertyTypeName'"
              [valueField]="'Id'"
              [valuePrimitive]="true"
              [size]="'small'"
              [fillMode]="'flat'"
              formControlName="PropertyTypeIds"
              placeholder="All Property Types"
              [kendoDropDownFilter]="filterSettings"
              style="padding-top: 5%;">
            </kendo-multiselect>
            <label>OR</label>
            <kendo-multiselect [autoClose]="false" [data]="properties"
              [textField]="'IdAndPropertyName'"
              [valueField]="'Id'"
              [valuePrimitive]="true"
              [size]="'small'"
              [fillMode]="'flat'"
              formControlName="ToPropertyIds"
              placeholder="All Properties"
              [kendoDropDownFilter]="filterSettings"
              (valueChange)="toPropertiesChange($event)"
              style="padding-top: 5%;">
            </kendo-multiselect>
          </div>
        </div>
      </div>
      <div class="copy-property-info-footer">
        <button mat-raised-button color="primary" [disabled]="!form.valid || !form.dirty || !hasSomethingToDo || saveInProgress" class="copy-button" (click)="copyInfo()">Copy</button>
      </div>
    </div>

