import { Injectable, inject } from '@angular/core';
import { ActivityInfo } from '../../calendars/calendars.models';
import { Campaign, CampaignActivities } from '../campaigns.models';
import { Observable } from 'rxjs';
import { DataHandlerService } from '../../../shared/services/data-handler.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SaveResult } from '../../../shared/models/result.model';


@Injectable()

export class CampaignAssignerService
{
  private dataHandler = inject(DataHandlerService);
  public configurationService = inject(ConfigurationService);

  private _unassignedActivitiesForClient: ActivityInfo[] = [];
  private _rowsSelected: ActivityInfo[] = [];

  constructor()
  {

  }
  get unassignedActivitiesForClient(): ActivityInfo[]
  {
    return this._unassignedActivitiesForClient;
  }
  set unassignedActivitiesForClient(value: ActivityInfo[])
  {
    this._unassignedActivitiesForClient = value;
  }

  get rowsSelected(): ActivityInfo[]
  {
    return this._rowsSelected;
  }
  set rowsSelected(value: ActivityInfo[])
  {
    this._rowsSelected = value;
  }





  getUnassignedCampaignActivities(id: number, useCachedData: boolean = false): Observable<ActivityInfo[]>
  {
    return this.dataHandler.getHttpObservable<ActivityInfo[]>(this.configurationService.cbSettings().campaignServiceUrl + "/GetUnassignedCampaignActivities?id=" + id, useCachedData, false);
  }

  assignActivitiesToCampaign(campaignActivities: CampaignActivities, useCachedData: boolean = false): Promise<SaveResult>
  {
    return this.dataHandler.postHttpPromise<SaveResult>(this.configurationService.cbSettings().campaignServiceUrl + "/AssignActivitiesToCampaign", campaignActivities, useCachedData, false);
  }
}
