import { Injectable, Signal, computed } from '@angular/core';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { DayPart } from '../../components/administration/administration.models';
import { Client, ContactTypeId, DaysInWeek } from '../../components/calendars/calendars.models';
import { Campaign } from '../../components/campaigns/campaigns.models';
import { ContactTypeUser } from '../models/common.models';
import { CommonService } from './common.service';
import { ConfigurationService } from './configuration.service';
import { DataHandlerService } from './data-handler.service';
import { Observable } from 'rxjs';


export const CURRENT_LOCALE = 'current-locale';
export const CURRENT_LOCALE_NAME = 'current-locale-name';

@Injectable({ providedIn: 'root' })

export class CommonDataService
{
  private _campaigns: Campaign[] = [];


  private _clientExecutiveLabelText: string = "";
  private _clientSupportLabelText: string = "";
  private _projectManagerLabelText: string = "";
  private _briefManagerLabelText: string = "";
  private _nationalProjectManagerLabelText: string = "";
  private _programmerLabelText: string = "";
  private _adOpsLabelText: string = "";

  public readonly bitmaskWeekdays: number = 62;
  public readonly bitmaskWeekends: number = 65;
  public readonly bitmaskEveryday: number = 127;

  public daysInTheWeek: DaysInWeek[] = [
    { Name: "Monday", Abbreviation: "Mon", FirstLetter: "M", ShortAbbreviation: "Mo", Value: 2, DayOfWeek: 1 },
    { Name: "Tuesday", Abbreviation: "Tue", FirstLetter: "T", ShortAbbreviation: "Tu", Value: 4, DayOfWeek: 2 },
    { Name: "Wednesday", Abbreviation: "Wed", FirstLetter: "W", ShortAbbreviation: "We", Value: 8, DayOfWeek: 3 },
    { Name: "Thursday", Abbreviation: "Thu", FirstLetter: "T", ShortAbbreviation: "Th", Value: 16, DayOfWeek: 4 },
    { Name: "Friday", Abbreviation: "Fri", FirstLetter: "F", ShortAbbreviation: "Fr", Value: 32, DayOfWeek: 5 },
    { Name: "Saturday", Abbreviation: "Sat", FirstLetter: "S", ShortAbbreviation: "Sa", Value: 64, DayOfWeek: 6 },
    { Name: "Sunday", Abbreviation: "Sun", FirstLetter: "S", ShortAbbreviation: "Su", Value: 1, DayOfWeek: 0 }
  ];

  public months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  public dayInMonthInstance = ["first", "second", "third", "fourth", "last"];

  constructor(
    private dataHandler: DataHandlerService,
    private commonService: CommonService,
    private configurationService: ConfigurationService)
  {

  }

  public dayparts: Signal<DayPart[]> = computed(() =>
  {
    const result: any = this.gueryDayParts.data();

    return result;
  });

  public gueryDayParts = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["DayParts"],
    queryFn: () => this.getDayParts(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public clients: Signal<Client[]> = computed(() =>
  {
    const result: any = this.gueryClients.data();

    return result;
  });

  public gueryClients = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["Clients"],
    queryFn: () => this.getClients(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public projectManagers: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryProjectManagers.data();

    return result;
  });

  public gueryProjectManagers = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["ProjectManagers"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.ProjectManager),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public clientExecutives: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryClientExecutives.data();

    return result;
  });

  public gueryClientExecutives = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["ClientExecutives"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.ClientExecutive),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public clientSupport: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryClientSupport.data();

    return result;
  });

  public gueryClientSupport = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["ClientSupport"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.ClientSupport),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public briefManagers: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryBriefManagers.data();

    return result;
  });

  public gueryBriefManagers = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["BriefManagers"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.BriefManager),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public nationalProjectManagers: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryNationalProjectManagers.data();

    return result;
  });

  public gueryNationalProjectManagers = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["NationalProjectManagers"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.NationalProjectManager),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public programmers: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryProgrammers.data();

    return result;
  });

  public gueryProgrammers = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["Programmers"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.Programmer),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public adOps: Signal<ContactTypeUser[]> = computed(() =>
  {
    const result: any = this.gueryAdOps.data();

    return result;
  });

  public gueryAdOps = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["AdOps"],
    queryFn: () => this.getContactTypeUsers(ContactTypeId.AdOps),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))


  get campaigns(): Campaign[]
  {
    return this._campaigns;
  }
  set campaigns(value: Campaign[])
  {
    this._campaigns = value;
  }




  get clientExecutiveLabelText(): string
  {
    if ((!this._clientExecutiveLabelText || this._clientExecutiveLabelText == "Account Lead") && this.clientExecutives() && this.clientExecutives().length > 0)
    {
      this._clientExecutiveLabelText = this.clientExecutives()[0].ContactTypeLabelText;
    }

    if (!this._clientExecutiveLabelText && this.clientExecutives().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._clientExecutiveLabelText = this.clientExecutives()[1].ContactTypeLabelText;
    }

    if (!this._clientExecutiveLabelText)
    {
      //set label default value if still ""
      this._clientExecutiveLabelText = "Account Lead";
    }

    return this._clientExecutiveLabelText;
  }

  get clientSupportLabelText(): string
  {
    if ((!this._clientSupportLabelText || this._clientSupportLabelText == "Account Support") && this.clientSupport() && this.clientSupport().length > 0)
    {
      this._clientSupportLabelText = this.clientSupport()[0].ContactTypeLabelText;
    }

    if (!this._clientSupportLabelText && this.clientSupport().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._clientSupportLabelText = this.clientSupport()[1].ContactTypeLabelText;
    }

    if (!this._clientSupportLabelText)
    {
      //set label default value if still ""
      this._clientSupportLabelText = "Account Support";
    }

    return this._clientSupportLabelText;
  }

  get briefManagerLabelText(): string
  {
    if ((!this._briefManagerLabelText || this._briefManagerLabelText == "Brief Manager") && this.briefManagers() && this.briefManagers().length > 0)
    {
      this._briefManagerLabelText = this.briefManagers()[0].ContactTypeLabelText;
    }

    if (!this._briefManagerLabelText && this.briefManagers().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._briefManagerLabelText = this.briefManagers()[1].ContactTypeLabelText;
    }

    if (!this._briefManagerLabelText)
    {
      //set label default value if still ""
      this._briefManagerLabelText = "Brief Manager";
    }

    return this._briefManagerLabelText;
  }

  get projectManagerLabelText(): string
  {
    if ((!this._projectManagerLabelText || this._projectManagerLabelText == "Delivery Manager") && this.projectManagers() && this.projectManagers().length > 0)
    {
      this._projectManagerLabelText = this.projectManagers()[0].ContactTypeLabelText;
    }

    if (!this._projectManagerLabelText && this.projectManagers().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._projectManagerLabelText = this.projectManagers()[1].ContactTypeLabelText;
    }

    if (!this._projectManagerLabelText)
    {
      //set label default value if still ""
      this._projectManagerLabelText = "Delivery Manager";
    }

    return this._projectManagerLabelText;
  }

  get nationalProjectManagerLabelText(): string
  {
    if ((!this._nationalProjectManagerLabelText || this._nationalProjectManagerLabelText == "Campaign Delivery Manager") && this.nationalProjectManagers() && this.nationalProjectManagers().length > 0)
    {
      this._nationalProjectManagerLabelText = this.nationalProjectManagers()[0].ContactTypeLabelText;
    }

    if (!this._nationalProjectManagerLabelText && this.nationalProjectManagers().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._nationalProjectManagerLabelText = this.nationalProjectManagers()[1].ContactTypeLabelText;
    }

    if (!this._nationalProjectManagerLabelText)
    {
      //set label default value if still ""
      this._nationalProjectManagerLabelText = "Campaign Delivery Manager";
    }

    return this._nationalProjectManagerLabelText;
  }

  get programmerLabelText(): string
  {
    if ((!this._programmerLabelText || this._programmerLabelText == "Programmer") && this.programmers() && this.programmers().length > 0)
    {
      this._programmerLabelText = this.programmers()[0].ContactTypeLabelText;
    }

    if (!this._programmerLabelText && this.programmers().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._programmerLabelText = this.programmers()[1].ContactTypeLabelText;
    }

    if (!this._programmerLabelText)
    {
      //set label default value if still ""
      this._programmerLabelText = "Programmer";
    }

    return this._programmerLabelText;
  }

  get adOpsLabelText(): string
  {
    if ((!this._adOpsLabelText || this._adOpsLabelText == "Ad Ops") && this.adOps() && this.adOps().length > 0)
    {
      this._adOpsLabelText = this.adOps()[0].ContactTypeLabelText;
    }

    if (!this._adOpsLabelText && this.adOps().length > 1)
    {
      //set label to the first contact after the NONE item if still ""
      this._adOpsLabelText = this.adOps()[1].ContactTypeLabelText;
    }

    if (!this._adOpsLabelText)
    {
      //set label default value if still ""
      this._adOpsLabelText = "Ad Ops";
    }

    return this._adOpsLabelText;
  }















  getContactTypeUsers(contactTypeId: number, useCachedData: boolean = false): Promise<ContactTypeUser[]>
  {
    return this.dataHandler.getHttpPromise<ContactTypeUser[]>(this.configurationService.cbSettings().calendarServiceUrl + "/GetContactTypeUsers?contactTypeId=" + contactTypeId, useCachedData, false);
  }

  getDayParts(useCachedData: boolean = false): Promise<DayPart[]>
  {
    return this.dataHandler.getHttpPromise<DayPart[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetDayParts", useCachedData, false);
  }

  getClients(useCachedData: boolean = false): Promise<Client[]>
  {
    return this.dataHandler.getHttpPromise<Client[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetClients", useCachedData, false);
  }


  getCampaigns(useCachedData: boolean = false): Observable<Campaign[]>
  {
    return this.dataHandler.getHttpObservable<Campaign[]>(this.configurationService.cbSettings().campaignServiceUrl + "/GetCampaigns", useCachedData, false);
  }


}



