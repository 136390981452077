import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InterfaceImport } from '../../../shared/models/common.models';
import { InterfaceImportResult } from '../../../shared/models/result.model';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { DataHandlerService } from '../../../shared/services/data-handler.service';




@Injectable({ providedIn: 'root' })

export class ControlPanelService
{

  constructor(private commonService: CommonService, private configurationService: ConfigurationService, private dataHandler: DataHandlerService)
  {

  }


  resetActivitiesCache(): Promise<boolean>
  {
    return this.dataHandler.getHttpPromise<boolean>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ResetActivitiesCache", false, false);
  }

  resetPropertiesCache(): Promise<boolean>
  {
    return this.dataHandler.getHttpPromise<boolean>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ResetPropertiesCache", false, false);
  }

  resetCategoriesCache(): Promise<boolean>
  {
    return this.dataHandler.getHttpPromise<boolean>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ResetCategoriesCache", false, false);
  }

  resetClientsCache(): Promise<boolean>
  {
    return this.dataHandler.getHttpPromise<boolean>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ResetClientsCache", false, false);
  }

  resetProductCategoriesCache(): Promise<boolean>
  {
    return this.dataHandler.getHttpPromise<boolean>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ResetProductCategoriesCache", false, false);
  }

  purgeAuditTrail(): Promise<boolean>
  {
    return this.dataHandler.postHttpPromise<boolean>(this.configurationService.cbSettings().controlPanelServiceUrl + "/PurgeAuditTrail", null, false, false);
  }

  importClientsAsync(interfaceImport: InterfaceImport): Promise<InterfaceImportResult>
  {
    return this.dataHandler.postHttpPromise<InterfaceImportResult>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ImportClients", interfaceImport, false, false);
  }

  importRatesAsync(interfaceImport: InterfaceImport): Promise<InterfaceImportResult>
  {
    return this.dataHandler.postHttpPromise<InterfaceImportResult>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ImportRates", interfaceImport, false, false);
  }

  importUsersAsync(interfaceImport: InterfaceImport): Promise<InterfaceImportResult>
  {
    return this.dataHandler.postHttpPromise<InterfaceImportResult>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ImportUsers", interfaceImport, false, false);
  }

  importActivitiesAsync(interfaceImport: InterfaceImport): Promise<InterfaceImportResult>
  {
    return this.dataHandler.postHttpPromise<InterfaceImportResult>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ImportActivities", interfaceImport, false, false);
  }

  importProductCategoriesAsync(interfaceImport: InterfaceImport): Promise<InterfaceImportResult>
  {
    return this.dataHandler.postHttpPromise<InterfaceImportResult>(this.configurationService.cbSettings().controlPanelServiceUrl + "/ImportProductCategories", interfaceImport, false, false);
  }


}
