import { Component } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButtonModule, MatDialogClose]
})
export class ConfirmationDialog
{
    constructor(public dialogRef: MatDialogRef<ConfirmationDialog>) { }

    public confirmTitle = "Confirm";
    public confirmHeader = "";
    public confirmMessage = "Are you sure?";
    public confirmText = "Confirm";
    public cancelText = "Cancel";
    public showCancelButton = true;
}

