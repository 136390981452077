import { Directive, EventEmitter, Injector, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { addMinutes, isDate, isValid } from 'date-fns';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Filter, ServerPagingParameters, Sort } from '../../models/common.models';
import { AuthorisationService } from '../../services/authorisation.service';
import { BaseComponent } from '../base/base.component';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { MatTableCrudService, Parameter } from './mat-table-crud.service';
import { ConfigurationService } from '../../services/configuration.service';


@Directive()
export class MatTableCrudComponent<T> extends BaseComponent implements OnInit
{
  public idField = "Id";
  public changedByField = "ChangedByUserId";
  public actionsColumnName = "actions";
  public autoGenerateColumns = false;
  public isEditable = true;
  public dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  public dialog: MatDialog;
  public dialogConfig = new MatDialogConfig();
  public displayedColumns: string[] = [];
  public filteredValues: FilterItem[] = [];
  public addedItem: T = {} as T;
  public deleteConfirmationMessage = "Are you sure you want to delete this record ?";
  public deleteHeaderText = "Delete Record ?";
  public saveInProgress = false;

  public mtCrudService: MatTableCrudService;
  public authorisationService: AuthorisationService;


  public dialogIsOpen = false;

  private _CanAdd = false;
  private _CanEdit = false;
  private _CanDelete = false;

  private _ServerPaging = false;
  private _spp: any = new ServerPagingParameters();

  private _getParameters: Parameter[] = [];
  private _postPayload: any = null;

  protected confirmDialog: MatDialog;
  private setUpAutoColumns = false;
  private firstDataFetch = true;

  @Output() public itemDeleted: EventEmitter<any> = new EventEmitter<any>();

  protected addCloseSubscription: Subscription;
  protected editCloseSubscription: Subscription;
  protected deleteCloseSubscription: Subscription;

  protected onSaveSubscription: Subscription;
  protected dataSubscription: Subscription;


  constructor(protected inj: Injector,
    private AddDialogComponent: any,
    private EditDialogComponent: any,
    private DeleteDialogComponent: any,
    private createActionMethod: string,
    private destroyActionMethod: string,
    private readActionMethod: string,
    private updateActionMethod: string,
    private readServiceUrl: string,
    private createServiceUrl: string,
    private destroyServiceUrl: string,
    private updateServiceUrl: string)
  {
    super();

    this.mtCrudService = inj.get(MatTableCrudService);
    this.authorisationService = inj.get(AuthorisationService);

    this.dialog = inj.get(MatDialog);
    this.confirmDialog = inj.get(MatDialog);

    this.dialogConfig.maxHeight = "100vh";
    this.dialogConfig.maxWidth = "100vw";

  }


  @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) public sort: MatSort;

  get getParameters(): Parameter[]
  {
    return this._getParameters;
  }
  set getParameters(value: Parameter[])
  {
    this._getParameters = value;

    this.mtCrudService.getParameters = this._getParameters;
  }

  get postPayload(): any
  {
    return this._postPayload;
  }
  set postPayload(value: any)
  {
    this._postPayload = value;

    this.mtCrudService.postPayload = this._postPayload;
  }

  get CanAdd(): boolean
  {
    return this._CanAdd;
  }
  set CanAdd(value: boolean)
  {
    this._CanAdd = value;
  }

  get CanEdit(): boolean
  {
    return this._CanEdit;
  }
  set CanEdit(value: boolean)
  {
    this._CanEdit = value;
  }

  get CanDelete(): boolean
  {
    return this._CanDelete;
  }
  set CanDelete(value: boolean)
  {
    this._CanDelete = value;
  }

  get ServerPagingParameters(): any
  {
    return this._spp;
  }
  set ServerPagingParameters(value: any)
  {
    this._spp = value;
  }


  get ServerPaging(): boolean
  {
    return this._ServerPaging;
  }
  set ServerPaging(value: boolean)
  {
    this._ServerPaging = value;
  }

  ngAfterViewInit()
  {
    if (this.ServerPaging)
    {
      // If the user changes the sort order, reset back to the first page.
      this.sort.sortChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() =>
      {
        this.paginator.pageIndex = 0;
        this.loadData();
      });

      this.paginator.page.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() =>
      {
        this.loadData();
      });
    }

    this.dataSource.paginator = this.paginator;

    //editable and no action column
    if (this.isEditable && this.displayedColumns.indexOf(this.actionsColumnName) < 0)
    {
      this.displayedColumns.push(this.actionsColumnName);
    }

    //remove action column if not editable and there is one defined
    if (!this.isEditable && this.displayedColumns.indexOf(this.actionsColumnName) > -1)
    {
      this.displayedColumns = this.displayedColumns.filter(c => c != this.actionsColumnName);
    }
  }


  public ngOnInit(): void
  {
    this.mtCrudService.createActionMethod = this.createActionMethod;
    this.mtCrudService.destroyActionMethod = this.destroyActionMethod;
    this.mtCrudService.readActionMethod = this.readActionMethod;
    this.mtCrudService.updateActionMethod = this.updateActionMethod;
    this.mtCrudService.createServiceUrl = this.createServiceUrl;
    this.mtCrudService.destroyServiceUrl = this.destroyServiceUrl;
    this.mtCrudService.readServiceUrl = this.readServiceUrl;
    this.mtCrudService.updateServiceUrl = this.updateServiceUrl;

    this.mtCrudService.ServerPaging = this.ServerPaging;

    if (!this.displayedColumns)
    {
      this.displayedColumns = [];
    }

    //if autogenerate columns and no displayed columns or the only displayed column is the action column
    this.setUpAutoColumns = this.autoGenerateColumns && (this.displayedColumns.length == 0 || (this.displayedColumns.length == 1 && this.displayedColumns.indexOf(this.actionsColumnName) > -1));

    if (!this.filteredValues)
    {
      this.filteredValues = [];
    }

    if (this.filteredValues != null && this.filteredValues.length > 0)
    {
      this.filteredValues.map((fi: FilterItem) =>
      {
        this.setupFilterColumn(fi.Field);
      });
    }

    this.dataSubscription = this.mtCrudService.OnDataFetched.subscribe((result: any) =>
    {
      let data: any = null;

      if (this.ServerPaging)
      {
        const ds = JSON.parse(result);

        if (ds)
        {
          data = ds.Data;

          this.dataSource = new MatTableDataSource<T>(data);

          setTimeout(() =>
          {
            this.paginator.length = ds.Total;

            const maxPageIndex = Math.floor(this.paginator.length / this.paginator.pageSize);

            if (maxPageIndex < this.paginator.pageIndex)
            {
              //Go to first page if the number of records do not allow current page
              this.paginator.firstPage();
            }
          }, 0);
        }
      }
      else
      {
        data = result;

        this.dataSource = new MatTableDataSource<T>(data);
        this.dataSource.paginator = this.paginator;

        this.dataSource.filterPredicate = this.customFilterPredicate(this.filteredValues);
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item: any, property: any) =>
        {
          //check if is property of child object
          if (property.indexOf(".") > -1)
          {
            return this.resolveValueFromChildObject(item, property);
          }

          //check if it is a date
          if (item && Object.prototype.toString.call(item) === "[object Date]" && !isNaN(item))
          {
            const newDate = new Date(item.date);
            return newDate;
          }

          return item[property];
        };

        this.applyFilters();
      }

      if (this.firstDataFetch)
      {
        if (data && data.length > 0)
        {
          const dataItem: T = data[0];

          //set added item to a clone of 1st data item to get an instance.
          this.addedItem = JSON.parse(JSON.stringify(dataItem));

          Object.keys(dataItem).forEach((key: string) =>
          {
            if (this.setUpAutoColumns)
            {
              this.displayedColumns.push(key);

              const filterValue: FilterItem = new FilterItem();
              this.filteredValues.push(filterValue);

              this.setupFilterColumn(key);
            }

            const aItem: any = this.addedItem;

            if (key == this.idField)
            {
              aItem[key as keyof typeof aItem] = 0;
            }
            else
            {
              //remove values from addedItem to get an empty instance
              aItem[key as keyof typeof aItem] = null;
            }
          });

          this.firstDataFetch = false;
        }
      }
    });


    this.onSaveSubscription = this.mtCrudService.OnSaved.subscribe((result: any) =>
    {
      this.saveInProgress = false;

      if (result)
      {
        if (result.IsSuccessful)
        {
          if (result.Message)
          {
            this.mtCrudService.notifySuccess("Saved", result.Message);
          }
        }
        else
        {
          let errMsg = "Unable to save record.";

          if (result.ErrorMessage)
          {
            errMsg = result.ErrorMessage;
          }

          this.mtCrudService.notifyFailure("Error", errMsg, result.ExceptionMessage, result.ValidationExceptionMessage);
        }
      }
    });

    this.loadData();
  }

  setupFilterColumn(key: string)
  {
    const x: any = this;

    x[key + "Filter" as keyof typeof x] = new FormControl();

    x[key + "ValueChangeSubscription" as keyof typeof x] = new Subscription();

    x[key + "ValueChangeSubscription" as keyof typeof x] = x[key + "Filter" as keyof typeof x].valueChanges
      .pipe(
        debounceTime(500), // discard emitted values that take less than the specified time between output
        distinctUntilChanged() // only emit when value has changed
      ).subscribe((nameFilterValue: any) =>
      {
        const fitem: FilterItem = this.filteredValues.filter(f => f.Field == key)[0];

        if (fitem != null)
        {
          fitem.Value = nameFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);

          if (this.ServerPaging)
          {
            this.paginator.pageIndex = 0;
            this.loadData();
          }
        }
      });
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();

    // prevent memory leak when component destroyed
    if (this.dataSubscription)
    {
      this.dataSubscription.unsubscribe();
    }
    if (this.addCloseSubscription)
    {
      this.addCloseSubscription.unsubscribe();
    }
    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }
    if (this.deleteCloseSubscription)
    {
      this.deleteCloseSubscription.unsubscribe();
    }
    if (this.filteredValues != null && this.filteredValues.length > 0)
    {
      this.filteredValues.map((fi: FilterItem) =>
      {
        const x: any = this;

        if (x[fi.Field + "ValueChangeSubscription" as keyof typeof x])
        {
          x[fi.Field + "ValueChangeSubscription" as keyof typeof x].unsubscribe();
        }
      });
    }
    if (this.onSaveSubscription)
    {
      this.onSaveSubscription.unsubscribe();
    }
  }



  refresh()
  {
    this.loadData();
  }


  clearFilters()
  {
    if (this.filteredValues != null && this.filteredValues.length > 0)
    {
      this.filteredValues.map((fi: FilterItem) =>
      {
        const x: any = this;

        if (x[fi.Field + "Filter" as keyof typeof x])
        {
          x[fi.Field + "Filter" as keyof typeof x].setValue("");
        }
      });
    }
  }

  applyFilters()
  {
    this.dataSource.filter = JSON.stringify(this.filteredValues);
  }

  addNew(event: any)
  {
    if (this.addedItem == null)
    {
      this.addedItem = {} as T;
    }

    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;

        const aItem: any = this.addedItem;

        aItem[this.changedByField as keyof typeof aItem] = this.authorisationService.currentuser.Id;

        this.dialogConfig.data = aItem;

        const dialogRef = this.dialog.open(this.AddDialogComponent, this.dialogConfig);
        const dialogRefComponentInstance: any = dialogRef.componentInstance;

        dialogRefComponentInstance['mtCrudService' as keyof typeof dialogRefComponentInstance] = this.mtCrudService;

        this.addCloseSubscription = dialogRef.afterClosed().subscribe(result =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            if (!this.ServerPaging)
            {
              // After dialog is closed we're doing frontend updates
              // For add we're just pushing a new row inside DataService
              this.mtCrudService.dataChange.value.push(this.mtCrudService.getDialogData());
            }

            // this.clearFilters();

            this.refreshTable();
            this.refresh();
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  editItem(event: any, dataItem: T)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;

        const dItem: any = dataItem;

        dItem[this.changedByField as keyof typeof dItem] = this.authorisationService.currentuser.Id;

        this.dialogConfig.data = dItem;

        const dialogRef = this.dialog.open(this.EditDialogComponent, this.dialogConfig);
        const dialogRefComponentInstance: any = dialogRef.componentInstance;

        dialogRefComponentInstance['mtCrudService' as keyof typeof dialogRefComponentInstance] = this.mtCrudService;

        this.editCloseSubscription = dialogRef.afterClosed().subscribe(result =>
        {
          const that = this;
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            //let editItem: T = this.mtCrudService.dataChange.value.filter((v: T) => v[this.idField] != dItem[this.idField])[0];

            //if (editItem)
            //{
            //    editItem = this.mtCrudService.getDialogData();
            //}

            // And lastly refresh table this will reset the filter after a save
            this.refreshTable();

            //this.clearFilters();
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  deleteItem(event: any, dataItem: T)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }


    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
    confirmDialogRef.componentInstance.confirmMessage = this.deleteConfirmationMessage;
    confirmDialogRef.componentInstance.confirmTitle = this.deleteHeaderText;

    this.deleteCloseSubscription = confirmDialogRef.afterClosed().subscribe(result =>
    {
      this.deleteCloseSubscription.unsubscribe();

      //The intention to delete was confirmed
      if (result)
      {
        this.mtCrudService.remove(dataItem);

        this.sort._stateChanges.next();

        this.itemDeleted.emit(dataItem[this.idField as keyof typeof dataItem]);

        // this.clearFilters();
      }

      confirmDialogRef = null;
    });
  }

  deleteItems(event: any, dataItems: T[])
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
    confirmDialogRef.componentInstance.confirmMessage = this.deleteConfirmationMessage;
    confirmDialogRef.componentInstance.confirmTitle = this.deleteHeaderText;

    this.deleteCloseSubscription = confirmDialogRef.afterClosed().subscribe(result =>
    {
      this.deleteCloseSubscription.unsubscribe();

      //The intention to delete was confirmed
      if (result)
      {
        dataItems.map((dataItem: T) =>
        {
          /// TODO ADD FUNCTIONALITY TO DELETE ACTIVITIES MAYBE BY IDs ARRAY ///
          // this.mtCrudService.remove(dataItem);
        });

        this.sort._stateChanges.next();

        this.itemDeleted.emit(result);

        // this.clearFilters();
      }

      confirmDialogRef = null;
    });
  }


  refreshTable()
  {
    if (this.paginator != null)
    {
      this.paginator._changePageSize(this.paginator.pageSize);
    }
  }


  public loadData()
  {
    if (this.ServerPaging)
    {
      this.ServerPagingParameters.Filter = null;
      this.ServerPagingParameters.Take = 10;
      this.ServerPagingParameters.Skip = 0;

      if (this.paginator)
      {
        this.ServerPagingParameters.Take = this.paginator.pageSize;
        this.ServerPagingParameters.Skip = (this.paginator.pageIndex * this.paginator.pageSize);
      }

      if (this.filteredValues && this.filteredValues.length > 0 && this.filteredValues.some((f: FilterItem) => f.Value != null && f.Value != "" || (f.DataType == 5 && (f.Value == true || f.Value == false))))
      {
        this.ServerPagingParameters.Filter = new Filter();
        this.ServerPagingParameters.Filter.filters = [];
        this.ServerPagingParameters.Filter.logic = "and";

        this.filteredValues.map((f: FilterItem) =>
        {
          if (f.Value != null && f.Value != "" || (f.DataType == 5 && (f.Value == true || f.Value == false)))
          {
            const filter: Filter = new Filter();
            filter.field = f.Field;
            filter.value = f.Value;
            filter.operator = "contains";

            if (isDate(filter.value) || filter.field.toLocaleLowerCase().endsWith("id") || f.DataType == FilterDataType.Date || f.DataType == FilterDataType.Number || f.DataType == FilterDataType.Boolean)
            {
              switch (f.DataType)
              {
                case FilterDataType.Date:
                  filter.value = this.formatDateForService(filter.value);
                  break;
                case FilterDataType.Number:
                  filter.value = parseInt(filter.value);
                  break;
                case FilterDataType.Boolean:

                  break;
              }

              filter.operator = "eq";
            }

            this.ServerPagingParameters.Filter.filters.push(filter);
          }
        });
      }

      if (this.sort && this.sort.active && this.sort.direction)
      {
        const s: Sort = new Sort();
        s.dir = this.sort.direction;
        s.field = this.sort.active;

        this.ServerPagingParameters.Sort = [];
        this.ServerPagingParameters.Sort.push(s);
      }

      this.mtCrudService.serverread(this.ServerPagingParameters);
    }
    else
    {
      this.mtCrudService.read(null);
    }
  }

  keyPressAlphaNumericWithCharacters(event: any)
  {

    const inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z ']/.test(inp))
    {
      return true;
    } else
    {
      event.preventDefault();
      return false;
    }
  }

  isValidDate(d: any)
  {
    if (!d)
    {
      return false;
    }

    return isValid(new Date(d));
  }

  parseJsonDate(dateItemToParse: any, unadjustTime: boolean = false): Date
  {
    if (this.isValidDate(dateItemToParse))
    {
      if (unadjustTime)
      {
        return this.myunadjustTimezone(new Date(dateItemToParse));
      }
      else
      {
        return new Date(dateItemToParse);
      }
    }

    if (dateItemToParse && dateItemToParse.length > 6)
    {
      if (unadjustTime)
      {
        return this.myunadjustTimezone(new Date(parseInt(dateItemToParse.toString().substr(6))));
      }
      else
      {
        return new Date(parseInt(dateItemToParse.toString().substr(6)));
      }
    }
    else
    {
      return dateItemToParse;
    }
  }

  public formatDateForService(value: any, dateOnly: boolean = false)
  {
    if (dateOnly)
    {
      value = new Date(value.toDateString())
    }

    if (this.isValidDate(value))
    {
      //Adjust date by adding minutes offset so date is always saved as the day
      const offset: number = new Date(value).getTimezoneOffset();

      value = addMinutes(this.parseJsonDate(value), -(offset));

      if (value.toString() == 'Invalid Date')
      {
        value = undefined;
      }
    }
    else
    {
      value = undefined;
    }

    return value;
  }

  public getISOStringFromDate(date: Date)
  {
    let monthString: string = (date.getMonth() + 1).toString();

    if (monthString.length == 1)
    {
      monthString = "0" + monthString;
    }

    let dayString: string = date.getDate().toString();

    if (dayString.length == 1)
    {
      dayString = "0" + dayString;
    }

    return date.getFullYear().toString() + "/" + monthString + "/" + dayString;
  }

  public myunadjustTimezone(date: any)
  {
    if (typeof date === 'string' || date instanceof String)
    {
      date = new Date(date.toString());
    }

    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  }


  customFilterPredicate(filteredValues: FilterItem[])
  {
    const myFilterPredicate = (data: T, filter: string): boolean =>
    {
      let filtered = true;

      if (this.filteredValues && this.filteredValues.length > 0 && this.filteredValues.some((f: FilterItem) => f.Value != null && f.Value != ""))
      {
        if (data && filter)
        {
          const searchValues: FilterItem[] = JSON.parse(filter);

          if (searchValues && searchValues.length > 0)
          {
            filteredValues.map((fi: FilterItem) =>
            {
              const fitem: FilterItem = searchValues.filter((f: FilterItem) => f.Field == fi.Field)[0];

              if (fitem && fitem.Value)
              {
                let value: any = null;

                //value is a value of a child object so path to field contains fullstop.
                if (fi.Path)
                {
                  value = this.resolveValueFromChildObject(data, fi.Path);
                }
                else
                {
                  value = data[fi.Field as keyof typeof data];
                }

                const fitemDate: any = new Date(fitem.Value);

                if (fitem != null && fitem.Value != null && data != null && value != null)
                {
                  //Check if value and filter values are numbers
                  if (fitem.DataType == FilterDataType.Number || (fitem.DataType == FilterDataType.Any && !(value instanceof Array) && (value - parseFloat(value) + 1) >= 0 && !(fitem.Value instanceof Array) && (fitem.Value - parseFloat(fitem.Value) + 1) >= 0))
                  {
                    filtered = filtered && parseFloat(value.toString()) == parseFloat(fitem.Value.toString());
                  } //check if value and filter are dates
                  else if (fitem.DataType == FilterDataType.Date || (fitem.DataType == FilterDataType.Any && Object.prototype.toString.call(value) === "[object Date]" && !isNaN(value)
                    && ((Object.prototype.toString.call(fitem.Value) === "[object Date]" && !isNaN(fitem.Value)) ||
                      (Object.prototype.toString.call(new Date(fitem.Value)) === "[object Date]" && !isNaN(fitemDate)))))
                  {
                    filtered = filtered && new Date(value).getTime() === new Date(fitem.Value).getTime();
                  }
                  else
                  {
                    filtered = filtered && value.toString().toLowerCase().trim().indexOf(fitem.Value.toString().toLowerCase()) !== -1;
                  }
                }
                else if (fitem.Value && !value)
                {
                  //Filter blanks in grid when filter value is not blank.
                  filtered = filtered && false;
                }
              }
            });
          }
        }
      }

      return filtered;
    }

    return myFilterPredicate;
  }


  resolveValueFromChildObject(obj: any, path: any)
  {
    path = path.split('.');

    let current = obj;

    if (current)
    {
      while (path.length)
      {
        if (typeof current !== 'object') return undefined;

        try
        {
          current = current[path.shift()];
        }
        catch { }
      }
    }

    return current;
  }

}

export class FilterItem
{
  public Field = "";
  public Value: any = null;
  public Path = "";
  public DataType: FilterDataType = FilterDataType.String;
}


export enum FilterDataType
{
  Any = 0,
  String = 1,
  Number = 2,
  Date = 3,
  Array = 4,
  Boolean = 5
}

