import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TelerikReportViewerComponent, TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import * as $ from 'jquery';
import { ReportInfo } from "../../shared/models/common.models";
import { GenericFilterPipe } from "../../shared/pipes/generic-filter.pipe";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { LoggerService } from '../../shared/services/logger.service';
import { CalendarsService } from '../calendars/calendars.service';
import { ReportService } from './report.service';

declare let kendo: any;


@Component({
  selector: "report",
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [],
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, TelerikReportingModule, MatButtonModule, GenericFilterPipe]
})
export class ReportComponent implements OnInit, OnDestroy
{
  public serviceUrl = "";
  public parameters: any;

  public reportService = inject(ReportService);
  private commonService = inject(CommonService);
  private loggerService = inject(LoggerService);
  private authenticationService = inject(AuthenticationService);
  private calendarsService = inject(CalendarsService);
  private configurationService = inject(ConfigurationService);

  @Input() public showParameters = true;
  @Input() public showCloseButton = false;
  @Input() public dragDisabled = true;

  @ViewChild('reportViewer', { static: false }) reportViewer: TelerikReportViewerComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: ReportInfo = null,
    @Optional() private dialogRef: MatDialogRef<ReportComponent> = null
  )
  {

    this.serviceUrl = this.configurationService.cbSettings().reportsApiUrl;
  }


  ngOnInit(): void
  {
    if (this.data)
    {
      this.reportService.reportName = this.data.reportName;
      this.showParameters = this.data.showParameters;
      this.reportService.reportSource.parameters = this.data.reportParameters || {};
      this.viewerContainerStyle.height = this.data.viewingContainerHeight;
      this.viewerContainerStyle.width = this.data.viewingContainerWidth;
      this.showCloseButton = this.data.showCloseButton;
      this.dragDisabled = this.data.dragDisabled;
    }
    else
    {
      //if there is no report name or if the report name is not in the reports dropdown list (comes from popup like Activity Confirmed)
      if (!this.reportService.reportName || !this.reportService.reportListItems.some(r => r.value == this.reportService.reportName))
      {
        //Set report as first report in list that is set to show.
        this.reportService.reportName = this.reportService.reportListItems.filter(r => r.show)[0].value;
      }

      this.reportService.setDynamicDefaultReportParameters(this.reportService.reportName);
    }
  }

  ngAfterViewInit()
  {
    this.reportViewer.setReportSource(this.reportService.reportSource);
  }

  onRenderingBegin(e: any, args: any): void
  {
    e.target.parametersAreaVisible(false);
  }

  onRenderingEnd(e: any, args: any): void
  {
    const datepickers = $("#reportViewer").find("[data-role='datepicker']");

    // let dateFormat = this.commonService.getDateFormatPattern(this.commonService.getLocaleName());

    if (datepickers.length)
    {
      //TODO change so firstDay and format are dynamic from current culture or configuration variable
      datepickers.each(function (index)
      {
        kendo.culture().calendar.firstDay = 1;

        $(this).data("kendoDatePicker").setOptions({
          format: "dd/MM/yyyy" //dateFormat
        });
      });
    }

    const combos = $("#reportViewer").find("[data-role='combobox']");

    if (combos.length)
    {
      combos.each(function (index)
      {
        const combo = $(this).data("kendoComboBox");

        combo.input.on("focus", function ()
        {
          setTimeout(function ()
          {
            combo.input.select();
          }, 0);
        });

        combo.bind("change", function ()
        {
          setTimeout(function ()
          {
            combo.input.select();
          }, 0);
        });
      });
    }

    e.target.parametersAreaVisible(true);
  }

  viewerContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '48rem',
    ['font-family']: '"Open Sans", Roboto, "Helvetica Neue", sans-serif'
  };


  reportChange(event: any)
  {
    this.reportService.isOnline = this.reportService.reportName == "DigitalReport.trdp";

    this.reportService.setDynamicDefaultReportParameters(this.reportService.reportName);
    this.reportViewer.setReportSource(this.reportService.reportSource);
  }

  onCloseClick()
  {
    this.dialogRef.close();
  }


  ngOnDestroy()
  {

  }








}


