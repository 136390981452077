import { DatePipe } from "@angular/common";
import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { MatTableExporterDirective } from "../../../shared/directives/mat-table-exporter.directive";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { LoggerService } from '../../../shared/services/logger.service';
import { CalendarsService } from "../../calendars/calendars.service";
import { AuditActivity } from "../audit.models";
import { AuditService } from '../audit.service';


@Component({
    selector: 'audit-activities',
    templateUrl: './audit-activities.component.html',
    styleUrls: ['./audit-activities.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [AuditService, MatTableCrudService],
    standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableExporterDirective, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatPaginatorModule, DatePipe]
})
export class AuditActivitiesComponent extends MatTableCrudComponent<AuditActivity> implements OnInit, OnDestroy
{
  public PropertyNameFilter: any;
  public ActivityIdFilter: any;
  public ClientNameFilter: any;
  public ProductFilter: any;
  public UserFilter: any;
  public ActivityNameFilter: any;
  public FieldChangedFilter: any;
  public StartDateFilter: any;
  public EndDateFilter: any;
  public ChangedDateFilter: any;


  constructor(protected injector: Injector,
    public calendarsService: CalendarsService,
    public configurationService: ConfigurationService,
    public auditService: AuditService,
    public commonService: CommonService,
    public loggerService: LoggerService)
  {
    super(injector,
      {},
      {},
      {},
      "",
      "",
      "GetAuditActivitiesServer",
      "",
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl);

    this.changedByField = "ChangedByUserId";
    this.ServerPaging = true;

    this.displayedColumns = ['ActivityId', 'PropertyName', 'ClientName', 'Product', 'ActivityName', 'StartDate', 'EndDate', 'ChangedDate', 'User', 'FieldChanged', 'OldValue', 'NewValue', 'Action'];
    this.filteredValues = [
      { Field: "ActivityId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "PropertyName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "ClientName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "Product", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "User", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "ActivityName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "FieldChanged", Value: "", Path: "", DataType: FilterDataType.String },
      //{ Field: "StartDate", Value: "", Path: "", DataType: FilterDataType.Date },
      //{ Field: "EndDate", Value: "", Path: "", DataType: FilterDataType.Date },
      //{ Field: "ChangedDate", Value: "", Path: "", DataType: FilterDataType.Date }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";

    this.CanAdd = false;
    this.CanEdit = false;
    this.CanDelete = false;
  }


  ngOnInit(): void
  {
    super.ngOnInit();
    
    this.dataSubscription.add(
      this.mtCrudService.OnDataFetched.subscribe((result: any) =>
      {
        let data: any;

        if (this.ServerPaging)
        {
          const ds = JSON.parse(result);

          if (ds)
          {
            data = ds.Data;
          }
        }
        else
        {
          data = result;
        }

        if (data)
        {
          data.map((a: AuditActivity) =>
          {
            if (a)
            {
              if (a.StartDate)
              {
                a.StartDate = this.commonService.parseJsonDate(a.StartDate);
              }

              if (a.EndDate)
              {
                a.EndDate = this.commonService.parseJsonDate(a.EndDate);
              }

              if (a.ChangedDate)
              {
                a.ChangedDate = this.commonService.parseJsonDate(a.ChangedDate);
              }
            }
          });
        }
      })
    );
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();

    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }


  }




}


