<mat-card appearance="outlined">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
    </mat-card-title>
    <div class="card-header-text-icons">
      Reload data:
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table matTableExporter #table [dataSource]="dataSource" matSort class="mat-table" #exporter="matTableExporter" (exportCompleted)="exportCompleted()" [hiddenColumns]="[7]">
      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="Id">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Id
          </div>
          <mat-form-field>
            <input matInput class="form-field" [formControl]="IdFilter" placeholder="Search Id" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="CategoryName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Category Name
          </div>
          <mat-form-field>
            <mat-select [formControl]="CategoryIdFilter" placeholder="All">
              <mat-option class="dropdown-filter-option" [value]="null">All</mat-option>
              @for (category of categories; track category) {
                <mat-option class="dropdown-filter-option" [value]="category.Id">{{category.CategoryName}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.CategoryName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="PropertyName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Property Name
          </div>
          <mat-form-field>
            <input matInput class="form-field" [formControl]="PropertyNameFilter" placeholder="Search Property Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.PropertyName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="PropertyTypeName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Property Type
          </div>
          <mat-form-field>
            <mat-select [formControl]="PropertyTypeIdFilter" placeholder="All">
              <mat-option class="dropdown-filter-option" [value]="null">All</mat-option>
              @for (propertyType of propertyTypes; track propertyType) {
                <mat-option class="dropdown-filter-option" [value]="propertyType.Id">{{propertyType.PropertyTypeName}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.PropertyTypeName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Stations">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Platform Provider
          </div>
          <mat-form-field>
            <input matInput class="form-field" [formControl]="StationsFilter" placeholder="Search Platform Provider" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.Stations}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="StartDate">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Start Date
          </div>
          <mat-form-field style="width:120px">
            <input matInput [matDatepicker]="sdatepicker" [formControl]="StartDateFilter" placeholder="Start Date" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
            <mat-datepicker #sdatepicker startView="month"></mat-datepicker>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.StartDate | date:'dd/MM/yyyy'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="EndDate">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            End Date
          </div>
          <mat-form-field style="width:120px">
            <input matInput [matDatepicker]="edatepicker" [formControl]="EndDateFilter" placeholder="End Date" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="edatepicker"></mat-datepicker-toggle>
            <mat-datepicker #edatepicker startView="month"></mat-datepicker>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.EndDate | date:'dd/MM/yyyy'}}</mat-cell>
      </ng-container>


      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
          <button mat-button [matMenuTriggerFor]="menu">
            <fa-icon [icon]="faFileExport" style="margin-right: 5px; font-size: small;"></fa-icon>
            <span>Export</span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="exportData('xlsx')">Excel</button>
            <button mat-menu-item (click)="exportData('csv')">Csv</button>
            <button mat-menu-item (click)="exportData('json')">Json</button>
            <button mat-menu-item (click)="exportData('txt')">Text</button>
          </mat-menu>
          <div class="card-header-text-icons">
            Reload data:
            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          @if (CanAdd) {
            <button mat-icon-button color="accent" (click)="addNew($event)" title="add">
              <i class="material-icons">add_box</i>
            </button>
          }
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row; let i=index;">
          @if (CanEdit) {
            <button mat-icon-button color="accent" (click)="editItem($event, row)" (mouseenter)="propertyHover($event, row.Id)" title="edit">
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
          }

          @if (CanDelete) {
            <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
          }
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource?.data?.length === 0 ? '' : 'none'">
      No results
    </div>

  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator
      [length]="dataSource?.filteredData?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="mtCrudService.pagerPageSizes"
      showFirstLastButtons="true">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
