import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, VERSION, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, HeaderModule, RowClassArgs, SelectAllCheckboxState, SelectableMode, SelectableSettings, SharedModule } from '@progress/kendo-angular-grid';
import { CheckBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { ActivitiesInfo } from '../../../shared/models/common.models';
import { CommonService } from '../../../shared/services/common.service';
import { ActivityInfo } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { CampaignActivities } from '../campaigns.models';
import { CampaignAssignerService } from './campaign-assigner.service';


@Component({
  selector: 'campaign-assigner',
  templateUrl: './campaign-assigner.component.html',
  styleUrls: ['./campaign-assigner.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CampaignAssignerComponent),
      multi: true
    },
    CampaignAssignerService
  ],
  standalone: true,
  imports: [AsyncPipe, MatProgressSpinnerModule, CdkDrag, CdkDragHandle, MatDialogTitle, FloatingLabelModule, TextBoxModule, ComboBoxModule, GridModule, ButtonModule, HeaderModule, CheckBoxModule, SharedModule, DatePipe, MatDialogActions, MatButtonModule]
})
export class CampaignAssignerComponent implements OnInit, ControlValueAccessor
{
  private _campaignId$ = new BehaviorSubject<number>(0);

  public campaignId$ = this._campaignId$.asObservable();

  public selectAllState: SelectAllCheckboxState = "unchecked";
  public mode: SelectableMode = "multiple";
  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: this.mode,
    drag: false,
  };

  public unassignedActivities$ = this.campaignId$.pipe(switchMap(campaignId => this.campaignAssignerService.getUnassignedCampaignActivities(campaignId).pipe(map((data: any) => this.campaignAssignerService.unassignedActivitiesForClient = data))));


  private _isDisabled = false;



  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  version = VERSION;


  @Input() get isDisabled(): boolean
  {
    return this._isDisabled;
  }
  set isDisabled(value: boolean)
  {
    this._isDisabled = value;
  }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: any) => { };

  public commonService = inject(CommonService);
  public campaignAssignerService = inject(CampaignAssignerService);
  private calendarsService = inject(CalendarsService);

  constructor(
    private dialogRef: MatDialogRef<CampaignAssignerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ActivitiesInfo)
  {
    if (data && data.campaign)
    {
      this._campaignId$.next(data.campaign.Id);
    }
  }

  ngOnInit(): void
  {


  }


  ngOnDestroy()
  {

  }



  public rowCallback = (context: RowClassArgs) =>
  {
    if (this.isRowSelected(context.dataItem.Id))
    {
      return { selected: true };
    }
    else
    {
      return { unselected: true };
    }
  };

  isRowSelected(id: number)
  {
    return this.campaignAssignerService.rowsSelected && this.campaignAssignerService.rowsSelected.length > 0 && this.campaignAssignerService.rowsSelected.some((row: ActivityInfo) => row.Id == id);
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState): void
  {
    if (checkedState === "checked")
    {
      this.campaignAssignerService.rowsSelected = [];
      this.campaignAssignerService.rowsSelected.push(...this.campaignAssignerService.unassignedActivitiesForClient);
      this.selectAllState = "checked";
    }
    else
    {
      this.campaignAssignerService.rowsSelected = [];
      this.selectAllState = "unchecked";
    }

    this.setSelectAllState();
  }

  public selectionChange(e: any)
  {
    if (e.target)
    {
      if (e.target.checked)
      {
        const row = this.campaignAssignerService.unassignedActivitiesForClient.filter((row: ActivityInfo) => row.Id == e.target.id)[0];

        if (row && !this.campaignAssignerService.rowsSelected.some((dataItem: ActivityInfo) => row.Id == dataItem.Id))
        {
          this.campaignAssignerService.rowsSelected.push(row);
        }
      }
      else
      {
        this.campaignAssignerService.rowsSelected = this.campaignAssignerService.rowsSelected.filter((dataItem: ActivityInfo) => dataItem.Id != e.target.id);
      }

      this.setSelectAllState();
    }
  }

  setSelectAllState()
  {
    const len = this.campaignAssignerService.rowsSelected.length;

    if (len === 0)
    {
      this.selectAllState = "unchecked";
    }
    else if (len > 0 && len < this.campaignAssignerService.unassignedActivitiesForClient.length)
    {
      this.selectAllState = "indeterminate";
    }
    else
    {
      this.selectAllState = "checked";
    }
  }


  assignActivities(event: any)
  {
    const campaignActivities: CampaignActivities = new CampaignActivities();
    campaignActivities.ActivityName = this.data.campaign.ActivityName;
    campaignActivities.BookingId = this.data.campaign.BookingId;
    campaignActivities.BriefManagerUserId = this.data.campaign.BriefManagerUserId;
    campaignActivities.CampaignId = this.data.campaign.Id;
    campaignActivities.ClientExecUserId = this.data.campaign.ClientExecUserId;
    campaignActivities.ClientSupportUserId = this.data.campaign.ClientSupportUserId;
    campaignActivities.NatProjectManagerUserId = this.data.campaign.NatProjectManagerUserId;
    campaignActivities.Product = this.data.campaign.Product;
    campaignActivities.ProjectManagerId = this.data.campaign.ProjectManagerId;
    campaignActivities.ActivityIds = this.campaignAssignerService.rowsSelected.map(a => a.Id);

    this.campaignAssignerService.assignActivitiesToCampaign(campaignActivities).then(
      data =>
      {
        if (data)
        {
          if (data.IsSuccessful)
          {
            if (data.Message)
            {
              this.commonService.notifySuccess("Saved", data.Message);
            }

            this.dialogRef.close(data);
          }
          else
          {
            let errMsg = "Unable to save record.";

            if (data.ErrorMessage)
            {
              errMsg = data.ErrorMessage;
            }

            this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
          }
        }
      }).catch((e) =>
      {
      });
  }

  onCancelClick()
  {
    this.dialogRef.close();
  }

  writeValue(value: any): void
  {

  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this._isDisabled = disabled
  }




}
