import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AfterValueChangedDirective } from '../directives/after-value-changed.directive';
import { TextBoxModule } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'kendo-textbox-filter',
  template: `
    <kendo-textbox size="small"
                   rounded="medium"
                   fillMode="outline" 
                   (afterValueChanged)="onChange($event)" 
                   [value]="selectedValue" 
    >
    </kendo-textbox>
  `,
  standalone: true,
  imports: [TextBoxModule, AfterValueChangedDirective]
})
export class TextBoxFilterComponent extends BaseFilterCellComponent
{
  @Output() filterSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  public get selectedValue(): any
  {
    const filter = this.filterByField(this.filterBy);
    return filter ? filter.value : null;
  }

  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public filterBy: string; //field to filter the grid's data by.
  @Input() public filterById: string; //field to filter the grid's data by.


  constructor(filterService: FilterService)
  {
    super(filterService);
  }

  public onChange(value: string): void
  {
    let txtValue: string = value.trim();

    if (!txtValue)
    {
      txtValue = null;
    }

    if (this.filterById && this.isNumber(txtValue))
    {
      // remove the text filter 
      this.removeFilter(this.filterBy);
      this.applyFilter(
        (txtValue === null || txtValue === undefined) ?
          this.removeFilter(this.filterById) : // remove the filter 
          this.updateFilter({ // add a filter for the field with the value
            field: this.filterById,
            operator: "eq",
            value: txtValue
          })
      ); // update the root filter
    }
    else
    {
      // remove the Id filter 
      this.removeFilter(this.filterById);
      this.applyFilter(
        (txtValue === null || txtValue === undefined) ?
          this.removeFilter(this.filterBy) : // remove the filter 
          this.updateFilter({ // add a filter for the field with the value
            field: this.filterBy,
            operator: "contains",
            value: txtValue
          })
      ); // update the root filter
    }

    this.filterSelectionChange.emit(value);
  }
  isNumber(str: string): boolean
  {
    if (typeof str !== 'string')
    {
      return false;
    }
    if (str.trim() === '')
    {
      return false;
    }
    return !Number.isNaN(Number(str));
  }
}
