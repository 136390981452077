<div id="calendar" position="right" class="calendar-control" (swipeleft)="swipeLeft($event)" (swipeRight)="swipeRight($event)">
  <div class="calendar-outer-header">
    <div class="noExport">
      <button type="button" mat-button (click)="drawer.toggle()" style="display: flex; padding: 0; margin-top: -7px;">
        @if (!drawer.opened)
        {
        <mat-icon title="Click to change calendar filters">filter_list</mat-icon>
        }
        @else
        {
        <mat-icon title="Click to close calendar filters">close</mat-icon>
        }
      </button>
    </div>
    <div class="calendar-header-info" (click)="!commonService.isLoading() && drawer.opened ? drawer.toggle() : ''">
      <div class="calendar-nav-buttons">
        <button type="button" class="btn btn-secondary btn-sm left-button noExport" (click)="previous($event)" [disabled]="commonService.isLoading()"><fa-icon [icon]="faChevronLeft" style="display:flex"></fa-icon></button>
        <kendo-dropdownlist [data]="calendarsService.weeksForSelectedYear"
                            [disabled]="commonService.isLoading()"
                            [textField]="'Name'"
                            [valueField]="'Id'"
                            [valuePrimitive]="false"
                            [size]="'small'"
                            [fillMode]="'outline'"
                            [rounded]="'medium'"
                            [(value)]="calendarsService.selectedWeek"
                            (valueChange)="handleWeeksChange($event)"
                            style="width:14rem; height:25px">
        </kendo-dropdownlist>
        <button type="button" class="btn btn-secondary btn-sm right-button noExport" (click)="next($event)" [disabled]="commonService.isLoading()"><fa-icon [icon]="faChevronRight" style="display:flex"></fa-icon></button>
      </div>
    </div>
    <div class="noExport" style="justify-self: flex-end;">
      <button class="k-button k-bare" title="Print calendar" (click)="print($event)" [disabled]="isPrinting() || commonService.isLoading()" style="border: none">
        <fa-icon [icon]="faPrint"></fa-icon>
      </button>
    </div>
  </div>
  <mat-drawer-container autosize class="calendar-drawer" style="margin-top: -29px">
    <mat-drawer #drawer mode="over" (closedStart)="drawerClosed()">
      <div class="calendar-toolbar noExport">
        <div>
          <h2 mat-dialog-title class="filter-header">Filter Options</h2>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label" style="display: inline-block; padding-right: 10px">Show Only Properties With Availability</label>
          <kendo-switch [(ngModel)]="calendarsService.availableOnly" (valueChange)="handleAvailOnlyChange($event);"></kendo-switch>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label">Property Types</label>
          <kendo-multiselect [autoClose]="false" #msPropertyTypes [data]="calendarsService.propertyTypes()"
                             [textField]="'PropertyTypeName'"
                             [valueField]="'Id'"
                             [valuePrimitive]="true"
                             [size]="'small'"
                             [fillMode]="'flat'"
                             [value]="calendarsService.selectedPropertyTypeIds()"
                             placeholder="All"
                             [kendoDropDownFilter]="filterSettings"
                             [disabled]="calendarsService.propertyTypes()===[] || commonService.isLoading()"
                             (valueChange)="handlePropertyTypeChange($event);">
          </kendo-multiselect>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label">Categories</label>
          <kendo-multiselect [autoClose]="false" #msCategories [data]="categories()"
                             [textField]="'CategoryName'"
                             [valueField]="'Id'"
                             [valuePrimitive]="true"
                             [size]="'small'"
                             [fillMode]="'flat'"
                             [(ngModel)]="calendarsService.selectedCategoryIds"
                             placeholder="All"
                             [kendoDropDownFilter]="filterSettings"
                             [disabled]="categories===[] || commonService.isLoading()"
                             (valueChange)="handleCategoryChange($event);">
          </kendo-multiselect>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label">Select Year</label>
          <kendo-dropdownlist [data]="calendarsService.years"
                              [disabled]="commonService.isLoading()"
                              [valuePrimitive]="true"
                              [size]="'small'"
                              [fillMode]="'outline'"
                              [rounded]="'medium'"
                              [(value)]="calendarsService.selectedYear"
                              (valueChange)="handleYearChange($event)"
                              style="width:14rem;">
          </kendo-dropdownlist>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label">Select Week</label>
          <kendo-dropdownlist [data]="calendarsService.weeksForSelectedYear"
                              [disabled]="commonService.isLoading()"
                              [textField]="'Name'"
                              [valueField]="'Id'"
                              [valuePrimitive]="false"
                              [size]="'small'"
                              [fillMode]="'outline'"
                              [rounded]="'medium'"
                              [(value)]="calendarsService.selectedWeek"
                              (valueChange)="handleWeeksChange($event)"
                              style="width:14rem;">
          </kendo-dropdownlist>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label">Platform Group</label>
          <kendo-dropdownlist [data]="stationGroups()"
                              [defaultItem]="defaultStationGroup"
                              [disabled]="stationGroups===[] || commonService.isLoading()"
                              [textField]="'StationTypeName'"
                              [valueField]="'Id'"
                              [valuePrimitive]="true"
                              [size]="'small'"
                              [fillMode]="'outline'"
                              [rounded]="'medium'"
                              [(value)]="calendarsService.selectedStationGroupId"
                              (valueChange)="handleStationGroupChange($event)"
                              style="width:14rem;">
          </kendo-dropdownlist>
        </div>
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label">Platform Providers</label>
          <kendo-multiselect [autoClose]="false" #stations [data]="calendarsService.availableStations"
                             [textField]="'StationName'"
                             [valueField]="'Id'"
                             [valuePrimitive]="false"
                             [size]="'small'"
                             [fillMode]="'flat'"
                             [(ngModel)]="calendarsService.selectedStations"
                             placeholder="Select platform provider(s)"
                             [kendoDropDownFilter]="filterSettings"
                             (valueChange)="stationsSelectedChange($event);"
                             [disabled]="calendarsService.availableStations===[] || commonService.isLoading()">
          </kendo-multiselect>
        </div>
        @if (false)
        {
        <div class="calendar-form-group">
          <label class="calendar-toolbar-label" for="noOfWeeks">Number Of Weeks</label>
          <select id="noOfWeeks" class="select-dropdown" (change)="handleNoOfWeeksChange($event)" [(ngModel)]="calendarsService.noOfWeeks" [disabled]="commonService.isLoading() || calendarsService?.selectedStations?.length > 1">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5" selected="selected">5</option>
          </select>
        </div>
        <button mat-button color="primary" (click)="filter()">Filter</button>
        }
      </div>
    </mat-drawer>
    @if (calendarsService.calendarInformation())
    {
    <div class="calendar-drawer-content">
      <div class="calendar-strip-info">
        <div class="calendar-property-types-title" title="Type of property">{{calendarsService.selectedPropertyTypeNames}}</div>
      </div>
      <div>
      </div>
      <div #corecalendar id="core-calendar" class="calendar-info scrollbar-cb" (scroll)="calendarScrolled($event)">
        <div class="property-blank">
          @if (authorisationService.hasPermission(authorisationService.Permissions.AddCategory) || authorisationService.hasPermission(authorisationService.Permissions.AddProperty))
          {
          <div class="noExport">
            <mat-menu #appMenu="matMenu" xPosition="after">
              @if (authorisationService.hasPermission(authorisationService.Permissions.AddCategory))
              {
              <button mat-menu-item (click)="newCategory()">Category</button>
              }
              @if (authorisationService.hasPermission(authorisationService.Permissions.AddProperty))
              {
              <button mat-menu-item (click)="newProperty()">Property</button>
              }
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="appMenu" class="new-calendar-button" [disabled]="commonService.isLoading()">New</button>
            <div style="font-size: 0.8rem; margin: 5px 0 0 12px;">
              <kendo-checkbox #hideDetails [(ngModel)]="hideActivityDetails" class="hide-details-checkbox"></kendo-checkbox><kendo-label class="k-checkbox-label"
                                                                                                                                         [for]="hideDetails"
                                                                                                                                         text="Hide Activity Details"></kendo-label>
            </div>
          </div>
          }
          @if (isPrinting())
          {
          <label>{{calendarsService.selectedWeek.Name}}</label>
          }
        </div>
        <ng-container>
          @for (station of calendarsService.selectedStations; track station.Id; let i = $index)
          {
          <div class="hsb calendar-stationbar" [ngStyle]="{'grid-column-start': (((i + 1) * calendarsService.stationsColSpan) - calendarsService.stationsColSpan) + 2, 'grid-column-end': ((i + 1) * calendarsService.stationsColSpan) + 2}"><div style="margin-right: 5%">{{station.StationName}}</div></div>
          }
        </ng-container>
        <ng-container>
          <!-- @for (dayLetter of calendarsService.dayLetters; track dayLetter.Id; let i = $index)
          {-->
          <!--<div class="hdb calendar-datebar" [ngStyle]="{'grid-column-start': (i + 2)}">-->
          <div class="hdb calendar-datebar" *ngFor="let dayLetter of calendarsService.dayLetters; let i = index" [ngStyle]="{'grid-column-start': (i + 2)}">
            <div class="hdl calendar-datebar-letters">
              <kendo-badge-container title="{{dayLetter.EventsTooltip}}">
                {{dayLetter.Letter}}
                @if (dayLetter && dayLetter.EventCount > 0)
                {
                <a [routerLink]="['/diary']" (click)="setEventDayMonthYear(dayLetter.DayNumber, dayLetter.EventMonth, dayLetter.EventYear, dayLetter.EventStationId, dayLetter.EventStationTypeId)">
                  <div>
                    <kendo-badge [align]="badgeAlign" [position]="badgePosition" [size]="badgeSize" [themeColor]="badgeThemeColor">{{dayLetter.EventCount}}</kendo-badge>
                  </div>
                </a>
                }
              </kendo-badge-container>
            </div>
            <div [ngClass]="{ 'hdn': true, 'calendar-datebar-numbers': !dayLetter.LastDivider, 'calendar-datebar-numbers-last': dayLetter.LastDivider}">
              {{dayLetter.DayNumber}}
            </div>
          </div>
          <!--}-->
        </ng-container>
        <div [ngClass]="hideActivityDetails ? 'calendar-content-blind' : 'calendar-content'" (domChange)="onDomChange($event)" (swipeleft)="swipeLeft($event)" (swipeRight)="swipeRight($event)" [ngStyle]="{'grid-column': calendarsService.cssGridSpan(calendarsService.colSpan)}" [innerHTML]="calendarsService.calendarInformation()">
        </div>
      </div>
      <div class="calendar-footer"></div>
    </div>
    }
    @else
    {
    <div class="calendar-filler">
      <kendo-skeleton shape="rectangle" style="grid-column: 1/ -1;"
                      animation="pulse"
                      width="100%"
                      height="100%"></kendo-skeleton>
      @for (i of [].constructor(102); track $index)
      {
      <kendo-skeleton shape="rectangle" animation="pulse" width="100%" height="100%"></kendo-skeleton>
      }
    </div>
    }
    <kendo-contextmenu #contextMenu (select)="onSelect($event)">
      <kendo-menu-item text="New Activity"></kendo-menu-item>
      <kendo-menu-item text="New Blocker"></kendo-menu-item>
    </kendo-contextmenu>
  </mat-drawer-container>
</div>


