import { DatePipe } from "@angular/common";
import { Component, Injector } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { Job } from "../administration.models";
import { AdministrationService } from '../administration.service';
import { JobEditComponent } from "./job-edit.component";


@Component({
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
  providers: [MatTableCrudService],
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatPaginatorModule, DatePipe]
})
export class JobsComponent extends MatTableCrudComponent<Job>
{
  public TitleFilter: any;
  public JobTypeIdFilter: any;
  public JobActionIdFilter: any;
  public StartDateFilter: any;

  constructor(public authorisationService: AuthorisationService, protected injector: Injector, public configurationService: ConfigurationService, public administrationService: AdministrationService)
  {
    super(injector,
      JobEditComponent,
      JobEditComponent,
      JobEditComponent,
      "CreateJob",
      "DeleteJob",
      "GetJobs",
      "UpdateJob",
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl);

    this.changedByField = "ChangedByUserId";

    this.displayedColumns = ['Id', 'Title', 'JobTypeName', 'JobActionName', 'StartDate'];
    this.filteredValues = [
      { Field: "Title", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "JobTypeId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "JobActionId", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "StartDate", Value: "", Path: "", DataType: FilterDataType.Date }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "65rem";

    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddJobs);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditJobs);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteJobs);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


  }

  addNew(event: any)
  {

    super.addNew(event);
  }

  deleteItem(event: any, job: Job)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this job (${job.Title})?`;
    this.deleteHeaderText = "Delete Job?";

    super.deleteItem(event, job);
  }





  ngOnDestroy()
  {
    super.ngOnDestroy();
  }











}

