<mat-card appearance="outlined">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-table matTableExporter #table [dataSource]="dataSource" matSort class="mat-table" #exporter="matTableExporter" (exportCompleted)="exportCompleted()">
      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="Id">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Id</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Action
          </div>
          <mat-form-field>
            <mat-label>Action</mat-label>
            <input matInput class="form-field" [formControl]="ActionFilter" placeholder="Action" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.Action}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="FromParameters">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            From Parameters
          </div>
          <mat-form-field>
            <mat-label>From Parameters</mat-label>
            <input matInput class="form-field" [formControl]="FromParametersFilter" placeholder="From Parameters" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.FromParameters}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ToParameters">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            To Parameters
          </div>
          <mat-form-field>
            <mat-label>To Parameters</mat-label>
            <input matInput class="form-field" [formControl]="ToParametersFilter" placeholder="To Parameters" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.ToParameters}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="AffectedRecords">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Affected Records
          </div>
          <mat-form-field>
            <mat-label>Affected Records</mat-label>
            <input matInput class="form-field" [formControl]="AffectedRecordsFilter" placeholder="AffectedRecords" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <div class="affected-records">{{row?.AffectedRecords}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="UpdatedDate">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Changed Date
          </div>
          @if (false) {
            <mat-form-field style="width:120px">
              <mat-label>Changed Date</mat-label>
              <input matInput [matDatepicker]="cdatepicker" [formControl]="UpdatedDateFilter" placeholder="Changed Date" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="cdatepicker"></mat-datepicker-toggle>
              <mat-datepicker #cdatepicker startView="month"></mat-datepicker>
            </mat-form-field>
          }
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.UpdatedDate | date:'dd/MM/yyyy'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="User">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            User
          </div>
          <mat-form-field>
            <mat-label>User</mat-label>
            <input matInput class="form-field" [formControl]="UserFilter" placeholder="User" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.User}}</mat-cell>
      </ng-container>


      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
          <button mat-button [matMenuTriggerFor]="menu">
            <fa-icon [icon]="faFileExport" style="margin-right: 5px; font-size: small;"></fa-icon>
            <span>Export</span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="exportData('xlsx')">Excel</button>
            <button mat-menu-item (click)="exportData('csv')">Csv</button>
            <button mat-menu-item (click)="exportData('json')">Json</button>
            <button mat-menu-item (click)="exportData('txt')">Text</button>
          </mat-menu>
          <div class="card-header-text-icons">
            Reload data:
            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          @if (CanAdd) {
            <button mat-icon-button color="accent" (click)="addNew($event)" title="add">
              <i class="material-icons">add_box</i>
            </button>
          }
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row; let i=index;">
          @if (CanEdit) {
            <button mat-icon-button color="accent" (click)="editItem($event, row)" title="edit">
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
          }

          @if (CanDelete) {
            <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
          }
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource?.data?.length === 0 ? '' : 'none'">
      No results
    </div>

  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator
      [length]="dataSource?.filteredData?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="mtCrudService.pagerPageSizes"
      showFirstLastButtons="true">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
