import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { faBroadcastTower, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { ContactType } from "../../calendars/calendars.models";
import { AdministrationService } from '../administration.service';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";


@Component({
    templateUrl: './contact-type-edit.component.html',
    selector: 'contact-type-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './contact-types.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDialogActions, MatButtonModule]
})
export class ContactTypeEditComponent extends MatTableCrudEditComponent<ContactTypeEditComponent, ContactType>
{
  public faBroadcastTower: IconDefinition = faBroadcastTower;

  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<ContactTypeEditComponent>, @Inject(MAT_DIALOG_DATA) public data: ContactType, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      ContactTypeName: [this.data.ContactTypeName, Validators.required],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    const contactType: any = this.form.value;

    if (contactType)
    {
      if (this.isNew)
      {
        contactType.Id = 0;

        if (contactType.Disabled == null)
        {
          contactType.Disabled = false;
        }
      }

      contactType[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(contactType);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Contact Type";

    if (this.isNew)
    {
      headerText = "New Contact Type";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.ContactTypeName}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
