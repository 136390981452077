import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PlatformService
{
    readonly isBrowser: boolean;
    constructor(
        @Inject(PLATFORM_ID) platformId: string,
    )
    {
        this.isBrowser = isPlatformBrowser(platformId);
    }
}
