<div class="copy-credit-lines-control" [formGroup]="form">
  @if (false) {
    <div class="copy-credit-lines-header">
      <label>Copy Credit Lines</label>
    </div>
  }
  <div class="copy-credit-lines-content-filter">
  </div>
  <div class="copy-credit-lines-content">
    <div>
      <label>From Activity</label>
      <div>
        <kendo-combobox [data]="calendarsService.properties()"
          formControlName="FromPropertyId"
          [kendoDropDownFilter]="filterSettings"
          [textField]="'IdAndPropertyName'"
          [valueField]="'Id'"
          [valuePrimitive]="true"
          [size]="'small'"
          [fillMode]="'flat'"
          [placeholder]="'Please Select Property'"
          style="width:100%"
          (valueChange)="propertyChange($event)">
        </kendo-combobox>
        <kendo-combobox [data]="calendarsService.stations()"
          formControlName="FromStationId"
          [kendoDropDownFilter]="filterSettings"
          [textField]="'StationName'"
          [valueField]="'Id'"
          [valuePrimitive]="true"
          [size]="'small'"
          [fillMode]="'flat'"
          [placeholder]="'Please Select Platform Provider'"
          style="width:100%"
          (valueChange)="stationChange($event)">
        </kendo-combobox>
        <mat-form-field style="width:unset">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Search Start Date" (dateChange)="startDateChange($event)" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
          <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width:unset">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="Search End Date" [min]="form.get('StartDate').value" (dateChange)="endDateChange($event)" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
          <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!findParametersSet" class="find-button" (click)="fillActivityList()">Find Activities</button>
        <kendo-combobox [data]="toolsService.activitiesList"
          formControlName="ActivityId"
          [kendoDropDownFilter]="filterSettings"
          [textField]="'Name'"
          [valueField]="'Id'"
          [valuePrimitive]="false"
          [size]="'small'"
          [fillMode]="'flat'"
          [placeholder]="activitiesMessage"
          style="width:100%"
          (valueChange)="activityChange($event)">
        </kendo-combobox>
        <label>OR</label>
        <mat-form-field>
          <mat-label>Activity Id</mat-label>
          <input matInput (focus)="$event.target.select()" title="Enter the Id of the Activity containing the information to copy" [formControl]="searchControl" placeholder="Enter Activity Id" autocomplete="off" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Selected Activity</mat-label>
          <input matInput (focus)="$event.target.select()" title="{{activityName}}" placeholder="Activity" autocomplete="off" [value]="activityName" disabled="disabled" />
        </mat-form-field>
      </div>
    </div>
    <div>
      <label title="Activities must have the same client as the From Activity and their End Dates must be greater than today">To Activities</label>
      <div>
        <kendo-floatinglabel text="Copy to these activities" class="selected-activities">
          <kendo-multiselect [autoClose]="false" [data]="activities"
            formControlName="ToActivities"
            [textField]="'Name'"
            [valueField]="'Id'"
            [valuePrimitive]="false"
            [size]="'small'"
            [fillMode]="'flat'"
            placeholder=" "
            [kendoDropDownFilter]="filterSettings">
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
              <span class="template" title="{{ dataItem.Name }}">{{ dataItem.Id }}</span>
            </ng-template>
          </kendo-multiselect>
        </kendo-floatinglabel>
      </div>
    </div>
  </div>
  <div class="copy-credit-lines-footer">
    <button mat-raised-button color="primary" [disabled]="!form.valid || !form.dirty || !hasSomethingToDo || saveInProgress" class="copy-button" (click)="copy()">Copy</button>
  </div>
</div>

