<div class="app-control" kendoTooltip tooltipClass="tooltip">
  <div class="app-header" [style.visibility]="!router?.url?.includes('sign') && !router?.url?.includes('login') ? 'visible' : 'hidden'">
    <a class="navbar-brand" [routerLink]="['/home']" style=" grid-column: 1; grid-row: 1; padding-left: 15px; border-bottom: 2px solid #3f51b5 !important;"><img src="assets/images/cb-logo-colour.svg" style="height: 55px; vertical-align: middle" alt="Campaign Boss" title="Campaign Boss" /></a>
    <nav-menu (sideBarToggle)="sidenav.toggle()" style="grid-column: 2; grid-row: 1;">
    </nav-menu>
  </div>
  <div class='app-content'>
    @if (commonService.isLoggedIn() && !router?.url?.includes('sign') && commonService.showPageHeader)
    {
    <h3>{{commonService.currentpagetitle}}</h3>
    }
    <mat-sidenav-container>
      <mat-sidenav #sidenav mode="over">
        <mat-nav-list class="menu">
          @for (routeCategory of appService.routeCategories; track routeCategory.CategoryName)
          {
          <div [ngClass]="{'selected-menu': commonService.currentpagecategory === routeCategory.CategoryName }">
            @if (routeCategory.RoutesInCategory === 1)
            {
            <button mat-button [routerLink]="['/' + routeCategory.Path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{routeCategory.Text}}</button>
            }
            @if (routeCategory.RoutesInCategory > 1)
            {
            <div>
              <button mat-button [matMenuTriggerFor]="menu">{{routeCategory.CategoryName}}</button>
              <mat-menu #menu="matMenu">
                @for (route of appService.routes | filter: [{visible: true}, {category: routeCategory.CategoryName}]; track route.path)
                {
                <button mat-menu-item [routerLink]="['/' + route.path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{route.text}}</button>
                }
              </mat-menu>
            </div>
            }
          </div>
          }
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
        <!--<angular-query-devtools initialIsOpen />-->
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div class='app-footer'>
  </div>
  @if (commonService.isNavigating || commonService.isLoading())
  {
  <div class="app-spinner-container">
    <mat-spinner></mat-spinner>
    <label class="app-spinner-loading-label">{{commonService.loadingText}}</label>
  </div>
  }
</div>
