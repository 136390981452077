import { EventEmitter, Injectable } from "@angular/core";
import { DataResult, process, State, toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { DeleteResult, SaveResult } from "../models/result.model";
import { CommonService } from './common.service';
import { ConfigurationService } from './configuration.service';
import { DataHandlerService } from './data-handler.service';


const CREATE_ACTION = "create";
const UPDATE_ACTION = "update";
const REMOVE_ACTION = "destroy";

@Injectable()

export class KendoGridService extends BehaviorSubject<any[]>
{
  public OnDataFetched: EventEmitter<any> = new EventEmitter<any>();
  public OnSaved: EventEmitter<any> = new EventEmitter<any>();
  public serverPaging: boolean = false;
  public gridAllData: any;
  public data: any;
  public editedItem: any;

  public gridState: State = {
    sort: [],
    skip: 0,
    take: this.kendoGridDefaultPageSize
  };

  private _serviceParameters: Parameter[] = [];

  private _readActionMethodPathFromRoot: string = "";
  private _getSingleItemActionMethodPathFromRoot: string = "";
  private _createActionMethodPathFromRoot: string = "";
  private _destroyActionMethodPathFromRoot: string = "";
  private _updateActionMethodPathFromRoot: string = "";
  private _exportActionMethodPathFromRoot: string = "";
  private _useReviver: boolean = false;
  private _postPayLoad: any = null;
  private _prepareDataForSave: boolean = false;
  private _pagerPageSizes: number[] = [];
  private _kendoGridDefaultPageSize: number = 20;
  private _extraParams: string = "";
  private _idField = "Id";

  private dataSourceRequestString = "";

  public useCachedData: boolean = false;
  public view: any;
  public total: number = 0;

  constructor(private configurationService: ConfigurationService,
    private dataHandler: DataHandlerService,
    private commonService: CommonService)
  {
    super([]);
  }



  get pagerPageSizes(): number[]
  {
    if (this.configurationService && this.configurationService.cbSettings() && this.configurationService.cbSettings().pagerPageSizes && this.configurationService.cbSettings().pagerPageSizes.length > 0)
    {
      this._pagerPageSizes = this.configurationService.cbSettings().pagerPageSizes;
    }

    return this._pagerPageSizes;
  }

  get kendoGridDefaultPageSize(): number
  {
    if (this.configurationService && this.configurationService.cbSettings() && this.configurationService.cbSettings().KendoGridDefaultPageSize > 0)
    {
      this._kendoGridDefaultPageSize = this.configurationService.cbSettings().KendoGridDefaultPageSize;
    }

    return this._kendoGridDefaultPageSize;
  }

  get serviceParameters(): Parameter[]
  {
    return this._serviceParameters;
  }
  set serviceParameters(value: Parameter[])
  {
    this._serviceParameters = value;
  }

  get idField(): string
  {
    return this._idField;
  }
  set idField(value: string)
  {
    this._idField = value;
  }

  get readActionMethodPathFromRoot(): string
  {
    return this._readActionMethodPathFromRoot;
  }
  set readActionMethodPathFromRoot(value: string)
  {
    this._readActionMethodPathFromRoot = value;
  }

  get getSingleItemActionMethodPathFromRoot(): string
  {
    return this._getSingleItemActionMethodPathFromRoot;
  }
  set getSingleItemActionMethodPathFromRoot(value: string)
  {
    this._getSingleItemActionMethodPathFromRoot = value;
  }


  get createActionMethodPathFromRoot(): string
  {
    return this._createActionMethodPathFromRoot;
  }
  set createActionMethodPathFromRoot(value: string)
  {
    this._createActionMethodPathFromRoot = value;
  }

  get destroyActionMethodPathFromRoot(): string
  {
    return this._destroyActionMethodPathFromRoot;
  }
  set destroyActionMethodPathFromRoot(value: string)
  {
    this._destroyActionMethodPathFromRoot = value;
  }

  get updateActionMethodPathFromRoot(): string
  {
    return this._updateActionMethodPathFromRoot;
  }
  set updateActionMethodPathFromRoot(value: string)
  {
    this._updateActionMethodPathFromRoot = value;
  }

  get exportActionMethodPathFromRoot(): string
  {
    return this._exportActionMethodPathFromRoot;
  }
  set exportActionMethodPathFromRoot(value: string)
  {
    this._exportActionMethodPathFromRoot = value;
  }

  get useReviver(): boolean 
  {
    return this._useReviver;
  }
  set useReviver(value: boolean)
  {
    this._useReviver = value;
  }

  get postPayLoad(): any
  {
    return this._postPayLoad;
  }
  set postPayLoad(value: any)
  {
    this._postPayLoad = value;
  }

  get prepareDataForSave(): boolean
  {
    return this._prepareDataForSave;
  }
  set prepareDataForSave(value: boolean)
  {
    this._prepareDataForSave = value;
  }

  public read(state: any, refreshData: boolean = false)
  {
    if (!state)
    {
      this.gridState = {
        sort: [],
        skip: 0,
        take: this.kendoGridDefaultPageSize
      };

      state = this.gridState;

      this.reset();
    }

    this.dataSourceRequestString = "";

    if (state && this.serverPaging)
    {
      this.dataSourceRequestString = `${toDataSourceRequestString(state)}`;
      const hasGroups = state.group && state.group.length;

      this.fetch()
        .pipe(
          tap((result: any) =>
          {
            this.data = {
              data: hasGroups ? translateDataSourceResultGroups(result.Data) : result.Data,
              total: result.Total
            };
          })
        )
        .subscribe(data =>
        {
          this.OnDataFetched.emit(this.data.data);
          super.next(this.data);
        });
    }
    else
    {
      if (!this.gridAllData || refreshData)
      {
        //get all data from database
        this.fetch()
          .pipe(
            tap(data =>
            {
              this.gridAllData = data;

              const dr: DataResult = process(data, state);
              this.data = dr.data;
              this.total = dr.total;

              this.view = dr;
            })
          )
          .subscribe(data => 
          {
            this.OnDataFetched.emit(data);
            super.next(data);
          });
      }
      else
      {
        //get data from all data filtered, sorted, etc.
        this.view = process(this.gridAllData, state);
      }
    }
  }

  public async serverPagingExport(state: any)
  {
    this.dataSourceRequestString = "";

    //state was sent so filter and sorting required.
    if (state)
    {
      //fill string with filter and sort information
      this.dataSourceRequestString = `${toDataSourceRequestString(state)}`;
      const hasGroups = state.group && state.group.length;
    }

    //get url to method that won't sort or filter but will return all data as if not server paged
    let url: string = this.configurationService.cbSettings().appServiceApiUrlRoot + `/${this.exportActionMethodPathFromRoot}`;

    if (this.dataSourceRequestString)
    {
      //use read method if wanting filters and sorting
      url = this.configurationService.cbSettings().appServiceApiUrlRoot + `/${this.readActionMethodPathFromRoot}`;
      url = `${url}?${this.dataSourceRequestString}&${this.extraParams}`;
    }
    else
    {
      url = `${url}?${this.extraParams}`;
    }

    const result = await lastValueFrom(this.dataHandler.getHttpObservable(url, this.useCachedData, this.useReviver));

    this.gridAllData = result;
  }

  public save(data: any, isNew?: boolean)
  {
    if (this.prepareDataForSave)
    {
      data = this.prepareData(data);
    }

    const action = isNew ? CREATE_ACTION : UPDATE_ACTION;

    this.editedItem = data;

    if (isNew)
    {
      this.create(data)
        .subscribe((res) => this.saveReturned(res), (err) => this.saveReturned(err));
    }
    else
    {
      this.update(data)
        .subscribe((res) => this.saveReturned(res), (err) => this.saveReturned(err));
    }
  }

  public saveReturned(result: any)
  {
    if (result)
    {
      if (result.IsSuccessful)
      {
        setTimeout(() =>
        {
          if (this.editedItem)
          {
            let gridData: any = this.gridAllData;

            if (this.serverPaging)
            {
              gridData = this.data?.data;
            }

            const itemIndex = gridData.findIndex((d: any) => d[this.idField] == this.editedItem[this.idField]);

            if (itemIndex > -1)
            {
              //update client-side array item with changes
              gridData[itemIndex] = this.editedItem;
            }
          }

          //pass gridState to read so filter is kept
          this.read(this.gridState);
        }, 0);
      }

      this.OnSaved.emit(result);
    }
  }

  private prepareData(data: any): any
  {
    const preparedData: any = data;

    Object.keys(preparedData).map(key => 
    {
      if (preparedData[key])
      {
        if (preparedData[key] instanceof Date)
        {
          //Convert any dates back to rest date format.
          preparedData[key] = `/Date(${preparedData[key].getTime()})/`;
        }

        if (preparedData[key] instanceof Object)
        {
          preparedData[key] = this.prepareData(preparedData[key]);
        }
      }
    });

    return preparedData;
  }

  public remove(data: any)
  {
    data = this.prepareData(data);

    this.destroy(data)
      .subscribe((res) => this.saveReturned(res), (err) => this.saveReturned(err));
  }

  public resetItem(dataItem: any)
  {
    if (!dataItem) { return; }

    //find orignal data item
    const originalDataItem = this.gridAllData.find((item: any) => item.Id === dataItem.Id);

    if (originalDataItem)
    {
      //revert changes
      Object.assign(originalDataItem, dataItem);
    }

    super.next(this.gridAllData);
  }

  private reset()
  {
    this.gridAllData = null;
  }

  get extraParams(): string
  {
    this._extraParams = "";

    if (this.serviceParameters && this.serviceParameters.length > 0)
    {
      this.serviceParameters.map(param =>
      {
        if (param)
        {
          if (param.name && param.name.length > 0 && param.value != null && param.value.toString().length > 0)
          {
            this._extraParams += param.name + "=" + param.value + "&";
          }
        }
      });

      //remove trailing &
      this._extraParams = this._extraParams.substring(0, this._extraParams.length - 1);
    }

    return this._extraParams;
  }

  private fetch(data?: any): Observable<any[]>
  {
    let url: string = this.configurationService.cbSettings().appServiceApiUrlRoot + `/${this.readActionMethodPathFromRoot}`;

    if (this.postPayLoad)
    {
      return this.dataHandler.postHttpObservable(url, this.postPayLoad, this.useCachedData, this.useReviver);
    }
    else
    {
      if (this.dataSourceRequestString)
      {
        url = `${url}?${this.dataSourceRequestString}&${this.extraParams}`;
      }
      else
      {
        url = `${url}?${this.extraParams}`;
      }

      return this.dataHandler.getHttpObservable(url, this.useCachedData, this.useReviver);
    }
  }

  private update(data?: any): Observable<SaveResult>
  {
    const url: string = this.configurationService.cbSettings().appServiceApiUrlRoot + `/${this.updateActionMethodPathFromRoot}`;

    return this.dataHandler.putHttpObservable<SaveResult>(url, data, this.useReviver);
  }

  private create(data?: any): Observable<SaveResult>
  {
    const url: string = this.configurationService.cbSettings().appServiceApiUrlRoot + `/${this.createActionMethodPathFromRoot}`;

    return this.dataHandler.postHttpObservable<SaveResult>(url, data, this.useCachedData, this.useReviver);
  }

  private destroy(data?: any): Observable<DeleteResult>
  {
    const url: string = this.configurationService.cbSettings().appServiceApiUrlRoot + `/${this.destroyActionMethodPathFromRoot}`;

    return this.dataHandler.putHttpObservable<DeleteResult>(url, data, this.useReviver);
  }

  private serializeModels(data?: any): string
  {
    return data ? `&models=${JSON.stringify([data])}` : '';
  }



}

export class Parameter
{
  public name = "";
  public value: any;
}
