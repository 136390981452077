@if (!!categoryName && categoryName!=='') {
<div class="category-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="category-header">
    <h1 mat-dialog-title class="dialog-header">{{categoryName}}</h1>
  </div>
  <div class="category-content">
    <div class="form-group" style="display:none">
      <mat-form-field>
        <mat-label>Id</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Id" formControlName="Id" readonly="readonly" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Category Name</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Category Name" formControlName="CategoryName" maxlength="100" autocomplete="off" />
        @if (form.get('CategoryName').hasError('required')) {
        <mat-error>Please enter a category name</mat-error>
        }
      </mat-form-field>
    </div>
    @if (false) {
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="HasOTP">Has OTP</mat-checkbox>
      </div>
    </div>
    }
    @if (false) {
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="HasPageBackground">Has PageBackground</mat-checkbox>
      </div>
    </div>
    }
    <div class="form-group">
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    @if (false) {
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
    }
  </div>
  <div class="category-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button [disabled]="saveInProgress" (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
}
@if (!categoryName) {
<div class="spinner-container">
  <mat-spinner></mat-spinner>
  <label class="spinner-loading-label">Loading Category, please wait...</label>
</div>
}
