import { ChangeDetectorRef, Component, Injector } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { User } from "../../../shared/models/common.models";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from "../../../shared/services/common.service";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { AdministrationService } from '../administration.service';
import { UserEditComponent } from "./user-edit.component";
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CheckBoxModule } from "@progress/kendo-angular-inputs";
import { NgClass } from "@angular/common";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { injectQueryClient } from "@tanstack/angular-query-experimental";


@Component({
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatSelectModule, MatOptionModule, CheckBoxModule, NgClass, MatPaginatorModule, GenericFilterPipe]
})
export class UsersComponent extends MatTableCrudComponent<User>
{
  public FirstNameFilter: any;
  public SurnameFilter: any;
  public EmailFilter: any;
  public HomeStationNameFilter: any;
  public RoleIdFilter: any;
  public ReportsToUserFilter: any;
  public ContactTypeNamesFilter: any;
  public DisabledFilter: any;

  private queryClient = injectQueryClient();

  public chkStatus = { status: "crossed" };

  constructor(private commonService: CommonService, public authorisationService: AuthorisationService, protected injector: Injector, private configurationService: ConfigurationService, public administrationService: AdministrationService, private cdr: ChangeDetectorRef)
  {
    super(injector,
      UserEditComponent,
      UserEditComponent,
      UserEditComponent,
      "CreateUser",
      "DeleteUser",
      "GetUsersServer",
      "UpdateUser",
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl,
      configurationService.cbSettings().adminServiceUrl);

    this.changedByField = "ChangedByUserId";
    this.ServerPaging = true;

    this.displayedColumns = ['Id', 'FirstName', 'Surname', 'Email', 'HomeStationName', 'UserRole.RoleName', 'ReportsToName', 'ContactTypeNames', 'Disabled'];
    this.filteredValues = [
      { Field: "FirstName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "Surname", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "Email", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "HomeStationName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "RoleId", Value: "", Path: "UserRole.Id", DataType: FilterDataType.Number },
      { Field: "ReportsToUser", Value: "", Path: "", DataType: FilterDataType.Number },
      { Field: "ContactTypeNames", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "Disabled", Value: null, Path: "", DataType: FilterDataType.Boolean }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "65rem";

    //Set to false so that users can only be added via registration.
    this.CanAdd = this.authorisationService.hasPermission(this.authorisationService.Permissions.AddUsers);
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditUsers);
    this.CanDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteUsers);
  }

  ngOnInit(): void
  {
    super.ngOnInit();

    this.commonService.isLoading.set(false);

    this.dataSubscription.add(
      this.mtCrudService.OnServerRead.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) =>
      {
        this.commonService.isLoading.set(true);
      })
    );

    this.dataSubscription.add(
      this.mtCrudService.OnDataFetched.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) =>
      {
        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "AdOps"
              || query.queryKey[0] == "Programmers"
              || query.queryKey[0] == "NationalProjectManagers"
              || query.queryKey[0] == "BriefManagers"
              || query.queryKey[0] == "ClientExecutives"
              || query.queryKey[0] == "ClientSupport"
              || query.queryKey[0] == "ProjectManagers";
          }
        });

        this.commonService.isLoading.set(false);
      })
    );
  }

  public onChange(value: any): void
  {
    const filteredValue = this.filteredValues.filter(f => f.Field == "Disabled")[0];

    switch (this.chkStatus.status)
    {
      case "checked": {
        this.chkStatus.status = "unchecked";
        break;
      }
      case "unchecked": {
        this.chkStatus.status = "crossed";
        break;
      }
      case "crossed": {
        this.chkStatus.status = "checked";
        break;
      }
    }

    if (this.chkStatus.status === "crossed")
    {
      filteredValue.Value = null;
    }
    else
    {
      filteredValue.Value = this.chkStatus?.status === 'checked'
    }

    this.loadData();
  }

  addNew(event: any)
  {
    this.addedItem.SortOrder = 0;

    super.addNew(event);
  }

  deleteItem(event: any, user: User)
  {
    this.deleteConfirmationMessage = `Are you sure you want to delete this user (${user.FirstName} ${user.Surname})?`;
    this.deleteHeaderText = "Delete User?";

    if (user && user.UserRole)
    {
      if (!user.UserRole.Permissions)
      {
        user.UserRole.Permissions = [];
      }
    }

    super.deleteItem(event, user);
  }

  lockUser(user: User)
  {
    this.administrationService.lockUnlockUser(user).then(
      data =>
      {
        if (data)
        {
          if (data.IsSuccessful)
          {
            if (data.Message)
            {
              user.IsLocked = !user.IsLocked;
              this.cdr.detectChanges();
              this.commonService.notifySuccess("Saved", data.Message);
            }
          }
          else
          {
            let errMsg = "Unable to lock/unlock user.";

            if (data.ErrorMessage)
            {
              errMsg = data.ErrorMessage;
            }

            this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
          }
        }

      }).catch((e) =>
      {
      });
  }



  ngOnDestroy()
  {


    super.ngOnDestroy();
  }











}

