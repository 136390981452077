import { faL } from "@fortawesome/free-solid-svg-icons";
import { ActivityInfo, Client } from "../calendars/calendars.models";

export class Campaign
{
  public Id = 0;
  public Name = "";
  public Client: Client;
  public Product = "";
  public BookingId = "";
  public ActivityName = "";
  public ClientExecUserId = 0;
  public ClientSupportUserId = 0;
  public BriefManagerUserId = 0;
  public NatProjectManagerUserId = 0;
  public ProjectManagerId = 0;
  public UpdateActivities = false;
  public SortOrder = 0;
  public Disabled = false;
  public Activities: ActivityInfo[] = [];
  public ChangedByUserId = 0;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class CampaignList
{
  public Id = 0;
  public Name = "";
  public ClientName = "";
  public Product = "";
  public BookingId = "";
  public ClientExecName = "";
  public ClientSupportName = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public Created: Date;
}

export class CampaignActivities
{
  public ActivityIds: number[] = [];
  public CampaignId = 0;
  public Product = "";
  public BookingId = "";
  public ActivityName = "";
  public ClientExecUserId = 0;
  public ClientSupportUserId = 0;
  public BriefManagerUserId = 0;
  public NatProjectManagerUserId = 0;
  public ProjectManagerId = 0;
  public ChangedByUserId = 0;
}

export class ActivityAssignInfo
{
  public ActivityId = 0;
  public CampaignId = 0;
  public ChangedByUserId = 0;
}
