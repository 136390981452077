import { Injectable } from '@angular/core';
import { ProductCategory, ValidatedControl } from '../calendars.models';
import { CalendarsService } from '../calendars.service';



@Injectable()

export class ActivityService
{

  private _validatedControls: ValidatedControl[] = [];
  private _stationIdsWithInvalidValues: number[] = [];
  private _productCategories: ProductCategory[] = [];

  constructor(private calendarsService: CalendarsService)
  {


  }

  get validatedControls(): ValidatedControl[]
  {
    return this._validatedControls;
  }
  set validatedControls(value: ValidatedControl[])
  {
    this._validatedControls = value;
  }


  get stationIdsWithInvalidValues(): number[]
  {
    return this._stationIdsWithInvalidValues;
  }
  set stationIdsWithInvalidValues(value: number[])
  {
    this._stationIdsWithInvalidValues = value;
  }

  get productCategories(): ProductCategory[]
  {
    if (!this._productCategories || this._productCategories.length < 1)
    {
      this._productCategories = this.calendarsService.productCategories();

      this._productCategories.map((pc: ProductCategory) =>
      {
        pc.HasWarning = false;
      });
    }

    return this._productCategories;
  }
  set productCategories(value: ProductCategory[])
  {
    this._productCategories = value;
  }






}
