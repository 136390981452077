import { Injectable } from '@angular/core';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';



@Injectable({ providedIn: 'root' })

export class SearchService
{
  constructor(private commonService: CommonService, private configurationService: ConfigurationService, private dataHandler: DataHandlerService)
  {

  }


}
