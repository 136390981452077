import { Injectable } from '@angular/core';
import { AuthorisationService } from '../../shared/services/authorisation.service';
import { CommonDataService } from '../../shared/services/common-data.service';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { CalendarsService } from '../calendars/calendars.service';
import { ReportListItem, ReportSource } from './reports.models';


@Injectable({ providedIn: 'root' })

export class ReportService
{
  private _reportName = "CreditLineFollowUps.trdp";
  private _reportSource: ReportSource = new ReportSource();
  private _isOnline = false;
  private _isCultureSetForParameterDateControls = false;
  private _reportListItems: ReportListItem[] = [];
  private readonly _sponsorshipPropertyTypeId = 1;

  constructor(
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    public configurationService: ConfigurationService,
    public authorisationService: AuthorisationService,
    public dataHandler: DataHandlerService,
    public calendarsService: CalendarsService)
  {
    this.reportSource.report = this._reportName;
    this.reportSource.parameters = {};


  }

  get reportName(): string
  {
    return this._reportName;
  }
  set reportName(value: string)
  {
    this._reportName = value;

    this.reportSource.report = this._reportName;
  }

  get reportSource(): ReportSource
  {
    return this._reportSource;
  }
  set reportSource(value: ReportSource)
  {
    this._reportSource = value;
  }

  get isOnline(): boolean
  {
    return this._isOnline;
  }
  set isOnline(value: boolean)
  {
    this._isOnline = value;
  }

  get isCultureSetForParameterDateControls(): boolean
  {
    return this._isCultureSetForParameterDateControls;
  }
  set isCultureSetForParameterDateControls(value: boolean)
  {
    this._isCultureSetForParameterDateControls = value;
  }


  get reportListItems(): ReportListItem[]
  {
    if (!this._reportListItems || this._reportListItems.length == 0)
    {
      this._reportListItems = [
        { name: "Missing Credit Lines", value: "CreditLineFollowUps.trdp", show: this.showCreditLineFollowUpsReport },
        { name: "Avails Summary", value: "AvailsSummary.trdp", show: this.showAvailsSummaryReport },
        { name: "Activities Summary", value: "ActivitiesForProperty.trdp", show: this.showActivitiesForPropertyReport },
        { name: "Credit Line", value: "CreditLine.trdp", show: this.showCreditLineReport },
        { name: "Avails Detail", value: "Avails.trdp", show: this.showAvailsReport },
        { name: "Fill", value: "Fill.trdp", show: this.showFillReport },
        { name: "Credits", value: "CreditsReport.trdp", show: this.showCreditsReport },
        { name: "Revenue", value: "PropertyRevenue.trdp", show: this.showRevenueReport },
        { name: "Digital", value: "DigitalReport.trdp", show: this.showDigitalReport }
      ];
    }

    return this._reportListItems;
  }

  setDynamicDefaultReportParameters(reportName: string)
  {
    let homeStationId: number = 1;

    if (this.authorisationService.currentuser && this.authorisationService.currentuser.HomeStationId > 0) 
    {
      homeStationId = this.authorisationService.currentuser.HomeStationId;
    }
    else if (this.calendarsService.availableStations && this.calendarsService.availableStations.length > 1)
    {
      homeStationId = this.calendarsService.availableStations[0].Id;
    }

    this.reportSource.parameters = {};

    switch (reportName)
    {
      case "CreditLine.trdp":
        this.reportSource.parameters = { propertyTypeIds: this._sponsorshipPropertyTypeId, stationIds: [homeStationId], clientExecLabelName: this.commonDataService.clientExecutiveLabelText, clientSupportLabelName: this.commonDataService.clientSupportLabelText };
        break;
      case "CreditLineFollowUps.trdp":
        this.reportSource.parameters = { propertyTypeIds: this._sponsorshipPropertyTypeId, stationIds: [homeStationId], clientExecLabelName: this.commonDataService.clientExecutiveLabelText, clientSupportLabelName: this.commonDataService.clientSupportLabelText };
        break;
      case "AvailsSummary.trdp":
        this.reportSource.parameters = { propertyTypeIds: null, categoryIds: null, stationIds: [homeStationId] };
        break;
      case "Avails.trdp":
        this.reportSource.parameters = { propertyTypeIds: null, categoryIds: null, propertyIds: null, stationIds: [homeStationId] };
        break;
      case "ActivitiesForProperty.trdp":
        this.reportSource.parameters = { stationIds: [homeStationId] };
        break;
      case "Fill.trdp":
        this.reportSource.parameters = { propertyTypeIds: null, categoryIds: null, propertyIds: null, stationIds: [homeStationId] };
        break;
    }
  }



  get showCreditLineReport(): boolean
  {
    return this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditLineReport);
  }
  get showCreditLineFollowUpsReport(): boolean
  {
    return this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditlineFollowupsReport);
  }
  get showAvailsReport(): boolean
  {
    return false; // this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewAvailsReport);
  }
  get showAvailsSummaryReport(): boolean
  {
    return this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewAvailsSummaryReport);
  }
  get showFillReport(): boolean
  {
    return false; // this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewFillReport);
  }
  get showCreditsReport(): boolean
  {
    return false; // this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewCreditReport);
  }
  get showRevenueReport(): boolean
  {
    return false; // this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewRevenueReport);
  }
  get showActivitiesForPropertyReport(): boolean
  {
    return this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitiesSummaryReport);
  }
  get showDigitalReport(): boolean
  {
    return false; // this.configurationService.cbSettings().showDigitalCalendar && this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewDigitalReport);
  }

















}
