<div class="airtime-credits-control">
  <div class="airtime-credits-control-row">
    <div class="header-column">&nbsp;</div>
    <div class="header-column header-day-column"><label>Mo</label></div>
    <div class="header-column header-day-column"><label>Tu</label></div>
    <div class="header-column header-day-column"><label>We</label></div>
    <div class="header-column header-day-column"><label>Th</label></div>
    <div class="header-column header-day-column"><label>Fr</label></div>
    <div class="header-column header-day-column"><label>Sa</label></div>
    <div class="header-column header-day-column"><label>Su</label></div>
    <div class="header-column footer-row"><label>Total</label></div>
  </div>
  <div class="airtime-credits-control-row" *ngFor="let ci of value">
    <div class="header-row">{{ci.DayPartLabel}}</div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Mon" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Tue" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Wed" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Thu" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Fri" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Sat" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <input matInput (focus)="$event.target.select()" [(ngModel)]="ci.Sun" type="number" max="{{creditMaxValue}}" min="0" (change)="inputValueChanged($event)" [disabled]="isDisabled" autocomplete="off" />
    </div>
    <div>
      <label>
        {{ci.Mon + ci.Tue + ci.Wed + ci.Thu + ci.Fri + ci.Sat + ci.Sun}}
      </label>
    </div>
  </div>
  <div class="airtime-credits-control-row">
    <div class="header-column footer-row">Total</div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Mon_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Tue_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Wed_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Thu_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Fri_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Sat_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-row">
      <input matInput [value]="airtimeCreditsService.Sun_Total" type="number" readonly="readonly" autocomplete="off" />
    </div>
    <div class="footer-column footer-row">
      <label>
        {{airtimeCreditsService.Grand_Total}}
      </label>
    </div>
  </div>
</div>
