<div class="activity-control" kendoTooltip tooltipClass="tooltip" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="activity-header">
    <h2 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h2>
  </div>
  @if (!processingInProgress) {
  <div class="activity-content">
    <mat-card appearance="outlined" class="control-mat-card-one-line">
      <div style="display: inline-flex; justify-content: space-between;">
        @if (currentActivityId > 0) {
        <mat-form-field style="width: 15rem;">
          <mat-label>Id</mat-label>
          <input matInput (focus)="$event.target.select()" placeholder="Id" formControlName="Id" readonly="readonly" autocomplete="off" />
        </mat-form-field>
        }
        <mat-form-field style="width: 15rem; padding-right: 2rem;">
          <mat-label>Property</mat-label>
          <input matInput (focus)="$event.target.select()" placeholder="Property" title="{{currentProperty?.PropertyName}}" value="{{currentProperty?.PropertyName}}" readonly="readonly" autocomplete="off" />
        </mat-form-field>
        <mat-form-field style="width: 15rem; padding-right: 2rem;">
          <mat-label>Property Type</mat-label>
          <input matInput (focus)="$event.target.select()" placeholder="Property Type" title="{{currentPropertyType?.PropertyTypeName}}" value="{{currentPropertyType?.PropertyTypeName}}" readonly="readonly" autocomplete="off" />
        </mat-form-field>
      </div>
    </mat-card>
    <mat-card appearance="outlined" class="control-mat-card-one-line">
      <div style="display: inline-flex; justify-content: space-between;">
        <mat-form-field style="width: 15rem;">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Start Date" (dateChange)="startDateChange($event)" [min]="blockerMinDate" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
          <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 15rem;">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="End Date" (dateChange)="endDateChange($event)" [min]="blockerMinDate" autocomplete="off">
          <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
          <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
        </mat-form-field>
        <button mat-raised-button color="primary" class="recurrence-button" (click)="recurrence()" title="{{recurrenceSummary}}" [disabled]="disableRecurrenceButton">
          Recurrence
          @if (currentActivity?.Recurrence?.RecurrenceType === RecurrenceTypeNone) {
          <kendo-svg-icon [icon]="icons.nonRecurrence"></kendo-svg-icon>
          }
          @if (currentActivity?.Recurrence?.RecurrenceType !== RecurrenceTypeNone) {
          <kendo-svg-icon [icon]="icons.recurrence"></kendo-svg-icon>
          }
        </button>
      </div>
    </mat-card>
    <mat-card appearance="outlined" class="control-mat-card-one-line">
      <mat-form-field>
        <mat-label>Blocker Description</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Blocker Description" formControlName="ClientName" maxlength="100" autocomplete="off" />
        <mat-error>Blocker Description is a required field.</mat-error>
      </mat-form-field>
    </mat-card>
    <mat-card appearance="outlined" class="control-mat-card-one-line" style="min-height: 105px;">
      <kendo-floatinglabel text="{{platformNameLabel}}s" style="padding-bottom: 1.1rem;">
        <kendo-multiselect [autoClose]="false"
                           [data]="propertyStations"
                           [textField]="'StationName'"
                           [valueField]="'Id'"
                           [valuePrimitive]="true"
                           [size]="'small'"
                           [fillMode]="'flat'"
                           formControlName="Stations"
                           placeholder="{{platformNameLabel}}s"
                           [kendoDropDownFilter]="filterSettings">
        </kendo-multiselect>
      </kendo-floatinglabel>
    </mat-card>
  </div>
  }
  @if (processingInProgress) {
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
    @if (processingInProgress) {
    <label class="spinner-loading-label">{{this.processingMessage}}</label>
    }
  </div>
  }
  @if (showFooter) {
  <div class="activity-footer">
    <hr style="margin-top: 0.2rem; margin-bottom: 0.75rem;" />
    <mat-dialog-actions>
      <div class="button-row">
        <div>
          <button mat-button [disabled]="processingInProgress" (click)="onCancelClick()">Close</button>
          <button mat-button color="primary" [disabled]="!isAuthorised() || !form.valid || !form.dirty || processingInProgress" (click)="onSave()">Save</button>
        </div>
        @if (canDeleteActivity && form.get('Id').value > 0) {
        <button mat-button class="delete-button" [disabled]="processingInProgress" (click)="delete($event)">Delete</button>
        }
      </div>
    </mat-dialog-actions>
  </div>
  }
</div>
