<form [formGroup]="form" class="campaign-editor-control">
  <div class="campaign-editor-header">
    <div class="form-group">
      <kendo-floatinglabel text="Name">
        <kendo-textbox size="small" rounded="medium" fillMode="flat" formControlName="Name" placeholder="Enter name" maxlength="100" required></kendo-textbox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="Client">
        <kendo-combobox name="clients"
          [data]="clients"
          size="small"
          rounded="medium"
          formControlName="Client"
          fillMode="flat"
          [textField]="'Name'"
          [valueField]="'Id'"
          [virtual]="virtual"
          [filterable]="true"
          (filterChange)="handleClientsFilter($event)"
          (valueChange)="clientChange($event)"
          [valuePrimitive]="false"
          required>
        </kendo-combobox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="Product">
        <kendo-textbox size="small" rounded="medium" fillMode="flat" formControlName="Product" placeholder="Enter product" maxlength="100"></kendo-textbox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="Booking Id">
        <kendo-textbox size="small" rounded="medium" fillMode="flat" formControlName="BookingId" placeholder="Enter booking id" maxlength="50"></kendo-textbox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="Activity Name">
        <kendo-textbox size="small" rounded="medium" fillMode="flat" formControlName="ActivityName" placeholder="Enter activity name" maxlength="100"></kendo-textbox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="{{commonDataService.clientExecutiveLabelText}}">
        <kendo-combobox name="clientExecutives"
          [data]="commonDataService.clientExecutives()"
          size="small"
          rounded="medium"
          formControlName="ClientExecUserId"
          fillMode="flat"
          [textField]="'FullName'"
          [valueField]="'Id'"
          [valuePrimitive]="true">
        </kendo-combobox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="{{commonDataService.clientSupportLabelText}}">
        <kendo-combobox name="clientSupport"
          [data]="commonDataService.clientSupport()"
          size="small"
          rounded="medium"
          formControlName="ClientSupportUserId"
          fillMode="flat"
          [textField]="'FullName'"
          [valueField]="'Id'"
          [valuePrimitive]="true">
        </kendo-combobox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="{{commonDataService.briefManagerLabelText}}">
        <kendo-combobox name="briefManagers"
          [data]="commonDataService.briefManagers()"
          size="small"
          rounded="medium"
          formControlName="BriefManagerUserId"
          fillMode="flat"
          [textField]="'FullName'"
          [valueField]="'Id'"
          [valuePrimitive]="true">
        </kendo-combobox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="{{commonDataService.nationalProjectManagerLabelText}}">
        <kendo-combobox name="nationalProjectManagers"
          [data]="commonDataService.nationalProjectManagers()"
          size="small"
          rounded="medium"
          formControlName="NatProjectManagerUserId"
          fillMode="flat"
          [textField]="'FullName'"
          [valueField]="'Id'"
          [valuePrimitive]="true">
        </kendo-combobox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <kendo-floatinglabel text="{{commonDataService.projectManagerLabelText}}">
        <kendo-combobox name="projectManagers"
          [data]="commonDataService.projectManagers()"
          size="small"
          rounded="medium"
          formControlName="ProjectManagerId"
          fillMode="flat"
          [textField]="'FullName'"
          [valueField]="'Id'"
          [valuePrimitive]="true">
        </kendo-combobox>
      </kendo-floatinglabel>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="UpdateActivities" title="Select to update all the activities in this campaign with the saved values">Update Activities</mat-checkbox>
      </div>
    </div>
    <div class="form-group" style="display:inline-flex; justify-content:space-between;">
      <mat-form-field style="width: 5rem; margin: 5px 10px 0 2px;">
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="campaign-editor-content">
    <kendo-grid [kendoGridBinding]="this.form?.get('Activities')?.value"
      class="kendo-child-grid"
      [rowClass]="rowCallback"
      [selectable]="selectableSettings"
      (selectionChange)="selectionChange($event)"
      [pageable]="false"
      (add)="addHandler($event)"
      (edit)="editHandler($event)">
      <ng-template kendoGridToolbarTemplate>
        @if (CanAdd) {
          <button kendoGridAddCommand size="small" rounded="medium" fillMode="solid" themeColor="inverse" [disabled]="isNew && (!form?.valid || !form?.dirty)">Add new activity</button>
        }
        <h3 style="font-weight: inherit; margin: 0 !important;">Activities For Campaign</h3>
        <div>
          @if (CanAssign) {
            <button
              kendoButton
              (click)="assignActivities($event)"
              themeColor="secondary"
              title="Click to assign existing unassigned activities to the campaign."
              size="small"
              rounded="medium"
              fillMode="solid"
              style=" margin-right: 20px;"
              [disabled]="isNew || form?.dirty">
              Assign Activities
            </button>
          }
          @if (CanProgressCampaignActivities) {
            <button
              kendoButton
              size="small"
              rounded="large"
              fillMode="solid"
              themeColor="tertiary"
              (click)="progressActivities($event)"
              [disabled]="!campaignEditorService?.rowsSelected || campaignEditorService?.rowsSelected?.length < 1"
              title="Click to progress the status and set the Credit Line and other details for selected activities">
              Progress Selected Activities
            </button>
          }
        </div>
      </ng-template>
      @if (CanProgressCampaignActivities) {
        <kendo-grid-checkbox-column width="25px">
          <ng-template kendoGridHeaderTemplate>
            <input type="checkbox"
              kendoCheckBox
              id="selectAllCheckboxId"
              kendoGridSelectAllCheckbox
              [state]="selectAllState"
              (selectAllChange)="onSelectAllChange($event)"
              style="margin: 4px 0;" />
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <input type="checkbox" kendoCheckBox id="{{dataItem.Id}}" style="margin: 4px 1px;" size="medium" rounded="medium" (change)="selectionChange($event)" [checked]="isRowSelected(dataItem.Id)" />
            </ng-template>
          </kendo-grid-checkbox-column>
        }
        <kendo-grid-column field="Id" title="Id" width="80px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.Id}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="PropertyName" title="Property" width="190px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.PropertyName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="PropertyTypeName" title="Property Type" width="105px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.PropertyTypeName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ClientName" title="Client" width="180px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.ClientName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Product" title="Product" width="160px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.Product}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ActivityName" title="Activity Name" width="180px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.ActivityName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Status" title="Status" width="125px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.Status}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="StartDate" title="First Date" width="100px" format="{0:d}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.StartDate | date }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="EndDate" title="Last Date" width="100px" format="{0:d}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.EndDate | date }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-command-column title="" width="50px">
          <ng-template kendoGridCellTemplate let-dataItem>
            @if (CanEdit) {
              <button kendoGridEditCommand title="edit" (mouseenter)="activityHover($event, dataItem.Id)" style="color:#75acff;">
                <mat-icon aria-label="Edit">edit</mat-icon>
              </button>
            }
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-command-column title="" width="105px">
          <ng-template kendoGridCellTemplate let-dataItem>
            @if (CanUnassign)
              {
              <button kendoGridRemoveCommand (click)="unassignItem(dataItem)" title="Unassign activity from campaign" style="color: #ee9191; ">
                UNASSIGN
              </button>
            }
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
    </div>
  </form>
