import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatCalendar, MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { isSameDay } from 'date-fns';
import { MatDialogMultiDatePickerService } from './mat-dialog-multi-datepicker.service';

@Component({
  selector: 'md-multi-datepicker',
  templateUrl: './mat-dialog-multi-datepicker.component.html',
  styleUrls: ['./mat-dialog-multi-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatDialogMultiDatePickerComponent),
      multi: true
    },
    MatDialogMultiDatePickerService
  ],
  standalone: true,
  imports: [MatDatepickerModule]
})
export class MatDialogMultiDatePickerComponent implements OnInit, ControlValueAccessor
{
  @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;

  private _value: Date[] = [];

  @Output() dateClicked: EventEmitter<MatDialogMultiDatePickerComponent> = new EventEmitter<MatDialogMultiDatePickerComponent>();

  @Input() isDisabled = false;


  @Input() public minDate: Date;
  @Input() public maxDate: Date;

  @Input() get value(): Date[]
  {
    return this._value;
  }
  set value(val: Date[])
  {
    this._value = val;

    this.calendar?.updateTodaysDate();

    if (this.propagateChange)
    {
      this.propagateChange(this._value);
    }
  }


  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: Date[]) => { };

  constructor(private matDialogMultiDatePickerService: MatDialogMultiDatePickerService, private cdr: ChangeDetectorRef)
  {

  }


  ngOnInit(): void
  {

  }

  ngOnDestroy()
  {

  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) =>
  {
    if (view == 'month')
    {
      const dateToFind = this._value.find((psd) => isSameDay(psd, cellDate));

      if (dateToFind !== null && dateToFind !== undefined)
      {
        return 'selected'
      }
    }
    return ''
  }


  daySelected(date: Date | null, calendar: any)
  {
    if (this.isDisabled)
    {
      return;
    }

    if (date)
    {
      const clickedDate = this._value.find((psd) => isSameDay(psd, date));

      if (clickedDate === null || clickedDate === undefined)
      {
        //add date if not found
        this._value.push(date);
      }
      else
      {
        //get date record index if found
        const index = this._value.findIndex((x) =>
          isSameDay(x, clickedDate)
        );

        //remove date as if selected date was clicked it should be unselected
        this._value.splice(index, 1);
      }

      calendar?.updateTodaysDate();

      this.dateClicked.emit(this);
    }
  }

  writeValue(value: any): void
  {
    if (value)
    {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this.isDisabled = disabled;
  }




}


export class MultiDatePickerClickEvent
{
  public dateClicked: Date;
  public dateIsSelected: boolean;
  public values: Date[] = [];
}
