import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[domChange]',
  standalone: true
})
export class DomChangeDirective implements OnDestroy
{
  private changes: MutationObserver;
  private element: any;

  @Output()
  public domChange = new EventEmitter();

  @Input()
  public domAttributes: boolean = false;

  @Input()
  public domChildList: boolean = true;

  @Input()
  public domCharacterData: boolean = false;

  constructor(private elementRef: ElementRef)
  {
    this.element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) =>
    {
      mutations.forEach((mutation: MutationRecord) =>
      {
        this.domChange.emit(mutation);
      });
    }
    );


  }

  ngOnInit()
  {
    this.changes.observe(this.element, {
      attributes: this.domAttributes,
      childList: this.domChildList,
      characterData: this.domCharacterData
    });
  }


  ngOnDestroy(): void
  {
    this.changes.disconnect();
  }
}
