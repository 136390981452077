import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { EventCategory } from "../administration.models";
import { AdministrationService } from '../administration.service';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";


@Component({
    templateUrl: './event-category-edit.component.html',
    selector: 'event-category-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './event-categories.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDialogActions, MatButtonModule]
})
export class EventCategoryEditComponent extends MatTableCrudEditComponent<EventCategoryEditComponent, EventCategory>
{
  constructor(public fb: UntypedFormBuilder, public dialogRef: MatDialogRef<EventCategoryEditComponent>, @Inject(MAT_DIALOG_DATA) public data: EventCategory, public administrationService: AdministrationService, public authorisationService: AuthorisationService, public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [{ value: this.data.Id, disabled: true }],
      Name: [{ value: this.data.Name, disabled: false }, Validators.required],
      ColourName: [{ value: this.data.ColourName, disabled: true }, Validators.required],
      Colour: [{ value: this.data.Colour, disabled: true }, Validators.required],
      Disabled: [{ value: this.data.Disabled, disabled: false }],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    const eventCategory: any = this.form.getRawValue();  //use getRawValue to include disabled controls as well

    if (eventCategory)
    {
      if (this.isNew)
      {
        eventCategory.Id = 0;

        if (eventCategory.Disabled == null)
        {
          eventCategory.Disabled = false;
        }
      }

      eventCategory[this.changedByField] = this.authorisationService.currentuser.Id;

      super.onSave(eventCategory);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Event Category";

    if (this.isNew)
    {
      headerText = "New Event Category";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.Name}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
