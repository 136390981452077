<div class="client-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="client-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="client-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="Name" maxlength="50" autocomplete="off">
        <mat-hint></mat-hint>
        @if (form.hasError('Name') && !form.hasError('required')) {
          <mat-error>
            Please enter a valid Name
          </mat-error>
        }
        @if (form.hasError('required')) {
          <mat-error>
            Name is <strong>required</strong>
          </mat-error>
        }
      </mat-form-field>
    </div>
    <div class="form-group" [style.visibility]="form.get('ExternalId')?.value>1 ? 'visible' : 'hidden'">
      <mat-form-field>
        <mat-label>External Id</mat-label>
        <input matInput placeholder="External Id" formControlName="ExternalId" maxlength="50" autocomplete="off" readonly>
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="textarea-form-group">
      <mat-form-field>
        <mat-label>Long Name</mat-label>
        <textarea matInput matAutosizeMaxRows="2" rows="2" (focus)="$event.target.select()" title="Long Name" placeholder="Long Name" formControlName="LongName" maxlength="500" autocomplete="off"></textarea>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Contact</mat-label>
        <input matInput placeholder="Contact" formControlName="Contact" maxlength="100" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Contact Email</mat-label>
        <input matInput placeholder="Contact Email" formControlName="ContactEmail" maxlength="100" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Contact Phone No</mat-label>
        <input matInput placeholder="Contact Phone No" formControlName="ContactPhoneNo" maxlength="25" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input matInput (focus)="$event.target.select()" placeholder="Sort Order" formControlName="SortOrder" type="number" min="0" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="client-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
