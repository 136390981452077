<div class="login-control">
  <div class="login-header">
    <img src="/assets/images/cb-logo-colour.svg" alt="Campaign Boss" class="loading-msg-image" /><label>Logging you into Campaign Boss</label>
  </div>
  <div class="login-content">
    <img src="/assets/images/cb-loading.gif" alt="Logging you into Campaign Boss." />
  </div>
  <div class="login-footer">
    <label>Please wait...</label>
  </div>
</div>
