import { HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { User as oidcUser, UserManager } from 'oidc-client-ts';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../models/common.models';
import { AuthorisationService } from './authorisation.service';
import { CommonService } from './common.service';
import { ConfigurationService } from './configuration.service';
import { DataHandlerService } from './data-handler.service';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })

export class AuthenticationService
{
  @Output() userPermissionsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() currentUserInfoRetrieved: EventEmitter<User> = new EventEmitter<User>();

  cachedRequests: Array<HttpRequest<any>> = [];

  private _currentUser: Observable<User>;
  private _currentUserSubject: BehaviorSubject<User>;


  private _userManager: UserManager;
  private _user: oidcUser;

  private _loginChangedSubject = new Subject<boolean>();
  loginChanged = this._loginChangedSubject.asObservable();

  private stsSettings: any = {};

  private router: Router;


  constructor(
    private injector: Injector,
    private authorisationService: AuthorisationService,
    private commonService: CommonService,
    private dataHandler: DataHandlerService,
    private configurationService: ConfigurationService)
  {
    this.router = this.injector.get(Router);

  }

  public async setupUserManager()
  {
    try
    {
      if (!!this.configurationService && !!this.configurationService.cbSettings() && !!this.configurationService.cbSettings().authProvider)
      {
        switch (this.configurationService.cbSettings().authProvider.authProviderName.toLowerCase())
        {
          case "azuread":
            this.stsSettings = {
              authority: this.configurationService.cbSettings().authProvider.stsAuthority,
              client_id: this.configurationService.cbSettings().authProvider.clientId,
              redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}signin-callback`,
              scope: this.configurationService.cbSettings().authProvider.scope,
              response_type: this.configurationService.cbSettings().authProvider.response_type,
              loadUserInfo: false, // setting loadUserInfo to true causes error because CORS blocks the call to the user info endpoint.
              post_logout_redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}signout-callback`,
              automaticSilentRenew: this.configurationService.cbSettings().authProvider.automaticSilentRenew,
              silent_redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}assets/silent-callback.html`,
              includeIdTokenInSilentRenew: this.configurationService.cbSettings().authProvider.includeIdTokenInSilentRenew,
              monitorSession: this.configurationService.cbSettings().authProvider.monitorSession,
              validateSubOnSilentRenew: this.configurationService.cbSettings().authProvider.validateSubOnSilentRenew,
              metadata: {
                issuer: `${this.configurationService.cbSettings().authProvider.stsAuthority}/${this.configurationService.cbSettings().authProvider.tenantId}/v2.0`,
                authorization_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}/${this.configurationService.cbSettings().authProvider.tenantId}/oauth2/v2.0/authorize`,
                token_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}/${this.configurationService.cbSettings().authProvider.tenantId}/oauth2/v2.0/token`,
                "jwks_uri": `https://login.microsoftonline.com/${this.configurationService.cbSettings().authProvider.tenantId}/discovery/v2.0/keys`,
                end_session_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}/${this.configurationService.cbSettings().authProvider.tenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURI(this.configurationService.cbSettings().authProvider.clientRoot)}signout-callback`
              }
            };
            break;
          case "auth0":
            this.stsSettings = {
              authority: this.configurationService.cbSettings().authProvider.stsAuthority,
              client_id: this.configurationService.cbSettings().authProvider.clientId,
              redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}signin-callback`,
              scope: this.configurationService.cbSettings().authProvider.scope,
              response_type: this.configurationService.cbSettings().authProvider.response_type,
              post_logout_redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}signout-callback`,
              automaticSilentRenew: this.configurationService.cbSettings().authProvider.automaticSilentRenew,
              silent_redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}assets/silent-callback.html`,
              includeIdTokenInSilentRenew: this.configurationService.cbSettings().authProvider.includeIdTokenInSilentRenew,
              monitorSession: this.configurationService.cbSettings().authProvider.monitorSession,
              validateSubOnSilentRenew: this.configurationService.cbSettings().authProvider.validateSubOnSilentRenew,
              metadata: {
                issuer: `${this.configurationService.cbSettings().authProvider.stsAuthority}`,
                authorization_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}authorize?audience=projects-api`,
                jwks_uri: `${this.configurationService.cbSettings().authProvider.stsAuthority}.well-known/jwks.json`,
                token_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}oauth/token`,
                userinfo_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}userinfo`,
                end_session_endpoint: `${this.configurationService.cbSettings().authProvider.stsAuthority}v2/logout?client_id=${this.configurationService.cbSettings().authProvider.clientId}&returnTo=${encodeURI(this.configurationService.cbSettings().authProvider.clientRoot)}signout-callback`
              }
            };
            break;
          default:
            this.stsSettings = {
              authority: this.configurationService.cbSettings().authProvider.stsAuthority,
              client_id: this.configurationService.cbSettings().authProvider.clientId,
              redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}signin-callback`,
              scope: this.configurationService.cbSettings().authProvider.scope,
              response_type: this.configurationService.cbSettings().authProvider.response_type,
              post_logout_redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}signout-callback`,
              automaticSilentRenew: this.configurationService.cbSettings().authProvider.automaticSilentRenew,
              silent_redirect_uri: `${this.configurationService.cbSettings().authProvider.clientRoot}assets/silent-callback.html`,
              includeIdTokenInSilentRenew: this.configurationService.cbSettings().authProvider.includeIdTokenInSilentRenew,
              monitorSession: this.configurationService.cbSettings().authProvider.monitorSession,
              validateSubOnSilentRenew: this.configurationService.cbSettings().authProvider.validateSubOnSilentRenew
            };
            break;
        }

        this._userManager = new UserManager(this.stsSettings);

        await this._userManager.clearStaleState();

        this._userManager.events.addAccessTokenExpired(_ =>
        {
          this._loginChangedSubject.next(false);
        });

        this._userManager.events.addUserLoaded(async user =>
        {
          if (this._user !== user)
          {
            this._user = user;

            this._loginChangedSubject.next(!!user && !user.expired);

            await this.loadSecurityContext();
          }
        });
      }
    }
    catch (e)
    {
      const x = e;
    }

  }


  public get currentUser(): Observable<User>
  {
    if (this.currentUserSubject == null)
    {
      this._currentUser = null;
    }

    if (this._currentUser == null && this.currentUserSubject != null)
    {
      this._currentUser = this.currentUserSubject.asObservable();
    }

    return this._currentUser;
  }


  public get currentUserSubject(): BehaviorSubject<User>
  {
    return this._currentUserSubject;
  }

  public updateUserInfo(user: User)
  {
    if (user)
    {
      this.authorisationService.currentuser = JSON.parse(JSON.stringify(user));
      this.dataHandler.changedByUserId = this.authorisationService.currentuser.Id;

      this.currentUserInfoRetrieved.emit(user);
    }
  }

  public get currentUserValue(): User
  {
    let user: User = null;

    if (this.currentUserSubject != null)
    {
      user = this.currentUserSubject.value;
    }

    return user;
  }

  async login()
  {
    if (!this._userManager)
    {
      await this.setupUserManager();
    }

    return this._userManager.signinRedirect();
  }

  isLoggedIn(): Promise<boolean>
  {
    return this._userManager.getUser().then((user: oidcUser) =>
    {
      const userCurrent = user != null && user.access_token != null && user.id_token != null && !!user && !user.expired;

      if (this._user !== user)
      {
        this._loginChangedSubject.next(userCurrent);
      }

      if (userCurrent && !this.currentUser)
      {
        this.loadSecurityContext();
      }
      this._user = user;

      return userCurrent;
    });
  }

  async completeLogin()
  {
    return await this._userManager.signinRedirectCallback().then((user: oidcUser) =>
    {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
      return user;
    }).catch((err: any) =>
    {

    });
  }

  logout()
  {
    this.commonService.manuallyLoggedOut = true;
    this._userManager.signoutRedirect();
  }

  completeLogout()
  {
    this._user = null;
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  getAccessToken()
  {
    return this._userManager.getUser().then((user: oidcUser) =>
    {
      if (!!user && !user.expired)
      {
        return user.access_token;
      }
      else
      {
        return null;
      }
    });
  }

  getIdToken()
  {
    return this._userManager.getUser().then((user: oidcUser) =>
    {
      if (!!user && !user.expired)
      {
        return user.id_token;
      }
      else
      {
        return null;
      }
    });
  }

  getToken(type: string)
  {
    if (type == "id")
    {
      return this.getIdToken();
    }

    return this.getAccessToken();
  }

  loadSecurityContext()
  {
    this.getUserContext().then(
      (user: User) =>
      {
        if (!user || !user.Id || !user.Email)
        {
          this.router.navigate(['/unauthorised']);
        }
        else
        {
          if (this.commonService.isLoggedIn()
            && this.commonService.currentpage
            && !this.commonService.currentpage.includes("unauthorised")
            && !this.commonService.currentpage.includes("signin")
            && !this.commonService.currentpage.includes("signout")
            && !this.commonService.currentpage.includes("login")
            && !this.commonService.currentpage.includes("register")
            && !this.commonService.currentpage.includes("diary")
          )
          {
            //navigate to the current page set before a refresh
            this.router.navigate([this.commonService.currentpage]);
          }
          else
          {
            this.router.navigate(['/home']);
          }

          this.setUserInfo(user);
        }
      });
  }


  setUserInfo(user: User)
  {
    if (user)
    {
      let oldUserPermissions = "";
      let newUserPermissions = "";

      if (this.currentUserValue)
      {
        oldUserPermissions = JSON.stringify(this.currentUserValue.UserRole);
      }

      newUserPermissions = JSON.stringify(user.UserRole);

      const permissionsChanged: boolean = oldUserPermissions != newUserPermissions;

      this._currentUserSubject = new BehaviorSubject<User>(user);

      this.currentUserSubject.next(user);

      this.updateUserInfo(this._currentUserSubject.value);

      if (permissionsChanged)
      {
        //Emit AFTER user updated
        this.userPermissionsChange.emit();
      }
    }
  }

  register(user: User)
  {
    return this.dataHandler.postHttpPromise<any>(`/api/authentication/register`, user, false, false);
  }


  public collectFailedRequest(request: HttpRequest<any>): void
  {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void
  {
    // retry the requests. this method can be called after the token is refreshed                
    this.cachedRequests.map((req) =>
    {
      const payload = (req as any).payload;

      switch (req.method.toUpperCase())
      {
        case "GET":
          this.dataHandler.getHttpObservable(req.url, false, false);
          break;
        case "POST":
          this.dataHandler.postHttpObservable(req.url, payload, false, false);
          break;
        case "PUT":
          this.dataHandler.putHttpObservable(req.url, payload, false);
          break;
        case "DELETE":
          this.dataHandler.deleteHttpObservable(req.url, false);
          break;
      }
    });

    this.cachedRequests = [];
  }


  resetAuthentication()
  {
    this.authorisationService.currentuser = null;

    this.cachedRequests = [];

    // remove user from local storage to log user out
    if (this.currentUserSubject)
    {
      this.currentUserSubject.next(null);
    }
  }





















  getUserContext(useCachedData: boolean = false): Promise<User>
  {
    return this.dataHandler.getHttpPromise<User>(this.configurationService.cbSettings().authorisationServiceUrl + `/GetUserContext`, useCachedData, false);
  }











}
