import { DatePipe } from "@angular/common";
import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FilterDataType, MatTableCrudComponent } from "../../../shared/components/mat-table-crud/mat-table-crud.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { LoggerService } from '../../../shared/services/logger.service';
import { CalendarsService } from "../../calendars/calendars.service";
import { AuditProperty } from "../audit.models";
import { AuditService } from '../audit.service';

@Component({
    selector: 'audit-properties',
    templateUrl: './audit-properties.component.html',
    styleUrls: ['./audit-properties.component.scss', '../../../shared/components/mat-table-crud/mat-table-crud.component.scss'],
    providers: [AuditService, MatTableCrudService],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatTableModule, MatSortModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatDatepickerModule, MatPaginatorModule, DatePipe]
})
export class AuditPropertiesComponent extends MatTableCrudComponent<AuditProperty> implements OnInit, OnDestroy
{
  public CategoryNameFilter: any;
  public PropertyNameFilter: any;
  public ChangedDateFilter: any;
  public UserFilter: any;

  constructor(protected injector: Injector,
    public calendarsService: CalendarsService,
    public configurationService: ConfigurationService,
    public auditService: AuditService,
    public commonService: CommonService,
    public loggerService: LoggerService)
  {
    super(injector,
      {},
      {},
      {},
      "",
      "",
      "GetAuditPropertiesServer",
      "",
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl,
      configurationService.cbSettings().calendarServiceUrl);


    this.changedByField = "ChangedByUserId";
    this.ServerPaging = true;

    this.displayedColumns = ['PropertyId', 'PropertyName', 'CategoryName', 'ChangedDate', 'User', 'FieldChanged', 'OldValue', 'NewValue', 'Action'];
    this.filteredValues = [
      { Field: "CategoryName", Value: "", Path: "", DataType: FilterDataType.String },
      { Field: "PropertyName", Value: "", Path: "", DataType: FilterDataType.String },
      //{ Field: "ChangedDate", Value: "", Path: "", DataType: FilterDataType.Date },
      { Field: "User", Value: "", Path: "", DataType: FilterDataType.String }
    ];

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";

    this.CanAdd = false;
    this.CanEdit = false;
    this.CanDelete = false;
  }


  ngOnInit(): void
  {
    super.ngOnInit();

    this.dataSubscription.add(
      this.mtCrudService.OnDataFetched.subscribe((result: any) =>
      {
        let data: any;

        if (this.ServerPaging)
        {
          const ds = JSON.parse(result);

          if (ds)
          {
            data = ds.Data;
          }
        }
        else
        {
          data = result;
        }

        if (data)
        {
          data.map((a: AuditProperty) =>
          {
            if (a)
            {
              if (a.ChangedDate)
              {
                a.ChangedDate = this.commonService.parseJsonDate(a.ChangedDate);
              }
            }
          });
        }
      })
    );
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();

    if (this.addCloseSubscription)
    {
      this.addCloseSubscription.unsubscribe();
    }
    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }

  }




}


