export { }

// STRING EXTENSIONS
// ================

declare global
{
  interface String
  {
    insertAt(index: number, string: string): string;
    replaceAt(index: number, string: string): string;
    isNullOrEmpty(): boolean;
    truncate(maxLength: number): string;
  }
}


String.prototype.insertAt = function (index: number, string: string)
{
  if (index > 0)
  {
    return this.substring(0, index) + string + this.substring(index, this.length);
  }
  else
  {
    return string + this;
  }
};

String.prototype.replaceAt = function (index: number, string: string)
{
  if (index === null || index === undefined)
  {
    index = -1;
  }

  string = string.toString();

  if (string && index > -1 && index < this.length)
  {
    if (string.length + index <= this.length)
    {
      //create a string with the replacement characters removed.
      let cutString: string = this.substring(0, index) + this.substring(index + string.length);

      //insert the new string with the replacement characters
      cutString = cutString.substring(0, index) + string + cutString.substring(index);

      return cutString;
    }
    else
    {
      //if replacement string is too long for index then cut off after length met
      return this.substring(0, index) + string.substring(0, this.length - index);
    }
  }
  else
  {
    return string;
  }
};

String.prototype.isNullOrEmpty = function ()
{
  return typeof (this) === "string" && this.trim().length == 0;
};

String.prototype.truncate = function (maxLength: number)
{
  if (this.length > maxLength)
  {
    return [this.slice(0, maxLength - 3), '...'].join('');
  }

  return this.toString();
};
