import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { AdministrationService } from '../administration.service';
import { NotifyGroup, NotifyGroupType } from "../administration.models";
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { CommonService } from "../../../shared/services/common.service";
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";

import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";



@Component({
    templateUrl: './notify-group-edit.component.html',
    selector: 'notify-group-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './notify-groups.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatDialogActions, MatButtonModule, GenericFilterPipe]
})
export class NotifyGroupEditComponent extends MatTableCrudEditComponent<NotifyGroupEditComponent, NotifyGroup>
{

  constructor(public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotifyGroupEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotifyGroup,
    public administrationService: AdministrationService,
    public authorisationService: AuthorisationService,
    public commonService: CommonService,
    public configurationService: ConfigurationService,
    public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      Name: [this.data.Name],
      NotifyGroupType: [this.data.NotifyGroupType, Validators.required],
      InformIfSubmittedForSale: [this.data.InformIfSubmittedForSale || false],
      InformIfOffSubmittedForSale: [this.data.InformIfOffSubmittedForSale || false],
      WarnFirstRightRefusalCountdown: [this.data.WarnFirstRightRefusalCountdown || false],
      InformIfFirstRightRefusalExpires: [this.data.InformIfFirstRightRefusalExpires || false],
      InformIfOnHoldExpires: [this.data.InformIfOnHoldExpires || false],
      InformFirstDayAircheckDue: [this.data.InformFirstDayAircheckDue || false],
      InformFinalPcrDue: [this.data.InformFinalPcrDue || false],
      InformDropDead: [this.data.InformDropDead || false],
      InformDropDeadWarning: [this.data.InformDropDeadWarning || false],
      InformCreditLineDue: [this.data.InformCreditLineDue || false],
      InformAudioDueToAdOps: [this.data.InformAudioDueToAdOps || false],
      InformCreditLineChanged: [this.data.InformCreditLineChanged || false],
      InformIfConfirmed: [this.data.InformIfConfirmed || false],
      InformIfOnHold: [this.data.InformIfOnHold || false],
      InformConfirmedActivityDisabled: [this.data.InformConfirmedActivityDisabled || false],
      InformConfirmedActivityDateRangeChanged: [this.data.InformConfirmedActivityDateRangeChanged || false],
      InformActivitiesImportErrors: [this.data.InformActivitiesImportErrors || false],
      InformActivitiesInterfaceEvents: [this.data.InformActivitiesInterfaceEvents || false],
      InformClientsInterfaceEvents: [this.data.InformClientsInterfaceEvents || false],
      InformClientCategoriesInterfaceEvents: [this.data.InformClientCategoriesInterfaceEvents || false],
      InformRatesInterfaceEvents: [this.data.InformRatesInterfaceEvents || false],
      InformUsersInterfaceEvents: [this.data.InformUsersInterfaceEvents || false],
      Disabled: [this.data.Disabled || false],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

    if (this.isNew)
    {
      const general = this.administrationService.notifyGroupTypes.filter(n => n.Id == this.commonService.generalNotificationGroupTypeId)[0];

      if (general)
      {
        //default notification type to general
        this.form.controls["NotifyGroupType"].patchValue(general);
      }
    }
  }

  get notifyGroupTypes(): NotifyGroupType[]
  {
    if (this.commonService.isInterfacing)
    {
      return this.administrationService.notifyGroupTypes;
    }
    else
    {
      return this.administrationService.notifyGroupTypes.filter(n => n.Id != this.commonService.interfacingNotificationGroupTypeId);
    }
  }

  onSave(): void
  {
    const notifyGroup: any = this.form.value as NotifyGroup;

    if (notifyGroup)
    {
      if (this.isNew)
      {
        notifyGroup.Id = 0;

        if (notifyGroup.Disabled == null)
        {
          notifyGroup.Disabled = false;
        }
      }

      if (!this.isNotifyTypeGeneral)
      {
        notifyGroup.InformCreditLineChanged = false;
        notifyGroup.InformCreditLineDue = false;
        notifyGroup.InformAudioDueToAdOps = false;
        notifyGroup.InformDropDead = false;
        notifyGroup.InformDropDeadWarning = false;
        notifyGroup.InformFinalPcrDue = false;
        notifyGroup.InformFirstDayAircheckDue = false;
        notifyGroup.InformIfFirstRightRefusalExpires = false;
        notifyGroup.InformIfOffSubmittedForSale = false;
        notifyGroup.InformIfOnHoldExpires = false;
        notifyGroup.WarnFirstRightRefusalCountdown = false;
        notifyGroup.InformConfirmedActivityDisabled = false;
        notifyGroup.InformConfirmedActivityDateRangeChanged = false;
      }

      if (!this.isNotifyTypeInterfacing)
      {
        notifyGroup.InformActivitiesImportErrors = false;
        notifyGroup.InformActivitiesInterfaceEvents = false;
        notifyGroup.InformClientCategoriesInterfaceEvents = false;
        notifyGroup.InformUsersInterfaceEvents = false;
        notifyGroup.InformClientsInterfaceEvents = false;
        notifyGroup.InformRatesInterfaceEvents = false;
      }


      notifyGroup[this.changedByField as keyof typeof notifyGroup] = this.authorisationService.currentuser.Id;

      super.onSave(notifyGroup);

      this.administrationService.resetNotifyGroups();

    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Notification Group";

    if (this.isNew)
    {
      headerText = "New  Notification Group";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.Name}`;
      }
    }

    return headerText;
  }

  equalsNotifyGroupType(o1: NotifyGroupType, o2: NotifyGroupType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  get isNotifyTypeGeneral(): boolean
  {
    return this.form.get('NotifyGroupType').value.Id == this.commonService.generalNotificationGroupTypeId;
  }

  get isNotifyTypeInterfacing(): boolean
  {
    return this.form.get('NotifyGroupType').value.Id == this.commonService.interfacingNotificationGroupTypeId;
  }



  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
