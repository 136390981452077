<div class="signin-control">
  <div class="signin-header">
    <img src="/assets/images/cb-logo-colour.svg" alt="Campaign Boss" class="loading-msg-image" /><label>Loading user information</label>
  </div>
  <div class="signin-content">
    <img src="/assets/images/cb-loading.gif" alt="Loading user information and permissions into Campaign Boss." />
  </div>
  <div class="signin-footer">
    <label>Please wait...</label>
  </div>
</div>
