<form novalidate [formGroup]="editForm" class="k-form-inline" id="eventForm">
  @if (active) {
    <div class="appointment-edit-control">
      <div class="appointment-edit-content">
        <div>
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput (focus)="$event.target.select()" placeholder="Title" formControlName="Title" maxlength="150" autocomplete="off" matautofocus />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput matAutosizeMaxRows="2" rows="2" (focus)="$event.target.select()" placeholder="Description" title="Description" formControlName="Description" maxlength="500"></textarea>
          </mat-form-field>
        </div>
        <div class="appointment-edit-controls">
          <div class="form-group">
            <kendo-multiselect [autoClose]="false"  [data]="groupedData"
              [textField]="'StationName'"
              [valueField]="'Id'"
              [valuePrimitive]="true"
              [size]="'small'"
              [fillMode]="'flat'"
              formControlName="StationIds"
              placeholder="Select platform providers..."
              style="width: 15rem;">
            </kendo-multiselect>
          </div>
          <div class="form-group">
            <kendo-dropdownlist formControlName="EventCategoryId"
              [data]="diaryService.eventCategories()"
              [defaultItem]="{ Id: 0, Name: 'Select Category'}"
              [textField]="'Name'"
              [valueField]="'Id'"
              [valuePrimitive]="true"
              [size]="'small'"
              [fillMode]="'flat'"
              style="width: 15rem;">
            </kendo-dropdownlist>
          </div>
          <div class="form-group" style="visibility:hidden">
            <input type='checkbox' id='k-is-allday-chkbox' class='k-checkbox' formControlName='IsAllDayEvent' />
            <label class='k-checkbox-label' for='k-is-allday-chkbox'>All Day Event</label>
          </div>
          <div class="k-form-field k-datetime-picker-wrapper date-picker-wrapper">
            <span>Start</span>
            <kendo-datepicker [size]="'small'" [fillMode]="'flat'" formControlName="StartDate" (valueChange)="startDateChange($event)">
            </kendo-datepicker>
            @if (!editForm.controls.IsAllDayEvent.value) {
              <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="StartDate">
              </kendo-timepicker>
            }
          </div>
          <div class="k-form-field k-datetime-picker-wrapper date-picker-wrapper">
            <span>End</span>
            <kendo-datepicker [size]="'small'" [fillMode]="'flat'" formControlName="EndDate" (valueChange)="endDateChange($event)">
            </kendo-datepicker>
            @if (!editForm.controls.IsAllDayEvent.value) {
              <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="EndDate">
              </kendo-timepicker>
            }
          </div>
        </div>
        <div class="appointment-edit-recurrence">
          @if (isEditingSeries && !formInitiallyDisabled) {
            <kendo-recurrence-editor formControlName='RecurrencePattern' [start]="editForm.controls.StartDate.value" [timezone]="'Etc/UTC'" (valueChange)="recurrenceChange($event)">
            </kendo-recurrence-editor>
          }
        </div>
      </div>
      <div class="appointment-edit-footer text-right">
        <hr />
        <button kendoButton (click)="onCancel($event)" [disabled]="diaryService.saveInProgress">Cancel</button>
        <button kendoButton (click)="onSave($event)" [disabled]="!editForm.valid || !editForm.dirty || diaryService.saveInProgress" [primary]="true">Save</button>
      </div>
    </div>
  }
</form>
