import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardComponent } from '../../shared/components/dashboard/dashboard.component';
import { User } from '../../shared/models/common.models';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../../shared/services/common.service';


@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  providers: [],
  standalone: true,
  imports: [DashboardComponent]
})
export class HomeComponent
{
  public currentUser: User;
  

  constructor(public commonService: CommonService, private authenticationService: AuthenticationService, private router: Router)
  {

  }

  ngOnInit()
  {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngAfterViewInit()
  {
    if (!this.commonService.isLoggedIn() && !this.commonService.manuallyLoggedOut)
    {
      this.router.navigate(['/login']);
    }
  }


  ngOnDestroy()
  {
    



  }




















}
