import { Permission } from "../../components/administration/administration.models";
import { ActivityInfo, CalendarActivity, ContactType, Station } from "../../components/calendars/calendars.models";
import { Campaign } from "../../components/campaigns/campaigns.models";


export class User
{
  public Id = 0;
  public ExternalId = 0;
  public UserName = "";
  public Password = "";
  public FirstName = "";
  public Surname = "";
  public Email = "";
  public Phone = "";
  public Mobile = "";
  public HomeStationId = 0;
  public HomeStationName = "";
  public UserRole: Role;
  public token?: string;
  public ContactTypeNames = "";
  public ContactTypes: ContactType[];
  public Stations: Station[];
  public ReportsToUser = 0;
  public ReportsToName = "";
  public DepartmentId = 0;
  public RoleId = 0;
  public IsLocked = false;
  public Disabled = false;
  public DisabledString = "";
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
  public SubordinatesUserIds: number[] = [];
}

export class InterfaceImport
{
  public StartDate: Date;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class RequestCacheEntry
{
  url: string = "";
  params: string = "";
  response: any;
  lastRead: number = 0;
}

export class ContactTypeUser
{
  public Id = 0;
  public FirstName = "";
  public Surname = "";
  public FullName = "";
  public Email = "";
  public Phone = "";
  public Mobile = "";
  public HomeStationId = 0;
  public ContactTypeLabelText = "";
  public StationIds: number[] = [];
  public Disabled = false;
  public SortOrder = 0;
}

export class Role
{
  public Id = 0;
  public RoleName = "";
  public RoleLevel = 0;
  public Permissions: Permission[] = [];
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class Department
{
  public Id = 0;
  public DepartmentName = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class LoginRequest
{
  public UserName = "";
  public Password = "";
  public LoginMethod: LoginMethods = LoginMethods.Form;
}

export enum LoginMethods
{
  Form = 0,
  Microsoft = 1
}

export enum GroupType
{
  CalendarState = "CalendarState",
  Misc = "Misc",
  Navigation = "Navigation"
}


export class JsonWebToken 
{
  public token: string;
}


export class Filter 
{
  public field: string;
  public operator: string;
  public value: any;
  public logic: string;
  public filters: Filter[];
}

export class Sort 
{
  public dir: string;
  public field: string;
  public StringNumericStartsAt: number;
}

export class ServerPagingParameters
{
  public Take: number;
  public Skip: number;
  public Sort: Sort[];
  public Filter: Filter;
}

export class ActivitySearchServerPagingParameters extends ServerPagingParameters
{
  public stationIds: number[] = [];
  public startDate: Date;
  public endDate: Date;
}

export class ActivitiesInfo
{
  public activity: CalendarActivity;
  public campaign: Campaign;
  public IsForCampaign = false;
  public stationIds: number[] = [];
  public newActivityOnOccupiedSpot = false;
  public id = 0;
}

export class ReportInfo
{
  public showParameters = false;
  public showCloseButton = false;
  public dragDisabled = true;
  public id = 0;
  public reportName = "";
  public reportParameters: any;
  public viewingContainerHeight = "48rem";
  public viewingContainerWidth = "100%";
}

export class PropertiesInfo
{
  public id = 0;
  public isOnline = false;
}

