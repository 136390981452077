<mat-card appearance="outlined" class="mat-crud-wide">
  <mat-card-header class="admin-mat-card-header">
    <mat-card-title>
      Platform Providers
    </mat-card-title>
    <div class="card-header-text-icons">
      Reload data:
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-table #table [dataSource]="dataSource" matSort class="mat-cell">
      <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
      <!-- ID Column-->
      <ng-container matColumnDef="Id">
        <mat-header-cell class="header no-filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">Id</div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.Id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="StationName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            Platform Provider Name
          </div>
          <mat-form-field>
            <mat-label>Search Platform Provider Name</mat-label>
            <input matInput class="form-field" [formControl]="StationNameFilter" placeholder="Search Platform Name" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.StationName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="State">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div mat-sort-header class="sort-header">
            State
          </div>
          <mat-form-field>
            <mat-label>Search State</mat-label>
            <input matInput class="form-field" [formControl]="StateFilter" placeholder="Search State" autocomplete="off">
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.State}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="StationType.StationTypeName">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div class="sort-header">
            Platform Group
          </div>
          <mat-form-field>
            <mat-select [formControl]="StationTypeIdFilter" placeholder="Search Platform Group">
              <mat-option [value]="null">All</mat-option>
              @for (stationtype of administrationService.stationTypes | filter:[{Disabled: false}]; track stationtype) {
                <mat-option [value]="stationtype.Id">{{stationtype.StationTypeName}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.StationType.StationTypeName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="PlatformType.Name">
        <mat-header-cell class="header filter" mat-header-cell *matHeaderCellDef>
          <div class="sort-header">
            Platform Type
          </div>
          <mat-form-field>
            <mat-select [formControl]="PlatformTypeIdFilter" placeholder="Search Platform Type">
              <mat-option [value]="null">All</mat-option>
              @for (platformType of administrationService.platformTypes(); track platformType) {
                <mat-option [value]="platformType.Id">{{platformType.Name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row?.PlatformType.Name}}</mat-cell>
      </ng-container>

      <!-- actions -->
      <ng-container matColumnDef="actions">
        <mat-header-cell class="header header-add-refresh" mat-header-cell *matHeaderCellDef>
          <div class="card-header-text-icons">
            Reload data:
            <button mat-icon-button (click)="refresh()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          @if (CanAdd) {
            <button mat-icon-button color="accent" (click)="addNew($event)" title="add">
              <i class="material-icons">add_box</i>
            </button>
          }
        </mat-header-cell>

        <mat-cell mat-cell *matCellDef="let row; let i=index;">
          @if (CanEdit) {
            <button mat-icon-button color="accent" (click)="editItem($event, row)" title="edit">
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
          }

          @if (CanDelete) {
            <button mat-icon-button color="accent" (click)="deleteItem($event, row)" title="delete">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
          }
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    <div class="no-results" [style.display]="dataSource?.data?.length === 0 ? '' : 'none'">
      No results
    </div>

  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator
      [length]="dataSource?.filteredData?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="mtCrudService.pagerPageSizes"
      showFirstLastButtons="true">
    </mat-paginator>
  </mat-card-footer>
</mat-card>
