@if (unassignedActivities$ | async; as calendarInfo)
{
<div cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div class="campaign-assigner-control">
    <div cdkDragHandle class="campaign-assigner-header">
      <h1 mat-dialog-title class="dialog-header">Assign Activities To Campaign</h1>
    </div>
    <div class="campaign-assigner-content">
      <kendo-grid [data]="calendarInfo"
                  class="kendo-grid"
                  style="height: 685px"
                  [rowClass]="rowCallback"
                  [selectable]="selectableSettings"
                  (selectionChange)="selectionChange($event)"
                  [pageable]="false">
        <kendo-grid-checkbox-column width="25px">
          <ng-template kendoGridHeaderTemplate>
            <input type="checkbox"
                   kendoCheckBox
                   id="selectAllCheckboxId"
                   kendoGridSelectAllCheckbox
                   [state]="selectAllState"
                   (selectAllChange)="onSelectAllChange($event)"
                   style="margin: 4px 0;" />
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <input type="checkbox" kendoCheckBox id="{{dataItem.Id}}" style="margin: 4px 1px;" size="medium" rounded="medium" (change)="selectionChange($event)" [checked]="isRowSelected(dataItem.Id)" />
          </ng-template>
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="Id" title="Id" width="80px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.Id}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="PropertyName" title="Property" width="190px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.PropertyName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="PropertyTypeName" title="Property Type" width="105px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.PropertyTypeName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ClientName" title="Client" width="180px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.ClientName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Product" title="Product" width="160px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.Product}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="ActivityName" title="Activity Name" width="180px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.ActivityName}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Status" title="Status" width="125px">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            {{dataItem.Status}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="StartDate" title="First Date" width="155px" format="{0:d}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.StartDate | date }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="EndDate" title="Last Date" width="155px" format="{0:d}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.EndDate | date }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <div>
      <hr />
      <mat-dialog-actions>
        <div class="button-row">
          <button mat-button (click)="onCancelClick()">Close</button>
          <button mat-stroked-button color="primary" (click)="assignActivities($event)" [disabled]="!campaignAssignerService?.rowsSelected || campaignAssignerService?.rowsSelected?.length < 1" title="Click to assign selected activities to the campaign">Assign</button>
        </div>
      </mat-dialog-actions>
    </div>
  </div>
</div>
}
@else
{
<div class="app-spinner-container">
  <mat-spinner></mat-spinner>
  <label class="app-spinner-loading-label">{{commonService.loadingText}}</label>
</div>
}
