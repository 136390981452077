<div class="job-control" [formGroup]="form" cdkDrag cdkDragBoundary=".cdk-overlay-container" cdkDragRootElement=".cdk-overlay-pane">
  <div cdkDragHandle class="job-header">
    <h1 mat-dialog-title class="dialog-header">{{dialogHeaderText}}</h1>
  </div>
  <div class="job-content">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput placeholder="Title" formControlName="Title" maxlength="150" autocomplete="off">
        <mat-hint></mat-hint>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Job Type</mat-label>
        <mat-select formControlName="JobTypeId" placeholder="Job Type" [compareWith]="equalsJobType">
          @for (jobtype of administrationService.jobTypes; track jobtype) {
            <mat-option [value]="jobtype.Id">{{jobtype.Name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Job Action</mat-label>
        <mat-select formControlName="JobActionId" placeholder="Job Action" [compareWith]="equalsJobAction">
          @for (jobaction of administrationService.jobActions; track jobaction) {
            <mat-option [value]="jobaction.Id">{{jobaction.Name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    @if (form.get('JobTypeId').value===1) {
      <div class="form-group">
        <mat-form-field>
          <mat-label>Report</mat-label>
          <mat-select formControlName="Target" placeholder="Report">
            @for (report of reportService.reportListItems | filter:[{show: true}]; track report) {
              <mat-option [value]="report.value">{{report.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (form.get('JobTypeId').value===1) {
      <div class="form-group">
        <button mat-raised-button color="primary" class="parameters-button" (click)="reportParameters()" title="Click to set report parameters">Report Parameters</button>
      </div>
    }
    @if (form.get('JobActionId').value===1) {
      <div class="form-group">
        <mat-form-field>
          <mat-label>Document Type</mat-label>
          <mat-select formControlName="JobDocumentTypeId" placeholder="Document Type" [compareWith]="equalsJobDocumentType">
            @for (jobdocumenttype of administrationService.jobDocumentTypes; track jobdocumenttype) {
              <mat-option [value]="jobdocumenttype.Id">{{jobdocumenttype.Name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    <div class="form-group" style="display:flex;">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="cdatepicker" formControlName="StartDate" placeholder="Start Date" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="cdatepicker"></mat-datepicker-toggle>
        <mat-datepicker #cdatepicker startView="month"></mat-datepicker>
      </mat-form-field>
      <kendo-floatinglabel text="Start Time" style="width:50%">
        <kendo-timepicker [size]="'small'" [fillMode]="'flat'" formControlName="StartTime" [format]="'HH:mm'" [popupSettings]="popupSettings" placeholder=""></kendo-timepicker>
      </kendo-floatinglabel>
    </div>
    @if (form.get('JobActionId').value===1) {
      <div class="form-group">
        <mat-form-field>
          <mat-label>Frequency</mat-label>
          <mat-select formControlName="Frequency">
            <mat-option [value]="1" selected>Daily</mat-option>
            <mat-option [value]="2">Weekly</mat-option>
            @if (false) {
              <mat-option [value]="3">Monthly</mat-option>
            }
            @if (false) {
              <mat-option [value]="4">Yearly</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (form.get('JobActionId').value===1) {
      <div class="form-group">
        <mat-form-field>
          <mat-label>Recipients</mat-label>
          <textarea style="font-size: 0.9rem;" matInput matAutosizeMaxRows="4" rows="4" (focus)="$event.target.select()" title="Recipients" placeholder="Recipients" formControlName="Recipients" maxlength="2500" autocomplete="off"></textarea>
        </mat-form-field>
      </div>
    }
    <div class="form-group">
      <div class="checkbox-form-group">
        <mat-checkbox formControlName="Disabled">Disabled</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="job-footer">
    <hr />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty || saveInProgress" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>
