<div class="grid-container scrollbar-cb">
  <kendo-tilelayout [columns]="gridListCols"
                    [autoFlow]="'column'"
                    [gap]="7"
                    [resizable]="false"
                    [reorderable]="false"
                    rowHeight="12.75rem"
                    class="tile-layout">
    <kendo-tilelayout-item id="1" [col]="tileOne.col" [row]="tileOne.row" [colSpan]="tileOne.colSpan" [rowSpan]="tileOne.rowSpan">
      @if (!dashboardService?.dashboardData())
      {
      <kendo-skeleton shape="rectangle" animation="pulse" width="calc(100% - 20px)" height="100%" style="margin: 10px"></kendo-skeleton>
      }
      @else
      {
      <kendo-tilelayout-item-body class="dashboard-card-content">
        <div class="number-cards-wrapper">
          @for (dashboardnumber of dashboardNumbers; track dashboardnumber.Title)
          {
          <dashboard-number [title]="dashboardnumber?.Title"
                            [label]="dashboardnumber?.Label"
                            [owner]="dashboardnumber?.Owner"
                            [colour]="dashboardnumber?.Colour"
                            [number]="dashboardnumber?.Number">
          </dashboard-number>
          }
        </div>
      </kendo-tilelayout-item-body>
      }
    </kendo-tilelayout-item>
    <kendo-tilelayout-item id="2" [col]="tileTwo.col" [row]="tileTwo.row" [colSpan]="tileTwo.colSpan" [rowSpan]="tileTwo.rowSpan">
      @if (!dashboardService?.dashboardData())
      {
      <kendo-skeleton shape="rectangle" animation="pulse" width="calc(100% - 20px)" height="100%" style="margin: 10px"></kendo-skeleton>
      }
      @else
      {
      <kendo-tilelayout-item-body class="dashboard-card-content">
        <div class="status-charts-wrapper">
          <div>
            <span class="status-charts-title">{{usersGroupName()}}</span>
            @if (dashboardService?.userStatusDataTotal > 0)
            {
            <kendo-chart>
              <kendo-chart-tooltip>
                <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                  <div style="display:flex; flex-direction:column; align-items:center"><div>{{ dataItem.name}}</div><div>{{ dataItem.value}}</div></div>
                </ng-template>
              </kendo-chart-tooltip>
              <kendo-chart-series>
                <kendo-chart-series-item type="donut" [data]="dashboardService?.userStatusData" [autoFit]="true" [holeSize]="40" categoryField="name" field="value" colorField="colour">
                  <kendo-chart-series-item-labels [content]="labelContent" visible="true" position="outsideEnd" font="bold 1em 'Open Sans', Roboto, 'Helvetica Neue', sans-serif">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            </kendo-chart>
            }
            @else
            {
            <div class="no-data-msg">no data</div>
            }
          </div>
          <div>
            <span class="status-charts-title">{{groupingItemName}}</span>
            @if (dashboardService?.stationStatusDataTotal > 0)
            {
            <kendo-chart>
              <kendo-chart-tooltip>
                <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
                  <div style="display:flex; flex-direction:column; align-items:center"><div>{{ dataItem.name}}</div><div>{{ dataItem.value}}</div></div>
                </ng-template>
              </kendo-chart-tooltip>
              <kendo-chart-series>
                <kendo-chart-series-item type="donut" [data]="dashboardService?.stationStatusData" [autoFit]="true" [holeSize]="40" categoryField="name" field="value" colorField="colour">
                  <kendo-chart-series-item-labels [content]="labelContent" visible="true" position="outsideEnd" font="bold 1em 'Open Sans', Roboto, 'Helvetica Neue', sans-serif">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            </kendo-chart>
            }
            @else
            {
            <div class="no-data-msg">no data</div>
            }
          </div>
        </div>
      </kendo-tilelayout-item-body>
      }
    </kendo-tilelayout-item>
    <kendo-tilelayout-item id="3" [col]="tileThree.col" [row]="tileThree.row" [colSpan]="tileThree.colSpan" [rowSpan]="tileThree.rowSpan">
      @if (!dashboardService?.dashboardData())
      {
      <kendo-skeleton shape="rectangle" animation="pulse" width="calc(100% - 20px)" height="100%" style="margin: 10px"></kendo-skeleton>
      }
      @else
      {
      <kendo-tilelayout-item-header>
        <h5 class="header-text-left">
          {{tileThree.title}}
        </h5>
      </kendo-tilelayout-item-header>
      <kendo-tilelayout-item-body class="dashboard-card-content">
        <ul class="user-info-list">
          <li>You are logged in as <strong>{{dashboardService?.currentUser()?.FirstName}} {{dashboardService?.currentUser()?.Surname}}</strong></li>
          @if (false)
          {
          <li>Your username is: <strong>{{dashboardService?.currentUser()?.UserName}}</strong></li>
          }
          <li>Your role is: <strong>{{dashboardService?.currentUser()?.UserRole?.RoleName}}</strong></li>
          <li>You report to: <strong>{{dashboardService?.currentUser()?.ReportsToName ?? "N/A"}}</strong></li>
          <li>Your home base is: <strong>{{dashboardService?.currentUser()?.HomeStationName}}</strong></li>
          @if(dashboardService?.currentUser()?.SubordinatesUserIds != null && dashboardService?.currentUser()?.SubordinatesUserIds?.length > 0)
          {
          <li>Show Team View:&nbsp;&nbsp;&nbsp;&nbsp;<kendo-switch (valueChange)="teamViewChange($event);" [checked]="dashboardService.teamView()"></kendo-switch></li>
          }
        </ul>
      </kendo-tilelayout-item-body>
      }
    </kendo-tilelayout-item>
    <kendo-tilelayout-item id="4" [col]="tileFour.col" [row]="tileFour.row" [colSpan]="tileFour.colSpan" [rowSpan]="tileFour.rowSpan">
      @if (!dashboardService?.dashboardData())
      {
      <kendo-skeleton shape="rectangle" animation="pulse" width="calc(100% - 20px)" height="100%" style="margin: 10px"></kendo-skeleton>
      }
      @else
      {
      <kendo-tilelayout-item-header>
        <h5 class="header-text">
          {{tileFour.title}}
        </h5>
      </kendo-tilelayout-item-header>
      <kendo-tilelayout-item-body class="dashboard-card-content">
        <kendo-chart [pannable]="false"
                     [zoomable]="false">
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [labels]="valueAxisLabels">
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [labels]="valueAxisLabels">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-series>
            @for (item of dashboardService?.dashboardData()?.RevenueChartData; track item.name)
            {
            <kendo-chart-series-item [stack]="{ group: 'a' }"
                                     [style]="'smooth'"
                                     [data]="item.data"
                                     [name]="item.name"
                                     categoryField="name">
              <kendo-chart-series-item-tooltip background="green" format={0:c0}>
              </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>
            }
          </kendo-chart-series>
          <kendo-chart-legend position="right" orientation="vertical">
          </kendo-chart-legend>
        </kendo-chart>
      </kendo-tilelayout-item-body>
      }
    </kendo-tilelayout-item>
    <dashboard-list [id]="5" [processing]="!dashboardService?.dashboardData()" [title]="tileFive.title" [iconName]="'Ghost'" [activitiesList]="dashboardService?.dashboardData()?.DropDeadActivities" [row]="tileFive.row" [rowspan]="tileFive.rowSpan" [col]="tileFive.col" [colspan]="tileFive.colSpan"></dashboard-list>
    <dashboard-list [id]="6" [processing]="!dashboardService?.dashboardData()"[title]="tileSix.title" [iconName]="'Pause'" [activitiesList]="dashboardService?.dashboardData()?.OnHoldActivities" [row]="tileSix.row" [rowspan]="tileSix.rowSpan" [col]="tileSix.col" [colspan]="tileSix.colSpan"></dashboard-list>
    <dashboard-list [id]="7" [processing]="!dashboardService?.dashboardData()"[title]="tileSeven.title" [iconName]="'Circle'" [activitiesList]="dashboardService?.dashboardData()?.CreditLineDueActivities" [row]="tileSeven.row" [rowspan]="tileSeven.rowSpan" [col]="tileSeven.col" [colspan]="tileSeven.colSpan"></dashboard-list>
    <dashboard-list [id]="8" [processing]="!dashboardService?.dashboardData()"[title]="tileEight.title" [iconName]="'Circle'" [activitiesList]="dashboardService?.dashboardData()?.FinalPcrActivities" [row]="tileEight.row" [rowspan]="tileEight.rowSpan" [col]="tileEight.col" [colspan]="tileEight.colSpan"></dashboard-list>
    <dashboard-list [id]="9" [processing]="!dashboardService?.dashboardData()"[title]="tileNine.title" [iconName]="'Circle'" [activitiesList]="dashboardService?.dashboardData()?.FirstDayAirCheckActivities" [row]="tileNine.row" [rowspan]="tileNine.rowSpan" [col]="tileNine.col" [colspan]="tileNine.colSpan"></dashboard-list>
  </kendo-tilelayout>
</div>
