import { Injectable } from '@angular/core';
import { CreditsInfo } from '../../../components/calendars/calendars.models';


@Injectable()

export class AirtimeCreditsService
{
  constructor()
  {

  }

  private _airtimeCredits: CreditsInfo[];

  get airtimeCredits(): CreditsInfo[]
  {
    return this._airtimeCredits;
  }
  set airtimeCredits(value: CreditsInfo[])
  {
    this._airtimeCredits = value;
  }

  get Mon_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a =>  tot += a.Mon);

    return tot;
  }

  get Tue_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += a.Tue);

    return tot;
  }

  get Wed_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += a.Wed);

    return tot;
  }

  get Thu_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += a.Thu);

    return tot;
  }

  get Fri_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += a.Fri);

    return tot;
  }

  get Sat_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += a.Sat);

    return tot;
  }

  get Sun_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += a.Sun);

    return tot;
  }

  get Grand_Total(): number
  {
    let tot = 0;

    this._airtimeCredits.map(a => tot += (a.Mon + a.Tue + a.Wed + a.Thu +a.Fri + a.Sat + a.Sun));

    return tot;
  }
}
