import { Component, Inject, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions, MatDialog } from '@angular/material/dialog';
import { ActivityInfo, ActivityStatus } from '../../../components/calendars/calendars.models';
import { CalendarsService } from '../../../components/calendars/calendars.service';
import { ProgressActivitiesInfo } from '../../../components/tools/tools.models';
import { AuthorisationService } from '../../../shared/services/authorisation.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SaveResult } from '../../models/result.model';
import { ActivitiesUpdaterService } from './activities-updater.service';
import { MatButtonModule } from '@angular/material/button';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { FormFieldModule, TextAreaModule } from '@progress/kendo-angular-inputs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { NgClass } from '@angular/common';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: 'activities-updater',
  templateUrl: './activities-updater.component.html',
  styleUrls: ['./activities-updater.component.scss'],
  providers: [ActivitiesUpdaterService],
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, MatDialogTitle, MatProgressSpinnerModule, DropDownListModule, FormsModule, NgClass, DatePickerModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatDatepickerModule, FormFieldModule, FloatingLabelModule, TextAreaModule, MatDialogActions, MatButtonModule]
})
export class ActivitiesUpdaterComponent implements OnInit
{
  public saveInProgress = false;

  public CreditLineDueDate: Date;
  public CreditLine: string = "";
  public CreditLineNotes = "";
  public OnHoldDate: Date;
  public disableHeldDate = false;
  public CreditLineRotationTypeId = 0;
  public CreditLineTypeId = 0;

  public showCreditLineType = false;
  public showCreditLineRotation = false;
  public showCreditLineNotes = false;
  public showCreditLine = true;

  private _statuses: ActivityStatus[] = [];
  private _heldUntilText = "";
  private _activityStatusClass: any = { 'unconfirmed-sold-activity-text': true };
  private _currentActivityStatus: ActivityStatus = { Name: 'Submitted For Delivery', Value: 'IsUnConfirmedSold' };

  @Input() get activities(): ActivityInfo[]
  {
    return this.activitiesUpdaterService.activities;
  }
  set activities(value: ActivityInfo[])
  {
    this.activitiesUpdaterService.activities = value;
  }

  public commonService = inject(CommonService);
  public configurationService = inject(ConfigurationService);
  public commonDataService = inject(CommonDataService);
  private calendarsService = inject(CalendarsService);
  private authorisationService = inject(AuthorisationService);
  private activitiesUpdaterService = inject(ActivitiesUpdaterService);

  constructor(
    private dialogRef: MatDialogRef<ActivitiesUpdaterComponent>,
    private confirmDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: ProgressActivitiesInfo)
  {

  }

  ngOnInit(): void
  {
    this.activities = this.data.SelectedActivities;

    this.setUpActivityStatus(this.currentActivityStatus.Value);


  }

  ngOnDestroy()
  {

  }

  get statuses(): ActivityStatus[]
  {
    //Don't allow IsGuaranteed or IsSold
    this._statuses = this.calendarsService.statuses.filter(s => s.Value != "IsGuaranteed" && s.Value != "IsSold");

    if (!this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToHoldStatus))
    {
      this._statuses = this.calendarsService.statuses.filter(s => s.Value != "OnHold");
    }
    if (!this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToSFDStatus))
    {
      this._statuses = this.calendarsService.statuses.filter(s => s.Value != "IsUnConfirmedSold");
    }
    //if (!this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeActivityToConfirmedStatus))
    //{
    //  this._statuses = this.calendarsService.statuses.filter(s => s.Value != "IsSold");
    //}

    return this._statuses;
  }

  handleActivityStatusChange(event: ActivityStatus)
  {
    this.currentActivityStatus = event;

    this.setUpActivityStatus(this.currentActivityStatus.Value);
  }

  heldDateChange(event: Date)
  {
    if (this.currentActivityStatus.Value == "Pending" || this.currentActivityStatus.Value == "IsSold")
    {
      this.OnHoldDate = null;
    }
    else
    {
      this.OnHoldDate = this.commonService.ensureDateIsNotOnWeekend(event);
    }
  }

  setUpActivityStatus(status: string)
  {
    this._activityStatusClass = { 'pending-activity-text': true };
    this._heldUntilText = "";

    switch (status)
    {
      case "Pending":
        this._activityStatusClass = { 'pending-activity-text': true };
        this._heldUntilText = "";
        break;
      case "IsSold":
        this._activityStatusClass = { 'sold-activity-text': true };
        this._heldUntilText = "";
        break;
      case "IsGuaranteed":
        this._activityStatusClass = { 'guaranteed-activity-text': true };
        this._heldUntilText = "Held Until Date";
        break;
      case "OnHold":
        this._activityStatusClass = { 'onhold-activity-text': true };
        this._heldUntilText = "Held Until Date";
        break;
      case "IsUnConfirmedSold":
        this._activityStatusClass = { 'unconfirmed-sold-activity-text': true };
        this._heldUntilText = "Held Until Date";
        break;
      default:
        this._activityStatusClass = { 'pending-activity-text': true };
        this._heldUntilText = "";
        break;
    }

    this.disableHeldDate = true;

    this.OnHoldDate = null;

    if (this.currentActivityStatus.Value == "OnHold" || this.currentActivityStatus.Value == "IsUnConfirmedSold")
    {
      let onholddate: Date = this.commonService.today;

      switch (this.currentActivityStatus.Value)
      {
        case "OnHold":
          if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeOnHoldHeldDate))
          {
            this.disableHeldDate = false;
          }
          break;
        case "IsUnConfirmedSold":
          if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ChangeSFDHeldDate))
          {
            this.disableHeldDate = false;
          }
          break;
      }

      onholddate = this.commonService.ensureDateIsNotOnWeekend(onholddate);

      this.OnHoldDate = onholddate;
    }
  }

  get currentActivityStatus(): ActivityStatus
  {
    return this._currentActivityStatus;
  }
  set currentActivityStatus(value: ActivityStatus)
  {
    this._currentActivityStatus = value;
  }

  get heldUntilText(): any
  {
    return this._heldUntilText;
  }

  get activityStatusClass(): any
  {
    return this._activityStatusClass;
  }


  progressActivities(event: any)
  {
    try
    {
      this.saveInProgress = true;

      if (this.activities && this.activities.length > 0)
      {
        const progressActivitiesInfo: ProgressActivitiesInfo = this.data;
        progressActivitiesInfo.ActivityIds = this.activities.map(a => a.Id);
        progressActivitiesInfo.CreditLine = this.CreditLine;
        progressActivitiesInfo.CreditLineDueDate = this.CreditLineDueDate;
        progressActivitiesInfo.HeldDate = this.OnHoldDate;
        progressActivitiesInfo.Status = this.currentActivityStatus.Value;

        if (progressActivitiesInfo.ActivityIds && progressActivitiesInfo.ActivityIds.length > 0)
        {
          this.activitiesUpdaterService.progressActivities(progressActivitiesInfo).then((sr: SaveResult) =>
          {
            this.saveInProgress = false;

            progressActivitiesInfo.SaveResult = sr;

            if (sr.IsSuccessful)
            {
              if (sr.Message)
              {
                this.commonService.notifySuccess("Success", sr.Message);
              }
            }
            else
            {
              if (sr.Message)
              {
                const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                confirmDialogRef.componentInstance.confirmMessage = sr.Message;
                confirmDialogRef.componentInstance.confirmHeader = sr.MessageHeader;
                confirmDialogRef.componentInstance.confirmTitle = sr.MessageTitle;
                confirmDialogRef.componentInstance.showCancelButton = false;
                confirmDialogRef.componentInstance.confirmText = "OK";

                if (sr.CssClassName)
                {
                  confirmDialogRef.addPanelClass(sr.CssClassName);
                }
              }
              else
              {
                let errMsg = "Unable to save record.";

                if (sr.ErrorMessage)
                {
                  errMsg = sr.ErrorMessage;
                }

                this.commonService.notifyFailure("Error", errMsg, sr.ExceptionMessage, sr.ValidationExceptionMessage);
              }
            }

            this.dialogRef.close(progressActivitiesInfo);
          }).catch(err => this.saveInProgress = false);
        }
      }
    }
    catch
    {
      this.saveInProgress = false;
    }
  }

  onCancelClick()
  {
    this.dialogRef.close();
  }




}
