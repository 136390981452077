import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from "../../../shared/services/common.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { Client } from "../../calendars/calendars.models";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";


@Component({
    templateUrl: './client-edit.component.html',
    selector: 'client-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './clients.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDialogActions, MatButtonModule]
})
export class ClientEditComponent extends MatTableCrudEditComponent<ClientEditComponent, Client>
{
  constructor(public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ClientEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Client,
    public commonService: CommonService,
    public authorisationService: AuthorisationService,
    public loggerService: LoggerService)
  {
    super(dialogRef, data);

    const isSalesClient: boolean = this.data != null &&  this.data.ExternalId != null && this.data.ExternalId > 0;

    this.form = this.fb.group({
      Id: [{ value: this.data.Id, disabled: false }],
      Name: [{ value: this.data.Name, disabled: isSalesClient }, Validators.required],
      LongName: [{ value: this.data.LongName, disabled: isSalesClient }],
      Contact: [{ value: this.data.Contact, disabled: isSalesClient }],
      ContactEmail: [{ value: this.data.ContactEmail, disabled: isSalesClient }],
      ContactPhoneNo: [{ value: this.data.ContactPhoneNo, disabled: isSalesClient }],
      ExternalId: [{ value: this.data.ExternalId, disabled: true }],
      Disabled: [this.data.Disabled],
      SortOrder: [this.data.SortOrder, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

  }

  onSave(): void
  {
    const client: any = this.form.value;

    if (client)
    {
      if (this.isNew)
      {
        client.Id = 0;

        if (client.Disabled == null)
        {
          client.Disabled = false;
        }
      }

      client[this.changedByField as keyof typeof client] = this.authorisationService.currentuser.Id;
      
      super.onSave(client);
    }
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Client";

    if (this.isNew)
    {
      headerText = "New Client";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        headerText = `${headerText} - ${this.data.Name}`;
      }
    }

    return headerText;
  }




  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
