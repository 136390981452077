<mat-toolbar color="primary" class="main-menu">
  <button mat-icon-button class="menu-button" (click)="sidenavButtonClick($event)">
    <mat-icon>menu</mat-icon>
  </button>
  <div class='main-nav'>
    @for (routeCategory of appService.routeCategories; track routeCategory) {
      <div [ngClass]="{'selected-menu': commonService.currentpagecategory === routeCategory.CategoryName }">
        @if (routeCategory.RoutesInCategory === 1) {
          <button mat-button [routerLink]="['/' + routeCategory.Path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{routeCategory.Text}}</button>
        }
        @if (routeCategory.RoutesInCategory > 1) {
          <div>
            <button mat-button [matMenuTriggerFor]="menu">{{routeCategory.CategoryName}}</button>
            <mat-menu #menu="matMenu">
              @for (route of appService.routes | filter: [{visible: true}, {category: routeCategory.CategoryName}]; track route) {
                <button mat-menu-item [routerLink]="['/' + route.path]" routerLinkActive="['link-active']" [routerLinkActiveOptions]="{exact: true}">{{route.text}}</button>
              }
            </mat-menu>
          </div>
        }
      </div>
    }
    @if (false && !commonService.isLoggedIn()) {
      <div #loginDiv id="loginDiv" class="nav-item nav-link" style="cursor:pointer" (click)="authenticationService.login();">Login</div>
    }
    @if (false && commonService.isLoggedIn()) {
      <div class="nav-item nav-link" style="cursor:pointer" (click)="authenticationService.logout()">Logout</div>
    }
    @if (!!authenticationService?.currentUserValue?.FirstName) {
      <div class="greeting-user">Hi {{authenticationService?.currentUserValue?.FirstName}}</div>
    }
  </div>
</mat-toolbar>

