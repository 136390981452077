import { SaveResult } from "../../shared/models/result.model";
import { ActivityInfo } from "../calendars/calendars.models";

export class ContactsReplacer
{
  public StartDate: Date
  public EndDate: Date;
  public StationIds: number[] = [];
  public ClientIds: number[] = [];
  public FromClientExecId: number = 0;
  public ToClientExecId: number = 0;
  public FromClientSupportId: number = 0;
  public ToClientSupportId: number = 0;
  public FromBriefManagerId: number = 0;
  public ToBriefManagerId: number = 0;
  public FromProjectManagerId: number = 0;
  public ToProjectManagerId: number = 0;
  public FromNatProjectManagerId: number = 0;
  public ToNatProjectManagerId: number = 0;
  public FromProgrammerId: number = 0;
  public ToProgrammerId: number = 0;
  public FromAdOpsId: number = 0;
  public ToAdOpsId: number = 0;
  public UpdatePropertyContacts: boolean = false;
  public ChangedByUserId = 0;
}


export class PropertyInfoCopier
{
  public StationIds: number[] = [];
  public PropertyTypeIds: number[] = [];
  public PropertyId: number = 0;
  public FromStationId: number = 0;
  public ToPropertyIds: number[] = [];
  public CopyExclusionDates: boolean = false;
  public CopyAirtimeCredit: boolean = false;
  public CopySpotsAvailable: boolean = false;
  public CopyProjectManager: boolean = false;
  public ChangedByUserId = 0;
}


export class ActivityInfoCopier
{
  public ActivityId: number = 0;
  public ToActivityIds: number[] = [];
  public CopyCreditLines: boolean = false;
  public ChangedByUserId = 0;
}


export class ActivitiesList
{
  public Id: number = 0;
  public Name: string = "";
}

export class ActivityListGetParameters
{
  public PropertyIds: number[] = [];
  public StationIds: number[] = [];
  public StartDate: Date
  public EndDate: Date;
}

export class ProgressActivitiesInfo
{
  public CampaignId: number = 0;
  public SelectedActivities: ActivityInfo[] = [];
  public ActivityIds: number[] = [];
  public Status = "";
  public CreditLine = "";
  public CreditLineDueDate: Date;
  public HeldDate: Date;
  public ChangedByUserId = 0;
  public SaveResult: SaveResult;
}
