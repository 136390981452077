import { Injectable, Component } from '@angular/core';


@Injectable()

export class MatDialogMultiDatePickerService
{
    constructor()
    {

    }

  
}