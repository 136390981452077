import { Component, EventEmitter, HostListener, Inject, OnInit, inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { KendoGridService, Parameter } from '../services/kendo-grid.service';
import { ConfigurationService } from '../services/configuration.service';



@Component({
  template: ''
})
export abstract class KendoGridComponent<T> implements OnInit
{
  public OnDataFetched: EventEmitter<any> = new EventEmitter<any>();
  public OnSaved: EventEmitter<any> = new EventEmitter<any>();
  private editedRowIndex: number;
  private editedItem: T;
  protected addedItem: T = {} as T;

  public modifiedByField: string = "ChangedByUserId";
  public idField = "Id";


  /////////////COLUMN TEMPLATE FOR HIDDING COLUMNS/////////////////////////////////////////////////
  //<ng-template kendoGridHeaderTemplate let-dataItem >
  //  Property Type
  //  <span(click)="hideColumn(dataItem.field)" class="k-icon k-i-x" style="padding-left:5px"></span>
  //</ng-template>
  /////////////COLUMN TEMPLATE FOR HIDDING COLUMNS/////////////////////////////////////////////////
  public hiddenColumns: string[] = [];
  public restoreColumns(): void
  {
    this.hiddenColumns = [];
  }
  public hideColumn(field: string): void
  {
    this.hiddenColumns.push(field);
  }
  private savedSubscription: Subscription;
  private dataFetchedSubscription: Subscription;
  protected useReviver: boolean = false;
  protected prepareDataForSave: boolean = false;

  public sort: SortDescriptor[] = [];
  public saveInProgress: boolean = false;
  public useCachedData: boolean = false;
  public exportAllData: boolean = false;

  private _CanAdd: boolean = false;
  private _CanEdit: boolean = false;
  private _CanDelete: boolean = false;
  private _CanExport: boolean = false;

  get CanAdd(): boolean
  {
    return this._CanAdd;
  }
  set CanAdd(value: boolean)
  {
    this._CanAdd = value;
  }

  get CanEdit(): boolean
  {
    return this._CanEdit;
  }

  set CanEdit(value: boolean)
  {
    this._CanEdit = value;
  }

  get CanDelete(): boolean
  {
    return this._CanDelete;
  }
  set CanDelete(value: boolean)
  {
    this._CanDelete = value;
  }

  get CanExport(): boolean
  {
    return this._CanExport;
  }
  set CanExport(value: boolean)
  {
    this._CanExport = value;
  }

  get gridAllData(): any
  {
    return this.kgKendoGridService.gridAllData;
  }

  get serviceParameters(): Parameter[]
  {
    return this.kgKendoGridService.serviceParameters;
  }
  set serviceParameters(value: Parameter[])
  {
    this.kgKendoGridService.serviceParameters = value;
  }

  public form: NgForm;

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent)
  {
    if (event.key === 'Enter')
    {
      event.preventDefault();
    }
  }

  public configurationService = inject(ConfigurationService);


  constructor(private kgKendoGridService: KendoGridService,
    @Inject('createActionMethodPathFromRoot') private createActionMethodPathFromRoot: string,
    @Inject('destroyActionMethodPathFromRoot') private destroyActionMethodPathFromRoot: string,
    @Inject('readActionMethodPathFromRoot') private readActionMethodPathFromRoot: string,
    @Inject('updateActionMethodPathFromRoot') private updateActionMethodPathFromRoot: string,
    @Inject('modifiedBy') private modifiedBy: any,
    @Inject('exportActionMethodPathFromRoot') private exportActionMethodPathFromRoot: string = readActionMethodPathFromRoot
  )
  {
    this.exportData = this.exportData.bind(this);
  }

  public async exportData(): Promise<ExcelExportData>
  {
    if (this.exportAllData)
    {
      if (!this.kgKendoGridService.serverPaging)
      {
        const exportState: State = {
          sort: [],
          skip: 0,
          take: null
        };

        const result: ExcelExportData = {
          data: process(this.gridAllData, exportState).data
        };

        return result;
      }

      await this.kgKendoGridService.serverPagingExport(null);

      const result: ExcelExportData = {
        data: this.gridAllData
      };

      return result;
    }
    else
    {
      //clone state to export state and remove paging
      const exportState: State = JSON.parse(JSON.stringify(this.gridState));
      exportState.skip = 0;
      exportState.take = null;

      if (!this.kgKendoGridService.serverPaging)
      {
        const result: ExcelExportData = {
          data: process(this.gridAllData, exportState).data
        };

        return result;
      }

      await this.kgKendoGridService.serverPagingExport(exportState);

      const result: ExcelExportData = {
        data: this.gridAllData.Data
      };

      return result;
    }
  }

  
  get view(): any
  {
    return this.kgKendoGridService.view;
  }
  set view(value: any)
  {
    this.kgKendoGridService.view = value;
  }

  get gridState(): State
  {
    return this.kgKendoGridService.gridState;
  }
  set gridState(value: State)
  {
    this.kgKendoGridService.gridState = value;
  }


  public ngOnInit(): void
  {
    this.dataFetchedSubscription = this.kgKendoGridService.OnDataFetched.subscribe((data: any) =>
    {
      this.OnDataFetched.emit(data);
    });

    this.savedSubscription = this.kgKendoGridService.OnSaved.subscribe((data: any) =>
    {
      this.saveInProgress = false;
      this.OnSaved.emit(data);
    });

    this.kgKendoGridService.createActionMethodPathFromRoot = this.createActionMethodPathFromRoot;
    this.kgKendoGridService.destroyActionMethodPathFromRoot = this.destroyActionMethodPathFromRoot;
    this.kgKendoGridService.readActionMethodPathFromRoot = this.readActionMethodPathFromRoot;
    this.kgKendoGridService.updateActionMethodPathFromRoot = this.updateActionMethodPathFromRoot;
    this.kgKendoGridService.exportActionMethodPathFromRoot = this.exportActionMethodPathFromRoot;
    this.kgKendoGridService.useReviver = this.useReviver;
    this.kgKendoGridService.prepareDataForSave = this.prepareDataForSave;
    this.kgKendoGridService.useCachedData = this.useCachedData;
    this.kgKendoGridService.idField = this.idField;

    this.kgKendoGridService.read(this.gridState);
  }

  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.dataFetchedSubscription)
    {
      this.dataFetchedSubscription.unsubscribe();
    }

    if (this.savedSubscription)
    {
      this.savedSubscription.unsubscribe();
    }
  }

  public onStateChange(state: State)
  {
    this.gridState = state;

    this.kgKendoGridService.read(state);
  }

  public addHandler({ sender, dataItem, formGroup }: any)
  {
    this.closeEditor(sender);

    if (formGroup)
    {
      sender.addRow(formGroup);
    }
    else if (dataItem)
    {
      this.addedItem = dataItem;
      sender.addRow(this.addedItem);
    }
    else
    {
      sender.addRow(this.addedItem);
    }
  }

  public editHandler({ sender, rowIndex, dataItem, formGroup }: any)
  {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;

    if (formGroup)
    {
      sender.editRow(rowIndex, formGroup);
    }
    else
    {
      this.editedItem = Object.assign({}, dataItem);
      sender.editRow(rowIndex);
    }
  }

  public cancelHandler({ sender, rowIndex }: any)
  {
    this.closeEditor(sender, rowIndex);
    this.resetForm();
  }

  private closeEditor(grid: any, rowIndex = this.editedRowIndex)
  {
    grid.closeRow(rowIndex);
    this.kgKendoGridService.resetItem(this.editedItem);
    this.editedRowIndex = undefined;
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }: any)
  {
    this.saveInProgress = true;

    try
    {
      if (this.modifiedByField && this.modifiedBy)
      {
        dataItem[this.modifiedByField] = this.modifiedBy;
      }

      this.kgKendoGridService.save(dataItem, isNew);

      sender.closeRow(rowIndex);

      this.editedRowIndex = undefined;
      this.editedItem = undefined;
    }
    catch (e)
    {
      this.saveInProgress = false;
    }
    finally
    {
      this.resetForm();
    }
  }

  public removeHandler({ dataItem }: any)
  {
    if (confirm('Are you sure you want to delete this item?'))
    {
      this.kgKendoGridService.remove(dataItem);
    }
  }


  private resetForm()
  {
    if (this.form && this.form.form)
    {
      this.form.form.markAsPristine();
    }
  }

}
