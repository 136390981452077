import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'kendo-checkbox-filter',
    template: `
    <input type="checkbox"
           kendoCheckBox
           size="large"
           rounded="medium"
           [indeterminate]="chkStatus?.status==='crossed'"
           [ngModel]="chkStatus?.status==='checked'"
           (change)="onChange(chkStatus)"
           [value]="selectedValue"
           />
  `,
    standalone: true,
    imports: [FormsModule, CheckBoxModule]
})
export class CheckBoxFilterComponent extends BaseFilterCellComponent
{
  @Output() filterSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  public get selectedValue(): any
  {
    const filter = this.filterByField(this.filterBy);
    return filter ? filter.value : null;
  }

  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public filterBy: string; //field to filter the grid's data by.


  public chkStatus = { status: "crossed" };

  constructor(filterService: FilterService)
  {
    super(filterService);
  }

  public onChange(value: any): void
  {
    switch (this.chkStatus.status)
    {
      case "checked": {
        this.chkStatus.status = "unchecked";
        break;
      }
      case "unchecked": {
        this.chkStatus.status = "crossed";
        break;
      }
      case "crossed": {
        this.chkStatus.status = "checked";
        break;
      }
    }

    this.applyFilter(
      this.chkStatus.status === "crossed" ? 
        this.removeFilter(this.filterBy) : // remove the filter 
        this.updateFilter({ // add a filter for the field with the value
          field: this.filterBy,
          operator: "eq",
          value: this.chkStatus?.status === 'checked'
        })
    ); // update the root filter

    this.filterSelectionChange.emit(value);
  }
}
