import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { compileFilter, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'kendo-dropdown-filter',
  template: `
    <kendo-dropdownlist
      size="small"
      rounded="medium"
      fillMode="outline"
      [data]="_data"
      (valueChange)="onChange($event)" 
      [defaultItem]="defaultItem"
      [value]="selectedValue" 
      [valuePrimitive]="true"
      [textField]="textField"
      [valueField]="valueField"
    >
    </kendo-dropdownlist>
  `,
  standalone: true,
  imports: [DropDownListModule]
})
export class DropDownListFilterComponent extends BaseFilterCellComponent
{
  @Output() filterSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  public get selectedValue(): any
  {
    const filter = this.filterByField(this.filterBy);
    return filter ? filter.value : null;
  }

  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public filterBy: string; //field to filter the grid's data by.

  @Input() public dataFilterField: string; //To filter the data for this filter dropdown in a cascade scenario
  @Input() public dataFilterValue: string; //To filter the data for this filter dropdown in a cascade scenario

  @Input() public childFilterByField: string; //To reset a child filter dropdown after parent dropdown filter changes in a cascade scenario


  public get _data(): any[]
  {
    if (!this.dataFilterField || !this.dataFilterValue)
    {
      //return all the data provided to the dropdown
      return this.data;
    }
    else
    {
      const predicate = compileFilter({
        logic: "and",
        filters: [{
          field: this.dataFilterField,
          operator: "eq",
          value: this.dataFilterValue
        }]
      });

      return this.data.filter(predicate);
    }
  }


  public get defaultItem(): any
  {
    return {
      [this.textField]: "All",
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService)
  {
    super(filterService);
  }

  public onChange(value: any): void
  {
    const ddlValue: any = value;

    if (this.childFilterByField)
    {
      //reset child filter dropdown value after parent filter has changed.
      this.removeFilter(this.childFilterByField);
    }

    this.applyFilter(
      (ddlValue === null || ddlValue === undefined) ? // value of the default item
        this.removeFilter(this.filterBy) : // remove the filter 
        this.updateFilter({ // add a filter for the field with the value
          field: this.filterBy,
          operator: "eq",
          value: ddlValue
        })
    ); // update the root filter

    this.filterSelectionChange.emit(value);
  }
}
