import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogActions } from '@angular/material/dialog';
import { MatTableCrudEditComponent } from "../../../shared/components/mat-table-crud/mat-table-crud-edit.component";
import { MatTableCrudService } from "../../../shared/components/mat-table-crud/mat-table-crud.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { PropertyType, Station } from "../../calendars/calendars.models";
import { CalendarsService } from "../../calendars/calendars.service";
import { NotifyGroup, NotifyGroupMember } from "../administration.models";
import { AdministrationService } from '../administration.service';
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";

import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";


@Component({
    templateUrl: './notify-group-member-edit.component.html',
    selector: 'notify-group-member-edit-form',
    styleUrls: ['../../../shared/components/mat-table-crud/mat-table-crud.component.scss', './notify-group-members.component.scss'],
    providers: [MatTableCrudService],
    standalone: true,
    imports: [CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatCheckboxModule, MatDialogActions, MatButtonModule, GenericFilterPipe]
})
export class NotifyGroupMemberEditComponent extends MatTableCrudEditComponent<NotifyGroupMemberEditComponent, NotifyGroupMember>
{

  constructor(public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NotifyGroupMemberEditComponent>, @Inject(MAT_DIALOG_DATA)
    public data: NotifyGroupMember,
    public administrationService: AdministrationService,
    public authorisationService: AuthorisationService,
    public calendarsService: CalendarsService,
    public loggerService: LoggerService)
  {
    super(dialogRef, data);

    this.form = this.fb.group({
      Id: [this.data.Id],
      Email: [this.data.Email, Validators.compose([Validators.email])],
      Mobile: [this.data.Mobile, Validators.compose([Validators.pattern('(?=.*[0-9])[- +()0-9]+')])],
      Station: [this.data.Station, Validators.required],
      PropertyType: [this.data.PropertyType],
      NotifyGroup: [this.data.NotifyGroup, Validators.required],
      Disabled: [this.data.Disabled]
    });

  }

  ngOnInit()
  {
    super.ngOnInit();

    this.setStationsValidation();
  }

  onSave(): void
  {
    const notifyGroupMember: any = this.form.value;

    if (notifyGroupMember)
    {
      if (this.isNew)
      {
        notifyGroupMember.Id = 0;

        if (notifyGroupMember.Disabled == null)
        {
          notifyGroupMember.Disabled = false;
        }
      }

      notifyGroupMember[this.changedByField as keyof typeof notifyGroupMember] = this.authorisationService.currentuser.Id;

      super.onSave(notifyGroupMember);
    }
  }

  notificationGroupChange(event: any)
  {
    this.setStationsValidation();
  }

  setStationsValidation()
  {
    if (this.isNotifyTypeInterfacing)
    {
      this.form.get('Station').clearValidators();
    }
    else
    {
      this.form.get('Station').setValidators(Validators.required)
    }

    this.form.get('Station').updateValueAndValidity({ emitEvent: false });
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Notification Group Member";

    if (this.isNew)
    {
      headerText = "Add Notification Group Member";
    }
    else
    {
      if (this.showEditItemNameInHeaderText)
      {
        let station: string = "";

        if (this.data && this.data.Station)
        {
          station = `${this.data.Station.StationName}/`;
        }

        headerText = `${headerText} - ${station}${this.data.NotifyGroup.Name}`;
      }
    }

    return headerText;
  }

  get isNotifyTypeInterfacing(): boolean
  {
    const ng: NotifyGroup = this.form.get('NotifyGroup').value;

    if (ng && ng.NotifyGroupType)
    {
      return ng?.NotifyGroupType?.Id == 7;
    }

    return this.data?.NotifyGroupType?.Id == 7;
  }

  equalsStation(o1: Station, o2: Station)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsPropertyType(o1: PropertyType, o2: PropertyType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  equalsNotifyGroup(o1: NotifyGroup, o2: NotifyGroup)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  trackByPropertyTypes(index: number, propertyType: PropertyType)
  {
    return propertyType["Id"];
  }


  ngOnDestroy()
  {
    super.ngOnDestroy();

  }



}
