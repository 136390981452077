import { Injectable } from '@angular/core';
import { CalendarsBaseService } from './calendars-base.service';

@Injectable({ providedIn: 'root' })

export class CalendarsService extends CalendarsBaseService
{
  protected isOnline = false;

  constructor()
  {
    super();
  }






}







