import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output, VERSION } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { CreditsInfo } from '../../../components/calendars/calendars.models';
import { AirtimeCreditsService } from './airtime-credits.service';
import { NgFor } from '@angular/common';

@Component({
  selector: 'airtime-credits',
  templateUrl: './airtime-credits.component.html',
  styleUrls: ['./airtime-credits.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AirtimeCreditsComponent),
      multi: true
    },
    AirtimeCreditsService
  ],
  standalone: true,
  imports: [NgFor, MatInputModule, FormsModule]
})
export class AirtimeCreditsComponent implements OnInit, ControlValueAccessor
{
  public creditMaxValue = 99;

  private _isDisabled = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  version = VERSION;

  @Input() get value(): CreditsInfo[]
  {
    return this.airtimeCreditsService.airtimeCredits;
  }
  set value(val: CreditsInfo[])
  {
    this.airtimeCreditsService.airtimeCredits = val;

    if (this.propagateChange)
    {
      this.propagateChange(val);
    }
  }


  @Input() get isDisabled(): boolean
  {
    return this._isDisabled;
  }
  set isDisabled(val: boolean)
  {
    this._isDisabled = val;
  }

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private propagateChange = (value: CreditsInfo[]) => { };

  constructor(public airtimeCreditsService: AirtimeCreditsService, private cdr: ChangeDetectorRef)
  {

  }

  ngOnInit(): void
  {
    if (!this.value)
    {
      this.airtimeCreditsService.airtimeCredits = [];
    }
  }

  ngOnDestroy()
  {

  }


  ngOnChanges(inputs: any): void
  {
    if (this.value)
    {
      if (this.propagateChange)
      {
        this.propagateChange(this.value);
      }

      this.cdr.detectChanges();
    }

  }

  inputValueChanged(event: any)
  {
    this.valueChange.emit(this.value);

    if (this.propagateChange)
    {
      this.propagateChange(this.value);
    }

    if (this.onTouchedCallback)
    {
      this.onTouchedCallback();
    }
  }



  writeValue(value: any): void
  {
    if (value)
    {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void
  {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(disabled: boolean): void
  {
    this._isDisabled = disabled
  }



}
