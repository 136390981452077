<kendo-tilelayout-item [id]="id" [col]="col" [row]="row" [colSpan]="colspan" [rowSpan]="rowspan" style="height:100%; width:100%">
  @if (processing)
  {
  <kendo-skeleton shape="rectangle" animation="pulse" width="calc(100% - 20px)" height="100%" style="margin: 10px"></kendo-skeleton>
  }
  @else
  {
  <kendo-tilelayout-item-header>
    <h5 class="header-text">
      {{title}}
    </h5>
  </kendo-tilelayout-item-header>
  <kendo-tilelayout-item-body class="dashboard-card-content">
    <ul class="todo-list scrollbar-cb">
      @for (activity of activitiesList; track activity.Id)
      {
      <li>
        <span (click)="activityClick($event, activity.Id)" (mouseenter)="activityHover($event, activity.Id)" class="todo-list-item-content" title="{{activity?.ListDate?.toLocaleDateString()}} | {{activity.ClientName}} {{activity.Product}}">
          <fa-icon [icon]="faIcon" class="todo-list-icon"></fa-icon><span>{{activity?.ListDate?.toLocaleDateString()}} | {{activity.ClientName}} {{activity.Product}}</span>
        </span>
      </li>
      }
    </ul>
  </kendo-tilelayout-item-body>
  }
</kendo-tilelayout-item>
