import { Injectable, Signal, computed } from '@angular/core';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { Observable } from 'rxjs';
import { Department, Role, User } from '../../shared/models/common.models';
import { SaveResult } from '../../shared/models/result.model';
import { CommonService } from '../../shared/services/common.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { DataHandlerService } from '../../shared/services/data-handler.service';
import { ContactType, Extension, ListItem, PlatformType, Station, StationType } from '../calendars/calendars.models';
import { AccessType, JobAction, JobDocumentType, JobType, NotifyGroup, NotifyGroupType, OnlineDependencyType, Permission } from './administration.models';




@Injectable({ providedIn: 'root' })

export class AdministrationService
{
  private _accessTypes: AccessType[] = [];
  private _permissions: Permission[] = [];
  private _stations: Station[] = null;
  private _notifyGroups: NotifyGroup[] = null;
  private _notifyGroupTypes: NotifyGroupType[] = null;
  private _supervisors: User[] = null;
  private _roles: Role[] = null;
  private _departments: Department[] = null;
  private _extensions: Extension[] = [];
  private _contactTypes: ContactType[] = [];
  private _onlineDependencyTypes: OnlineDependencyType[] = null;
  private _stationTypes: StationType[] = null;
  private _jobDocumentTypes: JobDocumentType[] = null;
  private _jobTypes: JobType[] = null;
  private _jobActions: JobAction[] = null;


  public statusCodes: ListItem[] = [{ Id: 5, Name: 'On Hold' }, { Id: 2, Name: 'Pending' }, { Id: 8, Name: 'Submitted For Delivery' }, { Id: 12, Name: 'First Right of Refusal' }, { Id: 15, Name: 'Confirmed' }];

  constructor(public commonService: CommonService, public configurationService: ConfigurationService, public dataHandler: DataHandlerService)
  {

  }

  public platformTypes: Signal<PlatformType[]> = computed(() =>
  {
    const result: any = this.gueryPlatformTypes.data();

    return result;
  });

  public gueryPlatformTypes = injectQuery(() =>
  ({
    enabled: this.commonService.isLoggedIn() && this.configurationService.cbSettings().calendarServiceUrl.length > 0,
    queryKey: ["PlatformTypes"],
    queryFn: () => this.getPlatformTypes(),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  resetNotifyGroups()
  {
    this._notifyGroups = [];
  }

  get notifyGroups(): NotifyGroup[]
  {
    return this._notifyGroups;
  }
  set notifyGroups(value: NotifyGroup[])
  {
    this._notifyGroups = value;
  }

  get notifyGroupTypes(): NotifyGroupType[]
  {
    return this._notifyGroupTypes;
  }
  set notifyGroupTypes(value: NotifyGroupType[])
  {
    this._notifyGroupTypes = value;
  }

  get stations(): Station[]
  {
    return this._stations;
  }
  set stations(value: Station[])
  {
    this._stations = value;
  }

  get supervisors(): User[]
  {
    return this._supervisors;
  }
  set supervisors(value: User[])
  {
    this._supervisors = value;
  }

  get roles(): Role[]
  {
    return this._roles;
  }
  set roles(value: Role[])
  {
    this._roles = value;
  }

  get departments(): Department[]
  {
    return this._departments;
  }
  set departments(value: Department[])
  {
    this._departments = value;
  }


  get onlineDependencyTypes(): OnlineDependencyType[]
  {
    return this._onlineDependencyTypes;
  }
  set onlineDependencyTypes(value: OnlineDependencyType[])
  {
    this._onlineDependencyTypes = value;
  }

  get contactTypes(): ContactType[]
  {
    return this._contactTypes;
  }
  set contactTypes(value: ContactType[])
  {
    this._contactTypes = value;
  }


  get stationTypes(): StationType[]
  {
    return this._stationTypes;
  }
  set stationTypes(value: StationType[])
  {
    this._stationTypes = value;
  }

  get accessTypes(): AccessType[]
  {
    return this._accessTypes;
  }
  set accessTypes(value: AccessType[])
  {
    this._accessTypes = value;
  }

  get extensions(): Extension[]
  {
    return this._extensions;
  }
  set extensions(value: Extension[])
  {
    this._extensions = value;
  }

  get permissions(): Permission[]
  {
    return this._permissions;
  }
  set permissions(value: Permission[])
  {
    this._permissions = value;
  }

  get jobDocumentTypes(): JobDocumentType[]
  {
    return this._jobDocumentTypes;
  }
  set jobDocumentTypes(value: JobDocumentType[])
  {
    this._jobDocumentTypes = value;
  }

  get jobTypes(): JobType[]
  {
    return this._jobTypes;
  }
  set jobTypes(value: JobType[])
  {
    this._jobTypes = value;
  }

  get jobActions(): JobAction[]
  {
    return this._jobActions;
  }
  set jobActions(value: JobAction[])
  {
    this._jobActions = value;
  }


  getAccessTypes(useCachedData: boolean = false): Observable<AccessType[]>
  {
    return this.dataHandler.getHttpObservable<AccessType[]>(this.configurationService.cbSettings().authorisationServiceUrl + "/GetAccessTypes", useCachedData, false);
  }

  getPermissions(useCachedData: boolean = false): Observable<Permission[]>
  {
    return this.dataHandler.getHttpObservable<Permission[]>(this.configurationService.cbSettings().authorisationServiceUrl + "/GetPermissions", useCachedData, false);
  }

  getNotifyGroups(useCachedData: boolean = false): Observable<NotifyGroup[]>
  {
    return this.dataHandler.getHttpObservable<NotifyGroup[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetNotifyGroups", useCachedData, false);
  }

  getNotifyGroupTypes(useCachedData: boolean = false): Observable<NotifyGroupType[]>
  {
    return this.dataHandler.getHttpObservable<NotifyGroupType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetNotifyGroupTypes", useCachedData, false);
  }

  getStations(useCachedData: boolean = false): Observable<Station[]>
  {
    return this.dataHandler.getHttpObservable<Station[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetStations", useCachedData, false);
  }

  getSupervisors(useCachedData: boolean = false): Observable<User[]>
  {
    return this.dataHandler.getHttpObservable<User[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetSupervisors", useCachedData, false);
  }

  getRoles(useCachedData: boolean = false): Observable<Role[]>
  {
    return this.dataHandler.getHttpObservable<Role[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetRoles", useCachedData, false);
  }

  getDepartments(useCachedData: boolean = false): Observable<Department[]>
  {
    return this.dataHandler.getHttpObservable<Department[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetDepartments", useCachedData, false);
  }

  getContactTypes(useCachedData: boolean = false): Observable<ContactType[]>
  {
    return this.dataHandler.getHttpObservable<ContactType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetContactTypes", useCachedData, false);
  }

  getExtensions(useCachedData: boolean = false): Observable<Extension[]>
  {
    return this.dataHandler.getHttpObservable<Extension[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetExtensions", useCachedData, false);
  }

  getOnlineDependencyTypes(useCachedData: boolean = false): Observable<OnlineDependencyType[]>
  {
    return this.dataHandler.getHttpObservable<OnlineDependencyType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetOnlineDependencyTypes", useCachedData, false);
  }

  getStationTypes(useCachedData: boolean = false): Observable<StationType[]>
  {
    return this.dataHandler.getHttpObservable<StationType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetStationTypes", useCachedData, false);
  }

  getJobDocumentTypes(useCachedData: boolean = false): Observable<JobDocumentType[]>
  {
    return this.dataHandler.getHttpObservable<JobDocumentType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetJobDocumentTypes", useCachedData, false);
  }

  getJobTypes(useCachedData: boolean = false): Observable<JobType[]>
  {
    return this.dataHandler.getHttpObservable<JobType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetJobTypes", useCachedData, false);
  }

  getJobActions(useCachedData: boolean = false): Observable<JobAction[]>
  {
    return this.dataHandler.getHttpObservable<JobAction[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetJobActions", useCachedData, false);
  }

  getPlatformTypes(useCachedData: boolean = false): Promise<PlatformType[]>
  {
    return this.dataHandler.getHttpPromise<PlatformType[]>(this.configurationService.cbSettings().adminServiceUrl + "/GetPlatformTypes", useCachedData, false);
  }



  lockUnlockUser(user: User): Promise<SaveResult>
  {
    return this.dataHandler.putHttpPromise<SaveResult>(this.configurationService.cbSettings().adminServiceUrl + "/LockUnlockUser", user, false);
  }



}
