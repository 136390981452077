<kendo-grid [data]="view | async"
  [ngClass]="{'grid': !gridIsChild, 'kendo-child-grid': gridIsChild}"
  [pageSize]="gridState.take"
  [pageable]="true"
  [size]="'none'"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{mode: 'multiple'}"
  [filter]="gridState.filter"
  [filterable]="true"
  [virtualColumns]="true"
  (dataStateChange)="onStateChange($event)"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler()">
  <ng-template kendoGridToolbarTemplate>
    @if (CanAdd) {
      <button kendoGridAddCommand size="small" rounded="medium" fillMode="solid" [disabled]="saveInProgress" style="position:absolute">Add new</button>
    }
    @if (CanExport) {
      <button kendoGridExcelCommand size="small" rounded="medium" fillMode="solid" [disabled]="(!kendoGridService.serverPaging && (!gridAllData || gridAllData.length===0)) || saveInProgress"><kendo-svg-icon style=" margin-right: 5px; margin-bottom: 1px;" [icon]="icons.excel"></kendo-svg-icon>Export to Excel</button>
    }
  </ng-template>
  <kendo-grid-column field="Id" title="Id" width="100px" [editable]="false" [locked]="true">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-numerictextbox-filter [filter]="filter"
        filterBy="Id">
      </kendo-numerictextbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.Id}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="BookingId" title="Booking Id" width="110px" [locked]="true">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-textbox-filter [filter]="filter"
        filterBy="BookingId">
      </kendo-textbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.BookingId}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Name" title="Name" width="200px" [locked]="true">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-textbox-filter [filter]="filter"
        filterBy="Name">
      </kendo-textbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.Name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ClientName" title="Client" width="225px">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-textbox-filter [filter]="filter"
        filterBy="ClientName">
      </kendo-textbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.ClientName}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Product" title="Product" width="225px">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-textbox-filter [filter]="filter"
        filterBy="Product">
      </kendo-textbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.Product}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ClientExecName" title="{{commonDataService.clientExecutiveLabelText}}" width="150px">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-textbox-filter [filter]="filter"
        filterBy="ClientExecName">
      </kendo-textbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.ClientExecName}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ClientSupportName" title="{{commonDataService.clientSupportLabelText}}" width="150px">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-textbox-filter [filter]="filter"
        filterBy="ClientSupportName">
      </kendo-textbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.ClientSupportName}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Created" title="Creation Date" width="155px" filter="date" format="{0:d}">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.Created | date }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Disabled" headerClass="kendo-grid-header-center" class="kendo-grid-column-center" title="Disabled" width="80px">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <kendo-checkbox-filter [filter]="filter"
        filterBy="Disabled">
      </kendo-checkbox-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" kendoCheckBox size="large" rounded="medium" [checked]="dataItem.Disabled" disabled />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" width="100px">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div style="width: 100%; white-space: nowrap;">
        @if (CanEdit) {
          <button mat-icon-button kendoGridEditCommand title="Edit" color="accent" style="color: #75acff; width: 50%" [disabled]="saveInProgress">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>
        }
        @if (CanDelete) {
          <button mat-icon-button kendoGridRemoveCommand title="Remove" color="accent" style="color: #75acff; width: 50%;" [disabled]="saveInProgress">
            <mat-icon aria-label="Remove">delete</mat-icon>
          </button>
        }
      </div>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-excel fileName="Campaigns.xlsx" [fetchData]="exportData"></kendo-grid-excel>
  <ng-template kendoPagerTemplate
    let-total="total"
    let-pageSize="pageSize"
    let-currentPage="currentPage">
    <mat-paginator #paginator
      [length]="total"
      [pageIndex]="currentPage-1"
      [pageSize]="pageSize"
      [pageSizeOptions]="kendoGridService.pagerPageSizes"
      showFirstLastButtons="true"
      (page)="page($event)">
    </mat-paginator>
  </ng-template>
</kendo-grid>
<campaign-edit-form [model]="editDataItem" [isNew]="isNew"
  (save)="saveHandler($event)"
  (cancel)="cancelHandler()">
</campaign-edit-form>
