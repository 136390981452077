import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivityExtension } from '../calendars.models';


@Injectable()

export class ActivityExtensionService extends BehaviorSubject<ActivityExtension[]>
{
  constructor()
  {
    super([]);
  }

  private _activityExtensions: ActivityExtension[];

  get activityExtensions(): ActivityExtension[]
  {
    return this._activityExtensions;
  }
  set activityExtensions(value: ActivityExtension[])
  {
    this._activityExtensions = value;

    this.next(this.activityExtensions);
  }


  save(item: ActivityExtension, isNew: boolean, rowIndex: number)
  {
    if (isNew)
    {
      this._activityExtensions.push(item);
    }
    else
    {
      const index = this.activityExtensions.findIndex((activtyExtension: ActivityExtension) => activtyExtension.Id == item.Id);

      if (index > -1)
      {
        this.activityExtensions[index] = item;
      }
    }

    this.next(this.activityExtensions);
  }

  remove(item: ActivityExtension, rowIndex: number)
  {
    const index = this.activityExtensions.findIndex((activtyExtension: ActivityExtension) => activtyExtension.Id == item.Id);

    if (index > -1)
    {
      this.activityExtensions.splice(index, 1);
    }

    this.next(this.activityExtensions);
  }


}
