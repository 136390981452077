<div class="recurrence-control" [formGroup]="form">
  <div class="recurrence-header">
    <h1 mat-dialog-title class="dialog-header">Activity Recurrence</h1>
  </div>
  <div class="recurrence-content">
    <div class="recurrence-pattern">
      <fieldset>
        <legend>Recurrence pattern</legend>
        <div>
          <div class="pattern-content">
            <mat-radio-group class="pattern-radio-group" formControlName="RecurrenceType" (change)="recurrenceTypeChanged($event)">
              <mat-radio-button class="pattern-radio-button" [value]="0" [checked]="form.get('RecurrenceType').value===RecurrenceTypeNone">None</mat-radio-button>
              <mat-radio-button class="pattern-radio-button" [value]="1" [checked]="form.get('RecurrenceType').value===RecurrenceTypeDaily">Daily</mat-radio-button>
              <mat-radio-button class="pattern-radio-button" [value]="2" [checked]="form.get('RecurrenceType').value===RecurrenceTypeWeekly">Weekly</mat-radio-button>
              <mat-radio-button class="pattern-radio-button" [value]="3" [checked]="form.get('RecurrenceType').value===RecurrenceTypeMonthly">Monthly</mat-radio-button>
              <mat-radio-button class="pattern-radio-button" [value]="4" [checked]="form.get('RecurrenceType').value===RecurrenceTypeYearly">Yearly</mat-radio-button>
            </mat-radio-group>
            <div>
              @if (form.get('RecurrenceType').value===RecurrenceTypeNone) {
                <div>
                </div>
              }
              @if (form.get('RecurrenceType').value===RecurrenceTypeDaily) {
                <div>
                  <mat-radio-group class="vertical-radio-group">
                    <mat-radio-button class="vertical-radio-button" [value]="1" [checked]="!form.get('EveryWeekDay').value" (change)="dailySelectionChanged($event)">
                      <div>
                        Every
                        <mat-form-field style="width:6.25rem">
                          <input matInput (focus)="$event.target.select()" placeholder="" formControlName="Interval" min="1" type="number" class="numeric-inputs" autocomplete="off" />
                          <span matSuffix>&nbsp;day(s)</span>
                        </mat-form-field>
                      </div>
                    </mat-radio-button>
                    <mat-radio-button class="vertical-radio-button" [value]="2" [checked]="form.get('EveryWeekDay').value" (change)="dailySelectionChanged($event)">Every weekday</mat-radio-button>
                  </mat-radio-group>
                </div>
              }
              @if (form.get('RecurrenceType').value===RecurrenceTypeWeekly) {
                <div>
                  Recur every
                  <mat-form-field style="width:8.5rem">
                    <input matInput (focus)="$event.target.select()" placeholder="" formControlName="Interval" min="1" type="number" class="numeric-inputs" autocomplete="off" />
                    <span matSuffix>&nbsp;week(s) </span>
                  </mat-form-field>
                  <div>
                    <mat-label>Repeat on</mat-label>
                    <selectable-chips [data]="commonDataService.daysInTheWeek" formControlName="SelectedDaysInWeek" textField="FirstLetter" valueField="Value" titleField="Name">
                    </selectable-chips>
                  </div>
                </div>
              }
              @if (form.get('RecurrenceType').value===RecurrenceTypeMonthly) {
                <div>
                  Recur every
                  <mat-form-field style="width:8rem">
                    <input matInput (focus)="$event.target.select()" placeholder="" formControlName="Interval" min="1" type="number" class="numeric-inputs" autocomplete="off" />
                    <span matSuffix>&nbsp;month(s)</span>
                  </mat-form-field>
                  <mat-radio-group class="vertical-radio-group" formControlName="OnSpecificDayOfMonth">
                    <mat-radio-button class="vertical-radio-button" [value]="true" [checked]="form.get('OnSpecificDayOfMonth').value" (change)="monthlySelectionChanged($event)">
                      <div>
                        On day {{ form.get('DayOfMonth').value }}
                      </div>
                    </mat-radio-button>
                    <mat-radio-button class="vertical-radio-button" [value]="false" [checked]="!form.get('OnSpecificDayOfMonth').value" (change)="monthlySelectionChanged($event)">
                      <div>
                        On the {{ commonDataService.dayInMonthInstance[form.get('Instance').value - 1] }} {{ dayOfWeekPatternStartDate?.Name }}
                      </div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              }
              @if (form.get('RecurrenceType').value===RecurrenceTypeYearly) {
                <div>
                  <div>
                    Recur every
                    <mat-form-field style="width:7rem">
                      <input matInput (focus)="$event.target.select()" placeholder="" formControlName="Interval" min="1" type="number" class="numeric-inputs" autocomplete="off" />
                      <span matSuffix>&nbsp;year(s)</span>
                    </mat-form-field>
                    <mat-radio-group class="vertical-radio-group" formControlName="OnSpecificDayOfMonth">
                      <mat-radio-button class="vertical-radio-button" [value]="true" [checked]="form.get('OnSpecificDayOfMonth').value" (change)="yearlySelectionChanged($event)">
                        <div>
                          On day {{ form.get('DayOfMonth').value }} of {{ commonDataService.months[form.get('MonthOfYear').value - 1] }}
                        </div>
                      </mat-radio-button>
                      <mat-radio-button class="vertical-radio-button" [value]="false" [checked]="!form.get('OnSpecificDayOfMonth').value" (change)="yearlySelectionChanged($event)">
                        <div>
                          On the {{ commonDataService.dayInMonthInstance[form.get('Instance').value - 1] }} {{ dayOfWeekPatternStartDate?.Name }} of {{ commonDataService.months[form.get('MonthOfYear').value - 1] }}
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    @if (form.get('RecurrenceType').value!==RecurrenceTypeNone) {
      <div class="recurrence-range">
        <fieldset>
          <legend>Range of recurrence</legend>
          <div>
            <mat-form-field style="width: 125px; margin-right: 25px; height: 75px;">
              <mat-label>Start</mat-label>
              <input matInput [matDatepicker]="sdatepicker" formControlName="PatternStartDate" placeholder="Start" (dateChange)="startDateChange($event)" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
              <mat-datepicker #sdatepicker startView="month"></mat-datepicker>
            </mat-form-field>
            <div>
              <mat-radio-group class="vertical-radio-group">
                <mat-radio-button class="vertical-radio-button" [value]="1" [checked]="form.get('NoEndDate').value" (change)="rangeTypeChanged($event)">
                  No end Date
                </mat-radio-button>
                <mat-radio-button class="vertical-radio-button" [value]="2" [checked]="form.get('EndAfter').value" (change)="rangeTypeChanged($event)">
                  <div>
                    End after
                    <mat-form-field style="width:10rem">
                      <input matInput (focus)="$event.target.select()" placeholder="" formControlName="Occurrences" min="1" type="number" class="numeric-inputs" (change)="occurrenceChange($event)" autocomplete="off" />
                      <span matSuffix>&nbsp;occurrences</span>
                    </mat-form-field>
                  </div>
                </mat-radio-button>
                <mat-radio-button class="vertical-radio-button" [value]="3" [checked]="form.get('EndBy').value" (change)="rangeTypeChanged($event)">
                  <mat-form-field style="width:125px">
                    <mat-label>End By</mat-label>
                    <input matInput [matDatepicker]="edatepicker" formControlName="PatternEndDate" placeholder="End By" (dateChange)="endDateChange($event)" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="edatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #edatepicker startView="month"></mat-datepicker>
                  </mat-form-field>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </fieldset>
      </div>
    }
  </div>
  <div class="recurrence-footer">
    <hr style="margin-top: 0.2rem; margin-bottom: 0.75rem;" />
    <mat-dialog-actions>
      <div class="button-row">
        <button mat-button (click)="onCancelClick()">Close</button>
        <button mat-button color="primary" [disabled]="!form.valid || !form.dirty" (click)="onSave()">Save</button>
      </div>
    </mat-dialog-actions>
  </div>
</div>

