import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'dashboard-number',
    templateUrl: './dashboard-number.component.html',
    styleUrls: ['./dashboard-number.component.scss', '../dashboard.component.scss'],
    providers: [],
    standalone: true,
    imports: [MatCardModule]
})
export class DashboardNumberComponent implements OnInit
{

  @Input() public title: string = "";
  @Input() public label: string = "";
  @Input() public owner: string = "";
  @Input() public colour: string = "";
  @Input() public number: number = 0;
 

  constructor()
  {
  }


  ngOnInit(): void
  {

  }

  
  ngOnDestroy()
  {

  }







}
