<form novalidate #adminForm="ngForm">
  <kendo-grid [data]="view | async"
              [ngClass]="{'grid': !gridIsChild, 'kendo-child-grid': gridIsChild}"
              [pageSize]="gridState.take"
              [pageable]="true"
              [size]="'none'"
              [skip]="gridState.skip"
              [sort]="gridState.sort"
              [sortable]="{mode: 'multiple'}"
              [filter]="gridState.filter"
              [filterable]="true"
              [virtualColumns]="true"
              [scrollable]="true"
              (dataStateChange)="onStateChange($event)"
              (cancel)="cancelHandler($event)"
              (save)="saveHandler($event)"
              (edit)="editHandler($event)"
              (remove)="removeHandler($event)"
              (add)="addHandler($event)">
    <ng-template kendoGridToolbarTemplate>
      @if (CanAdd)
      {
      <button kendoGridAddCommand size="small" rounded="medium" fillMode="solid" [disabled]="saveInProgress">Add new</button>
      }
      @if (CanExport)
      {
      <button type="button" kendoGridExcelCommand size="small" rounded="medium" fillMode="solid" [disabled]="(!kendoGridService.serverPaging && (!gridAllData || gridAllData.length===0)) || saveInProgress"><kendo-svg-icon style=" margin-right: 5px; margin-bottom: 1px;" [icon]="icons.excel"></kendo-svg-icon>Export to Excel</button>
      }
    </ng-template>
    <kendo-grid-column field="Id" title="Id" width="90px" [locked]="true">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-numerictextbox-filter [filter]="filter"
                                     filterBy="Id">
        </kendo-numerictextbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.Id}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="CategoryName" title="Category" width="110px" [locked]="true">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="CategoryName">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.CategoryName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="PropertyName" title="Property" width="110px" [locked]="true">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="PropertyName"
                              filterById="PropertyId">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.PropertyName}} - {{dataItem.PropertyId}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="PropertyTypeName" title="Property Type" width="105px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="PropertyTypeName">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.PropertyTypeName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ClientName" title="Client" width="115px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="ClientName">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.ClientName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Product" title="Product" width="115px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="Product">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.Product}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="StatusCode" title="Status" width="100px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-dropdown-filter [filter]="filter"
                               [data]="administrationService.statusCodes"
                               [textField]="'Name'"
                               [valueField]="'Id'"
                               filterBy="StatusCode">
        </kendo-dropdown-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.StatusName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Stations" title="Platform Provider" width="120px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="Stations">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.Stations}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ClientExecName" title="{{commonDataService.clientExecutiveLabelText}}" width="120px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="ClientExecName">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.ClientExecName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ClientSupportName" title="{{commonDataService.clientSupportLabelText}}" width="120px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-textbox-filter [filter]="filter"
                              filterBy="ClientSupportName">
        </kendo-textbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.ClientSupportName}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="StartDate" title="First Date" width="155px" filter="date" format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.StartDate | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="EndDate" title="Last Date" width="155px" filter="date" format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.EndDate | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="CreationDate" title="Creation Date" width="155px" filter="date" format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.CreationDate | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Disabled" headerClass="kendo-grid-header-center" class="kendo-grid-column-center" title="Disabled" width="80px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-checkbox-filter [filter]="filter"
                               filterBy="Disabled">
        </kendo-checkbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.Disabled ? "Disabled" : "Enabled"}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="FocStations" headerClass="kendo-grid-header-center" class="kendo-grid-column-center" title="FOC Stations" width="80px">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <kendo-checkbox-filter [filter]="filter"
                               filterBy="HasFoc">
        </kendo-checkbox-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.FocStations }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="" width="50px">
      <ng-template kendoGridCellTemplate let-dataItem>
        @if (CanEdit)
        {
        <button mat-icon-button color="accent" (click)="editItem(dataItem)" (mouseenter)="activityHover($event, dataItem.Id)" title="edit">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
        }
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-excel fileName="ActivitiesSearch.xlsx" [fetchData]="exportData"></kendo-grid-excel>
    <ng-template kendoPagerTemplate
                 let-total="total"
                 let-pageSize="pageSize"
                 let-currentPage="currentPage">
      <mat-paginator #paginator
                     [length]="total"
                     [pageIndex]="currentPage-1"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="kendoGridService.pagerPageSizes"
                     showFirstLastButtons="true"
                     (page)="page($event)">
      </mat-paginator>
    </ng-template>
  </kendo-grid>
</form>

