<div class="audit-control" [formGroup]="form">
  <div class="audit-header">
    <div class="form-group">
      <mat-form-field>
        <mat-select formControlName="SearchType" placeholder="Audit For">
          <mat-option [value]="1">Activities</mat-option>
          <mat-option [value]="2">Properties</mat-option>
          <mat-option [value]="3">Tools</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="audit-content">
    @if (form.get('SearchType').value===1) {
      <audit-activities class="audit-content-control"></audit-activities>
    }
    @if (form.get('SearchType').value===2) {
      <audit-properties class="audit-content-control"></audit-properties>
    }
    @if (form.get('SearchType').value===3) {
      <audit-tools class="audit-content-control"></audit-tools>
    }
  </div>
  <div class="audit-footer">
  </div>
</div>
