import { Route } from '@angular/router';
import { ContactTypesResolver } from '../../shared/resolvers/contact-types.resolver';
//import { DashboardResolver } from '../../shared/resolvers/dashboard.resolver';
import { ExtensionsResolver } from '../../shared/resolvers/extensions.resolver';
import { JobActionsResolver } from '../../shared/resolvers/job-actions.resolver';
import { JobDocumentTypesResolver } from '../../shared/resolvers/job-document-type.resolver';
import { JobTypesResolver } from '../../shared/resolvers/job-types.resolver';
import { NotifyGroupTypesResolver } from '../../shared/resolvers/notify-group-types.resolver';
import { NotifyGroupsResolver } from '../../shared/resolvers/notify-groups.resolver';
import { PermissionsResolver } from '../../shared/resolvers/permissions.resolver';
import { RolesResolver } from '../../shared/resolvers/roles.resolver';
import { StationTypesAdminResolver } from '../../shared/resolvers/station-types-admin.resolver';
import { StationsAdminResolver } from '../../shared/resolvers/stations-admin.resolver';
import { SupervisorsResolver } from '../../shared/resolvers/supervisors.resolver';
import { ClientsComponent } from '../administration/clients/clients.component';
import { ContactTypesComponent } from '../administration/contact-types/contact-types.component';
import { ControlPanelComponent } from '../administration/control-panel/control-panel.component';
import { DayPartsComponent } from '../administration/dayparts/dayparts.component';
import { EventCategoriesComponent } from '../administration/event-categories/event-categories.component';
import { ExtensionsComponent } from '../administration/extensions/extensions.component';
import { JobsComponent } from '../administration/jobs/jobs.component';
import { MechanicTypesComponent } from '../administration/mechanic-types/mechanic-types.component';
import { NotifyGroupMembersComponent } from '../administration/notify-group-members/notify-group-members.component';
import { NotifyGroupsComponent } from '../administration/notify-groups/notify-groups.component';
import { ProductCategoriesComponent } from '../administration/product-categories/product-categories.component';
import { PropertyTypesComponent } from '../administration/property-types/property-types.component';
import { RolesComponent } from '../administration/roles/roles.component';
import { StationTypesComponent } from '../administration/station-types/station-types.component';
import { StationsComponent } from '../administration/stations/stations.component';
import { UsersComponent } from '../administration/users/users.component';
import { AuditComponent } from '../audit/audit.component';
import { CalendarComponent } from '../calendars/calendar/calendar.component';
import { CampaignsComponent } from '../campaigns/campaigns.component';
import { DiaryComponent } from '../diary/diary.component';
import { HomeComponent } from '../home/home.component';
import { SigninRedirectCallbackComponent } from '../home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from '../home/signout-redirect-callback.component';
import { UnauthorisedComponent } from '../home/unauthorised.component';
import { LoginComponent } from '../login/login.component';
import { ReportComponent } from '../reports/report.component';
import { SearchComponent } from '../search/search.component';
import { CopyCreditLinesComponent } from '../tools/copy-credit-lines.component/copy-credit-lines.component';
import { CopyPropertyInfoComponent } from '../tools/copy-property-info.component/copy-property-info.component';
import { ReplaceContactsComponent } from '../tools/replace-contacts.component/replace-contacts.component';
import { AuthGuard } from './auth.guard';





export const appRoutes: ClientRoutes = [
  {
    name: 'Campaigns',
    text: 'Campaigns',
    category: 'Campaigns',
    path: 'campaigns',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: false,
    component: CampaignsComponent,
    resolve: {
      p: ExtensionsResolver
    }
  },
  {
    name: 'Audio',
    text: 'Audio',
    category: 'Planners',
    path: 'calendar',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: true,
    component: CalendarComponent,
    resolve: {
      p: ExtensionsResolver
    }
  },
  //{ name: 'Digital', text: 'Digital', category: 'Planners', path: 'onlinecalendar', component: OnlineCalendarComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  {
    name: 'WhatsOn',
    text: 'What\'s On',
    category: 'Planners',
    path: 'diary',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: true,
    component: DiaryComponent,
    resolve: {
      p: ExtensionsResolver
    }
  },
  {
    name: 'Search',
    text: 'Search',
    category: 'Search',
    path: 'search',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: false,
    component: SearchComponent,
    resolve: {
      p: ExtensionsResolver
    }
  },
  {
    name: 'Reports',
    text: 'Reports',
    category: 'Reports',
    path: 'reports',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: false,
    component: ReportComponent
  },
  //{
  //  name: 'Tools', text: 'Copy Activity Information', category: 'Tools', path: 'toolscopyactivityinfo', component: CopyActivityInfoComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: {
  //    a: PropertiesResolver
  //  }
  //},
  {
    name: 'Tools',
    text: 'Copy Credit Lines',
    category: 'Tools',
    path: 'toolscopycreditlines',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: true,
    component: CopyCreditLinesComponent
  },
  {
    name: 'Tools',
    text: 'Copy Property Information',
    category: 'Tools',
    path: 'toolscopypropertyinfo',
    //    component: CopyPropertyInfoComponent,
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: true,
    component: CopyPropertyInfoComponent
  },
  {
    name: 'Tools',
    text: 'Replace Contacts',
    category: 'Tools',
    path: 'toolsreplacecontacts',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: true,
    component: ReplaceContactsComponent
  },
  { name: 'Administration', text: 'Client Categories', category: 'Administration', path: 'productcategories', component: ProductCategoriesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Clients', category: 'Administration', path: 'clients', component: ClientsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Contact Types', category: 'Administration', path: 'contacttypes', component: ContactTypesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { data: ContactTypesResolver } },
  { name: 'Administration', text: 'Control Panel', category: 'Administration', path: 'controlpanel', component: ControlPanelComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  //{ name: 'Administration', text: 'Departments', category: 'Administration', path: 'departments', component: DepartmentsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { data: DepartmentsResolver } },
  //{ name: 'Administration', text: 'Digital Dependency Types', category: 'Administration', path: 'onlinedependencytypes', component: OnlineDependencyTypesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { data: OnlineDependencyTypesResolver } },
  { name: 'Administration', text: 'Day Parts', category: 'Administration', path: 'dayparts', component: DayPartsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Event Categories', category: 'Administration', path: 'eventcategories', component: EventCategoriesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Extensions', category: 'Administration', path: 'extensions', component: ExtensionsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Jobs', category: 'Administration', path: 'jobs', component: JobsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { x: JobActionsResolver, y: JobTypesResolver, z: JobDocumentTypesResolver } },
  { name: 'Administration', text: 'Mechanic Types', category: 'Administration', path: 'mechanictypes', component: MechanicTypesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Notification Groups', category: 'Administration', path: 'notifygroups', component: NotifyGroupsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { x: NotifyGroupsResolver, y: NotifyGroupTypesResolver } },
  { name: 'Administration', text: 'Notification Group Members', category: 'Administration', path: 'notifygroupmembers', component: NotifyGroupMembersComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { x: NotifyGroupsResolver, y: NotifyGroupTypesResolver, z: StationsAdminResolver } },
  { name: 'Administration', text: 'Platform Providers', category: 'Administration', path: 'platforms', component: StationsComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { a: StationsAdminResolver, b: StationTypesAdminResolver } },
  { name: 'Administration', text: 'Platform Groups', category: 'Administration', path: 'platformgroups', component: StationTypesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { data: StationTypesAdminResolver } },
  { name: 'Administration', text: 'Property Types', category: 'Administration', path: 'propertytypes', component: PropertyTypesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true },
  { name: 'Administration', text: 'Roles', category: 'Administration', path: 'roles', component: RolesComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { a: RolesResolver, b: PermissionsResolver } },
  { name: 'Administration', text: 'Users', category: 'Administration', path: 'users', component: UsersComponent, canActivate: [AuthGuard], visible: true, showPageHeader: true, resolve: { a: RolesResolver, c: ContactTypesResolver, e: StationsAdminResolver, g: SupervisorsResolver } },
  //{ name: 'Administration', text: 'Current Users', category: 'Administration', path: 'currentUsers', component: CurrentUsersComponent, canActivate: [AuthGuard], visible: true },
  {
    name: 'Audit',
    text: 'Audit',
    category: 'Audit',
    path: 'audit',
    canActivate: [AuthGuard],
    visible: true,
    showPageHeader: false,
    component: AuditComponent
  },
  {
    name: 'Home',
    text: '',
    category: '',
    path: 'home',
    pathMatch: 'full',
    visible: false,
    showPageHeader: false,
    component: HomeComponent,
    resolve: {
   //   a: DashboardResolver
    }
  },
  { name: 'Signin-callback', text: '', category: '', path: 'signin-callback', component: SigninRedirectCallbackComponent, visible: false, showPageHeader: false },
  { name: 'Signout-callback', text: '', category: '', path: 'signout-callback', component: SignoutRedirectCallbackComponent, visible: false, showPageHeader: false },
  { name: 'Unauthorised', text: '', category: '', path: 'unauthorised', component: UnauthorisedComponent, visible: false, showPageHeader: false },
  { name: 'Login', text: '', category: '', path: 'login', component: LoginComponent, visible: false, showPageHeader: false },
  //{ name: 'Register', text: '', category: '', path: 'register', component: RegisterComponent, visible: false, showPageHeader: false },
  { name: '', text: '', category: '', path: '', redirectTo: 'home', pathMatch: 'full', visible: false, showPageHeader: false },
  {
    name: '',
    text: '',
    category: '',
    path: '**',
    visible: false,
    showPageHeader: false,
    component: UnauthorisedComponent
  }
];





export interface ClientRoute extends Route
{
  name: string,
  text: string,
  category: string,
  children?: ClientRoutes,
  visible: boolean
  showPageHeader: boolean
}

export declare type ClientRoutes = ClientRoute[];

export class RouteCategory
{
  public CategoryName = "";
  public Path = "";
  public Text = "";
  public RoutesInCategory = 0;
}
